/* eslint-disable no-useless-concat */
import DateFnsUtils from "@date-io/date-fns";
import { AppBar, Button, CircularProgress, createMuiTheme, Grid, IconButton, MenuItem, Paper, Tab, Tabs, Typography, useMediaQuery } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import BuildIcon from "@material-ui/icons/Build";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { default as Edit, default as EditIcon } from "@material-ui/icons/Edit";
import PowerInputIcon from "@material-ui/icons/PowerInput";
import DenseIcon from "@material-ui/icons/Reorder";
import SaveAlt from "@material-ui/icons/SaveAlt";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import Axios from "axios";
import { startOfMonth } from "date-fns";
import { addHours, endOfMonth, endOfWeek, endOfYear, setHours, startOfWeek, startOfYear } from "date-fns/esm";
import MaterialTable, { MTableCell, MTableHeader, MTableToolbar } from "material-table";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import bgImage from "./../bg_1.jfif";
import CustomSnackbar from "./CustomSnackbar";
import "./EditEntries.css"

function EditEntries(props) {
  props.currentPathCallbackProp(props.location.pathname);
  props.headerNameProp("Edit entries");

  const classes = useStyles(props);
  const today = new Date();
  const tabLabels = [<BuildIcon />, "REPAIRS"];
  const pageOptions = [5, 10, 25, 50, 100];
  const employeesGETRef = useRef(false);
  const emptyDataMessage = props.t("EditEntries.NotFound");
  const defaultPageNumbers = 5;
  const snackbarMessage = props.t("EditEntries.Snackbar");
  const autoHideDuration = 1250;
  const minWidth = useMediaQuery("(min-width:400px)");
  const inputsm = useMediaQuery(theme.breakpoints.down("sm"));
  const [tab, setTab] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [listOfEmployees, setListOfEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [denseMode, setDenseMode] = useState(false);
  const [inputData, setInputData] = useState({
    employeeName: "",
    startDate: addHours(setHours(new Date(), 0), -18),
    endDate: addHours(setHours(new Date(), 0), 24)
  });
  const [employeeInfo, setEmployeeInfo] = useState({
    employeeId: ""
  });
  const [repairsTableState, setRepairsTableState] = useState({
    columns: [
      { title: props.t("EditEntries.ID"), field: "id", editable: "never" },
      { title: props.t("EditEntries.DateStart"), field: "dateStart", editable: "never" },
      { title: props.t("EditEntries.DateEnd"), field: "dateEnd", editable: "never" },
      { title: props.t("EditEntries.Customer"), field: "customerName", editable: "never" },
      { title: props.t("EditEntries.Division"), field: "divisionName", editable: "never" },
      { title: props.t("EditEntries.Activity"), field: "activityName", editable: "never" },
      {
        title: props.t("EditEntries.Quantity"),
        field: "quantity",
        type: "numeric"
        // cellStyle: {
        //   backgroundColor: "rgba(237, 237, 237, 1)"
        // }
      },
      { title: props.t("EditEntries.EditHistory"), field: "editHistory", editable: "never" },
      { title: "Leave", field: "leave", editable: "never", hidden: true }
    ],
    data: [],
    notFound: false
  });

  const tableTheme = createMuiTheme({
    palette: {
      primary: { main: blue[500] },
      secondary: { main: blue[500] }
    },
    overrides: {
      // MuiTableCell: {
      //   root: {
      //     "&:hover": {
      //       backgroundColor: "rgba(33, 150, 243, 0.25)"
      //     }
      //   }
      // },
      MuiIconButton: {
        root: {
          padding: denseMode ? "0x" : "9px"
        }
      },
      PrivateSwitchBase: {
        root: {
          padding: denseMode ? "0px" : "9px"
        }
      }
    }
  });

  function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  function closeSnackbarCallback() {
    setShowSnackbar(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setLoadingTableData(false);
  }, [repairsTableState]);

  async function employeesGET() {
    const address = props.baseAddressProp + "/Database" + "/GetEmployeesInfo";
    try {
      let result = await Axios.get(address);
      setListOfEmployees(result.data);
    } catch (error) {}
  }

  async function repairsGET(employeeId, timeStart, timeEnd) {
    if (!timeStart || !timeEnd || isNaN(timeStart.getTime()) || isNaN(timeStart.getTime())) return null;

    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address =
      props.baseAddressProp + "/Repairs" + "/GetEmployeeRepairInfoTime" + "?employeeId=" + employeeId + "&timeStart=" + timeStart + "&timeEnd=" + timeEnd;

    setLoadingTableData(true);
    try {
      let result = await Axios.get(address);
      let repairsTable = { ...repairsTableState };
      repairsTable.data = result.data;

      for (let i = 0; i < result.data.length; i++) {
        let amounts = [];
        let amountsString;
        for (let j = 0; j < result.data[i].editHistory.length; j++) {
          let value = result.data[i].editHistory[j].amount;
          if (value) {
            amounts.push(value);
            if (!amountsString) {
              amountsString = value.toString();
            } else {
              amountsString = amountsString + ", " + value.toString();
            }
          }
        }
        if (amounts.length <= 1) {
          amountsString = "";
        }
        repairsTable.data[i].editHistory = amountsString;
      }
      repairsTable.notFound = false
      setRepairsTableState(repairsTable);
    } catch (error) {
      let repairsTable = { ...repairsTableState };
      repairsTable.data = [];
      repairsTable.notFound = true
      setRepairsTableState(repairsTable);
    }
  }

  async function timesheetTableEntryPOST(newData) {
    const address = props.baseAddressProp + "/Repairs" + "/PostTimesheetTableEntry";
    try {
      await Axios.post(address, {
        TimesheetId: newData.id,
        Quantity: newData.quantity
      });
      repairsGET(props.userDataProp.id, inputData.startDate, inputData.endDate);
    } catch (error) {}
  }

  async function timesheetTableEntryDELETE(timesheetId, data) {
    //setLoading(true);
    const address = props.baseAddressProp + "/Database" + "/DeleteTimesheetInfo" + "?timesheetId=" + timesheetId;
    try {
      await Axios.delete(address);
      setRepairsTableState({ ...repairsTableState, data });
      setShowSnackbar(true);
    } catch (error) {
      //setLoading(false);
      // setDuplicateIDErrorMessage(false);
      // setIDGreaterThan0(false);
      // setRemoveErrorMessage(true);
    }
  }

  function handleChange(event, newValue) {
    setTab(newValue);
  }

  function handleInputDataChange(event, name) {
    setEmployeeInfo(event.target.value);
    setInputData({ ...inputData, [name]: event.target.value });
  }

  function handleDatePickerChange(date) {
    //setSelectedDate(date);
    let currentDate = { ...inputData };
    currentDate["startDate"] = date;
    currentDate["endDate"] = addHours(date, 24);
    setInputData(currentDate);
  }

  function handleStartDatePickerChange(date) {
    setInputData({ ...inputData, ["startDate"]: date });
  }

  function handleEndDatePickerChange(date) {
    setInputData({ ...inputData, ["endDate"]: date });
  }

  function handleStartEndDatePickerChange(start, end) {
    let data = { ...inputData };
    data["startDate"] = start;
    data["endDate"] = end;
    setInputData(data);
  }

  function insertTabs() {
    let items = [];
    for (let i = 0; i < tabLabels.length; i++) {
      if (i === 0) {
        items.push(<Tab label={tabLabels[i]} className={classes.tab} key={i} classes={{ root: classes.tab1 }} />);
      } else {
        items.push(<Tab label={tabLabels[i]} className={classes.tab} key={i} />);
      }
    }
    return items;
  }

  function insertMenuItems(arg) {
    let items = [];
    for (let i = 0; i < arg.length; i++) {
      items.push(
        <MenuItem value={arg[i]} key={i}>
          {`[${arg[i].employeeId}] ${arg[i].firstName} ${arg[i].lastName}`}
        </MenuItem>
      );
    }
    return items;
  }

  function weekMonthYear(employeeId, startDate, endDate) {
    if (!employeeId) {
      return null;
    }
    // dailyProductivityGET(employeeId, startDate, endDate);
    // productivityPerTaskGET(employeeId, startDate, endDate);

    //timesheet get with date etc..
  }

  function renderRepairs() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <div className={classes.appBarPosition}>
            <AppBar position="static" style={{ boxShadow: "none", minHeight: 64 }}>
              <Tabs
                value={tab}
                onChange={handleChange}
                TabIndicatorProps={{
                  style: {
                    height: tab === 0 ? "0px" : "2px"
                  }
                }}
              >
                {insertTabs()}
              </Tabs>
            </AppBar>
          </div>
          {tab === 0 ? renderLaunchPage() : null}
          {tab === 1 ? renderRepairsPage() : null}
        </ThemeProvider>
      </div>
    );
  }

  function closeSnackbarCallback() {
    setShowSnackbar(false);
  }

  function renderLaunchPage() {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.background}>
          <div className={classes.paperPositionHome}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h2" className={classes.title} style={{fontWeight:"400"}}>
                    Repairs
                    <BuildIcon className={classes.iconStyle} />
                  </Typography>

                  <Typography variant="h5" className={classes.subTitle}>
                    Alter quantities submitted by your employees
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </div>
      </ThemeProvider>
    );
  }

  function renderRepairsPage() {
    if (!employeesGETRef.current) {
      employeesGET();
      employeesGETRef.current = true;
    }
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.paperPosition}>
          {inputsm ? (
            // #region MOBILE
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper
                  className={classes.paperPropsMobile}
                  classes={{
                    rounded: classes.paperShape,
                    elevation1: classes.paperShadow
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={3}>
                      <Grid container style={{ padding: "12px" }}>
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Typography variant="h6" style={{ display: "inline-block", fontSize: minWidth ? "1.25rem" : "5vmin" }}>
                              {props.t("EditEntries.EditEntriesText")}
                            </Typography>
                          </Grid>

                          <Grid item>
                            <EditIcon style={{ position: "relative", top: "3px", left: "8px", fontSize: minWidth ? "1.75rem" : "6vmin" }} />
                          </Grid>
                          <IconButton
                            onClick={event => {
                              setShowAdvanced(!showAdvanced);
                            }}
                            style={{ marginLeft: "auto", marginRight: "0px", paddingRight: "0px" }}
                            className={classes.noHover}
                          >
                            <PowerInputIcon style={{ fontSize: minWidth ? "2rem" : "7vmin", color: "#2196f3" }} />
                          </IconButton>
                        </Grid>
                      </Grid>

                      {showAdvanced ? (
                        <React.Fragment>
                          <Grid item xs={12} style={{ paddingTop: "0px" }}>
                            <KeyboardDatePicker
                              fullWidth
                              margin="dense"
                              id="mui-pickers-date"
                              label={props.t("EditEntries.StartDate")}
                              inputVariant="outlined"
                              format="dd/MM/yyyy"
                              value={inputData.startDate}
                              onChange={(event, date) => {
                                handleStartDatePickerChange(event, date);
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} style={{ paddingTop: "0px" }}>
                            <KeyboardDatePicker
                              fullWidth
                              margin="dense"
                              id="mui-pickers-date"
                              label={props.t("EditEntries.EndDate")}
                              inputVariant="outlined"
                              format="dd/MM/yyyy"
                              value={inputData.endDate}
                              onChange={(event, date) => {
                                handleEndDatePickerChange(event, date);
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </Grid>

                          <Grid container style={{ marginLeft: "14px", paddingBottom: "12px" }}>
                            <Grid item xs={12}>
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginRight: "10px" }}
                                onClick={event => {
                                  const today = new Date();
                                  const start = startOfWeek(today, { weekStartsOn: 1 });
                                  const end = endOfWeek(today, { weekStartsOn: 1 });
                                  setInputData({...inputData, 
                                    startDate: start,
                                    endDate: end
                                  });
                                }}
                              >
                                {props.t("EditEntries.Week")}
                              </Button>

                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginRight: "10px" }}
                                onClick={event => {
                                  const today = new Date();
                                  const start = startOfMonth(today);
                                  const end = endOfMonth(today);
                                  setInputData({...inputData, 
                                    startDate: start,
                                    endDate: end
                                  });
                                }}
                              >
                                {props.t("EditEntries.Month")}
                              </Button>

                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginRight: "10px" }}
                                onClick={event => {
                                  const today = new Date();
                                  const start = startOfYear(today);
                                  const end = endOfYear(today);
                                  setInputData({...inputData, 
                                    startDate: start,
                                    endDate: end
                                  });
                                }}
                              >
                                {props.t("EditEntries.Year")}
                              </Button>
                            </Grid>
                            <div className="search-button-container">
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginRight: "10px" }}
                                disabled={loadingTableData}
                                className="no-shadow button-padding"
                                onClick={event => {
                                  repairsGET(props.userDataProp.id, inputData.startDate, inputData.endDate);
                                }}>
                                {props.t("Productivity.Search")}
                              </Button>
                            </div>
                          </Grid>
                          
                        </React.Fragment>
                      ) : (
                        <Grid item xs={12} style={{ paddingTop: "0px" }}>
                          <KeyboardDatePicker
                            fullWidth
                            margin="dense"
                            id="mui-pickers-date"
                            label={props.t("EditEntries.Date")}
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            shouldDisableDate={disableWeekends}
                            value={inputData.startDate}
                            onChange={(event, date) => {
                              handleDatePickerChange(event, date);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                          <div className="search-button-container">
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginRight: "10px" }}
                              className="no-shadow button-padding"
                              disabled={loadingTableData}
                              onClick={event => {
                                repairsGET(props.userDataProp.id, inputData.startDate, inputData.endDate);
                              }}>
                              {props.t("Productivity.Search")}
                            </Button>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Paper>
              </Grid>

              <React.Fragment>
                <Grid item xs={12}>
                  <Paper
                    className={classes.paperPropsMobile}
                    classes={{
                      rounded: classes.paperShape,
                      elevation1: classes.paperShadow
                    }}
                  >
                    {loadingTableData ? (
                      <Grid item xs={12} align="center">
                        <div style={{ position: "relative" }}>
                          <CircularProgress />
                        </div>
                      </Grid>
                    ) : (
                      <React.Fragment>
                        <React.Fragment>
                        {repairsTableState.notFound ? (
                          <Grid item xs={12} style={{paddingBottom: "20px"}}>
                            <Typography variant="h5" className={classes.subTitleText}>
                              {props.t("Repairs.NotFound")}
                            </Typography>
                          </Grid>) : (
                            <React.Fragment>
                              {repairsTableState.data.length === 0 ? (
                                <Grid item xs={12} style={{paddingBottom: "20px"}}>
                                  <Typography variant="h5" className={classes.subTitleText}>
                                    {props.t("Repairs.PressSearch")}
                                  </Typography>
                                </Grid>) : (
                              <Grid item xs={12}>
                                {renderTable(repairsTableState.data)}
                              </Grid>)}
                            </React.Fragment>)}
                      </React.Fragment>

                        <Grid item xs={12} direction="row" alignItems="center" style={{ marginBottom: "0px", marginTop: "12px", display: "flex" }}>
                          <Link to="/Timesheet" style={{ textDecoration: "none", width: "50%" }}>
                            <Button variant="contained" color="primary" style={{ width: "100%" }} className="no-shadow button-padding">
                              {props.t("EditEntries.AddEntry")}
                            </Button>
                          </Link>
                          <Link to="/Home" style={{ textDecoration: "none", width: "50%", marginLeft: "12px" }}>
                            <Button variant="outlined" color="primary" style={{ width: "100%" }}>
                              {props.t("EditEntries.Back")}
                            </Button>
                          </Link>
                        </Grid>
                      </React.Fragment>
                    )}

                    {loading ? (
                      <Grid item xs={12} align="center">
                        <div style={{ position: "relative" }}>
                          <CircularProgress />
                        </div>
                      </Grid>
                    ) : (
                      <React.Fragment />
                    )}
                  </Paper>
                </Grid>
              </React.Fragment>
              {/* )} */}
            </Grid>
          ) : (
            // #endregion
            // #region DESKTOP
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Paper
                  className={classes.paperProps}
                  classes={{
                    rounded: classes.paperShape,
                    elevation1: classes.paperShadow
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container spacing={3}>
                      <Grid container style={{ padding: "12px" }}>
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Typography variant="h6" style={{ display: "inline-block" }}>
                              {props.t("EditEntries.EditEntriesText")}
                            </Typography>
                          </Grid>

                          <Grid item>
                            <EditIcon style={{ position: "relative", top: "3px", left: "8px", fontSize: "1.75rem" }} />
                          </Grid>

                          <Button
                            variant="text"
                            style={{
                              color: blue[500],
                              marginBottom: 0,
                              fontSize: "1.0rem",
                              display: "inline-block",
                              float: "right",
                              paddingTop: "4px",
                              marginLeft: "auto",
                              marginRight: "0px"
                            }}
                            onClick={event => {
                              setShowAdvanced(!showAdvanced);
                            }}
                          >
                            {showAdvanced ? props.t("EditEntries.HideAdvanced") : props.t("EditEntries.ShowAdvanced")}
                          </Button>

                          {/* <Grid item>
                                <TocIcon style={{ position: "relative", top: "3px", left: "8px", fontSize: "1.75rem"}} />
                            </Grid> */}
                        </Grid>
                      </Grid>

                      {showAdvanced ? (
                        <React.Fragment>
                          <Grid item xs={6}>
                            <KeyboardDatePicker
                              fullWidth
                              margin="normal"
                              id="mui-pickers-date"
                              label={props.t("EditEntries.StartDate")}
                              inputVariant="outlined"
                              format="dd/MM/yyyy"
                              value={inputData.startDate}
                              onChange={(event, date) => {
                                handleStartDatePickerChange(event, date);
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <KeyboardDatePicker
                              fullWidth
                              margin="normal"
                              id="mui-pickers-date"
                              label={props.t("EditEntries.EndDate")}
                              inputVariant="outlined"
                              format="dd/MM/yyyy"
                              value={inputData.endDate}
                              onChange={(event, date) => {
                                handleEndDatePickerChange(event, date);
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </Grid>

                          <Grid container style={{ marginLeft: "14px", paddingBottom: "24px" }}>
                            <Grid item xs={12}>
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginRight: "10px" }}
                                onClick={event => {
                                  const today = new Date();
                                  const start = startOfWeek(today, { weekStartsOn: 1 });
                                  const end = endOfWeek(today, { weekStartsOn: 1 });
                                  setInputData({...inputData, 
                                    startDate: start,
                                    endDate: end
                                  });
                                }}
                              >
                                {props.t("EditEntries.Week")}
                              </Button>

                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginRight: "10px" }}
                                onClick={event => {
                                  const today = new Date();
                                  const start = startOfMonth(today);
                                  const end = endOfMonth(today);
                                  setInputData({...inputData, 
                                    startDate: start,
                                    endDate: end
                                  });
                                }}
                              >
                                {props.t("EditEntries.Month")}
                              </Button>

                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginRight: "10px" }}
                                onClick={event => {
                                  const today = new Date();
                                  const start = startOfYear(today);
                                  const end = endOfYear(today);
                                  setInputData({...inputData, 
                                    startDate: start,
                                    endDate: end
                                  });
                                }}
                              >
                                {props.t("EditEntries.Year")}
                              </Button>
                            </Grid>
                            <div className="search-button-container">
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginRight: "10px" }}
                                className="no-shadow button-padding"
                                disabled={loadingTableData}
                                onClick={event => {
                                  repairsGET(props.userDataProp.id, inputData.startDate, inputData.endDate);
                                }}>
                                {props.t("Productivity.Search")}
                              </Button>
                            </div>
                          </Grid>
                        </React.Fragment>
                      ) : (
                        <Grid item xs={12}>
                          <KeyboardDatePicker
                            fullWidth
                            margin="normal"
                            id="mui-pickers-date"
                            label={props.t("EditEntries.Date")}
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            shouldDisableDate={disableWeekends}
                            value={inputData.startDate}
                            onChange={(event, date) => {
                              handleDatePickerChange(event, date);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                          <div className="search-button-container">
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginRight: "10px" }}
                              className="no-shadow button-padding"
                              disabled={loadingTableData}
                              onClick={event => {
                                repairsGET(props.userDataProp.id, inputData.startDate, inputData.endDate);
                              }}>
                              {props.t("Productivity.Search")}
                            </Button>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Paper>
              </Grid>

              <React.Fragment>
                <Grid item xs={12}>
                  <Paper
                    className={classes.paperProps}
                    classes={{
                      rounded: classes.paperShape,
                      elevation1: classes.paperShadow
                    }}
                  >
                    {loadingTableData ? (
                      <Grid item xs={12} align="center">
                        <div style={{ position: "relative" }}>
                          <CircularProgress />
                        </div>
                      </Grid>
                    ) : (
                      <React.Fragment>
                        <React.Fragment>
                        {repairsTableState.notFound ? (
                          <Grid item xs={12} style={{paddingBottom: "20px"}}>
                            <Typography variant="h5" className={classes.subTitleText}>
                              {props.t("Repairs.NotFound")}
                            </Typography>
                          </Grid>) : (
                            <React.Fragment>
                              {repairsTableState.data.length === 0 ? (
                                <Grid item xs={12} style={{paddingBottom: "20px"}}>
                                  <Typography variant="h5" className={classes.subTitleText}>
                                    {props.t("Repairs.PressSearch")}
                                  </Typography>
                                </Grid>) : (
                              <Grid item xs={12}>
                                {renderTable(repairsTableState.data)}
                              </Grid>)}
                            </React.Fragment>)}
                      </React.Fragment>

                        <Grid item xs={12} direction="row" alignItems="center" style={{ marginBottom: "24px", marginTop: "12px" }}>
                          <Link to="/Timesheet" style={{ textDecoration: "none" }}>
                            <Button variant="contained" color="primary" className="no-shadow button-padding">
                              {props.t("EditEntries.AddEntry")}
                            </Button>
                          </Link>

                          <Link to="/Home" style={{ textDecoration: "none" }}>
                            <Button variant="outlined" color="primary" style={{ marginLeft: "12px" }}>
                              {props.t("EditEntries.ToDashboard")}
                            </Button>
                          </Link>
                        </Grid>
                      </React.Fragment>
                    )}

                    {loading ? (
                      <Grid item xs={12} align="center">
                        <div style={{ position: "relative" }}>
                          <CircularProgress />
                        </div>
                      </Grid>
                    ) : (
                      <React.Fragment />
                    )}
                  </Paper>
                </Grid>
              </React.Fragment>
              {/* )} */}
            </Grid>
            // #endregion
          )}
        </div>

        <div>
          <CustomSnackbar
            showSnackbarProp={showSnackbar}
            closeSnackbarCallbackProp={closeSnackbarCallback}
            snackbarMessageProp={snackbarMessage}
            autoHideDurationProp={autoHideDuration}
          />
        </div>
      </ThemeProvider>
    );
  }

  function renderTable(tableData) {
    return (
      <div>
        {inputsm ? (
          // #region MOBILE
          <MuiThemeProvider theme={tableTheme}>
            <MaterialTable
              components={{
                Toolbar: props => (
                  <MTableToolbar classes={{ root: classes.titlePadding, searchField: classes.searchfield, spacer: classes.spacer }} {...props} />
                ),
                Cell: props => <MTableCell classes={{ root: classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: classes.headerStyling }} {...props} />
              }}
              title={props.t("EditEntries.Entries").toUpperCase()}
              columns={repairsTableState.columns}
              data={repairsTableState.data}
              icons={{
                Edit: forwardRef((props, ref) => (
                  <Edit {...props} ref={ref} style={{ color: "rgb(117, 117, 117)", paddingBottom: "0px", paddingTop: "0px" }} />
                )),
                Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ margin: denseMode ? "9px" : "0px" }} />),
                Delete: forwardRef((props, ref) => <DeleteForever {...props} ref={ref} style={{ color: "rgb(117, 117, 117)" }} />)
              }}
              actions={[
                {
                  tooltip: "Dense mode",
                  icon: () => <DenseIcon style={{ color: denseMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px" }} />,
                  isFreeAction: true,
                  onClick: (event, tableData) => {
                    let dense = denseMode;
                    setDenseMode(!dense);
                  }
                }
              ]}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    const data = [...repairsTableState.data];
                    const index = data.indexOf(oldData);
                    data[index] = newData;
                    setRepairsTableState({ ...repairsTableState, data });
                    timesheetTableEntryPOST(newData);
                    //repairsTablePOST()
                    resolve();
                  }),

                onRowDelete: oldData =>
                  new Promise(resolve => {
                    resolve();
                    const data = [...repairsTableState.data];
                    data.splice(data.indexOf(oldData), 1);
                    timesheetTableEntryDELETE(oldData.id, data);
                  })
              }}
              localization={{
                header: {
                  actions: props.t("EditEntries.Actions")
                },
                pagination: {
                  labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                  labelRowsSelect: props.t("Database.Rows"),
                  firstTooltip: props.t("EditEntries.FirstPage"),
                  previousTooltip: props.t("EditEntries.PreviousPage"),
                  nextTooltip: props.t("EditEntries.NextPage"),
                  lastTooltip: props.t("EditEntries.LastPage")
                },
                toolbar: {
                  nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                  searchPlaceholder: props.t("EditEntries.Search"),
                  searchTooltip: props.t("EditEntries.Search"),
                  exportTitle: props.t("EditEntries.Export"),
                  exportName: props.t("EditEntries.Export")
                },
                body: {
                  editRow: {
                    deleteText: ""
                  },
                  addTooltip: "Add row",
                  deleteToolTip: "Delete row",
                  editToolTip: "Edit row",
                  emptyDataSourceMessage: emptyDataMessage
                }
              }}
              onRowClick={(event, row) => {}}
              options={{
                exportButton: true,
                filtering: false,
                showTitle: false,
                search: true,
                sorting: true,
                pageSize: defaultPageNumbers,
                pageSizeOptions: pageOptions,
                padding: "dense",
                rowStyle: (rowData, index) => {
                  return {
                    background:
                      index % 2 === 0
                        ? rowData.editHistory
                          ? "linear-gradient(45deg, rgba(242, 242, 242,1), rgba(85, 243, 33, 0.3))"
                          : "rgba(242, 242, 242, 1)"
                        : rowData.editHistory
                        ? "linear-gradient(45deg, rgba(255, 255, 255,1), rgba(85, 243, 33, 0.3))"
                        : "rgba(255, 255, 255, 1)"
                  };
                }
              }}
              style={{ boxShadow: "none" }}
              isLoading={loading}
              // #endregion
            />
          </MuiThemeProvider>
        ) : (
          // #region DESKTOP
          <MuiThemeProvider theme={tableTheme}>
          <MaterialTable
            components={{
              Toolbar: props => <MTableToolbar classes={{ root: classes.titlePadding }} {...props} />,
              Cell: props => <MTableCell classes={{ root: classes.cellStyling }} {...props} />,
              Header: props => <MTableHeader classes={{ header: classes.headerStyling }} {...props} />
            }}
            title={props.t("EditEntries.Entries").toUpperCase()}
            columns={repairsTableState.columns}
            data={repairsTableState.data}
            icons={{
              Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ color: "rgb(117, 117, 117)", paddingBottom: "0px", paddingTop: "0px" }} />),
              Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ margin: denseMode ? "9px" : "0px" }} />),
              Delete: forwardRef((props, ref) => <DeleteForever {...props} ref={ref} style={{ color: "rgb(117, 117, 117)" }} />)
            }}
            actions={[
              {
                tooltip: "Dense mode",
                icon: () => <DenseIcon style={{ color: denseMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px" }} />,
                isFreeAction: true,
                onClick: (event, tableData) => {
                  let dense = denseMode;
                  setDenseMode(!dense);
                }
              }
            ]}
            editable={{
              isEditable: rowData => rowData.leave === 0,
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...repairsTableState.data];
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  setRepairsTableState({ ...repairsTableState, data });
                  timesheetTableEntryPOST(newData);
                  //repairsTablePOST()
                  resolve();
                }),

              onRowDelete: oldData =>
                new Promise(resolve => {
                  resolve();
                  const data = [...repairsTableState.data];
                  data.splice(data.indexOf(oldData), 1);
                  timesheetTableEntryDELETE(oldData.id, data);
                })
            }}
            onRowClick={(event, row) => {}}
            options={{
              exportButton: true,
              filtering: false,
              search: true,
              sorting: true,
              pageSize: defaultPageNumbers,
              pageSizeOptions: pageOptions,
              padding: "dense",
              rowStyle: (rowData, index) => {
                return {
                  background:
                    (rowData.leave === 1 || rowData.leave === 2) ?
                      "rgba(243, 177, 71, 0.75)":

                    (index % 2 === 0
                      ? rowData.editHistory
                        ? "linear-gradient(45deg, rgba(242, 242, 242,1), rgba(85, 243, 33, 0.3))"
                        : "rgba(242, 242, 242, 1)"
                      : rowData.editHistory
                      ? "linear-gradient(45deg, rgba(255, 255, 255,1), rgba(85, 243, 33, 0.3))"
                      : "rgba(255, 255, 255, 1)")
                };
              }
            }}
            style={{ boxShadow: "none" }}
            localization={{
              header: {
                actions: props.t("EditEntries.Actions")
              },
              pagination: {
                labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                labelRowsSelect: props.t("Database.Rows"),
                firstTooltip: props.t("EditEntries.FirstPage"),
                previousTooltip: props.t("EditEntries.PreviousPage"),
                nextTooltip: props.t("EditEntries.NextPage"),
                lastTooltip: props.t("EditEntries.LastPage")
              },
              toolbar: {
                nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                searchPlaceholder: props.t("EditEntries.Search"),
                searchTooltip: props.t("EditEntries.Search"),
                exportTitle: props.t("EditEntries.Export"),
                exportName: props.t("EditEntries.Export")
              },
              body: {
                editRow: {
                  deleteText: ""
                },
                addTooltip: "Add row",
                deleteToolTip: "Delete row",
                editToolTip: "Edit row",
                emptyDataSourceMessage: emptyDataMessage
              }
            }}
            isLoading={loading}
            // #endregion
          />
          </MuiThemeProvider>
        )}
      </div>
    );
  }

  return renderRepairsPage();
}

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] },
    secondary: { main: "#FFFFFF" }
  }
});

const drawerWidth = 250;
const minToolbarHeight = 64;
const tabsHeight = 64;

const useStyles = makeStyles(theme => ({
  tab: {
    minHeight: 64
  },
  appBarPosition: {
    marginTop: minToolbarHeight,

    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      marginTop: minToolbarHeight
    }
  },
  launchPage: {
    paddingLeft: "40px",
    paddingTop: `calc(${minToolbarHeight}px + 40px)`,

    [theme.breakpoints.up("sm")]: {
      paddingLeft: `calc(${drawerWidth}px + 100px)`,
      paddingTop: `calc(${minToolbarHeight}px + 40px)`
    }
  },

  paperPosition: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: 64 + 16,
    marginBottom: "16px",

    [theme.breakpoints.up("lg")]: {
      marginLeft: 32,
      marginRight: "32px",
      marginTop: 64 + 32,
      marginBottom: "32px",
    }
  },

  background: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    marginTop: -130,

    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      width: "195%"
    },

    [theme.breakpoints.up("md")]: {
      width: "130%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%"
    }
  },

  paperPositionHome: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth + 32,
      marginRight: "32px",
      marginTop: "32px",
    }
  },

  paperShape: {
    borderRadius: 8
  },
  paperProps: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3)
    }
  },
  paperPropsMobile: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3)
    }
  },
  subTitleText: {
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    }
  },
  tab1: {
    minWidth: 80,
    width: 80
  },
  buttonProgress: {
    position: "absolute"
  },
  bg: {
    backgroundColor: "#FFFFFF"
  },
  titlePadding: {
    paddingRight: 0,
    paddingLeft: 0
  },
  title: {
    fontSize: "10vmin",
    paddingLeft: "0px",
    paddingTop: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3.75rem",
      paddingLeft: "7px",
      paddingTop: "10px"
    }
  },
  subTitle: {
    fontSize: "5vmin",
    marginBottom: 20,
    paddingLeft: "0px",
    paddingTop: "10px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem",
      marginBottom: 35,
      paddingLeft: "10px",
      paddingTop: "10px"
    }
  },
  iconStyle: {
    position: "relative",
    top: "5px",
    left: "10px",
    fontSize: "unset",
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      top: "10px",
      left: "10px",
      fontSize: "unset"
    }
  },
  noHover: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  cellStyling: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "0.75rem"
  },
  headerStyling: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "0.75rem"
  },
  searchfield: {
    padding: "0px"
  },

  spacer: {
    flex: "1 1 0%"
  }
}));

export default withRouter(EditEntries);
