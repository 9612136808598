import { createMuiTheme, ListItemIcon, Menu, MenuItem, Tooltip } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { blue } from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InputIcon from "@material-ui/icons/Input";
import LanguageIcon from "@material-ui/icons/Language";
import MenuIcon from "@material-ui/icons/Menu";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { ThemeProvider } from "@material-ui/styles";
import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import "typeface-roboto";
import en1 from "./../en.png";
import pl1 from "./../pl.png";
import Axios from "axios";

function Header(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles(props);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function header() {
    return (
      <div className={classes.root} style={{ boxShadow: "none" }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppBar
            position="fixed"
            style={
              props.loggedInProp
                ? { boxShadow: "none" }
                : { backgroundColor: "rgba(0,0,0,0.15)", boxShadow: "none" }
            }
          >
            <Toolbar classes={{ regular: classes.toolbarRegular }}>
              {props.userDataProp.role === "Admin" || props.userDataProp.role === "Database admin" ? (
                <IconButton color="inherit" edge="start" onClick={props.toggleDrawerProp} className={classes.menuButton}>
                  <MenuIcon />
                </IconButton>
              ) : null}
              <Typography
                variant="h6"
                noWrap
                style={{ flexGrow: 1 }}
                classes={props.userDataProp.role === "Admin" || props.userDataProp.role === "Database admin" ? { root: classes.moveText } : null}
              >
                {props.headerNameProp}
              </Typography>

              {props.loggedInProp && props.userDataProp.role === "User" && (
                <React.Fragment>
                  <Tooltip title={props.t("Header.DashboardTooltip")} TransitionComponent={Zoom}>
                    <Link to={"./Home"} style={{ textDecoration: "none", color: "#FFFFFF" }} key={"Dashboard"}>
                      <IconButton color="inherit">
                        <DashboardIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </React.Fragment>
              )}

              {props.loggedInProp ? (
                <React.Fragment>
                  <Tooltip title={props.t("Header.MyAccountTooltip")} TransitionComponent={Zoom}>
                    <Link to={"./MyAccount"} style={{ textDecoration: "none", color: "#FFFFFF" }} key={"MyAccount"}>
                      <IconButton color="inherit">
                        <AccountCircle />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </React.Fragment>
              ) : null}
              <Tooltip title={props.t("Header.ChangeLanguageTooltip")} TransitionComponent={Zoom}>
                <IconButton color="inherit" onClick={handleClick}>
                  <LanguageIcon />
                </IconButton>
              </Tooltip>
              <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                  onClick={event => {
                    props.changeLanguageProp("en");
                    handleClose(event);
                  }}
                >
                  <ListItemIcon style={{ minWidth: "20px" }}>
                    <img src={en1} title="English flag icon" style={{ paddingBottom: "2px" }} alt="English flag" />
                  </ListItemIcon>
                  <Typography variant="inherit">EN</Typography>
                </MenuItem>
                <MenuItem
                  onClick={event => {
                    props.changeLanguageProp("pl");
                    handleClose(event);
                  }}
                >
                  <ListItemIcon style={{ minWidth: "20px" }}>
                    <img src={pl1} title="Polish flag icon" alt="Polish flag" />
                  </ListItemIcon>
                  <Typography variant="inherit">PL</Typography>
                </MenuItem>
              </Menu>

              {props.loggedInProp ? (
                <Tooltip title={props.t("Header.SignOutTooltip")} TransitionComponent={Zoom}>
                  <Link to={"/"} style={{ textDecoration: "none", color: "#FFFFFF" }} key={"Logout"}>
                    <IconButton color="inherit" onClick={ async () => await logOut()}>
                      <InputIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              ) : null}
            </Toolbar>
          </AppBar>
        </ThemeProvider>
      </div>
    );
  }

  async function logOut() {
    props.emptyUserDataProp();
    props.loggedInCallbackProp(false);
    // sessionStorage.setItem("loggedIn", JSON.stringify(false));
    sessionStorage.setItem("userData", "");
    await logOutPOST()
  }

  async function logOutPOST() {
    const address = props.baseAddressProp + "/Authentication" + "/Logout";
    await Axios.post(address);
  }

  return header();
}

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] }
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  moveText: {
    [theme.breakpoints.up("lg")]: {
      
      // paddingLeft: props => props.drawerWidthProp //`calc(${props => props.drawerWidthProp}px + 30px)`
      paddingLeft: props => props.drawerWidthProp + 8
    }
  },

  theme1: {
    // background: props => props.drawerColorProp, //"linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
    border: 0,
    borderRadius: 0,
    //boxShadow: "0 3px 5px 2px rgba(33, 150, 243, 0.3)",
    color: "white",
    borderColor: "yellow !important",
    borderWidth: "5px",
    marginLeft: props => props.drawerWidthProp,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${props => props.drawerWidthProp}px)`
    }
  },
  theme2: {
    // background: "linear-gradient(45deg, #2B2D3D 50%, #21CBF3 90%)",
    background: "linear-gradient(45deg, #2B2D3D 50%, #21CBF3 90%)",
    border: 0,
    borderRadius: 0,
    // boxShadow: "0 3px 5px 2px rgba(33, 150, 243, 0.3)",
    color: "white",
    borderColor: "yellow !important",
    borderWidth: "5px",
    marginLeft: props => props.drawerWidthProp,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${props => props.drawerWidthProp}px)`
    }
  },
  toolbarRegular: {
    minHeight: props => props.minToolbarHeightProp
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none"
    }
  },
  test: {
    width: "50%"
  },
  elevation: {
    boxShadow: "0 0px 0px 0px rgba(33, 150, 243, 0.3)"
  }
}));

export default withRouter(Header);
