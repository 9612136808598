import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5)
  },
  root: {
    backgroundColor: "rgba(67, 160, 71, 1)"
  }
}));

export default function CustomSnackbar(props) {
  const classes = useStyles();

  function handleClose(event, reason) {
    props.closeSnackbarCallbackProp();
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={props.showSnackbarProp}
        autoHideDuration={props.autoHideDurationProp}
        onClose={handleClose}
        ContentProps={{
          classes: {
            root: classes.root
          }
        }}
        message={
          <span id="message-id" style={{ display: "inline-flex" }}>
            <CheckCircleIcon style={{ marginRight: "10px" }} />
            <Typography variant="body1">
              {props.snackbarMessageProp}
            </Typography>
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  );
}
