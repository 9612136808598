import { Button, CircularProgress, createMuiTheme, Grid, IconButton, InputAdornment, Paper, TextField, Typography, useMediaQuery } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ThemeProvider } from "@material-ui/styles";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import bgImage from "./../bg_1.jfif";
import bgLandscape from "./../bg_1.jfif";
import ponefaLogo from "./../ponefaLogo.png";
//import MuiThemeProvider from "@material-ui/styles/MuiThemeProvider";
import Alert from '@material-ui/lab/Alert';
import "./Login.css"

Axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL

function Login(props) {
  props.currentPathCallbackProp(props.location.pathname);
  props.headerNameCallbackProp("");

  const classes = useStyles(props);
  const [fields, setFields] = useState({
    userName: "",
    password: "",
    showPassword: false
  });
  const fieldsRef = React.useRef()
  const [redirect, setRedirect] = useState(false);
  const addressExtension = "/Authentication";
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const inputsm = useMediaQuery(theme.breakpoints.down("sm"));
  const minWidth = useMediaQuery("(min-width:400px)");

  useEffect(() => {
    window.addEventListener("keypress", keyPress)
    return () => {
      window.removeEventListener("keypress", keyPress)
    }
  }, [])

  useEffect(() => {
    fieldsRef.current = fields;
  }, [fields])

  function keyPress(event) {
    if(event.key === "Enter") {
      loginPOST()
    }
  }

  function handleFieldChange(event, name) {
    setFields({ ...fields, [name]: event.target.value.trim() });
  }

  function handleClickShowPassword() {
    setFields({ ...fields, showPassword: !fields.showPassword });
  }

  async function loginPOST(index) {
    setShowErrorMessage(false);
    setLoading(true);
    const address = props.baseAddressProp + addressExtension + "/Login";
    try {
      let result = await Axios.post(address, {
        UserName: fieldsRef.current.userName ?? "",
        Password: fieldsRef.current.password ?? "",
        // GRecaptchaToken: document.querySelector("#grecaptcha").value,
      });

      if (result.data.token != null) {
        props.loggedInCallbackProp(true);
        props.tokenCallbackProp(result.data.token);
        props.userDataCallbackProp(result.data);
        setRedirect(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response?.data === "Username or password is not valid.") {
        setErrorMessage(props.t("SignInPage.Invalid"));
        setShowErrorMessage(true);
      } else {
        setErrorMessage(props.t("SignInPage.Unable"));
        setShowErrorMessage(true);
      }
    }
  }

  if (redirect) {
    return <Redirect to={"/Home"} style={{ textDecoration: "none" }} key={"ToHome"} />;
  } else {
    return (
      <React.Fragment>
        {inputsm ? (
          // #region MOBILE
          <ThemeProvider theme={theme}>
            <div className={classes.backgroundLandscape} style={{backgroundPosition: "40% 100%"}}>
              <div className={classes.paperPositionMobile}>
                <Paper
                  className={classes.paperPropsMobile}
                  classes={{
                    rounded: classes.paperShape,
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12} />
                    <Grid item xs={12} style={{ display: "flex" }}>
                      <Typography variant="h4" className="header4">
                        {props.t("SignInPage.SignIn")}
                      </Typography>
                      <img
                        src={ponefaLogo}
                        alt="Ponefa Logo"
                        style={{ position: "relative", top: "-17%", paddingLeft: 0, marginLeft: "auto", marginRight: "0px", maxHeight: "45px" }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="outlined-name"
                        label={props.t("SignInPage.Username")}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={fields.userName}
                        style={{ marginBottom: "8px" }}
                        onChange={event => handleFieldChange(event, "userName")}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="outlined-adornment-password"
                        variant="outlined"
                        type={fields.showPassword ? "text" : "password"}
                        label={props.t("SignInPage.Password")}
                        fullWidth
                        value={fields.password}
                        margin="dense"
                        onChange={event => handleFieldChange(event, "password")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                                {fields.showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: 15, paddingTop: "5px", display: "inherit" }}>
                      <Typography variant="caption" style={{ fontSize: minWidth ? "0.75rem" : "3vmin" }}>
                        {props.t("SignInPage.NoAccount")}{" "}
                        <Link to={"/Register"} style={{ textDecoration: "none", color: "#2296f3" }} key={"Register"}>
                          {props.t("SignInPage.CreateOne")}
                        </Link>
                      </Typography>

                      <Typography variant="caption" style={{ marginLeft: "auto", marginRight: "0px", fontSize: minWidth ? "0.75rem" : "3vmin" }}>
                        <Link to={"/PasswordRecovery"} style={{ color: "#2296f3", textDecoration: "none" }} key={"PasswordRecovery"}>
                          {props.t("SignInPage.ForgotPassword")}
                        </Link>
                      </Typography>
                    </Grid>

                    {showErrorMessage ? (
                      <Grid item xs={12} style={{ marginBottom: "12px" }}>
                        <Alert severity="error">
                          <b>{props.t("SignInPage.Error")} </b>
                          {errorMessage}
                        </Alert>
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <div className={classes.buttons}>
                        <Button variant="contained" color="primary" onClick={loginPOST} disabled={loading} className="no-shadow">
                          {props.t("SignInPage.SignIn")}
                          {loading && <CircularProgress size={24} style={{ top: "17%", position: "absolute" }} />}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </div>
          </ThemeProvider>
        ) : (
          // #endregion
          // #region DESKTOP
          <ThemeProvider theme={theme}>
            <div className={classes.backgroundLandscape}>
              <div className={classes.paperPosition}>
                <Paper
                  className={classes.paperProps}
                  classes={{
                    rounded: classes.paperShape,
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                      <img
                        src={ponefaLogo}
                        alt="Ponefa Logo"
                        className="logo"
                      />
                      <Typography variant="h4" className="header4">{props.t("SignInPage.SignIn")}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="outlined-name"
                        label={props.t("SignInPage.Username")}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        value={fields.userName}
                        onChange={event => handleFieldChange(event, "userName")}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="outlined-adornment-password"
                        variant="outlined"
                        type={fields.showPassword ? "text" : "password"}
                        label={props.t("SignInPage.Password")}
                        fullWidth
                        value={fields.password}
                        margin="normal"
                        onChange={event => handleFieldChange(event, "password")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                                {fields.showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: 15, paddingTop: "5px", display: "inherit" }}>
                      <Typography variant="caption">
                        {props.t("SignInPage.NoAccount")}{" "}
                        <Link to={"/Register"} style={{ color: "#2296f3" }} key={"Register"}>
                          {props.t("SignInPage.CreateOne")}
                        </Link>
                      </Typography>

                      <Typography variant="caption" style={{ marginLeft: "auto", marginRight: "0px" }}>
                        <Link to={"/PasswordRecovery"} style={{ color: "#2296f3", textDecoration: "none" }} key={"PasswordRecovery"}>
                          {props.t("SignInPage.ForgotPassword")}
                        </Link>
                      </Typography>
                    </Grid>

                    {showErrorMessage ? (
                      <Grid item xs={12} style={{ marginBottom: "12px" }}>
                        <Alert severity="error">
                          <b>{props.t("SignInPage.Error")}</b>
                          {errorMessage}
                        </Alert>
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <div className={classes.buttons}>
                        <Button variant="contained" color="primary" onClick={loginPOST} disabled={loading} className="no-shadow button-padding">
                          {props.t("SignInPage.SignIn")}
                          {loading && <CircularProgress size={24} style={{ top: "17%", position: "absolute" }} />}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </div>
          </ThemeProvider>
          // #endregion
        )}
      </React.Fragment>
    );
  }
}

const minToolbarHeight = 48;

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] }
  }
});

const useStyles = makeStyles(theme => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },

  paperPositionMobile: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "16px",
    marginRight: "16px",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
    }
  },

  paperPosition: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "15%",
    marginRight: "15%",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      marginRight: "25%"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "32%",
      marginRight: "32%"
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "38%",
      marginRight: "38%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    }
  },
  paperShape: {
    borderRadius: "4px",
  },
  paperShadow: {
    boxShadow: "0 10px 45px -10px rgba(0, 0, 0, 0.2) !important"
  },
  paperProps: {
    padding: "32px"
  },
  paperPropsMobile: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },

  // launchPage: {
  //   paddingLeft: "40px",
  //   paddingTop: `calc(${minToolbarHeight}px + 40px)`,

  //   [theme.breakpoints.up("sm")]: {
  //     paddingLeft: `calc(${drawerWidth}px + 100px)`,
  //     paddingTop: `calc(${minToolbarHeight}px + 40px)`
  //   }
  // },

  backgroundLandscape: {
    backgroundImage: `url(${bgLandscape})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    width: "100%",
    height: "100vh",
    overflow: "auto",
    backgroundColor: "#213e54",
  },

  background: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "200%",
    height: "100vh",

    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      width: "195%"
    },

    [theme.breakpoints.up("md")]: {
      width: "130%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%"
    }
  }

  // registerText:{
  //   marginRight: "60%",
  //   fontSize: "3.0rem",

  //   [theme.breakpoints.up("sm")]: {
  //     marginRight: "60%",
  //     fontSize: "3.0rem"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     marginRight: "30%",
  //     fontSize: "3.4rem"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     marginRight: "0%",
  //     fontSize: "3.75rem"
  //   }
  // },

  // timeText:{
  //   fontSize: "1.2rem",

  //   [theme.breakpoints.up("sm")]: {
  //     marginRight: "50%",
  //     fontSize: "1.2rem"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     marginRight: "60%",
  //     fontSize: "1.35rem"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     marginRight: "0%",
  //     fontSize: "1.5rem"
  //   }
  // },

  // borderMargin:{
  //   width: "30%",
  //   [theme.breakpoints.up("sm")]: {
  //     width: "30%"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     width: "40%"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     width: "50%"
  //   }
  // },
}));

export default withRouter(Login);
