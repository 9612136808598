import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import React, { Component } from "react";

export class FormUserDetails extends Component {
  constructor(props) {
    super(props);
    this.Continue = this.Continue.bind(this);
  }

  Continue(event) {
    event.preventDefault();
    this.props.nextStep();
  }

  render() {
    const { values, handleChange } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <TextField
          required
          id="fn"
          label="First Name"
          onChange={handleChange("firstName")}
          margin="normal"
          defaultValue={values.firstName}
        />
        <br />
        <TextField
          required
          id="fn"
          label="Last Name"
          onChange={handleChange("lastName")}
          margin="normal"
          defaultValue={values.lastName}
        />
        <br />
        <TextField
          required
          id="fn"
          label="City"
          onChange={handleChange("city")}
          margin="normal"
          defaultValue={values.city}
        />
        <br />
        <Button variant="contained" color="primary" onClick={this.Continue}>
          Submit
        </Button>
      </React.Fragment>
    );
  }
}

export default FormUserDetails;
