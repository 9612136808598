import { Button, CircularProgress, createMuiTheme, Grid, IconButton, InputAdornment, Paper, TextField, Typography, useMediaQuery } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ThemeProvider } from "@material-ui/styles";
import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import bgImage from "./../bg_1.jfif";
import bgLandscape from "./../bgLandscape1.jpg";
import CustomSnackbar from "./CustomSnackbar";
import Alert from '@material-ui/lab/Alert';

function ResetPassword(props, {match}) {
  props.currentPathCallbackProp(props.location.pathname);
  const classes = useStyles(props);
  const [fields, setFields] = useState({
    //userName: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
    email: "",
    username: "",
  });
  const fieldsRef = useRef()
  const addressExtension = "/Authentication";
  const snackbarMessage = props.t("RegisterPage.SnackbarMessage");
  const autoHideDuration = 2500;
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [sucessfulMessage, setSuccessfulMessage] = useState("");
  const [successfulRegistration, setSuccessfulRegistration] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputsm = useMediaQuery(theme.breakpoints.down("sm"));
  const minWidth = useMediaQuery("(min-width:400px)");

  useEffect(() => {
    window.addEventListener("keypress", keyPress)
    return () => {
      window.removeEventListener("keypress", keyPress)
    }
  }, [])

  useEffect(() => {
    fieldsRef.current = fields;
  }, [fields])

  function keyPress(event) {
    if(event.key === "Enter") {
      verifyRecoveryTokenGET(fieldsRef.current.username, fieldsRef.current.email, props.match.params.token, fieldsRef.current.confirmPassword)
    }
  }
  
  function redirectToLogin() {
    return <Redirect to={"/"} style={{ textDecoration: "none" }} key={"ToLogin"} />;
  }

  function handleFieldChange(event, name) {
    setFields({ ...fields, [name]: event.target.value.trim() });
  }

  function handleClickShowPassword() {
    setFields({ ...fields, showPassword: !fields.showPassword });
  }

  function handleDateChange(event, date) {
    setFields({ ...fields, dob: date });
  }

  function closeSnackbarCallback() {
    setShowSnackbar(false);
  }

  function checkErrorMessages() {
    for (var i in fieldsRef.current) {
      if (fieldsRef.current[i] === "") {
        setErrorMessage(props.t("RegisterPage.Error1"));
        setSuccessful(false);
        setShowErrorMessage(true);
        return true;
      }
    }

    if (fieldsRef.current["password"].length <= 7) {
      setErrorMessage(props.t("RegisterPage.Error3"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (!new RegExp(/[0-9]/).test(fieldsRef.current["password"])) {
      setErrorMessage(props.t("RegisterPage.Error4"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (!new RegExp(/[A-Z]/).test(fieldsRef.current["password"])) {
      setErrorMessage(props.t("RegisterPage.Error5"));
      setShowErrorMessage(true);
      return true;
    }

    if (!new RegExp(/[!#@$%^&*)(+=._-]/).test(fieldsRef.current["password"])) {
      setErrorMessage(props.t("RegisterPage.Error6"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (fieldsRef.current.password != fieldsRef.current.confirmPassword) {
      setErrorMessage(props.t("RegisterPage.Error7"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    setShowErrorMessage(false);
    return false;
  }

  
  async function verifyRecoveryTokenGET(username, email, token, password) {
    if (checkErrorMessages()) {
      return;
    }

    setLoading(true);
    const address = props.baseAddressProp + addressExtension + "/VerifyPasswordRecoveryToken" + "?username=" + username + "&email=" +  email + "&token=" + token + "&pw=" + password;
    try {
        let result = await Axios.get(address);
        setSuccessful(true);
        setSuccessfulRegistration(true);
        setLoading(false);
    } catch (error) {
        setSuccessful(false);
        setErrorMessage(props.t("ResetPassword.Invalid"));
        setShowErrorMessage(true);
        setLoading(false);
    }
  }

  function registrationSuccessfull() {
    return (
      <ThemeProvider theme={theme}>
        {inputsm ? (
          // #region MOBILE
          <div className={classes.backgroundLandscape} style={{backgroundPosition: "40% 100%"}}>
            <div className={classes.paperPositionMobileFixed}>
              <Paper
                className={classes.paperPropsMobile}
                classes={{
                  rounded: classes.paperShape,
                  elevation1: classes.paperShadow
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      border: "1px solid rgb(34, 150, 243)",
                      borderRadius: "4px",
                      backgroundColor: "rgba(34, 150, 243, 0.1)",
                      marginBottom: "8px"
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{ fontSize: minWidth ? "1.75rem" : "6.5vmin", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}
                    >
                      {props.t("ResetPassword.PasswordChanged")}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <div style={{ display: "flex" }}>
                      <Link to={"/Database"} style={{ textDecoration: "none", width: "100%" }} key={"previous"}>
                        <Button variant="contained" color="primary" style={{ width: "100%" }}>
                          {props.t("RegisterPage.Continue")}
                        </Button>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </div>
        ) : (
          //# endregion
          // #region DESKTOP
          <div className={classes.backgroundLandscape}>
            <div className={props.loggedInProp ? classes.paperPositionSuccessful : classes.paperPositionSuccessfulNotLoggedIn}>
              <Paper
                className={classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                  elevation1: classes.paperShadow
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      border: "1px solid rgb(34, 150, 243)",
                      borderRadius: "4px",
                      backgroundColor: "rgba(34, 150, 243, 0.1)",
                      marginBottom: "8px"
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{ fontSize: minWidth ? "1.75rem" : "6.5vmin", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}
                    >
                      {props.t("ResetPassword.PasswordChanged")}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <div style={{ display: "flex" }}>
                      <Link to={"/Database"} style={{ textDecoration: "none", marginLeft: "auto", marginRight: "0px" }} key={"previous"}>
                        <Button variant="contained" color="primary">
                          {props.t("RegisterPage.Continue")}
                        </Button>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </div>
        )
        //# endregion
        }
      </ThemeProvider>
    );
  }

  return (
    <React.Fragment>
      {successfulRegistration ? (
        registrationSuccessfull()
      ) : (
        <React.Fragment>
          <div className={classes.backgroundLandscape}>
            {inputsm ? (
              // #region MOBILE
              <ThemeProvider theme={theme}>
                <div className={props.loggedInProp ? classes.paperPositionLoggedIn : classes.paperPositionMobile}>
                  <Paper
                    className={classes.paperPropsMobile}
                    classes={{
                      rounded: classes.paperShape,
                      elevation1: classes.paperShadow
                    }}
                  >
                    <Grid container spacing={0}>
                      <Typography variant="h4" style={{ fontSize: minWidth ? "2.125rem" : "7vmin", marginBottom: "16px" }}>
                        {props.t("ResetPassword.ResetPasswordText")}
                      </Typography>

                      <Grid item xs={12}>
                        <TextField
                          style={{ marginTop: 8 }}
                          id="outlined-username"
                          label={props.t("PasswordRecovery.Username")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          value={fields.username}
                          onChange={event => handleFieldChange(event, "username")}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          style={{ marginTop: 8 }}
                          id="outlined-email"
                          label={props.t("PasswordRecovery.EmailAddress")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          value={fields.email}
                          onChange={event => handleFieldChange(event, "email")}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-adornment-password"
                          variant="outlined"
                          type={fields.showPassword ? "text" : "password"}
                          label={props.t("ResetPassword.NewPassword")}
                          fullWidth
                          value={fields.password}
                          margin="dense"
                          onChange={event => handleFieldChange(event, "password")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                                  {fields.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-adornment-password"
                          variant="outlined"
                          type={fields.showPassword ? "text" : "password"}
                          label={props.t("ResetPassword.ConfirmNewPassword")}
                          fullWidth
                          value={fields.confirmPassword}
                          margin="dense"
                          onChange={event => handleFieldChange(event, "confirmPassword")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                                  {fields.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          marginTop: 8,
                          color: "#757575",
                          padding: "12px",
                          border: "1px solid rgb(34, 150, 243)",
                          borderRadius: "4px",
                          backgroundColor: "rgba(34, 150, 243, 0.1)"
                        }}
                      >
                        <Typography variant="caption" style={{ fontSize: minWidth ? "0.75rem" : "3vmin" }}>
                          <em>
                            {props.t("RegisterPage.Requirement1")} <br />
                            {props.t("RegisterPage.Requirement2")} <br />
                            {props.t("RegisterPage.Requirement3")} <br />
                            {props.t("RegisterPage.Requirement4")}
                            <br />
                          </em>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} style={{ marginBottom: 20 }} />

                      <Grid item xs={12} style={{ marginBottom: "12px" }}>
                        {showErrorMessage ? (
                          <Alert severity="error">
                              <b>{props.t("RegisterPage.Error")} </b>
                              {errorMessage}
                          </Alert>
                        ) : successful ? (
                          <Alert severity="success">
                            {props.t("RegisterPage.YourRegistrationHasBeen")} <b>{props.t("RegisterPage.Successful")}</b>.
                          </Alert>
                        ) : null}
                      </Grid>

                      <Grid item xs={12}>
                        <div style={{ display: "flex" }}>
                          <Button fullWidth variant="contained" color="primary" onClick={event=>{verifyRecoveryTokenGET(fields.username, fields.email, props.match.params.token, fields.confirmPassword)}} style={{ width: "100%", marginLeft: "12px" }} disabled={loading}>
                            {props.t("ResetPassword.Reset")}
                            {loading && <CircularProgress size={24} style={{top: "17%", position: "absolute"}} />}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </ThemeProvider>
            ) : (
              // #endregion
              // #region DESKTOP
              <ThemeProvider theme={theme}>
                <div className={props.loggedInProp ? classes.paperPositionLoggedIn : classes.paperPosition}>
                  <Paper
                    className={classes.paperProps}
                    classes={{
                      rounded: classes.paperShape,
                      elevation1: classes.paperShadow
                    }}
                  >
                    <Grid container spacing={0}>
                      <Typography variant="h4" style={{ marginBottom: 25 }}>
                        {props.t("ResetPassword.ResetPasswordText")}
                      </Typography>

                      <Grid item xs={12}>
                        <TextField
                          style={{ marginTop: 8 }}
                          id="outlined-username"
                          label={props.t("PasswordRecovery.Username")}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          value={fields.username}
                          onChange={event => handleFieldChange(event, "username")}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          style={{ marginTop: 8 }}
                          id="outlined-email"
                          label={props.t("PasswordRecovery.EmailAddress")}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          value={fields.email}
                          onChange={event => handleFieldChange(event, "email")}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-adornment-password"
                          variant="outlined"
                          type={fields.showPassword ? "text" : "password"}
                          label={props.t("ResetPassword.NewPassword")}
                          fullWidth
                          value={fields.password}
                          margin="normal"
                          onChange={event => handleFieldChange(event, "password")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                                  {fields.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-adornment-password"
                          variant="outlined"
                          type={fields.showPassword ? "text" : "password"}
                          label={props.t("ResetPassword.ConfirmNewPassword")}
                          fullWidth
                          value={fields.confirmPassword}
                          margin="normal"
                          onChange={event => handleFieldChange(event, "confirmPassword")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                                  {fields.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          marginTop: 8,
                          color: "#757575",
                          padding: "12px",
                          border: "1px solid rgb(34, 150, 243)",
                          borderRadius: "4px",
                          backgroundColor: "rgba(34, 150, 243, 0.1)"
                        }}
                      >
                        <Typography variant="caption" style={{ fontSize: minWidth ? "0.75rem" : "3vmin" }}>
                          <em>
                            {props.t("RegisterPage.Requirement1")} <br />
                            {props.t("RegisterPage.Requirement2")} <br />
                            {props.t("RegisterPage.Requirement3")} <br />
                            {props.t("RegisterPage.Requirement4")}
                            <br />
                          </em>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} style={{ marginBottom: 20 }} />

                      <Grid item xs={12} style={{ marginBottom: "12px" }}>
                        {showErrorMessage ? (
                          <Alert severity="error">
                              <b>{props.t("RegisterPage.Error")} </b>
                              {errorMessage}
                          </Alert>
                        ) : successful ? (
                          <Alert severity="success">
                            {props.t("RegisterPage.YourRegistrationHasBeen")} <b>{props.t("RegisterPage.Successful")}</b>.
                          </Alert>
                        ) : null}
                      </Grid>

                      <Grid item xs={12}>
                        <div className={classes.buttons}>
                          <Button variant="contained" color="primary" onClick={event=>{verifyRecoveryTokenGET(fields.username, fields.email, props.match.params.token, fields.confirmPassword)}} className={classes.buttonSpacing} disabled={loading}>
                            {props.t("ResetPassword.Reset")}
                            {loading && <CircularProgress size={24} style={{top: "17%", left: "35%", position: "absolute"}} />}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </ThemeProvider>
              // #endregion
            )}
          </div>
          <div>
            <CustomSnackbar
              showSnackbarProp={showSnackbar}
              closeSnackbarCallbackProp={closeSnackbarCallback}
              snackbarMessageProp={snackbarMessage}
              autoHideDurationProp={autoHideDuration}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

const drawerWidth = 250;
const minToolbarHeight = 64;

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] }
  }
});

const useStyles = makeStyles(theme => ({
  buttonSpacing: {
    marginLeft: theme.spacing(1)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  timePickers: {
    flexGrow: 1,
    maxWidth: "100%"
  },

  paperPositionSuccessful: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "15%",
    marginRight: "15%",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      marginRight: "25%"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `calc(${drawerWidth}px + 21%)`,
      marginRight: "21%"
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: `calc(${drawerWidth}px + 27%)`,
      marginRight: "27%"
    }
  },

  paperPositionSuccessfulNotLoggedIn: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "15%",
    marginRight: "15%",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    //paddingTop: `calc(${minToolbarHeight}px + 15%)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      marginRight: "25%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "32%",
      marginRight: "32%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "38%",
      marginRight: "38%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    }
  },

  paperPosition: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "15%",
    marginRight: "15%",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    //paddingTop: `calc(${minToolbarHeight}px + 15%)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      marginRight: "25%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "32%",
      marginRight: "32%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "38%",
      marginRight: "38%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    }
  },

  paperPositionMobile: {
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "10%",
    paddingTop: `calc(${minToolbarHeight}px + 15%)`
  },

  paperPositionLoggedIn: {
    marginLeft: "15%",
    marginRight: "15%",
    paddingTop: `calc(${minToolbarHeight}px + 5%)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%",
      paddingTop: `calc(${minToolbarHeight}px + 5%)`
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      marginRight: "25%",
      paddingTop: `calc(${minToolbarHeight}px + 5%)`,
      marginBottom: "5%"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `calc(${drawerWidth}px + 21%)`,
      marginRight: "21%",
      paddingTop: `calc(${minToolbarHeight}px + 5%)`,
      marginBottom: "5%"
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: `calc(${drawerWidth}px + 27%)`,
      marginRight: "27%",
      paddingTop: `calc(${minToolbarHeight}px + 5%)`,
      marginBottom: "5%"
    }
  },

  paperShape: {
    borderRadius: 8
  },
  paperShadow: {
    boxShadow: "0 10px 45px -10px rgba(0, 0, 0, 0.2)"
  },
  paperProps: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3)
  },

  paperPropsMobile: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },

  // launchPage: {
  //   paddingLeft: "40px",
  //   paddingTop: `calc(${minToolbarHeight}px + 40px)`,

  //   [theme.breakpoints.up("sm")]: {
  //     paddingLeft: `calc(${drawerWidth}px + 100px)`,
  //     paddingTop: `calc(${minToolbarHeight}px + 40px)`
  //   }
  // },

  backgroundLandscape: {
    backgroundImage: `url(${bgLandscape})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    width: "100%",
    height: "100vh",
    overflow: "auto"
  },

  background: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "200%",
    height: "100vh",

    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      width: "195%"
    },

    [theme.breakpoints.up("md")]: {
      width: "130%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%"
    }
  },

  paperPositionMobileFixed: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "16px",
    marginRight: "16px",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
    }
  },

  paperPositionFixed: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "15%",
    marginRight: "15%",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    //paddingTop: `calc(${minToolbarHeight}px + 15%)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      marginRight: "25%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "32%",
      marginRight: "32%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "38%",
      marginRight: "38%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    }
  }

  // registerText:{
  //   marginRight: "60%",
  //   fontSize: "3.0rem",

  //   [theme.breakpoints.up("sm")]: {
  //     marginRight: "60%",
  //     fontSize: "3.0rem"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     marginRight: "30%",
  //     fontSize: "3.4rem"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     marginRight: "0%",
  //     fontSize: "3.75rem"
  //   }
  // },

  // timeText:{
  //   fontSize: "1.2rem",

  //   [theme.breakpoints.up("sm")]: {
  //     marginRight: "50%",
  //     fontSize: "1.2rem"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     marginRight: "60%",
  //     fontSize: "1.35rem"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     marginRight: "0%",
  //     fontSize: "1.5rem"
  //   }
  // },

  // borderMargin:{
  //   width: "30%",
  //   [theme.breakpoints.up("sm")]: {
  //     width: "30%"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     width: "40%"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     width: "50%"
  //   }
  // },
}));

export default withRouter(ResetPassword);
