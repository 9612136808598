import React from "react";
import { Redirect, Route } from "react-router-dom";

function PublicRoute({ component: Component, loggedIn, restricted, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        loggedIn === true && restricted === true ? <Redirect to={{ pathname: "/Home", state: { from: props.location } }} /> : <Component {...rest} {...props} />
      }
    />
  );
}

export default PublicRoute;
