/* eslint-disable react-hooks/exhaustive-deps */
import DateFnsUtils from "@date-io/date-fns";
import { AppBar, Button, CircularProgress, Collapse, createMuiTheme, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Tab, Tabs, TextField, Tooltip, Typography, useMediaQuery, Zoom } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import Axios from "axios";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns/esm";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import bgImage from "./../bgImagev5.svg";
import CustomSnackbar from "./CustomSnackbar";
import Alert from '@material-ui/lab/Alert';

function AnnualLeave(props) {
  props.currentPathCallbackProp(props.location.pathname);
  props.headerNameProp(props.t("AnnualLeave.AnnualLeaveText"));

  const classes = useStyles(props);
  const bgColorTimesheetLabels = "#f3f3f3";
  const snackbarMessage = props.t("AnnualLeave.Snackbar");
  const autoHideDuration = 1250;
  const tabLabels = [<DateRangeIcon />, props.t("AnnualLeave.Input"), props.t("AnnualLeave.YearlyOverview")];
  const [tab, setTab] = useState(0);
  const [year, setYear] = useState(new Date());
  const [paidLeaveDays, setPaidLeaveDays] = useState(0);
  const [holidayDates, setHolidayDates] = useState([]);
  const [currentHolidayDate, setCurrentHolidayDate] = useState(new Date());
  const addressExtension = "/AnnualLeave";
  const [submitLoading, setSubmitLoading] = useState(false);
  const [yearlyOverviewLoading, setYearlyOverviewLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [annualLeaveData, setAnnualLeaveData] = useState();
  var annualLeaveDataBool = useRef(false);
  const expansionPanels = useRef([]);
  const [expansionPanelsState, setExpansionPanelsState] = useState([]);
  const inputsm = useMediaQuery(theme.breakpoints.down("sm"));
  const minWidth = useMediaQuery("(min-width:400px)");

  useEffect(() => {
    annualLeaveGET();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [year]);

  useEffect(() => {
    // if(!annualLeaveData) { return }
    if (!annualLeaveDataBool.current) {
      annualLeaveDataBool.current = true;
      return;
    }

    let array = [];
    if(annualLeaveData) {
      let amount = Object.keys(annualLeaveData["holiday"]);
      for (let i = 0; i < amount.length; i++) {
        array.push(false);
      }
    } 
    expansionPanels.current = array;
    setExpansionPanelsState(array);
    findExistingHolidayDates(new Date().getFullYear());
  }, [annualLeaveData]);

  function findExistingHolidayDates(year) {
    if(!annualLeaveData) { return }
    let holidays = [];
    let paidLeaveDays = 0;

    for (var item in annualLeaveData["holiday"]) {
      if (item === year) {
        for (let i = 0; i < annualLeaveData["holiday"][item].length; i++) {
          holidays.push(new Date(annualLeaveData["holiday"][item][i].date).toLocaleDateString("en-GB"));
        }
      }
    }

    for (let i = 0; i < annualLeaveData["paidLeave"].length; i++) {
      if (new Date(annualLeaveData["paidLeave"][i].date).toLocaleDateString("en-GB", { year: "numeric" }) === year) {
        paidLeaveDays = annualLeaveData["paidLeave"][i].amount;
      }
    }

    setHolidayDates(holidays);
    setPaidLeaveDays(paidLeaveDays);
    setYear(new Date(year, 1, 1));
    setCurrentHolidayDate(new Date(year, 0, 1));
  }

  async function annualLeaveGET() {
    setLoading(true);
    const address = props.baseAddressProp + addressExtension + "/GetAnnualLeaveInfo";
    try {
      let result = await Axios.get(address);
      setAnnualLeaveData(result.data);
      setYearlyOverviewLoading(false);
    } catch (error) {
      setAnnualLeaveData(null);
      setYearlyOverviewLoading(false);
    }
    setLoading(false)
  }

  async function annualLeavePOST() {
    if (paidLeaveDays < 0 || !holidayDates || holidayDates.length <= 0) return null;

    setSubmitLoading(true);
    const address = props.baseAddressProp + addressExtension + "/PostAnnualLeaveInfo";
    try {
      await Axios.post(address, {
        Amount: paidLeaveDays,
        Holidays: holidayDates
      });
      setShowSnackbar(true);
      annualLeaveGET();
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
    }
  }

  async function annualLeaveDELETE(yearStr) {
    setYearlyOverviewLoading(true);
    let year = parseInt(yearStr);
    const address = props.baseAddressProp + addressExtension + "/DeleteYear" + "?year=" + year;
    try {
      await Axios.delete(address);
      //setShowSnackbar(true);
      annualLeaveGET();

    } catch (error) {
      setYearlyOverviewLoading(false);
    }
  }

  function handleChange(event, newValue) {
    setTab(newValue);
  }

  function insertTabs() {
    let items = [];
    for (let i = 0; i < tabLabels.length; i++) {
      if (i === 0) {
        items.push(<Tab label={tabLabels[i]} className={classes.tab} key={i} classes={{ root: classes.tab1 }} />);
      } else {
        items.push(<Tab label={tabLabels[i]} className={classes.tab} key={i} />);
      }
    }
    return items;
  }

  function renderAnnualLeave() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <div className={classes.appBarPosition}>
            <AppBar position="static" style={{ boxShadow: "none", minHeight: 64 }}>
              <Tabs
                classes={{root: classes.customTabs}}
                value={tab}
                variant="scrollable"
                scrollButtons="on"
                onChange={handleChange}
                TabIndicatorProps={{
                  style: {
                    height: tab === 0 ? "0px" : "2px"
                  }
                }}
              >
                {insertTabs()}
              </Tabs>
            </AppBar>
          </div>
          {tab === 0 ? renderLaunchPage() : null}
          {tab === 1 ? renderInputPage() : null}
          {tab === 2 ? renderOverviewPage() : null}
          {/* {tab === 2 ? renderDivisionsProductivity() : null}
          {tab === 3 ? renderTopPerformers() : null} */}
        </ThemeProvider>
      </div>
    );
  }

  function fixDate(d) {
    let newDate = new Date(d.match(new RegExp(/\d+-\d+-\d+/))).toLocaleDateString("en-GB");
    return newDate;
  }

  function yearlyExpansionPanels() {
    if (!annualLeaveData) return null;
    if (expansionPanels.length < 1) return null;

    // const ExpansionPanelSummary = withStyles({
    //   root: {
    //     backgroundColor: "#e9e9e9"
    //   }
    // })(MuiExpansionPanelSummary);

    let array = [];
    let keys = Object.keys(annualLeaveData["holiday"]);
    let paidLeave = annualLeaveData["paidLeave"];
    paidLeave.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });

    for (let i = 0; i < keys.length; i++) {
      array.push(
        <React.Fragment>
          <Paper>
            <List style={{ padding: "0px" }}>
              <ListItem
                button
                onClick={() => {
                  let panels = [...expansionPanelsState];
                  panels[i] = !panels[i];
                  setExpansionPanelsState(panels);
                }}
                style={{ backgroundColor: "#e9e9e9" }}
                classes={{ root: classes.listStyle }}
                key={i}
              >
                <ListItemText
                  primary={
                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin" }}>
                      {keys[i]}
                    </Typography>
                  }
                />

                {expansionPanelsState[i] ? (
                  <ExpandLessIcon style={{ color: blue[500], marginRight: inputsm ? "0px" : "15px" }} />
                ) : (
                  <ExpandMoreIcon style={{ color: blue[500], marginRight: inputsm ? "0px" : "15px" }} />
                )}
              </ListItem>
              <Collapse in={expansionPanelsState[i]} timeout="auto" unmountOnExit>
                <List>
                  <ListItem>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingBottom: "0px"
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{
                            color: "#FFFFFF",
                            backgroundColor: "rgb(89 89 89)",
                            fontSize: minWidth ? "1.25rem" : "4vmin",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            paddingLeft: "15px"
                          }}
                        >
                          {props.t("AnnualLeave.PaidLeaveDays")}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} style={{ paddingTop: "0px" }}>
                        <TextField
                          id="outlined-days"
                          label="Amount"
                          margin="normal"
                          variant="filled"
                          type="number"
                          fullWidth
                          inputProps={{ readOnly: true, root: classes.bg }}
                          style={{ marginTop: "0px", backgroundColor: "#ffffff", color: "black" }}
                          value={paidLeave[i].amount}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingBottom: "0px"
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{
                            color: "#FFFFFF",
                            backgroundColor: "#595959",
                            fontSize: minWidth ? "1.25rem" : "4vmin",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            paddingLeft: "15px"
                          }}
                        >
                          {props.t("AnnualLeave.HolidayDates")}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} style={{ paddingTop: "0px" }}>
                        <List dense={true} style={{ paddingTop: "0px" }}>
                          {annualLeaveData["holiday"][keys[i]].map((item, index) => {
                            return (
                              <ListItem
                                key={index}
                                style={{
                                  border: "1px solid rgb(242, 242, 242)",
                                  backgroundColor: index % 2 === 0 ? "rgb(255, 255, 255)" : "rgb(242, 242, 242)"
                                }}
                              >
                                <ListItemText primary={fixDate(item.date)} />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={event => annualLeaveDELETE(keys[i])}
                          className="no-shadow button-padding"
                        >
                          {props.t("TimeRegistration.Delete")}
                        </Button>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Paper>
        </React.Fragment>
      );
    }
    return array;
  }

  function renderOverviewPage() {
    return (
      <div className={classes.paperPosition}>
        <Grid container>
          <Grid item xs={12}>
            <Paper
              className={inputsm ? classes.paperPropsMobile : classes.paperProps}
              classes={{
                rounded: classes.paperShape,
              }}
            >
              {inputsm ? (
                // #region MOBILE
                <React.Fragment>
                  {yearlyOverviewLoading ? (
                    <CircularProgress size={24} style={{ marginLeft: "47.5%" }} />
                  ) : (
                    <React.Fragment>
                      <Grid
                        item
                        xs={12}
                      >
                      </Grid>
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          color: "white",
                          backgroundColor: "#595959",
                          display: !!annualLeaveData ? "flex" : "none"
                        }}
                        classes={{ root: classes.buttonStyle }}
                        onClick={event => {
                          let panels = [...expansionPanelsState];
                          let state = false;
                          let counter = 0;
                          for (let i = 0; i < panels.length; i++) {
                            if (panels[i]) {
                              counter++;
                            }
                          }

                          if (counter === panels.length) {
                            for (let i = 0; i < panels.length; i++) {
                              panels[i] = false;
                            }
                            state = false;
                          } else {
                            for (let i = 0; i < panels.length; i++) {
                              panels[i] = true;
                            }
                            state = true;
                          }
                          console.log(panels)
                          setExpansionPanelsState(panels); //0 is expand all // 1 is collapse all
                        }}
                      >
                        <UnfoldMoreIcon />
                      </Button>

                      <Grid item xs={12} />
                      <Grid item xs={12}>
                        {!annualLeaveData && 
                          <Typography variant="body2">{props.t("AnnualLeave.NoData")}</Typography>
                        }
                        {yearlyExpansionPanels()}
                      </Grid>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                // #endregion
                // #region DESKTOP
                <React.Fragment>
                  {yearlyOverviewLoading ? (
                    <CircularProgress size={24} style={{ marginLeft: "49%" }} />
                  ) : (
                    <React.Fragment>
                      <Grid
                        item
                        xs={12}
                      >
                        </Grid>
                        <Button
                          variant="contained"
                          style={{
                            color: "white",
                            backgroundColor: "#595959",
                            display: !!annualLeaveData ? "flex" : "none",
                            width: "100%"
                          }}
                          classes={{ root: classes.buttonStyle }}
                          onClick={event => {
                            let panels = [...expansionPanelsState];
                            let state = false;
                            let counter = 0;
                            for (let i = 0; i < panels.length; i++) {
                              if (panels[i]) {
                                counter++;
                              }
                            }

                            if (counter === panels.length) {
                              for (let i = 0; i < panels.length; i++) {
                                panels[i] = false;
                              }
                              state = false;
                            } else {
                              for (let i = 0; i < panels.length; i++) {
                                panels[i] = true;
                              }
                              state = true;
                            }

                            setExpansionPanelsState(panels); //0 is expand all // 1 is collapse all
                          }}
                        >
                          {/* {expandAll ? expandAllText[1] : expandAllText[0]} */}
                          <UnfoldMoreIcon />
                        </Button>
                        <Grid item xs={12} />

                      <Grid item xs={12}>
                        {!annualLeaveData && 
                          <Typography variant="body2">{props.t("AnnualLeave.NoData")}</Typography>
                        }
                        {yearlyExpansionPanels()}
                      </Grid>
                    </React.Fragment>
                  )}
                </React.Fragment>
                // #endregion
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  function renderInputPage() {
    return (
      <div className={classes.paperPosition}>
        <Grid container>
          <Grid item xs={12}>
            <Paper
              className={inputsm ? classes.paperPropsMobile : classes.paperProps}
              classes={{
                rounded: classes.paperShape,
              }}
            >
              {loading ? (
                <Grid item xs={12} align="center" style={{ height: "100%", paddingBottom: "75px" }}>
                  <div style={{ position: "relative", top: "50%" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  {inputsm ? (
                    // #region MOBILE
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* <Grid
                        item
                        xs={12}
                        style={{ marginBottom: "12px", padding: "12px", paddingLeft: "12px", backgroundColor: blue[500], borderRadius: "8px", color: "white" }}
                      >
                        <Typography variant="h5" style={{ fontSize: "1.5rem" }}>
                          {props.t("AnnualLeave.Input")}
                        </Typography>
                      </Grid> */}

                      <Alert severity="info" style={{marginBottom: "16px"}}>
                        <div>{props.t("AnnualLeave.Atleast")}</div>
                      </Alert>

                      <Grid container spacing={3}>
                        <Grid
                          container
                          spacing={3}
                          style={{
                            border: "1px solid rgba(197, 197, 197, 1.0)",
                            borderRadius: "8px",
                            margin: "12px",
                            marginBottom: "12px"
                          }}
                        >
                          <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                            <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin" }}>
                              {props.t("AnnualLeave.Year")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {/* <Typography variant="h6">Year selected: </Typography> */}
                            <KeyboardDatePicker
                              fullWidth
                              inputVariant="outlined"
                              views={["year"]}
                              label={props.t("AnnualLeave.Year")}
                              value={year}
                              margin="dense"
                              style={{ backgroundColor: bgColorTimesheetLabels }}
                              onChange={(event, date) => {
                                findExistingHolidayDates(date);
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={3}
                          style={{
                            border: "1px solid rgba(197, 197, 197, 1.0)",
                            borderRadius: "8px",
                            margin: "12px",
                            marginBottom: "12px",
                            marginTop: "0px"
                          }}
                        >
                          <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                            <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin" }}>
                              {props.t("AnnualLeave.PaidLeaveDays")}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              id="outlined-days"
                              label={props.t("AnnualLeave.Amount")}
                              variant="outlined"
                              margin="dense"
                              type="number"
                              fullWidth
                              style={{ marginTop: "0px", backgroundColor: bgColorTimesheetLabels }}
                              value={paidLeaveDays}
                              onChange={event => setPaidLeaveDays(event.target.value)}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={3}
                          style={{
                            border: "1px solid rgba(197, 197, 197, 1.0)",
                            borderRadius: "8px",
                            margin: "12px",
                            marginBottom: "12px",
                            marginTop: "0px"
                          }}
                        >
                          <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                            <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin" }}>
                              {props.t("AnnualLeave.Holidays")}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <KeyboardDatePicker
                              fullWidth
                              id="mui-pickers-date"
                              label={props.t("AnnualLeave.Date")}
                              inputVariant="outlined"
                              format="yyyy/MM/dd"
                              margin="dense"
                              value={currentHolidayDate}
                              minDate={startOfMonth(new Date(year.getFullYear(), 0, 1)).toLocaleDateString()}
                              maxDate={endOfMonth(new Date(year.getFullYear(), 11, 1)).toLocaleDateString()}
                              maxDateMessage={"Year cannot be larger than selected year."}
                              minDateMessage={"Year cannot be smaller than selected year."}
                              invalidDateMessage={"Invalid date."}
                              style={{ marginTop: "0px", marginBottom: "0px", flexGrow: 4, backgroundColor: bgColorTimesheetLabels }}
                              onChange={(event, date) => setCurrentHolidayDate(new Date(date))}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} style={{ paddingTop: "0px" }}>
                            <Button
                              fullWidth
                              variant="outlined"
                              color="primary"
                              style={{ marginLeft: "0px" }}
                              classes={{ contained: classes.buttonContained }}
                              onClick={event => {
                                let dates = [...holidayDates];
                                let str =
                                  // new String(currentHolidayDate.toLocaleDateString("en-EN", { weekday: "long" })) +
                                  // ", " +
                                  currentHolidayDate.toLocaleDateString("en-GB");
                                if (dates.includes(str)) return null;
                                dates.push(str);
                                dates.sort((a, b) => {
                                  let date_a = new Date(a.replace(/(\d+)\/(\d+)\/(\d+)/, "$2/$1/$3"));
                                  let date_b = new Date(b.replace(/(\d+)\/(\d+)\/(\d+)/, "$2/$1/$3"));
                                  return date_a.getTime() - date_b.getTime();
                                });
                                setHolidayDates(dates);
                              }}
                            >
                              {props.t("AnnualLeave.Add")}
                            </Button>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            style={{
                              paddingBottom: "0px",
                              display: "flex",
                              backgroundColor: "#595959",
                              margin: "12px",
                              marginBottom: "0px",
                              padding: "0px"
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                color: "#FFFFFF",
                                width: "100%",
                                fontSize: minWidth ? "1.25rem" : "5vmin",
                                paddingLeft: "15px"
                              }}
                            >
                              {props.t("AnnualLeave.HolidayDates")}
                            </Typography>

                            <Tooltip title={props.t("AnnualLeave.RemoveAll")} TransitionComponent={Zoom}>
                              <IconButton
                                style={{
                                  color: "white",
                                  marginInlineEnd: "4px",
                                  padding: "0px",
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  paddingRight: "12px"
                                }}
                                onClick={event => {
                                  setHolidayDates([]);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} style={{ paddingTop: "0px" }}>
                            <List dense={true} style={{ paddingTop: "0px" }}>
                              {listItems()}
                            </List>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ position: "relative" }}>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={submitLoading || paidLeaveDays < 0 || !holidayDates || holidayDates.length <= 0}
                              style={{ display: "flex", flexGrow: 1, marginBottom: "0px", width: "100%" }}
                              className="no-shadow"
                              classes={{ contained: classes.buttonContained }}
                              onClick={event => annualLeavePOST()}
                            >
                              {props.t("AnnualLeave.Submit")}
                              {submitLoading ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                            </Button>
                          </div>
                        </Grid>

                        <Grid item xs={12} style={{ padding: "0px" }}>
                          <CustomSnackbar
                            showSnackbarProp={showSnackbar}
                            closeSnackbarCallbackProp={closeSnackbarCallback}
                            snackbarMessageProp={snackbarMessage}
                            autoHideDurationProp={autoHideDuration}
                          />
                        </Grid>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  ) : (
                    // #endregion
                    // #region DESKTOP
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* <Grid
                        item
                        xs={12}
                        style={{ marginBottom: "35px", padding: "12px", paddingLeft: "25px", backgroundColor: blue[500], borderRadius: "8px", color: "white" }}
                      >
                        <Typography variant="h4" style={{fontSize: "1.5rem"}}>{props.t("AnnualLeave.Input")}</Typography>
                      </Grid> */}

                      <Alert severity="info" style={{marginBottom: "16px"}}>
                        <div>{props.t("AnnualLeave.Atleast")}</div>
                      </Alert>

                      <Grid container spacing={3}>
                        <Grid
                          container
                          spacing={3}
                          style={{
                            border: "1px solid rgba(197, 197, 197, 1.0)",
                            borderRadius: "8px",
                            padding: "12px",
                            margin: "12px",
                            marginBottom: "24px"
                          }}
                        >
                          <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                            <Typography variant="h6">{props.t("AnnualLeave.Year")}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {/* <Typography variant="h6">Year selected: </Typography> */}
                            <KeyboardDatePicker
                              fullWidth
                              inputVariant="outlined"
                              views={["year"]}
                              label={props.t("AnnualLeave.Year")}
                              value={year}
                              style={{ backgroundColor: bgColorTimesheetLabels }}
                              onChange={(event, date) => {
                                findExistingHolidayDates(date);
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={3}
                          style={{
                            border: "1px solid rgba(197, 197, 197, 1.0)",
                            borderRadius: "8px",
                            padding: "12px",
                            margin: "12px",
                            marginBottom: "24px"
                          }}
                        >
                          <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                            <Typography variant="h6">{props.t("AnnualLeave.PaidLeaveDays")}</Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              id="outlined-days"
                              label={props.t("AnnualLeave.Amount")}
                              margin="normal"
                              variant="outlined"
                              type="number"
                              fullWidth
                              style={{ marginTop: "0px", backgroundColor: bgColorTimesheetLabels }}
                              value={paidLeaveDays}
                              onChange={event => setPaidLeaveDays(event.target.value)}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={3}
                          style={{
                            border: "1px solid rgba(197, 197, 197, 1.0)",
                            borderRadius: "8px",
                            padding: "12px",
                            margin: "12px",
                            marginBottom: "24px"
                          }}
                        >
                          <Grid item xs={12}>
                            <Typography variant="h6">{props.t("AnnualLeave.Holidays")}</Typography>
                          </Grid>

                          <Grid item xs={12} style={{ display: "flex" }}>
                            <KeyboardDatePicker
                              margin="normal"
                              id="mui-pickers-date"
                              label={props.t("AnnualLeave.Date")}
                              inputVariant="outlined"
                              format="yyyy/MM/dd"
                              value={currentHolidayDate}
                              minDate={startOfMonth(new Date(year.getFullYear(), 0, 1)).toLocaleDateString()}
                              maxDate={endOfMonth(new Date(year.getFullYear(), 11, 1)).toLocaleDateString()}
                              maxDateMessage={"Year cannot be larger than selected year."}
                              minDateMessage={"Year cannot be smaller than selected year."}
                              invalidDateMessage={"Invalid date."}
                              style={{ marginTop: "0px", marginBottom: "0px", flexGrow: 4, backgroundColor: bgColorTimesheetLabels }}
                              onChange={(event, date) => setCurrentHolidayDate(new Date(date))}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />

                            <Button
                              variant="outlined"
                              color="primary"
                              style={{ marginLeft: "25px", flexGrow: 1 }}
                              classes={{ contained: classes.buttonContained }}
                              onClick={event => {
                                let dates = [...holidayDates];
                                let str =
                                  // new String(currentHolidayDate.toLocaleDateString("en-EN", { weekday: "long" })) +
                                  // ", " +
                                  currentHolidayDate.toLocaleDateString("en-GB");
                                if (dates.includes(str)) return null;
                                dates.push(str);
                                dates.sort((a, b) => {
                                  let date_a = new Date(a.replace(/(\d+)\/(\d+)\/(\d+)/, "$2/$1/$3"));
                                  let date_b = new Date(b.replace(/(\d+)\/(\d+)\/(\d+)/, "$2/$1/$3"));
                                  return date_a.getTime() - date_b.getTime();
                                });
                                setHolidayDates(dates);
                              }}
                            >
                              {props.t("AnnualLeave.Add")}
                            </Button>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            style={{
                              padding: "0px",
                              paddingBottom: "5px",
                              paddingTop: "5px",
                              display: "flex",
                              backgroundColor: "#595959",
                              margin: "12px",
                              marginBottom: "0px"
                            }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                color: "#FFFFFF",
                                width: "100%",

                                paddingLeft: "15px"
                              }}
                            >
                              {props.t("AnnualLeave.HolidayDates")}
                            </Typography>

                            <Tooltip title={props.t("AnnualLeave.RemoveAll")} TransitionComponent={Zoom}>
                              <IconButton
                                style={{
                                  color: "white",
                                  marginInlineEnd: "4px",
                                  padding: "0px",
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  paddingRight: "12px"
                                }}
                                onClick={event => {
                                  setHolidayDates([]);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} style={{ paddingTop: "0px" }}>
                            <List dense={true} style={{ paddingTop: "0px" }}>
                              {listItems()}
                            </List>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ position: "relative" }}>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={submitLoading || paidLeaveDays < 0 || !holidayDates || holidayDates.length <= 0}
                              style={{ display: "flex", flexGrow: 1, marginBottom: "15px" }}
                              className="no-shadow button-padding"
                              classes={{ contained: classes.buttonContained }}
                              onClick={event => annualLeavePOST()}
                            >
                              {props.t("AnnualLeave.Submit")}
                              {submitLoading ? <CircularProgress size={24} className={classes.buttonProgress} /> : null}
                            </Button>
                          </div>
                        </Grid>
                        <CustomSnackbar
                          showSnackbarProp={showSnackbar}
                          closeSnackbarCallbackProp={closeSnackbarCallback}
                          snackbarMessageProp={snackbarMessage}
                          autoHideDurationProp={autoHideDuration}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    // #endregion
                  )}
                </React.Fragment>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  function closeSnackbarCallback() {
    setShowSnackbar(false);
  }

  function listItems() {
    let array = [];

    holidayDates.map((item, index) => {
      array.push(
        <ListItem
          key={index}
          style={{ border: "1px solid rgb(242, 242, 242)", backgroundColor: index % 2 === 0 ? "rgb(255, 255, 255)" : "rgb(242, 242, 242)" }}
        >
          <ListItemText primary={item} />
          <ListItemSecondaryAction>
            <Tooltip title={props.t("AnnualLeave.RemoveEntry")} TransitionComponent={Zoom}>
              <IconButton
                edge="end"
                onClick={event => {
                  let dates = [...holidayDates];
                  dates.splice(index, 1);
                  setHolidayDates(dates);
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });

    return array;
  }

  function renderLaunchPage() {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.background}>
          <div className={classes.paperPositionHome}>
            <Paper
              className={inputsm ? classes.paperPropsMobile : classes.paperProps}
              classes={{
                rounded: classes.paperShape,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.title}>
                    {props.t("AnnualLeave.AnnualLeaveText")}
                    <DateRangeIcon className={classes.iconStyle} />
                  </Typography>

                  <Typography variant="h5" className={classes.subTitle}>
                    {props.t("AnnualLeave.Specifiy")}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </div>
      </ThemeProvider>
    );
  }

  return renderAnnualLeave();
}

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] },
    secondary: { main: "#FFFFFF" }
  }
});

const drawerWidth = 250;
const minToolbarHeight = 64;
const tabsHeight = 64;

const useStyles = makeStyles(theme => ({
  tab: {
    minHeight: 64
  },
  buttonStyle: {
    border: "1px solid rgba(255, 255, 255, 0.5)",
    "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.15)", border: "1px solid rgba(255, 255, 255, 1)" }
  },
  buttonContained: {
    boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)"
  },
  listStyle: {
    // backgroundColor: "#FFF",
    // "&:hover": {
    //   //you want this to be the same as the backgroundColor above
    //   backgroundColor: "#FFF"
    // }
  },
  appBarPosition: {
    marginTop: minToolbarHeight,

    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth,
      marginTop: minToolbarHeight
    }
  },
  launchPage: {
    paddingLeft: "40px",
    paddingTop: `calc(${minToolbarHeight}px + 40px)`,

    [theme.breakpoints.up("sm")]: {
      paddingLeft: `calc(${drawerWidth}px + 100px)`,
      paddingTop: `calc(${minToolbarHeight}px + 40px)`
    }
  },

  background: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    marginTop: -130,

    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      width: "100%"
    },

    [theme.breakpoints.up("md")]: {
      width: "100%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%"
    }
  },
  paperPosition: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    marginBottom: "16px",
    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth + 32,
      marginRight: "32px",
      marginTop: "32px",
      marginBottom: "32px",
    }
  },
  paperPositionHome: {
    marginLeft: "16px",
    marginRight: "16px",
    paddingTop: `calc(${minToolbarHeight}px + ${tabsHeight}px)`,

    [theme.breakpoints.up("lg")]: {
      marginLeft: `calc(${drawerWidth}px + 32px)`,
      marginRight: "32px",
      paddingTop: `calc(${minToolbarHeight}px + ${tabsHeight}px)`
    }
  },

  paperShape: {
    borderRadius: 8
  },
  paperProps: {
    padding: "32px",
    [theme.breakpoints.up("sm")]: {
      padding: "32px"
    }
  },
  paperPropsMobile: {
    padding: "16px",
    [theme.breakpoints.up("sm")]: {
      padding: "32px",
    }
  },
  tab1: {
    minWidth: 80,
    width: 80
  },
  buttonProgress: {
    position: "absolute"
  },
  bg: {
    backgroundColor: "#FFFFFF"
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 500,
    paddingLeft: "0px",
    paddingTop: "0px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
      paddingLeft: "7px",
      paddingTop: "10px",
      fontWeight: 500
    }
  },
  subTitle: {
    fontSize: "1rem",
    marginBottom: 10, 
    paddingLeft: "0px", 
    paddingTop: "10px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 20, 
      fontSize: "1rem",
      paddingLeft: "10px", 
      paddingTop: "10px"
    }
  },
  customTabs: {
    "& .MuiTabScrollButton-root": {
      width: "32px",
    }
  },
  iconStyle: {
    position: "relative",
    left: "10px",
    fontSize: "unset",
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      fontSize: "unset"
    }
  },
}));

export default withRouter(AnnualLeave);
