import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationPL from "./locales/pl/translation.json";

const resources = {
  en: {
    translation: translationEN
  },
  pl: {
    translation: translationPL
  }
};

i18n
  .use(detector)
  .use(reactI18nextModule)
  .init({
    resources,
    lng: window.localStorage.getItem("i18nextLng") || "pl",
    fallbackLng: "en",

    keySeparator: ".",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
