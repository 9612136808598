/* eslint-disable */
import DateFnsUtils from "@date-io/date-fns";
import { AppBar, Button, CircularProgress, Collapse, createMuiTheme, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography, useMediaQuery } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import AssessmentIcon from "@material-ui/icons/AssessmentOutlined";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IndeterminateCheckBox from "@material-ui/icons/IndeterminateCheckBox";
import LaunchIcon from "@material-ui/icons/Launch";
import OpacityIcon from "@material-ui/icons/Opacity";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import Axios from "axios";
import { startOfMonth } from "date-fns";
import { endOfMonth, endOfWeek, endOfYear, isSameDay, startOfWeek, startOfYear } from "date-fns/esm";
import MaterialTable, { MTableCell, MTableHeader, MTableToolbar } from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Cell, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import bgImage from "./../bgImagev5.svg";
import "./Productivity.css";

function Productvity(props) {
  props.currentPathCallbackProp(props.location.pathname);
  props.headerNameProp(props.t("Productivity.ProductivityText"));
  const classes = useStyles(props);
  // const tabLabels = [<AssessmentIcon />, props.t("Productivity.PerEmployee").toUpperCase(), props.t("Productivity.PerCustomer").toUpperCase(), props.t("Productivity.PerformanceRankings").toUpperCase()];
  const tabLabels = [<AssessmentIcon />, props.t("Productivity.PerEmployee").toUpperCase(), props.t("Productivity.PerCustomer").toUpperCase()];
  const productivityTableHead = [props.t("Productivity.TimeDays"), props.t("Productivity.ProducedUnits"), props.t("Productivity.TargetUnits"), props.t("Productivity.ProductivityPercentage")];
  const timelineTableHead = [props.t("Productivity.TimeDays"), props.t("Productivity.WorkingTimeMinutes"), props.t("Productivity.TargetMinutes"), props.t("Productivity.ProgressionPercentage")];
  var today = new Date();
  const addressExtension = "/Productivity";
  const unitsProduced = useRef(0);
  const unitsTarget = useRef(0);
  const dailyWorkEfficiencyBool = useRef(false);
  const finalWorkEfficiencyArrayBool = useRef(false);
  const timeSpentWorking = useRef(0);
  const targetTimeSpentWorking = useRef(0);
  const inputLabel = React.useRef(null);
  const [timeSpentWorkingArray, setTimeSpentWorkingArray] = useState([]);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingFiltersettings, setLoadingFilterSettings] = useState(true);
  var loadingFilterSettingsBool = useRef(false);
  const [finalWorkEfficiencyArray, setFinalWorkEfficiencyArray] = useState([]);
  const [finalProductivityPerTaskArray, setFinalProductivityPerTaskArray] = useState([]);
  const [dailyWorkEfficiencyData, setDailyWorkEfficiencyData] = useState({});
  const [productivityPerTaskData, setProductivityPerTaskData] = useState("");
  const [productivityPerDivisionData, setProductivityPerDivisionData] = useState("");
  const [productivityPerCustomerPerDivisionData, setProductivityPerCustomerPerDivisionData] = useState({});
  const [productivityPerCustomerData, setProductivityPerCustomerData] = useState("");
  const [enlargePaper, setEnlargePaper] = useState(false);
  const [enlargePaperProductivityCharts, setEnlargePaperProductivityCharts] = useState(false);
  const [expansionPanelsState, setExpansionPanelsState] = useState([]);
  const [isLoadingFilterSettingsEmployee, setLoadingFilterSettingsEmployee] = useState(true)
  const [isLoadingFilterSettingsCustomer, setLoadingFilterSettingsCustomer] = useState(true)
  const [isLoadingPerCustomerPerDivisionData, setLoadingPerCustomerPerDivisionData] = useState(false)
  const [isLoadingPerEmployeeData, setLoadingPerEmployeeData] = useState(false)
  const [inputData, setInputData] = useState({
    employeeName: "",
    startDate: new Date(today.getFullYear(), today.getMonth(), 1, 0),
    startTime: new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0),
    endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0, 0),
    endTime: new Date(today.getFullYear(), today.getMonth() + 1, 0, 0, 0)
  });
  const [employeeInfo, setEmployeeInfo] = useState({
    employeeId: ""
  });
  var employeeInfoBool = useRef(false);
  const [listOfEmployees, setListOfEmployees] = useState([]);

  var workDayMinutes = 465.0;
  const workDay = 465.0;

  //--------------------------//

  const [inputDataDivisions, setInputDataDivisions] = useState({
    customer: {customerId: null, customerName: null, division: null},
    customerName: "",
    customerId: "",
    startDate: new Date(today.getFullYear(), today.getMonth(), 1, 0),
    endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0, 0)
  });

  const divisionsGETBool = useRef(false);
  const [listofDivisions, setListofDivisions] = useState([]);
  const [productivityDivisionsData, setProductivityDivisionsData] = useState("");
  const productivityDivisionsDataBool = useRef(false);
  const [loadingProductivityDivisionsData, setLoadingProductivityDivisionsData] = useState(true);
  const [prodPerActivityArray, setProdPerActivityArray] = useState([]);
  const [tabDivisions, setTabtabDivisions] = useState(0);
  const [chartProdData, setChartProdData] = useState({});
  const [chartProdDataDivision, setChartProdDataDivision] = useState({});

  const [productivityPerDivision, setProductivityPerDivision] = useState({});

  //-------------------------//

  const [listOfCustomers, setListOfCustomers] = useState([]);
  const [finalProductivityPerCustomerArray, setFinalProductivityPerCustomerArray] = useState([]);
  const [finalProductivityPerDivisionArray, setFinalProductivityPerDivisionArray] = useState([]);

  const [performancePerEmployeePerCustomer, setPerformancePerEmployeePerCustomer] = useState();

  const [performancePerEmployeePerDivision, setPerformancePerEmployeePerDivision] = useState();
  const [performancePerEmployeePerActivity, setPerformancePerEmployeePerActivity] = useState();
  const performancePerEmployeePerActivityBool = useRef(false);
  const [totals, setTotals] = useState();
  const [expandAll, setExpandAll] = useState(false);
    const [fillChart, setFillChart] = useState(true);
    const inputsm = useMediaQuery(theme.breakpoints.down("sm"));

  const expandAllText = ["Expand all", "Collapse all"];
  const minWidth = useMediaQuery("(min-width:400px)");
  const tableWidth = useMediaQuery("(min-width:1700px)");


  const defaultPageNumbers = 5;
  const pageOptions = [5, 10, 25, 50, 100];
  const emptyDataMessage = props.t("Productivity.NotFoundEntries");
  const [tableState, setTableState] = useState({
    columns: [
      { title: props.t("Productivity.Ranking"), field: "id", editable: "never" },
      { title: props.t("Productivity.EmployeeID"), field: "id", editable: "never" },
      { title: props.t("Productivity.FirstName"), field: "firstName", editable: "never" },
      { title: props.t("Productivity.LastName"), field: "lastName", editable: "never" },
      { title: props.t("Productivity.ProducedUnits"), field: "amountProduced", editable: "never" },
      { title: props.t("Productivity.TargetUnits"), field: "targetProduced", editable: "never" },
      { title: props.t("Productivity.TimeSpentMinutes"), field: "timeSpent", editable: "never" },
      { title: props.t("Productivity.ProductivityPercentage"), field: "productivity", editable: "never" },
    ],
    data: []
  });



  //---------------------//

  // #region Database_interaction

  async function productivityPerEmployeeGET() {
    setLoading(true)
    await Promise.all([
      dailyProductivityGET(employeeInfo.employeeId, inputData.startDate, inputData.endDate),
      productivityPerTaskGET(employeeInfo.employeeId, inputData.startDate, inputData.endDate),
      productivityPerDivisionGET(employeeInfo.employeeId, inputData.startDate, inputData.endDate),
      productivityPerCustomerGET(employeeInfo.employeeId, inputData.startDate, inputData.endDate),
    ])
    setLoading(false)
  }

  async function employeesGET() {
    setLoadingFilterSettingsEmployee(true)
    const address = props.baseAddressProp + "/Database" + "/GetEmployeesInfo";
    try {
      const result = await Axios.get(address);
      setListOfEmployees(result.data);
    } catch (error) {}
    // await new Promise(resolve => setTimeout(resolve, 10000))
    setLoadingFilterSettingsEmployee(false)
  }

  async function divisionsGET() {
    const address = props.baseAddressProp + "/Database" + "/GetDivisionsInfo";
    try {
      const result = await Axios.get(address);
      setListofDivisions(result.data);
   
    } catch (error) {}
  }

  async function customersGET() {
    setLoadingFilterSettingsCustomer(true)
    const address = props.baseAddressProp + "/Productivity" + "/GetCustomers";
    try {
      const result = await Axios.get(address);
      setListOfCustomers(result.data);
    } catch (error) {}
    setLoadingFilterSettingsCustomer(false)
  }

  async function dailyProductivityGET(employeeId, timeStart, timeEnd) {
    if (!employeeId || !timeStart || !timeEnd) {
      return null;
    }

    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address =
      props.baseAddressProp + addressExtension + "/GetDailyProductivity" + "?employeeId=" + employeeId + "&timeStart=" + timeStart + "&timeEnd=" + timeEnd;
    try {
      let result = await Axios.get(address);
      setDailyWorkEfficiencyData(result.data);
    } catch (error) {}
  }

  async function productivityPerTaskGET(employeeId, timeStart, timeEnd) {
    if (!employeeId || !timeStart || !timeEnd) {
      return null;
    }

    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address =
      props.baseAddressProp + addressExtension + "/GetProductivityPerTask" + "?employeeId=" + employeeId + "&timeStart=" + timeStart + "&timeEnd=" + timeEnd;
    try {
      let result = await Axios.get(address);
      setProductivityPerTaskData(result.data);
    } catch (error) {}
  }

  async function productivityPerDivisionGET(employeeId, timeStart, timeEnd) {
    if (!employeeId || !timeStart || !timeEnd) {
      return null;
    }

    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address =
      props.baseAddressProp + addressExtension + "/GetProductivityPerDivision" + "?employeeId=" + employeeId + "&timeStart=" + timeStart + "&timeEnd=" + timeEnd;
    try {
      let result = await Axios.get(address);


      setProductivityPerDivisionData(result.data);
    } catch (error) {}
  }

  async function productivityCustomerPerDivisionGET(timeStart, timeEnd, customerId) {
    if (!timeStart || !timeEnd || !customerId) {
      return null;
    }
    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address =
      props.baseAddressProp + addressExtension + "/GetProductivityPerCustomerPerDivision" + "?timeStart=" + timeStart + "&timeEnd=" + timeEnd + "&customerId=" + customerId;
    try {
      setLoadingPerCustomerPerDivisionData(true)
      const result = await Axios.get(address);
      setProductivityPerCustomerPerDivisionData(result.data);
    } catch (error) {}
    setLoadingPerCustomerPerDivisionData(false)
  }

  async function productivityPerCustomerGET(employeeId, timeStart, timeEnd) {
    if (!employeeId || !timeStart || !timeEnd) {
      return null;
    }

    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address =
      props.baseAddressProp + addressExtension + "/GetProductivityPerCustomer" + "?employeeId=" + employeeId + "&timeStart=" + timeStart + "&timeEnd=" + timeEnd;
    try {
      let result = await Axios.get(address);
      setProductivityPerCustomerData(result.data);
    } catch (error) {}
  }

  //GetProductivityPerEmployeePerCustomer

  async function productivityPerEmployeePerCustomerGET(timeStart, timeEnd, userName) {
    if (!timeStart || !timeEnd || !userName) {
      return null;
    }

    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address =
      props.baseAddressProp + addressExtension + "/GetProductivityPerEmployeePerCustomer" + "?timeStart=" + timeStart + "&timeEnd=" + timeEnd + "&userName=" + userName;
    try {
      setLoadingPerEmployeeData(true)
      let result = await Axios.get(address);
      setPerformancePerEmployeePerCustomer(result.data);
    } catch (error) {}
    setLoadingPerEmployeeData(false)
  }

  async function productivityPerEmployeePerDivisionGET(timeStart, timeEnd) {
    if (!timeStart || !timeEnd) {
      return null;
    }

    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address =
      props.baseAddressProp + addressExtension + "/GetProductivityPerEmployeePerDivision" + "?timeStart=" + timeStart + "&timeEnd=" + timeEnd;
    try {
      let result = await Axios.get(address);
      setPerformancePerEmployeePerDivision(result.data);
    } catch (error) {}
  }

  async function productivityPerEmployeePerActivityGET(timeStart, timeEnd) {
    if (!timeStart || !timeEnd) {
      return null;
    }

    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address =
      props.baseAddressProp + addressExtension + "/GetProductivityPerEmployeePerActivity" + "?timeStart=" + timeStart + "&timeEnd=" + timeEnd;
    try {
      let result = await Axios.get(address);
      setPerformancePerEmployeePerActivity(result.data);
    } catch (error) {}
  }

  async function productivityDivisionsGET(timeStart, timeEnd) {
    if (!timeStart || !timeEnd || Object.keys(productivityDivisionsData || {}).length > 0) {
      return null;
    }

    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address = props.baseAddressProp + addressExtension + "/GetProductivityDivisions" + "?timeStart=" + timeStart + "&timeEnd=" + timeEnd;
    try {
      let result = await Axios.get(address);
      let chartDataDivisions = [];
      Object.keys(result.data).map((keyName, i) => {
        let activitiesArray = [];
        chartProdData[keyName] = {};
        Object.keys(result.data[keyName]).map((keyName2, j) =>{
          activitiesArray.push({[keyName2]: result.data[keyName][keyName2]});
          let prod = calcProdPerActivity(result.data[keyName][keyName2]);
          chartProdData[keyName][keyName2] = {"Produced": prod[0], "Target": prod[1], "Productivity": !prod[2] ? 0 : prod[2], "ProdPerActivity": prod[3]};
        });
        chartDataDivisions.push(chartProdDataPerDivision(activitiesArray, keyName));
      });
      setChartProdDataDivision(chartDataDivisions);
      setChartProdData(chartProdData);
      setProductivityDivisionsData(result.data);
    } catch (error) {
      console.error("error", error)
    }
  }

  async function totalsGET(timeStart, timeEnd) {
    if (!timeStart || !timeEnd) {
      return null;
    }

    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address =
      props.baseAddressProp + addressExtension + "/GetProductivityTotals" + "?timeStart=" + timeStart + "&timeEnd=" + timeEnd;
    try {
      let result = await Axios.get(address);


      setTotals(result.data);
    } catch (error) {}
  }

  // #endregion

  function chartProdDataPerDivision(activitiesArray, division){
    let chartActivityData = [];
    let divisionProductivity = [];

    for(let i = 0; i < activitiesArray.length; i++){
      let arg = activitiesArray[i][Object.keys(activitiesArray[i])];

      for(let j = 0; j < arg.length; j++){
        let activityEfficiency = Math.round((arg[j].amountProduced / arg[j].targetProduced) * 100 * 10) / 10;

        if(i == 0){
            let dwedSplitted = arg[j].date.toString().split("/");
            let d = new Date(dwedSplitted[2], dwedSplitted[1] - 1, dwedSplitted[0]);
            let day = d.toLocaleDateString("en-EN", { weekday: "long" }).toString();

            chartActivityData.push({
              name: (j + 1).toString(),
              Day: `${day}, ${arg[j].date}`,
              [new String(Object.keys(activitiesArray[i])) + new String("_Produced")]: Math.round(arg[j].amountProduced),
              [new String(Object.keys(activitiesArray[i])) + new String("_Target")]: Math.round(arg[j].targetProduced),
              // TargetPercentage: day != "Saturday" && day != "Sunday" ? 100.0 : 0.0, //insert exceptions -> holidays etc.
              [Object.keys(activitiesArray[i])]: !activityEfficiency ? 0.0 : activityEfficiency,
            });

            divisionProductivity.push({
              name: (j + 1).toString(),
              Day: `${day}, ${arg[j].date}`,
              Produced: arg[j].amountProduced,
              Target: arg[j].targetProduced
            });
        } else {
          chartActivityData[j][new String(Object.keys(activitiesArray[i])) + new String("_Produced")] = Math.round(arg[j].amountProduced);
          chartActivityData[j][new String(Object.keys(activitiesArray[i])) + new String("_Target")] = Math.round(arg[j].targetProduced);
          chartActivityData[j][Object.keys(activitiesArray[i])] = !activityEfficiency ? 0.0 : activityEfficiency;

          divisionProductivity[j]["Produced"] = Math.round(divisionProductivity[j].Produced + arg[j].amountProduced);
          divisionProductivity[j]["Target"] = Math.round(divisionProductivity[j].Target + arg[j].targetProduced);
        }
      }
    }

    let currentProductivityPerDivision = productivityPerDivision;
    currentProductivityPerDivision[division] = divisionProductivity
    setProductivityPerDivision(currentProductivityPerDivision);
    return chartActivityData;
  }

  function renderProductivity() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <div className={classes.appBarPosition}>
            <AppBar position="static" style={{ boxShadow: "none", minHeight: 64 }}>
              <Tabs
                value={tab} 
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                classes={{root: classes.customTabs}}
                TabIndicatorProps={{style: {
                  height: (tab === 0) ? "0px" : "2px"
              }}}>
                {insertTabs()}
              </Tabs>
            </AppBar>
          </div>
          {tab === 0 ? renderProductivityHome() : null}
          {tab === 1 ? renderEmployeesProductivity() : null}
          {tab === 2 ? renderDivisionsProductivity() : null}
          {tab === 3 ? renderTopPerformers() : null}
        </ThemeProvider>
      </div>
    );
  }

  function handleChange(event, newValue) {
    setTab(newValue);
  }

  function handleTabChangeDivisions(event, newValue) {
    setTabtabDivisions(newValue);
  }

  function insertTabs() {
    let items = [];
    for (let i = 0; i < tabLabels.length; i++) {
      if (i === 0) {
        items.push(<Tab label={tabLabels[i]} className={classes.tab} key={i} classes={{ root: classes.tab1 }} />);
      } else {
        items.push(<Tab label={tabLabels[i]} className={classes.tab} key={i} />);
      }
    }
    return items;
  }

  // #region useEffect

  useEffect(() => {
    if (!performancePerEmployeePerActivityBool.current) {
      performancePerEmployeePerActivityBool.current = true;
      return;
    }

    let array = [];
    let amount = Object.keys(performancePerEmployeePerActivity);
    for (let i = 0; i < amount.length; i++) {
      array.push(false);
    }

    setExpansionPanelsState(array);
  }, [performancePerEmployeePerActivity]);

  useEffect(() => {
    if (!productivityDivisionsDataBool.current) {
      productivityDivisionsDataBool.current = true;
      return;
    }

    setLoadingProductivityDivisionsData(false);
  }, [productivityDivisionsData]);

  useEffect(() => {
    if (!loadingFilterSettingsBool.current) {
      loadingFilterSettingsBool.current = true;
      return;
    }

    setLoadingFilterSettings(false);
  }, [listOfEmployees]);

  useEffect(() => {
    holidaysGET();
  }, [dailyWorkEfficiencyData]);

  useEffect(() => {
    productivityPerTask();
  }, [productivityPerTaskData]);

  useEffect(() => {
    productivityPerCustomer();
  }, [productivityPerCustomerData]);

  useEffect(() => {
    productivityPerDivisionFunction();
  }, [productivityPerDivisionData]);

  // useEffect(() => {
  //   if (!employeeInfoBool.current) {
  //     employeeInfoBool.current = true;
  //     return;
  //   }

  //   dailyProductivityGET(employeeInfo.employeeId, inputData.startDate, inputData.endDate);
  //   productivityPerTaskGET(employeeInfo.employeeId, inputData.startDate, inputData.endDate);
  //   productivityPerDivisionGET(employeeInfo.employeeId, inputData.startDate, inputData.endDate);
  //   productivityPerCustomerGET(employeeInfo.employeeId, inputData.startDate, inputData.endDate);
  // }, [employeeInfo, inputData.endDate, inputData.startDate]);

  useEffect(() => {
    if (!finalWorkEfficiencyArrayBool.current) {
      finalWorkEfficiencyArrayBool.current = true;
      return;
    }
    setLoading(false);
  }, [finalWorkEfficiencyArray]);

  // #endregion

  function productivityPerTask() {
    if (!productivityPerTaskData) {
      return;
    }

    let prodPerTaskArray = [];
    let count = 0;

    for (var i in productivityPerTaskData) {
      let productivity = Math.round((productivityPerTaskData[i].amountProduced / productivityPerTaskData[i].targetProduced) * 100 * 10) / 10;

      prodPerTaskArray.push({
        name: i,
        Productivity: !productivity ? 0.0 : productivity,
        Produced: productivityPerTaskData[i].amountProduced,
        Target: productivityPerTaskData[i].targetProduced,
        Colors: count++
      });
    }
    prodPerTaskArray.sort((a, b)=>b.Productivity - a.Productivity);

    setFinalProductivityPerTaskArray(prodPerTaskArray);
  }

  function productivityPerCustomer() {
    if (!productivityPerCustomerData) {
      return;
    }
    let prodPerCustomerArray = [];
    let count = 0;

    for (var i in productivityPerCustomerData) {
      let productivity = Math.round((productivityPerCustomerData[i].amountProduced / productivityPerCustomerData[i].targetProduced) * 100 * 10) / 10;

      prodPerCustomerArray.push({
        name: i,
        Productivity: !productivity ? 0.0 : productivity,
        Produced: productivityPerCustomerData[i].amountProduced,
        Target: productivityPerCustomerData[i].targetProduced,
        Colors: count++
      });
    }

    prodPerCustomerArray.sort((a, b)=>b.Productivity - a.Productivity);
    setFinalProductivityPerCustomerArray(prodPerCustomerArray);
  }

  function productivityPerDivisionFunction() {
    if (!productivityPerDivisionData) {
      return;
    }
    let prodPerDivisionArray = [];
    let count = 0;

    for (var i in productivityPerDivisionData) {
      let productivity = Math.round((productivityPerDivisionData[i].amountProduced / productivityPerDivisionData[i].targetProduced) * 100 * 10) / 10;

      prodPerDivisionArray.push({
        name: productivityPerDivisionData[i].divisionName,
        Productivity: !productivity ? 0.0 : productivity,
        Produced: productivityPerDivisionData[i].amountProduced,
        Target: productivityPerDivisionData[i].targetProduced,
        Colors: count++
      });
    }

    prodPerDivisionArray.sort((a, b)=>b.Productivity - a.Productivity);
    setFinalProductivityPerDivisionArray(prodPerDivisionArray);
  }

  async function holidaysGET(){
    const address = props.baseAddressProp + "/AnnualLeave" + "/GetHolidays";
    let holidays = [];
    try{
      let result = await Axios.get(address);
      let paidLeaveDays = 0;

      for(let i = 0; i < result.data.length; i++){
        holidays.push(new Date(result.data[i]["date"]));
      }

      props.holidaysCallbackProp(holidays);
      
    } catch(error){}
    workEfficiency(holidays);
  }

  function workEfficiency(holidays) {
    if (!dailyWorkEfficiencyData) {
      return;
    }

    let workEfficiencyArray = [];
    let timelineArray = [];
    let totalAmountProduced = 0;
    let totalTarget = 0;
    let totalTimeWorked = 0;
    let totalTargetTimeWorked = 0;

    for (let i = 0; i < dailyWorkEfficiencyData.length; i++) {
      let holiday = false;
      workDayMinutes = workDay;
      let dwedSplitted = dailyWorkEfficiencyData[i].date.toString().split("/");
      let d = new Date(dwedSplitted[2], dwedSplitted[1] - 1, dwedSplitted[0]);
      let day = d.toLocaleDateString("en-EN", { weekday: "long" }).toString();

      for(let j = 0; j < holidays.length; j++){  
        if(isSameDay(d, holidays[j])){
          holiday = true;
        }
      }

      totalAmountProduced += dailyWorkEfficiencyData[i].amountProduced;
      totalTarget += dailyWorkEfficiencyData[i].targetProduced;
      let workEfficiency = Math.round((dailyWorkEfficiencyData[i].amountProduced / dailyWorkEfficiencyData[i].targetProduced) * 100 * 10) / 10;

      workEfficiencyArray.push({
        name: (i + 1).toString(),
        Productivity: !workEfficiency ? 0.0 : workEfficiency,
        Produced: dailyWorkEfficiencyData[i].amountProduced,
        Target: dailyWorkEfficiencyData[i].targetProduced === 0 ? 0 : dailyWorkEfficiencyData[i].targetProduced,
        TargetPercentage: (day !== props.t("Productivity.Saturday") && day !== props.t("Productivity.Sunday") && dailyWorkEfficiencyData[i].targetProduced !== 0)? 100.0 : 0.0,
        Day: `${day}, ${dailyWorkEfficiencyData[i].date}`,
        DayShort: day,
        leave: dailyWorkEfficiencyData[i].leave,
        isHoliday: holiday
      });

      totalTimeWorked += dailyWorkEfficiencyData[i].timeSpent;

      if (day !== props.t("Productivity.Saturday") && day !== props.t("Productivity.Sunday")) {
        //target time worked exceptions
        totalTargetTimeWorked += workDayMinutes;
      } else {
        workDayMinutes = 0.0;
      }

      let timeEfficiency = Math.round((dailyWorkEfficiencyData[i].timeSpent / workDayMinutes) * 100 * 10) / 10;

      timelineArray.push({
        name: (i + 1).toString(),
        Efficiency: !timeEfficiency ? 0.0 : timeEfficiency,
        TimeSpent: dailyWorkEfficiencyData[i].timeSpent,
        Target: holiday ? 0.0 : workDayMinutes,
        TargetPercentage: day !== props.t("Productivity.Saturday") && day !== props.t("Productivity.Sunday") && holiday !== true? 100.0 : 0.0,
        Day: `${day}, ${dailyWorkEfficiencyData[i].date}`,
        DayShort: day,
        leave: dailyWorkEfficiencyData[i].leave,
        isHoliday: holiday
      });
      d.setDate(d.getDate() + 1);
    }
    unitsProduced.current = totalAmountProduced;
    unitsTarget.current = totalTarget;
    setFinalWorkEfficiencyArray(workEfficiencyArray);

    timeSpentWorking.current = totalTimeWorked;
    targetTimeSpentWorking.current = totalTargetTimeWorked;
    setTimeSpentWorkingArray(timelineArray);
  }

  function insertProdTableHead() {
    let tableHead = [];
    productivityTableHead.map((value, index) => {
      tableHead.push(
        <TableCell align="right" style={{ fontWeight: 600, color: "rgb(0, 0, 0)" }} key={value}>
          {value}
        </TableCell>
      );
    });
    return tableHead;
  }

  function insertTimelineTableHead() {
    let tableHead = [];
    timelineTableHead.map((value, index) => {
      tableHead.push(
        <TableCell align="right" style={{ fontWeight: 600, color: "rgb(0, 0, 0)" }} key={value}>
          {value}
        </TableCell>
      );
    });
    return tableHead;
  }

  function insertProdTableRows() {
    let tableRow = [];
    finalWorkEfficiencyArray.map((value, index) => {
      let bgColor = "inherit";

      if(value.DayShort === "Saturday" || value.DayShort === "Sunday"){
        bgColor = "rgba(242, 242, 242, 1)";
      } else if(value.leave === 1 || value.leave === 2){
        bgColor = "rgba(243, 177, 71, 0.75)";
      }

      if(value.isHoliday){
        bgColor ="rgba(22, 160, 31, 0.63)";
      }

      tableRow.push(
        <TableRow key={value.name} style={{ backgroundColor: bgColor}}>
          <TableCell align="right" style={{ fontSize: "0.75rem" }}>
            {value.Day}
          </TableCell>
          <TableCell align="right" style={{ fontSize: "0.75rem" }}>
            {value.DayShort !== "Saturday" && value.DayShort !== "Sunday" ? value.Produced : ""}
          </TableCell>
          <TableCell align="right" style={{ fontSize: "0.75rem" }}>
            {value.DayShort !== "Saturday" && value.DayShort !== "Sunday" ? value.Target : ""}
          </TableCell>
          <TableCell align="right" style={{ fontSize: "0.75rem" }}>
            {value.DayShort !== "Saturday" && value.DayShort !== "Sunday" ? value.Productivity : ""}
          </TableCell>
        </TableRow>
      );
    });
    return tableRow;
  }

  function insertTimelineTableRows() {
    let tableRow = [];
    timeSpentWorkingArray.map((value, index) => {
      let bgColor = "inherit";

      if(value.DayShort === "Saturday" || value.DayShort === "Sunday"){
        bgColor = "rgba(242, 242, 242, 1)";
      } else if(value.leave === 1 || value.leave === 2){
        bgColor = "rgba(243, 177, 71, 0.75)";
      }

      if(value.isHoliday){
        bgColor ="rgba(22, 160, 31, 0.63)";
      }

      tableRow.push(
        <TableRow
          key={value.name}
          style={{
            // background: (value.Day != "Saturday" && value.Day != "Sunday") ? "inherit" :
            //   "repeating-linear-gradient( 315deg,rgba(230, 230, 230, 0.5),rgba(230, 230, 230, 0.5) 10px,rgba(190, 190, 190, 0.5) 10px,rgba(190, 190, 190, 0.5) 20px)"
            backgroundColor: bgColor
          }}
        >
          <TableCell align="right" style={{ fontSize: "0.75rem" }}>
            {value.Day}
          </TableCell>
          <TableCell align="right" style={{ fontSize: "0.75rem" }}>
            {value.DayShort !== "Saturday" && value.DayShort !== "Sunday" ? value.TimeSpent : ""}
          </TableCell>
          <TableCell align="right" style={{ fontSize: "0.75rem" }}>
            {value.DayShort !== "Saturday" && value.DayShort !== "Sunday" ? value.Target : ""}
          </TableCell>
          <TableCell align="right" style={{ fontSize: "0.75rem" }}>
            {value.DayShort !== "Saturday" && value.DayShort !== "Sunday" ? value.Efficiency : ""}
          </TableCell>
        </TableRow>
      );
    });
    return tableRow;
  }

  const ProductivityTooltip = ({ active, payload, label }) => {
    if (!dailyWorkEfficiencyData) return null;

    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid rgba(225, 225, 225, 1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            padding: "10px"
          }}
        >
          <Typography variant="button" style={{ color: blue[500], textTransform: "capitalize" }} className="label">{`${payload[0].payload.Day}`}</Typography>
          <br />
          <div>
            {payload[0].payload.isHoliday ? (
              <React.Fragment>
              <Typography variant="caption" style={{color: "rgba(22, 160, 31, 1)", fontWeight: 500}}>{"Holiday"}</Typography>
              <br />
              </React.Fragment>
            ) : (payload[0].payload.leave === 1 ? (
              <React.Fragment>
              <Typography variant="caption" style={{color: "rgba(243, 177, 71, 1)", fontWeight: 500}}>{"Paid leave"}</Typography>
              <br />
              </React.Fragment>
            ) : (payload[0].payload.leave === 2 ? (
              <React.Fragment>
              <Typography variant="caption" style={{color: "rgba(243, 177, 71, 1)", fontWeight: 500}}>{"Sent home"}</Typography>
              <br />
              </React.Fragment>
            ) : (null)))}
          </div>
          <Typography variant="caption" style={{ color: "#8884d8" }}>
            {" "}
            {`${props.t("Productivity.ProductivityText")}: ${payload[0].payload.Productivity}%`}
          </Typography>
          <br />
          <Typography variant="caption">{`${props.t("Productivity.Produced")}: ${Math.round(payload[0].payload.Produced)} ${props.t("Productivity.Units")}`}</Typography>
          <br />
          <Typography variant="caption">{`${props.t("Productivity.Target")}: ${Math.round(payload[0].payload.Target)} ${props.t("Productivity.Units")}`}</Typography>
          <br />
        </div>
      );
    }
    return null;
  };

  const TimelineTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid rgba(225, 225, 225, 1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            padding: "10px"
          }}
        >
          <Typography variant="button" style={{ color: blue[500], textTransform: "capitalize" }} className="label">{`${payload[0].payload.Day}`}</Typography>
          <br />
          <div>
            {payload[0].payload.isHoliday ? (
              <React.Fragment>
              <Typography variant="caption" style={{color: "rgba(22, 160, 31, 1)", fontWeight: 500}}>{"Holiday"}</Typography>
              <br />
              </React.Fragment>
            ) : (payload[0].payload.leave === 1 ? (
              <React.Fragment>
              <Typography variant="caption" style={{color: "rgba(243, 177, 71, 1)", fontWeight: 500}}>{"Paid leave"}</Typography>
              <br />
              </React.Fragment>
            ) : (payload[0].payload.leave === 2 ? (
              <React.Fragment>
              <Typography variant="caption" style={{color: "rgba(243, 177, 71, 1)", fontWeight: 500}}>{"Sent home"}</Typography>
              <br />
              </React.Fragment>
            ) : (null)))}
          </div>
          <Typography variant="caption" style={{ color: "#82ca9d" }}>
            {" "}
            {`${props.t("Productivity.Progression")}: ${payload[0].payload.Efficiency}%`}
          </Typography>
          <br />
          <Typography variant="caption">{`${props.t("Productivity.WorkingTime")}: ${Math.round(payload[0].payload.TimeSpent)} ${props.t("Productivity.Minutes")}`}</Typography>
          <br />
          <Typography variant="caption">{`${props.t("Productivity.Target")}: ${Math.round(payload[0].payload.Target)} ${props.t("Productivity.Minutes")}`}</Typography>
          <br />
        </div>
      );
    }
    return null;
  };

  const ProductivityPerTaskTooltip = ({ active, payload, label }) => {
    if (!dailyWorkEfficiencyData) return null;
    if(!payload) return null;

    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid rgba(225, 225, 225, 1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            padding: "10px"
          }}
        >
          <Typography variant="button" style={{ color: blue[500], textTransform: "capitalize" }} className="label">{`${payload[0].payload.name}`}</Typography>
          <br />
          <Typography variant="caption" style={{ color: colors[payload[0].payload.Colors], fontWeight: 600 }}>
            {" "}
            {`${props.t("Productivity.Productivity")}: ${payload[0].payload.Productivity}%`}
          </Typography>
          <br />
          <Typography variant="caption">{`${props.t("Productivity.Produced")}: ${Math.round(payload[0].payload.Produced)} ${props.t("Productivity.Units")}`}</Typography>
          <br />
          <Typography variant="caption">{`${props.t("Productivity.Target")}: ${Math.round(payload[0].payload.Target)} ${props.t("Productivity.Units")}`}</Typography>
          <br />
        </div>
      );
    }
    return null;
  };

  const ProductivityPerEmployeePerCustomerTooltip = ({ active, payload, label }) => {
    if(!performancePerEmployeePerCustomer) return null;
    
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid rgba(225, 225, 225, 1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            padding: "10px"
          }}
        >
          <Typography variant="button" style={{ color: blue[500], textTransform: "capitalize" }} className="label">{`${payload[0].payload.firstName} ${payload[0].payload.lastName}`}</Typography>
          <br />
          <Typography variant="caption" style={{ color: colors[payload[0].payload.Colors], fontWeight: 600 }}>
            {" "}
            {`${props.t("Productivity.Productivity")}: ${payload[0].payload.productivity}%`}
          </Typography>
          <br />
          <Typography variant="caption">{`${props.t("Productivity.Produced")}: ${Math.round(payload[0].payload.amountProduced)} ${props.t("Productivity.Units")}`}</Typography>
          <br />
          <Typography variant="caption">{`${props.t("Productivity.Target")}: ${Math.round(payload[0].payload.targetProduced)} ${props.t("Productivity.Units")}`}</Typography>
          <br />
        </div>
      );
    }
    return null;
  };

  const TotalsTooltip = ({ active, payload, label }) => {
    if(!performancePerEmployeePerCustomer) return null;
    
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid rgba(225, 225, 225, 1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            padding: "10px"
          }}
        >
          <Typography variant="button" style={{ color: blue[500], textTransform: "capitalize" }} className="label">{`${payload[0].payload.firstName} ${payload[0].payload.lastName}`}</Typography>
          <br />
          <Typography variant="caption" style={{ color: colors[0], fontWeight: 600 }}>
            {" "}
            {`${props.t("Productivity.Productivity")}: ${payload[0].payload.productivity}%`}
          </Typography>
          <br />
          <Typography variant="caption">{`${props.t("Productivity.Produced")}: ${Math.round(payload[0].payload.amountProduced)} ${props.t("Productivity.Units")}`}</Typography>
          <br />
          <Typography variant="caption">{`${props.t("Productivity.Target")}: ${Math.round(payload[0].payload.targetProduced)} ${props.t("Productivity.Units")}`}</Typography>
          <br />
        </div>
      );
    }
    return null;
  };

  const ActivitiesDivisionTooltip = ({ active, payload, label }) => {
   
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid rgba(225, 225, 225, 1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            padding: "10px"
          }}
        >
          <Typography variant="button" style={{ color: blue[500], textTransform: "capitalize" }} className="label">{`${payload[0].payload.Day}`}</Typography>
          <br />
          <Typography variant="caption" style={{ color: "#8884d8" }}>
            {" "}
            {`${props.t("Productivity.Productivity")}: ${payload[0].payload.Productivity}%`}
          </Typography>
          <br />
          <Typography variant="caption">{`${props.t("Productivity.Produced")}: ${Math.round(payload[0].payload.Produced)} ${props.t("Productivity.Units")}`}</Typography>
          <br />
          <Typography variant="caption">{`${props.t("Productivity.Target")}: ${Math.round(payload[0].payload.Target)} ${props.t("Productivity.Units")}`}</Typography>
          <br />
        </div>
      );
    }
    return null;
  };

  const ChartsDivisionTooltip = ({ active, payload, label }) => {
    if(!payload)
    return null;

    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid rgba(225, 225, 225, 1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            padding: "10px"
          }}
        >
          <Typography variant="button" style={{ color: blue[500], textTransform: "capitalize" }} className="label">{`${payload[0].payload.Day}`}</Typography>
          <br />

          {payload.map((item, index) =>{
            let newIndex = 4 + index * 3;
            return(
            <div key={index}>
            <Typography variant="caption" style={{ color: colors[index] }}>
            {`${props.t("Productivity.Productivity")}: ${item.payload[Object.keys(item.payload)[newIndex]]}% | ${props.t("Productivity.Produced")}: ${Math.round(item.payload[Object.keys(item.payload)[2 + index * 3]])} ${props.t("Productivity.Units")} | ${props.t("Productivity.Target")}: ${Math.round(item.payload[Object.keys(item.payload)[3 + index * 3]])} ${props.t("Productivity.Units")}`}
          </Typography>
          <br/>
          </div>
          )})}


          {/* <Typography variant="caption">{`Produced: ${payload[0].payload.Produced} units`}</Typography>
          <br />
          <Typography variant="caption">{`Target: ${payload[0].payload.Target} units`}</Typography>
          <br /> */}
        </div>
      );
    }
    return null;
  };

  const DivisionsTooltip = ({ active, payload, label, type }) => {
    if(!payload)
    return null;

    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid rgba(225, 225, 225, 1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            padding: "10px"
          }}
        >
          <Typography variant="button" style={{ color: blue[500], textTransform: "capitalize" }} className="label">{`${payload[0].payload.date}`}</Typography>
          <br />

          {payload.map((item, index) =>{
            const keys = Object.keys(item.payload).filter(i => i !== "date" && i !== "name")
            return(
              <div key={index}>
                <Typography variant="caption" style={{ color: colors[index] }}>
                {type == "customer" ?
                `${props.t("Productivity.Productivity")}: ${item.payload.productivity.toFixed(1)}% | ${props.t("Productivity.Produced")}: ${Math.round(item.payload.amountProduced)} ${props.t("Productivity.Units")} | ${props.t("Productivity.Target")}: ${Math.round(item.payload.targetProduced)} ${props.t("Productivity.Units")}`
                : `${props.t("Productivity.Productivity")}: ${item.payload[keys[1 + index * 3]]}% | ${props.t("Productivity.Produced")}: ${Math.round(item.payload[keys[index * 3]])} ${props.t("Productivity.Units")} | ${props.t("Productivity.Target")}: ${Math.round(item.payload[keys[2 + index * 3]])} ${props.t("Productivity.Units")}`
                }
                </Typography>
                <br/>
              </div>
          )})}
        </div>
      );
    }
    return null;
  };

  function handleInputDataChange(event) {
    const result = listOfEmployees.find(i => i.userName === event.target.value)
    setEmployeeInfo({employeeId: result.employeeId});
    setInputData({ ...inputData, employeeName: event.target.value });
  }

  function handleCustomerChange(event) {
    const result = listOfCustomers.find(i => i.customerId === event.target.value)
    setInputDataDivisions({ ...inputDataDivisions, customer: result, customerName: event.target.value });
  }

  function insertMenuItems() {
    return (listOfEmployees.map((i) => (
      <MenuItem key={i.employeeId} value={i.userName}>
        {`[${i.employeeId}] ${i.firstName} ${i.lastName}`}
      </MenuItem>
    )))
  }

  function insertCustomerMenuItems() {
    return (listOfCustomers.map((i) => (
      <MenuItem key={i.customerId} value={i.customerId}>
        {`[${i.customerId}] ${i.customerName}`}
      </MenuItem>
    )))
  }

  function handleStartDatePickerChange(date) {
    setInputData({ ...inputData, ["startDate"]: date });
  }

  function handleEndDatePickerChange(date) {
    setInputData({ ...inputData, ["endDate"]: date });
  }

  function handleStartEndDatePickerChange(start, end){
    let data = {...inputData};
    data["startDate"] = start;
    data["endDate"] = end;
    setInputData(data);
  }
  
  function handleStartDatePickerChangeDivisions(date) {
    setInputDataDivisions({ ...inputDataDivisions, ["startDate"]: date });
  }

  function handleEndDatePickerChangeDivisions(date) {
    setInputDataDivisions({ ...inputDataDivisions, ["endDate"]: date });
  }

  // function handleStartTimePickerChange(date) {
  //   setInputData({ ...inputData, ["startTime"]: date });
  // }

  // function handleEndTimePickerChange(date) {
  //   setInputData({ ...inputData, ["endTime"]: date });
  // }

  // function createStartTime() {
  //   let date = new Date();
  //   let newDate = new Date(date.getFullYear(), date.getMonth(), date.getDay(), 6);
  //   return newDate;
  // }

  function weekMonthYear(employeeId, startDate, endDate) {
    if (!employeeId) {
      return null;
    }
    dailyProductivityGET(employeeId, startDate, endDate);
    productivityPerTaskGET(employeeId, startDate, endDate);
  }

  function weekMonthYearDivisions(startDate, endDate) {
    // productivityDivisionsGET(startDate, endDate);
    // console.log(startDate, endDate, inputDataDivisions["customerName"])
    // console.log(inputDataDivisions.customerName)
    // productivityCustomerPerDivisionGET(startDate, endDate, inputDataDivisions.customerName)
  }

  function weekMonthYearPerCustomer(startDate, endDate, customerName) {
    // productivityCustomerPerDivisionGET(startDate, endDate, customerName)
  }

  function renderTopPerformers() {
    // if (!divisionsGETBool.current) {
    //   divisionsGET();
    //   customersGET();
    //   divisionsGETBool.current = true;
    // }
    return (
      <div className={classes.paperPosition}>
        {inputsm ? (
          // #region MOBILE
          <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              className={classes.paperPropsMobile}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{paddingBottom: "0px"}}>
                    <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{props.t("Productivity.FilterSettings")}</Typography>
                  </Grid>

                  <Grid item xs={12} style={{paddingBottom: "0px"}}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="mui-pickers-date"
                      label={props.t("Productivity.StartDate")}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={inputDataDivisions.startDate}
                      onChange={(event, date) => {
                        handleStartDatePickerChangeDivisions(event, date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="mui-pickers-date"
                      label={props.t("Productivity.EndDate")}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={inputDataDivisions.endDate}
                      onChange={(event, date) => {
                        handleEndDatePickerChangeDivisions(event, date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>

              <Grid container style={{paddingTop: "12px"}}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}SSS
                    onClick={event => {
                      let today = new Date();
                      let start = startOfWeek(today, { weekStartsOn: 1 });
                      let end = endOfWeek(today, { weekStartsOn: 1 });
                      let iDataDivisions = inputDataDivisions;
                      iDataDivisions["startDate"] = start;
                      iDataDivisions["endDate"] = end;
                      setInputDataDivisions(iDataDivisions);
                      weekMonthYearDivisions(start, end);
                    }}
                  >
                    {props.t("Productivity.Week")}
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      let today = new Date();
                      let start = startOfMonth(today);
                      let end = endOfMonth(today);
                      let iDataDivisions = inputDataDivisions;
                      iDataDivisions["startDate"] = start;
                      iDataDivisions["endDate"] = end;
                      setInputDataDivisions(iDataDivisions);
                      weekMonthYearDivisions(start, end);
                    }}
                  >
                    {props.t("Productivity.Month")}
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      let today = new Date();
                      let start = startOfYear(today);
                      let end = endOfYear(today);
                      let iDataDivisions = inputDataDivisions;
                      iDataDivisions["startDate"] = start;
                      iDataDivisions["endDate"] = end;
                      setInputDataDivisions(iDataDivisions);
                      weekMonthYearDivisions(start, end);
                    }}
                  >
                    {props.t("Productivity.Year")}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              className={classes.paperPropsMobile}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              {loadingProductivityDivisionsData ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (


                <Grid item xs={12} style={{ marginTop: inputsm ? 0 : 10, padding: inputsm ? "0px" : "10px" }}>


            <AppBar position="static" color="default" style={{ boxShadow: "none", marginBottom: inputsm ? "20px" : "40px"}}>
              <Tabs value={tabDivisions} textColor="primary" indicatorColor="primary" onChange={handleTabChangeDivisions} variant="scrollable"
                scrollButtons="on">
                {[props.t("Productivity.Customers"), props.t("Productivity.Divisions"), props.t("Productivity.Activities"), props.t("Productivity.Totals") ].map((value, index)=>(
                  <Tab label={value} key={index}/>
                ))}

                {tabDivisions !== 3 && !inputsm ? (
                 <Button variant="outlined" color="primary" style={{marginLeft:"auto", marginRight:"0px", borderRadius:"0px"}} onClick={event => {
                  let panels = [...expansionPanelsState];
                  let state = false;
                  let counter = 0;
                  for (let i = 0; i < panels.length; i++) {
                    if (panels[i]) {
                      counter++;
                    }
                  }

                  if (counter === panels.length) {
                    for (let i = 0; i < panels.length; i++) {
                      panels[i] = false;
                    }
                    state = false;
                  } else {
                    for (let i = 0; i < panels.length; i++) {
                      panels[i] = true;
                    }
                    state = true;
                  }

                  setExpansionPanelsState(panels); //0 is expand all // 1 is collapse all
                }}>
                {/* {expandAll ? expandAllText[1] : expandAllText[0]} */}
                <UnfoldMoreIcon />
                </Button>) : null}
              </Tabs>

             
            </AppBar>

            

            {tabPerformanceSwitch(tabDivisions)}
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
        // #endregion
        ) : (
          // #region DESKTOP
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{props.t("Productivity.FilterSettings")}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="mui-pickers-date"
                      label={props.t("Productivity.StartDate")}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={inputDataDivisions.startDate}
                      onChange={(event, date) => {
                        handleStartDatePickerChangeDivisions(event, date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="mui-pickers-date"
                      label={props.t("Productivity.EndDate")}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={inputDataDivisions.endDate}
                      onChange={(event, date) => {
                        handleEndDatePickerChangeDivisions(event, date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>

              <Grid container>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      let today = new Date();
                      let start = startOfWeek(today, { weekStartsOn: 1 });
                      let end = endOfWeek(today, { weekStartsOn: 1 });
                      let iDataDivisions = inputDataDivisions;
                      iDataDivisions["startDate"] = start;
                      iDataDivisions["endDate"] = end;
                      setInputDataDivisions(iDataDivisions);
                      weekMonthYearDivisions(start, end);
                    }}
                  >
                    {props.t("Productivity.Week")}
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      let today = new Date();
                      let start = startOfMonth(today);
                      let end = endOfMonth(today);
                      let iDataDivisions = inputDataDivisions;
                      iDataDivisions["startDate"] = start;
                      iDataDivisions["endDate"] = end;
                      setInputDataDivisions(iDataDivisions);
                      weekMonthYearDivisions(start, end);
                    }}
                  >
                    {props.t("Productivity.Month")}
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      let today = new Date();
                      let start = startOfYear(today);
                      let end = endOfYear(today);
                      let iDataDivisions = inputDataDivisions;
                      iDataDivisions["startDate"] = start;
                      iDataDivisions["endDate"] = end;
                      setInputDataDivisions(iDataDivisions);
                      weekMonthYearDivisions(start, end);
                    }}
                  >
                    {props.t("Productivity.Year")}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              {loadingProductivityDivisionsData ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} style={{ marginTop: 10, padding: "10px" }}>
            <AppBar position="static" color="default" style={{ boxShadow: "none", marginBottom: "40px"}}>
              <Tabs value={tabDivisions} textColor="primary" indicatorColor="primary" onChange={handleTabChangeDivisions}>
                {[props.t("Productivity.Customers"), props.t("Productivity.Divisions"), props.t("Productivity.Activities"), props.t("Productivity.Totals") ].map((value, index)=>(
                  <Tab label={value} key={index}/>
                ))}

                {tabDivisions !== 3 ? (
                 <Button variant="outlined" color="primary" style={{marginLeft:"auto", marginRight:"0px", borderRadius:"0px"}} onClick={event => {
                  let panels = [...expansionPanelsState];
                  let state = false;
                  let counter = 0;
                  for (let i = 0; i < panels.length; i++) {
                    if (panels[i]) {
                      counter++;
                    }
                  }

                  if (counter === panels.length) {
                    for (let i = 0; i < panels.length; i++) {
                      panels[i] = false;
                    }
                    state = false;
                  } else {
                    for (let i = 0; i < panels.length; i++) {
                      panels[i] = true;
                    }
                    state = true;
                  }

                  setExpansionPanelsState(panels); //0 is expand all // 1 is collapse all
                }}>
                {/* {expandAll ? expandAllText[1] : expandAllText[0]} */}
                <UnfoldMoreIcon />
                </Button>) : null}
              </Tabs>

             
            </AppBar>

            

            {tabPerformanceSwitch(tabDivisions)}
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
        // #endregion
        )}
      </div>
    );
  }


  function tabPerformanceSwitch(currentTab){
    switch(currentTab){
      case 0: return customerPerformance();
      case 1: return divisionPerformance();
      case 2: return activityPerformance();
      case 3: return totalsPerformance();
    }
  }


  function totalsPerformance(){
    if(!totals || Object.keys(totals).length <= 0)
    return null;

    let array = [];
    let tempArray = [];
    let dataHolder = {};

    let objectKeys = Object.keys(totals);
    let meanProductivity = 0;
    let counter = 0;

    let target = 0;
    let amount = 0;
    let users = 0;

    for(let i = 0; i < objectKeys.length; i++){
      tempArray.push(totals[objectKeys[i]]);
      meanProductivity += totals[objectKeys[i]].productivity;
      amount += totals[objectKeys[i]].amountProduced;
      target += totals[objectKeys[i]].targetProduced;
      counter++;
    }

    //meanProductivity = Math.round(amount / target * 100 * 10) / 10;
    meanProductivity = Math.round(meanProductivity / counter * 10) / 10;
    tempArray.sort((a, b)=>b.productivity - a.productivity);

    for(let k = 0; k < tempArray.length; k++){
      tempArray[k]["rank"] = k + 1;
      users++;
    }

    const table = {
      columns: [
        { title: props.t("Productivity.Ranking"), field: "rank", editable: "never" },
        { title: props.t("Productivity.EmployeeID"), field: "id", editable: "never" },
        { title: props.t("Productivity.FirstName"), field: "firstName", editable: "never" },
        { title: props.t("Productivity.LastName"), field: "lastName", editable: "never" },
        { title: props.t("Productivity.ProducedUnits"), field: "amountProduced", editable: "never" },
        { title: props.t("Productivity.TargetUnits"), field: "targetProduced", editable: "never" },
        { title: props.t("Productivity.AnnualLeaveMinutes"), field: "leaveTime", editable: "never" },
        { title: props.t("Productivity.WorkingTimeMinutes"), field: "timeSpent", editable: "never" },
        { title: "Total time [minutes]", field: "totalTime", editable: "never" },
        { title: props.t("Productivity.ProductivityPercentage"), field: "productivity", editable: "never" },
      ],
      data: tempArray
    };


    array.push(
          <Paper key ={1} style={{marginBottom: inputsm ? 25 : 50, overflow: "auto"}}>

          <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin", padding: "15px", paddingBottom: inputsm ? "0px" : "15px", display:"inline-block"}} >
          {props.t("Productivity.Totals")}
        </Typography>

        {inputsm ? (

        <Typography variant="button" style={{paddingLeft: "15px", paddingBottom: "15px", display:"flex"}} >
         {props.t("Productivity.MeanProductivity")} <div style={{color: blue[500], marginLeft: "4px"}}>{meanProductivity}%</div>
        </Typography>) : (

        <Typography variant="button" style={{padding: "15px", float:"right", paddingRight: "25px", display:"inline-flex"}} >
         {props.t("Productivity.MeanProductivity")} <div style={{color: blue[500], marginLeft: "4px"}}>{meanProductivity}%</div>
        </Typography>
        )}

        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            data={tempArray}
                            margin={{
                              top: 0,
                              right: 25,
                              left: 10,
                              bottom: 30
                            }}
                          >
                       
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="id">
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y="280" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("Productivity.EmployeeID").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="0" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="36" dy="1.5em">
                                    {props.t("Productivity.ProductivityPercentage").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </YAxis>
                            <Tooltip content={<TotalsTooltip />} />
                            <Legend
                              payload={[{
                                id: "Productivity per employee",
                                type: "square",
                                value: props.t("Productivity.ProdPerEmployee"),
                                color: colors[0]
                              }]}
                              verticalAlign="top"
                              height={36}
                            />

                            <Bar dataKey="productivity" fill={colors[0]}>
                              {/* {dataHolder[item].map((entry, index) => {
                                return <Cell fill={colors[index]} key={colors[index]} />;
                              })} */}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>

                        {/* <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{color: blue[500]}} />}> */}
                        <div style={{paddingLeft: "25px", paddingRight: "25px", marginTop: inputsm ? "0px" : "25px", paddingBottom: "30px"}}>

          <MaterialTable
            components={{
              Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />
            }}
            columns={table.columns}
            data={table.data}
            
            onRowClick={(event, row) => {}}
            options={{
              exportButton: true,
              filtering: false,
              search: true,
              sorting: true,
              pageSize: users,
              pageSizeOptions: pageOptions,
              padding: "dense",
              showTitle: false,
              rowStyle: (rowData, index) => {
                if(rowData["rank"] === 1){
                 return {backgroundColor: "rgba(214, 175, 54, 0.5)"};
                } else if(rowData["rank"] === 2){
                  return {backgroundColor: "rgba(215, 215, 215, 0.5)"};
                } else if(rowData["rank"] === 3){
                  return {backgroundColor: "rgba(167, 112, 68, 0.5)"};
                }else{
                  return {backgroundColor: "#FFFFFF"};
                }
              }
            }}
            style={{ boxShadow: "none" }}
            localization={{
              pagination:{
                labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                labelRowsSelect: props.t("Database.Rows"),
                firstTooltip: props.t("EditEntries.FirstPage"),
                previousTooltip: props.t("EditEntries.PreviousPage"),
                nextTooltip: props.t("EditEntries.NextPage"),
                lastTooltip: props.t("EditEntries.LastPage"),
              },
              toolbar:{
                nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                searchPlaceholder: props.t("EditEntries.Search"),
                searchTooltip: props.t("EditEntries.Search"),
                exportTitle: props.t("EditEntries.Export"),
                exportName: props.t("EditEntries.Export")
              },
              body: {
                editRow: {
                  deleteText: ""
                },
                addTooltip: "Add row",
                deleteToolTip: "Delete row",
                editToolTip: "Edit row",
                emptyDataSourceMessage: emptyDataMessage
              }
            }}
            />

            </div>

                        {/* </ExpansionPanelDetails>
                      </ExpansionPanel> */}
                       
                        </Paper>
        );

    return array;
  }

  function renderDivisionsProductivity() {
    if (!divisionsGETBool.current) {
      divisionsGET();
      customersGET();
      divisionsGETBool.current = true;
    }

    return (
      <div className={classes.paperPosition}>
        {inputsm ? (
          // #region MOBILE
          <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              className={classes.paperPropsMobile}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{paddingBottom: "0px"}}>
                    <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{props.t("Productivity.FilterSettings")}</Typography>
                  </Grid>

                  <Grid className="employee-change" item xs={12} style={{ paddingBottom: "0px" }}>
                    <TextField
                      fullWidth
                      select
                      margin="dense"
                      label={props.t("Productivity.Customer")}
                      value={inputDataDivisions.customer.customerId}
                      onChange={event => handleCustomerChange(event)}
                      variant="outlined"
                      disabled={isLoadingFilterSettingsCustomer}
                      >
                        {listOfCustomers.length > 0 && insertCustomerMenuItems()}
                    </TextField>
                    {isLoadingFilterSettingsCustomer && 
                      <div className="employee-change-loading">
                      <CircularProgress size={20}/>
                    </div>}
                  </Grid>

                  <Grid item xs={12} style={{paddingBottom: "0px"}}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="mui-pickers-date"
                      label={props.t("Productivity.StartDate")}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={inputDataDivisions.startDate}
                      onChange={(event, date) => {
                        handleStartDatePickerChangeDivisions(event, date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="mui-pickers-date"
                      label={props.t("Productivity.EndDate")}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={inputDataDivisions.endDate}
                      onChange={(event, date) => {
                        handleEndDatePickerChangeDivisions(event, date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>

                <div className="date-button-container">
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      const today = new Date();
                      const start = startOfWeek(today, { weekStartsOn: 1 });
                      const end = endOfWeek(today, { weekStartsOn: 1 });
                      setInputDataDivisions({...inputDataDivisions, 
                        startDate: start,
                        endDate: end
                      });
                    }}
                  >
                    {props.t("Productivity.Week")}
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      const today = new Date();
                      const start = startOfMonth(today);
                      const end = endOfMonth(today);
                      setInputDataDivisions({...inputDataDivisions, 
                        startDate: start,
                        endDate: end
                      });
                    }}
                  >
                    {props.t("Productivity.Month")}
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={event => {
                      const today = new Date();
                      const start = startOfYear(today);
                      const end = endOfYear(today);
                      setInputDataDivisions({...inputDataDivisions, 
                        startDate: start,
                        endDate: end
                      });
                    }}
                  >
                    {props.t("Productivity.Year")}
                  </Button>
                </div>

                <div className="search-button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    className="no-shadow"
                    disabled={isLoadingFilterSettingsCustomer || !inputDataDivisions.customerName}
                    onClick={event => {
                      productivityCustomerPerDivisionGET(inputDataDivisions.startDate, inputDataDivisions.endDate, inputDataDivisions.customer.customerId)
                    }}>
                    {props.t("Productivity.Search")}
                  </Button>
                </div>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              className={classes.paperPropsMobile}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              {isLoadingPerCustomerPerDivisionData ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  {Object.keys(productivityPerCustomerPerDivisionData || {}).length <= 0 &&
                    (<Typography variant="h5" className={classes.subTitleText}>
                      {props.t("Productivity.SelectCustomer")}
                    </Typography>)}

                  {Object.keys(productivityPerCustomerPerDivisionData || {}).length > 0 && (<Grid item xs={12} style={{ marginTop: inputsm ? 0 : 10, padding: inputsm ? "0px" : "10px" }}>
                    <AppBar position="static" color="default" style={{ boxShadow: "none", marginBottom: "20px"}}>
                      <Tabs value={tabDivisions} textColor="primary" indicatorColor="primary" onChange={handleTabChangeDivisions} variant="scrollable"
                        scrollButtons="on">
                        {[props.t("Productivity.Customer"), props.t("Productivity.Division")].map((value, index)=>(
                          <Tab label={value} key={index}/>
                        ))}

                        {!inputsm ? (<Button variant="outlined" color="primary" style={{marginLeft:"auto", marginRight:"0px", borderRadius:"0px"}} onClick={(event)=>{
                      setFillChart(!fillChart);
                    }}><OpacityIcon/></Button>) :  null}
                      </Tabs>
                    </AppBar>
                    {tabDivisionsSwitch(tabDivisions)}
                  </Grid>)}
                </React.Fragment>
              )}
            </Paper>
          </Grid>
        </Grid>
        // #endregion
        ) : (
          //#region DESKTOP
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{props.t("Productivity.FilterSettings")}</Typography>
                  </Grid>

                  <Grid className="employee-change" item xs={12}>
                    <TextField
                      fullWidth
                      select
                      label={props.t("Productivity.Customer")}
                      value={inputDataDivisions.customer.customerId}
                      onChange={event => handleCustomerChange(event)}
                      variant="outlined"
                      disabled={isLoadingFilterSettingsCustomer}
                      >
                      {listOfCustomers.length > 0 && insertCustomerMenuItems()}
                    </TextField>
                    {isLoadingFilterSettingsCustomer && 
                      <div className="employee-change-loading">
                      <CircularProgress size={20}/>
                    </div>}
                  </Grid>

                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="mui-pickers-date"
                      label={props.t("Productivity.StartDate")}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={inputDataDivisions.startDate}
                      onChange={(event, date) => {
                        handleStartDatePickerChangeDivisions(event, date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="mui-pickers-date"
                      label={props.t("Productivity.EndDate")}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={inputDataDivisions.endDate}
                      onChange={(event, date) => {
                        handleEndDatePickerChangeDivisions(event, date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>

              <div className="date-button-container">
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      const today = new Date();
                      const start = startOfWeek(today, { weekStartsOn: 1 });
                      const end = endOfWeek(today, { weekStartsOn: 1 });
                      setInputDataDivisions({...inputDataDivisions, 
                        startDate: start,
                        endDate: end
                      });
                    }}
                  >
                    {props.t("Productivity.Week")}
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      const today = new Date();
                      const start = startOfMonth(today);
                      const end = endOfMonth(today);
                      setInputDataDivisions({...inputDataDivisions, 
                        startDate: start,
                        endDate: end
                      });
                    }}
                  >
                    {props.t("Productivity.Month")}
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={event => {
                      const today = new Date();
                      const start = startOfYear(today);
                      const end = endOfYear(today);
                      setInputDataDivisions({...inputDataDivisions, 
                        startDate: start,
                        endDate: end
                      });
                    }}
                  >
                    {props.t("Productivity.Year")}
                  </Button>
                </div>

                <div className="search-button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    className="no-shadow button-padding"
                    disabled={isLoadingFilterSettingsCustomer || !inputDataDivisions.customerName}
                    onClick={event => {
                      productivityCustomerPerDivisionGET(inputDataDivisions.startDate, inputDataDivisions.endDate, inputDataDivisions.customer.customerId)
                    }}>
                    {props.t("Productivity.Search")}
                  </Button>
                </div>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              {isLoadingPerCustomerPerDivisionData ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  {Object.keys(productivityPerCustomerPerDivisionData || {}).length <= 0 &&
                    (<Typography variant="h5" className={classes.subTitleText}>
                      {props.t("Productivity.SelectCustomer")}
                    </Typography>)}

                  {Object.keys(productivityPerCustomerPerDivisionData || {}).length > 0 && (<Grid item xs={12} style={{ marginTop: 10, padding: "10px" }}>
                    <AppBar position="static" color="default" style={{ boxShadow: "none", marginBottom: "40px"}}>
                      <Tabs value={tabDivisions} textColor="primary" indicatorColor="primary" onChange={handleTabChangeDivisions}>
                        {[props.t("Productivity.Customer"), props.t("Productivity.Division")].map((value, index)=>(
                          <Tab label={value} key={index}/>
                        ))}
                        <Button variant="outlined" color="primary" style={{marginLeft:"auto", marginRight:"0px", borderRadius:"0px"}} onClick={(_)=>{
                          setFillChart(!fillChart);
                        }}>
                          <OpacityIcon/>
                        </Button>
                      </Tabs>
                    </AppBar>
                  {tabDivisionsSwitch(tabDivisions)}
                  </Grid>)}
                </React.Fragment>
              )}
            </Paper>
          </Grid>
        </Grid>
        // #endregion
        )}
      </div>
    );
  }

  function tabDivisionsSwitch(currentTab){
    switch(currentTab){
      case 0: return customers();
      case 1: return divisions();
      // case 2: return activitiesPerDivision(); //performance issues -- too much data to fetch
    }
  }

  function activityPerformance(){
    if(!performancePerEmployeePerActivity)
    return null;

    let array = [];
    let dataHolder = {};
    let customerKeys = Object.keys(performancePerEmployeePerActivity);

    let meanProductivityArray = [];
    let meanProductivity = 0;
    let counter = 0;
    let amount = 0;
    let target = 0;

    for(let i in performancePerEmployeePerActivity){
      let tempArray = [];
      counter = 0;
      meanProductivity = 0;

      for(let j in performancePerEmployeePerActivity[i]){
        tempArray.push(performancePerEmployeePerActivity[i][j]);
        meanProductivity += performancePerEmployeePerActivity[i][j].productivity;
        amount += performancePerEmployeePerActivity[i][j].amountProduced;
        target += performancePerEmployeePerActivity[i][j].targetProduced;
        counter++;

      }
      tempArray.sort((a, b)=>b.productivity - a.productivity);

      for(let k = 0; k < tempArray.length; k++){
        tempArray[k]["rank"] = k + 1;
      }

      dataHolder[i] = tempArray;
      //meanProductivityArray.push(Math.round(amount / target * 100 * 10) / 10);
      meanProductivityArray.push(Math.round(meanProductivity / counter * 10) / 10);
    }

        Object.keys(performancePerEmployeePerActivity).map((item, index)=>{

          const table = {
            columns: [
              { title: props.t("Productivity.Ranking"), field: "rank", editable: "never" },
              { title: props.t("Productivity.EmployeeID"), field: "id", editable: "never" },
              { title: props.t("Productivity.FirstName"), field: "firstName", editable: "never" },
              { title: props.t("Productivity.LastName"), field: "lastName", editable: "never" },
              { title: props.t("Productivity.ProducedUnits"), field: "amountProduced", editable: "never" },
              { title: props.t("Productivity.TargetUnits"), field: "targetProduced", editable: "never" },
              { title: props.t("Productivity.WorkingTimeMinutes"), field: "timeSpent", editable: "never" },
              { title: props.t("Productivity.ProductivityPercentage"), field: "productivity", editable: "never" },
            ],
            data: dataHolder[item]
          };

         
        array.push(
          <Paper key={item} style={{marginBottom: inputsm ? 25 : 50}}>

          <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin", padding: "15px", paddingBottom: inputsm ? "0px" : "15px", display:"inline-block"}} >
         {dataHolder[item][0].activityName}
        </Typography>

        {inputsm ? (
          <Typography variant="button" style={{paddingLeft: "15px", paddingBottom: "15px", display:"flex"}} >
          {props.t("Productivity.MeanProductivity")} <div style={{color: blue[500], marginLeft: "4px"}}>{meanProductivityArray[index]}%</div>
         </Typography>
        ) : (
        <Typography variant="button" style={{padding: "15px", float:"right", paddingRight: "25px", display:"inline-flex"}} >
         {props.t("Productivity.MeanProductivity")} <div style={{color: blue[500], marginLeft: "4px"}}>{meanProductivityArray[index]}%</div>
        </Typography>
        )}

        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            data={dataHolder[item]}
                            margin={{
                              top: 0,
                              right: 25,
                              left: 10,
                              bottom: 30
                            }}
                          >
                       
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="id">
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y="280" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                      {props.t("Productivity.EmployeeID")}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="0" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="36" dy="1.5em">
                                    {props.t("Productivity.ProductivityPercentage")}
                                    </tspan>
                                  </text>
                                }
                              />
                            </YAxis>
                            <Tooltip content={<ProductivityPerEmployeePerCustomerTooltip />} />
                            <Legend
                              payload={[{
                                id: "Productivity per employee",
                                type: "square",
                                value: props.t("Productivity.ProdPerEmployee"),
                                color: colors[index]
                              }]}
                              verticalAlign="top"
                              height={36}
                            />

                            <Bar dataKey="productivity" fill={colors[index]}>
                              {/* {dataHolder[item].map((entry, index) => {
                                return <Cell fill={colors[index]} key={colors[index]} />;
                              })} */}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>

                      <Paper>
                        <List style={{padding: "0px"}}>
                          <ListItem 
                            button
                            onClick={() => {
                              let panels = [...expansionPanelsState];
                              panels[index] = !panels[index];
                              setExpansionPanelsState(panels);
                            }}
                            // style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}
                            >
                            <ListItemText primary={<Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                            {props.t("Productivity.PerformanceRankings")}
                            </Typography>}/>
                            {expansionPanelsState[index] ? (
                                <ExpandLessIcon style={{ color: blue[500]}} />
                              ) : (
                                <ExpandMoreIcon style={{ color: blue[500]}} />
                              )}
                          </ListItem>
                          <Collapse in={expansionPanelsState[index]} timeout="auto" unmountOnExit>
                          <List>
                            <ListItem>
                            {/* <Table size="small" style={{marginBottom: 40, overflow: "hidden"}}> 
            <TableHead>
              <br/>
              <TableRow>{["Ranking", "Employee ID", "First Name", "Last Name", "Produced [units]", "Target [units]", "Time Spent [minutes]", "Productivity [%]"].map((value, index) =>
                (<TableCell align="left" style={{fontWeight: 600, fontSize: "0.875rem", color: "rgb(0, 0, 0, 1)"}} key={value}>
                  {value}
                </TableCell>)
              )}</TableRow>
            </TableHead>
            <TableBody>
      
            {dataHolder[item].map((employee, index2) => {
              var c;
              if(index2 === 0){
                c = "rgba(214, 175, 54, 0.5)";
              } else if(index2 === 1){
                c = "rgba(215, 215, 215, 0.5)";
              } else if(index2 === 2){
                c = "rgba(167, 112, 68, 0.5)";
              }else{
                c = "#FFFFFF"
              }

              return(
              <TableRow key={employee.id} style={{backgroundColor: c}}>
                  <TableCell align="left">
                       {index2 + 1}
                     </TableCell>
                    <TableCell align="left">
                       {employee.id}
                     </TableCell>
                     <TableCell align="left">
                       {employee.firstName}
                     </TableCell>
                     <TableCell align="left">
                       {employee.lastName}
                     </TableCell>
                     <TableCell align="left">
                       {employee.amountProduced}
                     </TableCell>
                     <TableCell align="left">
                       {employee.targetProduced}
                     </TableCell>
                     <TableCell align="left">
                       {employee.timeSpent}
                     </TableCell>
                     <TableCell align="left">
                       {employee.productivity}
                     </TableCell>
                     </TableRow>
            )})}
            </TableBody>
            </Table> */}

        <div style={{overflow: "auto", width: "100%"}}>
        <MaterialTable
            components={{
              Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />
            }}
            columns={table.columns}
            data={table.data}
            
            onRowClick={(event, row) => {}}
            options={{
              exportButton: true,
              filtering: false,
              search: true,
              sorting: true,
              pageSize: defaultPageNumbers,
              pageSizeOptions: pageOptions,
              padding: "dense",
              showTitle: false,
              rowStyle: (rowData, index) => {
                if(rowData["rank"] === 1){
                 return {backgroundColor: "rgba(214, 175, 54, 0.5)"};
                } else if(rowData["rank"] === 2){
                  return {backgroundColor: "rgba(215, 215, 215, 0.5)"};
                } else if(rowData["rank"] === 3){
                  return {backgroundColor: "rgba(167, 112, 68, 0.5)"};
                }else{
                  return {backgroundColor: "#FFFFFF"};
                }
              }
            }}
            style={{ boxShadow: "none" }}
            localization={{
              pagination:{
                labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                labelRowsSelect: props.t("Database.Rows"),
                firstTooltip: props.t("EditEntries.FirstPage"),
                previousTooltip: props.t("EditEntries.PreviousPage"),
                nextTooltip: props.t("EditEntries.NextPage"),
                lastTooltip: props.t("EditEntries.LastPage"),
              },
              toolbar:{
                nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                searchPlaceholder: props.t("EditEntries.Search"),
                searchTooltip: props.t("EditEntries.Search"),
                exportTitle: props.t("EditEntries.Export"),
                exportName: props.t("EditEntries.Export")
              },
              body: {
                editRow: {
                  deleteText: ""
                },
                addTooltip: "Add row",
                deleteToolTip: "Delete row",
                editToolTip: "Edit row",
                emptyDataSourceMessage: emptyDataMessage
              }
            }}
            />
            </div>

                            </ListItem>
                          </List>
                          </Collapse>
                        </List>
                      </Paper>

                        {/* <ExpansionPanel expanded={expandAll}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{color: blue[500]}} />}>
                        <Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                            Performance rankings
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>

                      

                        </ExpansionPanelDetails>
                      </ExpansionPanel> */}
                       
                        </Paper>
        )});

    return array;
  }

  function divisionPerformance(){
    if(!performancePerEmployeePerDivision)
    return null;

    let array = [];
    let dataHolder = {};
    let customerKeys = Object.keys(performancePerEmployeePerDivision);

    let meanProductivityArray = [];
    let meanProductivity = 0;
    let counter = 0;
    let amount = 0;
    let target = 0;

    for(let i in performancePerEmployeePerDivision){
      let tempArray = [];
        counter = 0;
        meanProductivity = 0;

      for(let j in performancePerEmployeePerDivision[i]){
        tempArray.push(performancePerEmployeePerDivision[i][j]);
        meanProductivity += performancePerEmployeePerDivision[i][j].productivity;
        amount += performancePerEmployeePerDivision[i][j].amountProduced;
        target += performancePerEmployeePerDivision[i][j].targetProduced;
        counter++;
      }

      tempArray.sort((a, b)=>b.productivity - a.productivity);

      for(let k = 0; k < tempArray.length; k++){
        tempArray[k]["rank"] = k + 1;
      }

      dataHolder[i] = tempArray;
      //meanProductivityArray.push(Math.round(amount / target * 100 * 10) / 10);
      meanProductivityArray.push(Math.round(meanProductivity / counter * 10) / 10);
    }

        Object.keys(performancePerEmployeePerDivision).map((item, index)=>{

          const table = {
            columns: [
              { title: props.t("Productivity.Ranking"), field: "rank", editable: "never" },
              { title: props.t("Productivity.EmployeeID"), field: "id", editable: "never" },
              { title: props.t("Productivity.FirstName"), field: "firstName", editable: "never" },
              { title: props.t("Productivity.LastName"), field: "lastName", editable: "never" },
              { title: props.t("Productivity.ProducedUnits"), field: "amountProduced", editable: "never" },
              { title: props.t("Productivity.TargetUnits"), field: "targetProduced", editable: "never" },
              { title: props.t("Productivity.WorkingTimeMinutes"), field: "timeSpent", editable: "never" },
              { title: props.t("Productivity.ProductivityPercentage"), field: "productivity", editable: "never" },
            ],
            data: dataHolder[item]
          };

        array.push(
          <Paper key={item} style={{marginBottom: inputsm ? 25 : 50,  overflow: "auto"}}>

          <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin", padding: "15px", paddingBottom: inputsm ? "0px" : "15px", display:"inline-block"}} >
         {dataHolder[item][0].divisionName}
        </Typography>

        {inputsm ? (
          <Typography variant="button" style={{paddingLeft: "15px", paddingBottom: "15px", display:"flex"}} >
          {props.t("Productivity.MeanProductivity")} <div style={{color: blue[500], marginLeft: "4px"}}>{meanProductivityArray[index]}%</div>
         </Typography>
        ) : (
        <Typography variant="button" style={{padding: "15px", float:"right", paddingRight: "25px", display:"inline-flex"}} >
         {props.t("Productivity.MeanProductivity")} <div style={{color: blue[500], marginLeft: "4px"}}>{meanProductivityArray[index]}%</div>
        </Typography>
        )}

        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            data={dataHolder[item]}
                            margin={{
                              top: 0,
                              right: 25,
                              left: 10,
                              bottom: 30
                            }}
                          >
                       
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="id">
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y="280" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("Productivity.EmployeeID")}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="0" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="36" dy="1.5em">
                                    {props.t("Productivity.ProductivityPercentage")}
                                    </tspan>
                                  </text>
                                }
                              />
                            </YAxis>
                            <Tooltip content={<ProductivityPerEmployeePerCustomerTooltip />} />
                            <Legend
                              payload={[{
                                id: "Productivity per employee",
                                type: "square",
                                value: props.t("Productivity.ProdPerEmployee"),
                                color: colors[index]
                              }]}
                              verticalAlign="top"
                              height={36}
                            />

                            <Bar dataKey="productivity" fill={colors[index]}>
                              {/* {dataHolder[item].map((entry, index) => {
                                return <Cell fill={colors[index]} key={colors[index]} />;
                              })} */}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>

                        {/* <ExpansionPanel expanded={expandAll}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{color: blue[500]}} />}>
                          <Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                            Performance rankings
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails> */}

<Paper>
                        <List style={{padding: "0px"}}>
                          <ListItem 
                            button
                            onClick={() => {
                              let panels = [...expansionPanelsState];
                              panels[index] = !panels[index];
                              setExpansionPanelsState(panels);
                            }}
                            // style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}
                            >
                            <ListItemText primary={<Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                            {props.t("Productivity.PerformanceRankings")}
                            </Typography>}/>
                            {expansionPanelsState[index] ? (
                                <ExpandLessIcon style={{ color: blue[500]}} />
                              ) : (
                                <ExpandMoreIcon style={{ color: blue[500]}} />
                              )}
                          </ListItem>
                          <Collapse in={expansionPanelsState[index]} timeout="auto" unmountOnExit>
                          <List>
                            <ListItem>

                        {/* <Table size="small" style={{marginBottom: 40, overflow: inputsm ? "auto" : "hidden", display: inputsm ? "inline-block" : "unset" }}> 
            <TableHead>
              <TableRow>{["Ranking", "Employee ID", "First Name", "Last Name", "Produced [units]", "Target [units]", "Time Spent [minutes]", "Productivity [%]"].map((value, index) =>
                (<TableCell align="left" style={{fontWeight: 600, fontSize: "0.875rem", color: "rgb(0, 0, 0, 1)"}} key={value}>
                  {value}
                </TableCell>)
              )}</TableRow>
            </TableHead>
            <TableBody>
      
            {dataHolder[item].map((employee, index2) => {
              var c;
              if(index2 === 0){
                c = "rgba(214, 175, 54, 0.5)";
              } else if(index2 === 1){
                c = "rgba(215, 215, 215, 0.5)";
              } else if(index2 === 2){
                c = "rgba(167, 112, 68, 0.5)";
              }else{
                c = "#FFFFFF"
              }

              return(
              <TableRow key={employee.id} style={{backgroundColor: c}}>
                  <TableCell align="left">
                       {index2 + 1}
                     </TableCell>
                    <TableCell align="left">
                       {employee.id}
                     </TableCell>
                     <TableCell align="left">
                       {employee.firstName}
                     </TableCell>
                     <TableCell align="left">
                       {employee.lastName}
                     </TableCell>
                     <TableCell align="left">
                       {employee.amountProduced}
                     </TableCell>
                     <TableCell align="left">
                       {employee.targetProduced}
                     </TableCell>
                     <TableCell align="left">
                       {employee.timeSpent}
                     </TableCell>
                     <TableCell align="left">
                       {employee.productivity}
                     </TableCell>
                     </TableRow>
            )})}
            </TableBody>
            </Table> */}
           <div style={{overflow: "auto", width: "100%"}}>
          <MaterialTable
            components={{
              Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />
            }}
            columns={table.columns}
            data={table.data}
            
            onRowClick={(event, row) => {}}
            options={{
              exportButton: true,
              filtering: false,
              search: true,
              sorting: true,
              pageSize: defaultPageNumbers,
              pageSizeOptions: pageOptions,
              padding: "dense",
              showTitle: false,
              rowStyle: (rowData, index) => {
                if(rowData["rank"] === 1){
                 return {backgroundColor: "rgba(214, 175, 54, 0.5)"};
                } else if(rowData["rank"] === 2){
                  return {backgroundColor: "rgba(215, 215, 215, 0.5)"};
                } else if(rowData["rank"] === 3){
                  return {backgroundColor: "rgba(167, 112, 68, 0.5)"};
                }else{
                  return {backgroundColor: "#FFFFFF"};
                }
              }
            }}
            style={{ boxShadow: "none" }}
            localization={{
              pagination:{
                labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                labelRowsSelect: props.t("Database.Rows"),
                firstTooltip: props.t("EditEntries.FirstPage"),
                previousTooltip: props.t("EditEntries.PreviousPage"),
                nextTooltip: props.t("EditEntries.NextPage"),
                lastTooltip: props.t("EditEntries.LastPage"),
              },
              toolbar:{
                nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                searchPlaceholder: props.t("EditEntries.Search"),
                searchTooltip: props.t("EditEntries.Search"),
                exportTitle: props.t("EditEntries.Export"),
                exportName: props.t("EditEntries.Export")
              },
              body: {
                editRow: {
                  deleteText: ""
                },
                addTooltip: "Add row",
                deleteToolTip: "Delete row",
                editToolTip: "Edit row",
                emptyDataSourceMessage: emptyDataMessage
              }
            }}
            />
            </div>

                        {/* </ExpansionPanelDetails>
                      </ExpansionPanel> */}
                              </ListItem>
                          </List>
                          </Collapse>
                        </List>
                      </Paper>
                       
                        </Paper>
        )});

    return array;
  }

  function customerPerformance(){
    if(!performancePerEmployeePerCustomer)
    return null;

    let array = [];
    let dataHolder = {};
    let customerKeys = Object.keys(performancePerEmployeePerCustomer);

    let meanProductivityArray = [];
    let meanProductivity = 0;
    let counter = 0;

    let amount = 0;
    let target = 0;

    // for(let i in performancePerEmployeePerDivision){
    //   let tempArray = [];
    //     counter = 0;
    //     meanProductivity = 0;

    //   for(let j in performancePerEmployeePerDivision[i]){
    //     tempArray.push(performancePerEmployeePerDivision[i][j]);

    //     meanProductivity += performancePerEmployeePerDivision[i][j].productivity;
    //     counter++;
    //   }
    //   tempArray.sort((a, b)=>b.productivity - a.productivity);
    //   dataHolder[i] = tempArray;
    //   meanProductivityArray.push(Math.round(meanProductivity / counter * 10) / 10);
    // }

    for(let i in performancePerEmployeePerCustomer){
      let tempArray = [];
      counter = 0;
      meanProductivity = 0;

      for(let j in performancePerEmployeePerCustomer[i]){
        tempArray.push(performancePerEmployeePerCustomer[i][j]);
        meanProductivity += performancePerEmployeePerCustomer[i][j].productivity;
        amount += performancePerEmployeePerCustomer[i][j].amountProduced;
        target += performancePerEmployeePerCustomer[i][j].targetProduced;
        counter++;
      }
      tempArray.sort((a, b)=>b.productivity - a.productivity);

      for(let k = 0; k < tempArray.length; k++){
        tempArray[k]["rank"] = k + 1;
      }

      dataHolder[i] = tempArray;
      //meanProductivityArray.push(Math.round(amount / target * 100 * 10) / 10);
      meanProductivityArray.push(Math.round(meanProductivity / counter * 10) / 10);
    }

        Object.keys(performancePerEmployeePerCustomer).map((item, index)=>{

          const table = {
            columns: [

              { title: props.t("Productivity.Ranking"), field: "rank", editable: "never" },
              { title: props.t("Productivity.EmployeeID"), field: "id", editable: "never" },
              { title: props.t("Productivity.FirstName"), field: "firstName", editable: "never" },
              { title: props.t("Productivity.LastName"), field: "lastName", editable: "never" },
              { title: props.t("Productivity.ProducedUnits"), field: "amountProduced", editable: "never" },
              { title: props.t("Productivity.TargetUnits"), field: "targetProduced", editable: "never" },
              { title: props.t("Productivity.WorkingTimeMinutes"), field: "timeSpent", editable: "never" },
              { title: props.t("Productivity.ProductivityPercentage"), field: "productivity", editable: "never" },
            ],
            data: dataHolder[item]
          };

        array.push(
          <Paper key={item} style={{marginBottom: inputsm ? 25 : 50}}>

          <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin", padding: "15px", paddingBottom: inputsm ? "0px" : "15px", display:"inline-block"}} >
         {dataHolder[item][0].customerName}
        </Typography>

        {inputsm ? (
          <Typography variant="button" style={{paddingLeft: "15px", paddingBottom: "15px", display:"flex"}} >
          {props.t("Productivity.MeanProductivity")} <div style={{color: blue[500], marginLeft: "4px"}}>{meanProductivityArray[index]}%</div>
         </Typography>
        ) : (
        <Typography variant="button" style={{padding: "15px", float:"right", paddingRight: "25px", display:"inline-flex"}} >
         {props.t("Productivity.MeanProductivity")} <div style={{color: blue[500], marginLeft: "4px"}}>{meanProductivityArray[index]}%</div>
        </Typography>
        )}

        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            data={dataHolder[item]}
                            margin={{
                              top: 0,
                              right: 25,
                              left: 10,
                              bottom: 30
                            }}
                          >
                       
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="id">
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y="280" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("Productivity.EmployeeID")}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="0" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="36" dy="1.5em">
                                    {props.t("Productivity.ProductivityPercentage")}
                                    </tspan>
                                  </text>
                                }
                              />
                            </YAxis>
                            <Tooltip content={<ProductivityPerEmployeePerCustomerTooltip />} />
                            <Legend
                              payload={[{
                                id: "Productivity per employee",
                                type: "square",
                                value: "Productivity per employee",
                                color: colors[index]
                              }]}
                              verticalAlign="top"
                              height={36}
                            />

                            <Bar dataKey="productivity" fill={colors[index]}>
                              {/* {dataHolder[item].map((entry, index) => {
                                return <Cell fill={colors[index]} key={colors[index]} />;
                              })} */}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>

                        {/* <ExpansionPanel expanded={expandAll}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{color: blue[500]}} />}>
                          <Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                            Performance rankings
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails> */}

                      <Paper>
                        <List style={{padding: "0px"}}>
                          <ListItem 
                            button
                            onClick={() => {
                              let panels = [...expansionPanelsState];
                              panels[index] = !panels[index];
                              setExpansionPanelsState(panels);
                            }}
                            // style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}
                            >
                            <ListItemText primary={<Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                            {props.t("Productivity.PerformanceRankings")}
                            </Typography>}/>
                            {expansionPanelsState[index] ? (
                                <ExpandLessIcon style={{ color: blue[500]}} />
                              ) : (
                                <ExpandMoreIcon style={{ color: blue[500]}} />
                              )}
                          </ListItem>
                          <Collapse in={expansionPanelsState[index]} timeout="auto" unmountOnExit>
                          <List>
                            <ListItem>
                        {/* <div style={{overflow: "auto"}}>

                        <Table size="small" style={{marginBottom: 40, overflow: inputsm ? "auto" : "hidden", display: inputsm ? "inline-block" : "unset"}}> 
            <TableHead>
              <TableRow>{["Ranking", "Employee ID", "First Name", "Last Name", "Produced [units]", "Target [units]", "Time Spent [minutes]", "Productivity [%]"].map((value, index) =>
                (<TableCell align="left" style={{fontWeight: 600, fontSize: "0.875rem", color: "rgb(0, 0, 0, 1)"}} key={value}>
                  {value}
                </TableCell>)
              )}</TableRow>
            </TableHead>
            <TableBody>
      
            {dataHolder[item].map((employee, index2) => {
              var c;
              if(index2 === 0){
                c = "rgba(214, 175, 54, 0.5)";
              } else if(index2 === 1){
                c = "rgba(215, 215, 215, 0.5)";
              } else if(index2 === 2){
                c = "rgba(167, 112, 68, 0.5)";
              }else{
                c = "#FFFFFF"
              }

              return(
              <TableRow key={employee.id} style={{backgroundColor: c}}>
                  <TableCell align="left">
                       {index2 + 1}
                     </TableCell>
                    <TableCell align="left">
                       {employee.id}
                     </TableCell>
                     <TableCell align="left">
                       {employee.firstName}
                     </TableCell>
                     <TableCell align="left">
                       {employee.lastName}
                     </TableCell>
                     <TableCell align="left">
                       {employee.amountProduced}
                     </TableCell>
                     <TableCell align="left">
                       {employee.targetProduced}
                     </TableCell>
                     <TableCell align="left">
                       {employee.timeSpent}
                     </TableCell>
                     <TableCell align="left">
                       {employee.productivity}
                     </TableCell>
                     </TableRow>
            )})}
            </TableBody>
            </Table> */}


            <div style={{overflow: "auto", width: "100%"}}>
          <MaterialTable
            components={{
              Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />
            }}
            columns={table.columns}
            data={table.data}
            
            onRowClick={(event, row) => {}}
            options={{
              exportButton: true,
              filtering: false,
              search: true,
              sorting: true,
              pageSize: defaultPageNumbers,
              pageSizeOptions: pageOptions,
              padding: "dense",
              showTitle: false,
              rowStyle: (rowData, index) => {
                if(rowData["rank"] === 1){
                 return {backgroundColor: "rgba(214, 175, 54, 0.5)"};
                } else if(rowData["rank"] === 2){
                  return {backgroundColor: "rgba(215, 215, 215, 0.5)"};
                } else if(rowData["rank"] === 3){
                  return {backgroundColor: "rgba(167, 112, 68, 0.5)"};
                }else{
                  return {backgroundColor: "#FFFFFF"};
                }
              }
            }}
            style={{ boxShadow: "none" }}
            localization={{
              pagination:{
                labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                labelRowsSelect: props.t("Database.Rows"),
                firstTooltip: props.t("EditEntries.FirstPage"),
                previousTooltip: props.t("EditEntries.PreviousPage"),
                nextTooltip: props.t("EditEntries.NextPage"),
                lastTooltip: props.t("EditEntries.LastPage"),
              },
              toolbar:{
                nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                searchPlaceholder: props.t("EditEntries.Search"),
                searchTooltip: props.t("EditEntries.Search"),
                exportTitle: props.t("EditEntries.Export"),
                exportName: props.t("EditEntries.Export")
              },
              body: {
                editRow: {
                  deleteText: ""
                },
                addTooltip: "Add row",
                deleteToolTip: "Delete row",
                editToolTip: "Edit row",
                emptyDataSourceMessage: emptyDataMessage
              }
            }}
            />
            </div>

                        {/* </ExpansionPanelDetails>
                      </ExpansionPanel> */}
                              </ListItem>
                          </List>
                          </Collapse>
                        </List>
                      </Paper>
                       
                        </Paper>
        )});

    return array;
  }

  function customers(){
    if(!listOfCustomers || Object.keys(productivityPerCustomerPerDivisionData || {}).length <= 0) {
      return null;
    }
    if(typeof productivityPerCustomerPerDivisionData?.customerTotals?.totalProductivity !== 'string') {
      productivityPerCustomerPerDivisionData.customerTotals.totalProductivity = productivityPerCustomerPerDivisionData.customerTotals.totalProductivity.toFixed(1)
    }
    productivityPerCustomerPerDivisionData.customerTotals.totalTarget = Math.round(productivityPerCustomerPerDivisionData.customerTotals.totalTarget)
    const table = {
      columns: [
        { title: props.t("Productivity.Customer"), field: "name", editable: "never" },
        { title: props.t("Productivity.ProducedUnits"), field: "totalProduced", editable: "never" },
        { title: props.t("Productivity.TargetUnits"), field: "totalTarget", editable: "never" },
        { title: props.t("Productivity.ProductivityPercentage"), field: "totalProductivity", editable: "never" },
      ],
      data: [productivityPerCustomerPerDivisionData.customerTotals]
    };
    return(
      <Paper key={1} style={{marginBottom: inputsm ? 25 : 50}}>
      <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin", padding: "15px" }}>
        {productivityPerCustomerPerDivisionData.customerTotals.name}
      </Typography>
    <div style={{overflow: "auto", padding: "15px", paddingTop: "0px"}}>
          <MaterialTable
            components={{
              Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />
            }}
            columns={table.columns}
            data={table.data}
            
            onRowClick={(event, row) => {}}
            options={{
              exportButton: false,
              filtering: false,
              search: false,
              sorting: true,
              pageSize: 1,
              pageSizeOptions: pageOptions,
              padding: "dense",
              showTitle: false,
              showFirstLastPageButtons: false,
              paging: false,
              toolbar: false,
              rowStyle: (rowData, index) => {
                return { backgroundColor: index % 2 === 0 ? "rgba(242, 242, 242, 1)" : "#FFFFFF" };
              }
            }}
            style={{ boxShadow: "none" }}
            localization={{
              pagination:{
                labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                labelRowsSelect: props.t("Database.Rows"),
                firstTooltip: props.t("EditEntries.FirstPage"),
                previousTooltip: props.t("EditEntries.PreviousPage"),
                nextTooltip: props.t("EditEntries.NextPage"),
                lastTooltip: props.t("EditEntries.LastPage"),
              },
              toolbar:{
                nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                searchPlaceholder: props.t("EditEntries.Search"),
                searchTooltip: props.t("EditEntries.Search"),
                exportTitle: props.t("EditEntries.Export"),
                exportName: props.t("EditEntries.Export")
              },
              body: {
                editRow: {
                  deleteText: ""
                },
                addTooltip: "Add row",
                deleteToolTip: "Delete row",
                editToolTip: "Edit row",
                emptyDataSourceMessage: emptyDataMessage
              }
            }}
            />
            </div>

    <div style={{marginTop: inputsm ? "0px" : "25px"}}>
    <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={productivityPerCustomerPerDivisionData.customer} margin={{ top: 0, right: 25, left: 10, bottom: 30 }}>
            <XAxis dataKey="name">
              <Label
                width={30}
                position="center"
                content={
                  <text x="50%" y="280" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                    <tspan alignmentBaseline="middle" fontSize="14">
                      {props.t("Productivity.TimeDays").toUpperCase()}
                    </tspan>
                  </text>
                }
              />
            </XAxis>
            <YAxis>
              <Label
                position="center"
                content={
                  <text x="0" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                    <tspan x="36" dy="1.5em">
                      {props.t("Productivity.ProductivityPercentage").toUpperCase()}
                    </tspan>
                  </text>
                }
              />
            </YAxis>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<DivisionsTooltip type="customer"/>} />
            <Area
              name={props.t("Productivity.ProductivityTargetPercentage")}
              type="monotone"
              dataKey="TargetPercentage"
              stroke="rgba(200, 200, 200, 1)"
              fill="rgba(230, 230, 230, 0.5)"
            />
            <Area name={props.t("Productivity.ProductivityPercentage")} key={"name"} type="monotone" dataKey={"productivity"} stroke={colors[0]} fill={colors[0]} fillOpacity={fillChart ? 0.6 : 0}/>))
            <Legend
              payload={[{
                id: 0,
                type: "line",
                value: productivityPerCustomerPerDivisionData.customerTotals.name,
                color: colors[0]
              }]}
              verticalAlign="top"
              height={45}
            />
          </AreaChart>
        </ResponsiveContainer>
        </div>
    </Paper>
    );
  }

  function divisions(){
    if(!listOfCustomers || Object.keys(productivityPerCustomerPerDivisionData || {}).length <= 0) {
      return null;
    }

    let array = [];
    // let divisionsCombined = [];
    // let divisionTotals = [];
    // let divisionKeys = Object.keys(productivityPerDivision);
    // let divisionNames = [];
    

    // for(let k = 0; k < listOfCustomers.length; k++){ //for every customer
    //   divisionsCombined[k] = [];
    //   divisionTotals[k] = [];
    //   for(let j = 0; j < listOfCustomers[k]["division"].length; j++){ //for every division
    //     let division = listOfCustomers[k]["division"][j]["divisionName"];
    //     divisionNames.push(division);
    //     let index = j;
    //   let totalProduced = 0;
    //   let totalTarget = 0;
    //   let totalProductivity = 0;

    //   for(let i = 0; i < productivityPerDivision[division].length; i++){
    //     let productivity = Math.round((productivityPerDivision[division][i].Produced / productivityPerDivision[division][i].Target) * 100 * 10) / 10;

    //     if(index === 0){
    //       divisionsCombined[k].push({
    //         name: productivityPerDivision[division][i].name,
    //         Day: productivityPerDivision[division][i].Day,
    //         [new String(division) + new String("_Productivity")]: !productivity ? 0.0 : productivity,
    //         [new String(division) + new String("_Produced")]: productivityPerDivision[division][i].Produced,
    //         [new String(division) + new String("_Target")]: Math.round(productivityPerDivision[division][i].Target),
    //       });
    //       totalProduced += productivityPerDivision[division][i].Produced;
    //       totalTarget += productivityPerDivision[division][i].Target;

    //     }else{

    //       if(divisionsCombined[k] === undefined || divisionsCombined[k].length === 0)
    //         continue;

    //       divisionsCombined[k][i][new String(division) + new String("_Productivity")] = !productivity ? 0.0 : productivity;
    //       divisionsCombined[k][i][new String(division) + new String("_Produced")] = productivityPerDivision[division][i].Produced;
    //       divisionsCombined[k][i][new String(division) + new String("_Target")] = Math.round(productivityPerDivision[division][i].Target);

    //       totalProduced += productivityPerDivision[division][i].Produced;
    //       totalTarget += productivityPerDivision[division][i].Target;
    //     }
    //   }
    //   totalProductivity = Math.round((totalProduced / totalTarget) * 100 * 10) / 10;
    //   divisionTotals[k].push({
    //     TotalProduced: totalProduced,
    //     TotalTarget: Math.round(totalTarget),
    //     TotalProductivity: !totalProductivity ? 0.0 : totalProductivity,
    //     Name: division
    //   })
    // }};

    const customerName = productivityPerCustomerPerDivisionData.customerTotals.name

    // for(let l = 0; l < listOfCustomers.length; l++){
      let users = 0;
      const keys = Object.keys(productivityPerCustomerPerDivisionData.division)
      const keysHeight = 25
      const chartHeight = 300 + keys.length * keysHeight
      const keysTotals = Object.keys(productivityPerCustomerPerDivisionData.divisionTotals)
      const tableData = keysTotals.map(i => { 
        return {
          ...productivityPerCustomerPerDivisionData.divisionTotals[i],
          totalProductivity: productivityPerCustomerPerDivisionData.divisionTotals[i].totalProductivity.toFixed(1),
          totalTarget: Math.round(productivityPerCustomerPerDivisionData.divisionTotals[i].totalTarget)
      }})
      const chartData = []

      for(let i = 0; i < keys.length; i++) {
        for(let j = 0; j < productivityPerCustomerPerDivisionData.division[keys[i]].length; j++) {
          const item = productivityPerCustomerPerDivisionData.division[keys[i]][j]
          const data = {
            [`${keys[i]}_amountProduced`]: item["_amountProduced"] ?? 0,
            [`${keys[i]}_productivity`]: item["productivity"].toFixed(1) ?? 0,
            [`${keys[i]}_targetProduced`]: item["targetProduced"] ?? 0,
            // [`${keys[i]}_timeSpent`]: item["timeSpent"],
            date: item["date"],
            name: item["name"],
          }
          if(i === 0) {
            chartData.push(data)
          } else {
            Object.assign(chartData[j], data)
          }
        }
      }

      for(let i = 0; i < listOfCustomers.length; i++){
        users++;
      }
      const table = {
        columns: [
          { title: props.t("Productivity.Division"), field: "name", editable: "never" },
          { title: props.t("Productivity.ProducedUnits"), field: "totalProduced", editable: "never" },
          { title: props.t("Productivity.TargetUnits"), field: "totalTarget", editable: "never" },
          { title: props.t("Productivity.ProductivityPercentage"), field: "totalProductivity", editable: "never" },
        ],
        data: tableData
      };

        (array.push(
          <Paper key={customerName} style={{marginBottom: inputsm ? 25 : 50}}>

          <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin", padding: "15px" }}>
          {customerName}
        </Typography>

        <div style={{overflow: "auto", padding: "15px", paddingTop: "0px"}}>
          <MaterialTable
            components={{
              Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />
            }}
            columns={table.columns}
            data={table.data}
            
            onRowClick={(event, row) => {}}
            options={{
              exportButton: false,
              filtering: false,
              search: false,
              sorting: true,
              pageSize: users,
              pageSizeOptions: pageOptions,
              padding: "dense",
              showTitle: false,
              showFirstLastPageButtons: false,
              paging: false,
              toolbar: false,
              rowStyle: (rowData, index) => {
                return { backgroundColor: index % 2 === 0 ? "rgba(242, 242, 242, 1)" : "#FFFFFF" };
              }
            }}
            style={{ boxShadow: "none" }}
            localization={{
              pagination:{
                labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                labelRowsSelect: props.t("Database.Rows"),
                firstTooltip: props.t("EditEntries.FirstPage"),
                previousTooltip: props.t("EditEntries.PreviousPage"),
                nextTooltip: props.t("EditEntries.NextPage"),
                lastTooltip: props.t("EditEntries.LastPage"),
              },
              toolbar:{
                nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                searchPlaceholder: props.t("EditEntries.Search"),
                searchTooltip: props.t("EditEntries.Search"),
                exportTitle: props.t("EditEntries.Export"),
                exportName: props.t("EditEntries.Export")
              },
              body: {
                editRow: {
                  deleteText: ""
                },
                addTooltip: "Add row",
                deleteToolTip: "Delete row",
                editToolTip: "Edit row",
                emptyDataSourceMessage: emptyDataMessage
              }
            }}
            />
            </div>

        <div style={{marginTop: inputsm ? "0px" : "25px"}}>
        <ResponsiveContainer width="100%" height={chartHeight} debounce={1}>
          <AreaChart data={chartData} margin={{ top: 0, right: 25, left: 10, bottom: 30 }}>
            <XAxis dataKey="name">
              <Label
                width={30}
                position="center"
                content={
                  <text x="50%" y={chartHeight - 20} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                    <tspan alignmentBaseline="middle" fontSize="14">
                      {props.t("Productivity.TimeDays").toUpperCase()}
                    </tspan>
                  </text>
                }
              />
            </XAxis>
            <YAxis>
              <Label
                position="center"
                content={
                  <text x={chartHeight} y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                    <tspan x="0" dy="1.5em">
                      {props.t("Productivity.ProductivityPercentage").toUpperCase()}
                    </tspan>
                  </text>
                }
              />
            </YAxis>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<DivisionsTooltip type="division" />} />

            <Area
              name={props.t("Productivity.ProductivityTargetPercentage")}
              type="monotone"
              dataKey="TargetPercentage"
              stroke="rgba(200, 200, 200, 1)"
              fill="rgba(230, 230, 230, 0.5)"
            />
            {keys.map((value, index) => (
              <Area name={props.t("Productivity.ProductivityPercentage")} key={value} type="monotone" dataKey={`${value}_productivity`} stroke={colors[index]} fill={colors[index]} fillOpacity={fillChart ? 0.6 : 0} />))
            }
            {/* // <Area name={props.t("Productivity.ProductivityPercentage")} key={division.Name} type="monotone" dataKey={new String(division.Name) + new String("_Productivity")} stroke={colors[index]} fill={colors[index]} fillOpacity={fillChart ? 0.6 : 0} />)) */}
            
            {/* {productivityPerCustomerPerDivisionData.division[keys[l]].map((division, index) => ( */}
            {/* // <Area name={props.t("Productivity.ProductivityPercentage")} key={division.Name} type="monotone" dataKey={new String(division.Name) + new String("_Productivity")} stroke={colors[index]} fill={colors[index]} fillOpacity={fillChart ? 0.6 : 0} />))} */}
            {/* // <Area name={props.t("Productivity.ProductivityPercentage")} key={"name"} type="monotone" dataKey={"productivity"} stroke={colors[index]} fill={colors[index]} fillOpacity={fillChart ? 0.6 : 0} />))} */}
           
            <Legend
              payload={keys.map((value, index) => ({
                id: value,
                type: "line",
                value: value,
                color: colors[index]
              }))}
              verticalAlign="top"
              height={keys.length * keysHeight}
            />
          </AreaChart>
        </ResponsiveContainer>
        </div>
        </Paper>
        ))
    // }
      

    return array;

  }

  function activitiesPerDivision(){
    if(!chartProdData) {
      return null;
    }

    let array = [];
    let data = [];

    Object.keys(chartProdData).map((item, i) => {
      let arr = [];
      Object.keys(chartProdData[item]).map((item2, i) =>{
        let arg = chartProdData[item][item2];
        arg["Name"] = Object.keys(chartProdData[item])[i].toString();
        arr.push(arg);
      });
      data.push(arr);
    });


    Object.keys(productivityDivisionsData).map((keyName, i) => {
      let users = 0;

      for(let i = 0; i < listOfCustomers.length; i++){
        users++;
      }

      const table = {
        columns: [
          { title: props.t("Productivity.Activity"), field: "Name", editable: "never" },
          { title: props.t("Productivity.ProducedUnits"), field: "Produced", editable: "never" },
          { title: props.t("Productivity.TargetUnits"), field: "Target", editable: "never" },
          { title: props.t("Productivity.ProductivityPercentage"), field: "Productivity", editable: "never" },
        ],
        data: data[i]
      };

      (array.push(
        <Paper key={keyName} style={{marginBottom: inputsm ? 25 : 50}}>

        <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin", padding: "15px" }}>
        {keyName}
      </Typography>

      <div style={{overflow: "auto", padding: "15px", paddingTop: "0px"}}>
          <MaterialTable
            components={{
              Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />
            }}
            columns={table.columns}
            data={table.data}
            
            onRowClick={(event, row) => {}}
            options={{
              exportButton: false,
              filtering: false,
              search: false,
              sorting: true,
              pageSize: users,
              pageSizeOptions: pageOptions,
              padding: "dense",
              showTitle: false,
              showFirstLastPageButtons: false,
              paging: false,
              toolbar: false,
              rowStyle: (rowData, index) => {
                return { backgroundColor: index % 2 === 0 ? "rgba(242, 242, 242, 1)" : "#FFFFFF" };
              }
            }}
            style={{ boxShadow: "none" }}
            localization={{
              pagination:{
                labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                labelRowsSelect: props.t("Database.Rows"),
                firstTooltip: props.t("EditEntries.FirstPage"),
                previousTooltip: props.t("EditEntries.PreviousPage"),
                nextTooltip: props.t("EditEntries.NextPage"),
                lastTooltip: props.t("EditEntries.LastPage"),
              },
              toolbar:{
                nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                searchPlaceholder: props.t("EditEntries.Search"),
                searchTooltip: props.t("EditEntries.Search"),
                exportTitle: props.t("EditEntries.Export"),
                exportName: props.t("EditEntries.Export")
              },
              body: {
                editRow: {
                  deleteText: ""
                },
                addTooltip: "Add row",
                deleteToolTip: "Delete row",
                editToolTip: "Edit row",
                emptyDataSourceMessage: emptyDataMessage
              }
            }}
            />
            </div>

        <div style={{marginTop: inputsm ? "0px" : "25px"}}>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={chartProdDataDivision[i]} margin={{ top: 0, right: 25, left: 10, bottom: 30 }}>
            <XAxis dataKey="name">
              <Label
                width={30}
                position="center"
                content={
                  <text x="50%" y="280" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                    <tspan alignmentBaseline="middle" fontSize="14">
                      {props.t("Productivity.TimeDays")}
                    </tspan>
                  </text>
                }
              />
            </XAxis>
            <YAxis>
              <Label
                position="center"
                content={
                  <text x="0" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                    <tspan x="36" dy="1.5em">
                      {props.t("Productivity.ProductivityPercentage")}
                    </tspan>
                  </text>
                }
              />
            </YAxis>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<ChartsDivisionTooltip />} />

            {Object.keys(productivityDivisionsData[keyName]).map((keyName2, index) => (
            <Area name={props.t("Productivity.ProductivityPercentage")} key={keyName2} type="monotone" dataKey={keyName2} stroke={colors[index]} fill={colors[index]} fillOpacity={fillChart ? 0.6 : 0} />))}
            <Legend
                              payload={Object.keys(productivityDivisionsData[keyName]).map((entry, index) => ({
                                id: entry,
                                type: "line",
                                value: entry,
                                color: colors[index]
                              }))}
                              verticalAlign="top"
                              height={45}
                            />
          </AreaChart>
        </ResponsiveContainer>
        </div>
      </Paper>
      ))});

      return array;
  }

  function detailedStatistics(){
    if(!chartProdData)
    return null;

    let array = [];

    Object.keys(productivityDivisionsData).map((keyName, i) => (
      array.push(
      <ExpansionPanel key={keyName}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{color: blue[500]}}/>}>
          <Typography variant="h6">
            {keyName}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>

        <Grid item xs={12} >
          {Object.keys(productivityDivisionsData[keyName]).map((keyName2, j) => (

        

        <Grid container  spacing={0} key={keyName2}
          style={{
        marginBottom: 20,
        paddingBottom: 5,
        border: "1px solid rgba(225, 225, 225, 1)",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px"
      }}>
        <Grid
        item
        xs={12}
        style={{ marginBottom: 10, backgroundColor: blue[500], padding: "10px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
      >
        <Typography variant="h6" style={{ fontSize: "1.1rem", color: "white" }}>
        {keyName2}
        </Typography>
      </Grid>
      <Grid item xs={4} style={{ padding: "10px" }}>
        <Typography variant="button">{props.t("Productivity.Produced").toUpperCase()}</Typography>
        <br />
        <Typography variant="h6">{chartProdData[keyName][keyName2].Produced}</Typography>
        <Typography variant="caption">{props.t("Productivity.Units")}</Typography>
      </Grid>

      <Grid
        item
        xs={4}
        style={{
          padding: "10px"
        }}
      >
        <Typography variant="button">{props.t("Productivity.Target").toUpperCase()}</Typography>
        <br />
        <Typography variant="h6">{chartProdData[keyName][keyName2].Target}</Typography>
        <Typography variant="caption">{props.t("Productivity.Units")}</Typography>
      </Grid>

      <Grid item xs={4} style={{ padding: "10px" }}>
        <Typography variant="button">{props.t("Productivity.Productivity").toUpperCase()}</Typography>
        <br />
        <Typography variant="h6">{chartProdData[keyName][keyName2].Productivity}%</Typography>
      </Grid>

        <Grid item xs={12}>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={chartProdData[keyName][keyName2].ProdPerActivity} margin={{ top: 0, right: 0, left: 10, bottom: 20 }}>
            <XAxis dataKey="name">
              <Label
                width={30}
                position="center"
                content={
                  <text x="50%" y="290" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                    <tspan alignmentBaseline="middle" fontSize="14">
                      {props.t("Productivity.TimeDays").toUpperCase()}
                    </tspan>
                  </text>
                }
              />
            </XAxis>
            <YAxis>
              <Label
                position="center"
                content={
                  <text x="0" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                    <tspan x="36" dy="1.0em">
                      {props.t("Productivity.ProductivityPercentage")}
                    </tspan>
                  </text>
                }
              />
            </YAxis>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={<ActivitiesDivisionTooltip />} />

            <Area
              name="Productivity target [%]"
              type="monotone"
              dataKey="TargetPercentage"
              stroke="rgba(200, 200, 200, 1)"
              fill="rgba(230, 230, 230, 0.5)"
            />
            <Area name="Productivity [%]" type="monotone" dataKey="Productivity" stroke="#8884d8" fill="#8884d8" />
            <Legend verticalAlign="top" height={36} />
          </AreaChart>
        </ResponsiveContainer>
        </Grid>

        </Grid>
        
     
          ))}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )));

    return array;
  }


  function createActivityChartsPerDivision() {
    let obj = {
      division: [],
      activity: [],
      activityData: []
    };

    Object.keys(productivityDivisionsData).map((keyName, i) => (
      <ExpansionPanel key={keyName}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{color: blue[500]}}/>}>
          <Typography variant="h6" style={{ fontSize: "0.9rem" }}>
            {keyName}
          </Typography>
        </ExpansionPanelSummary>
      </ExpansionPanel>
    ));

    return null;
  }

  function calcTotalAmountProduced(activitiesArray){
    let totalAmountProduced = 0;

    for (let i = 0; i < activitiesArray.length; i++) {
      totalAmountProduced += activitiesArray[i].amountProduced;
    }
    return totalAmountProduced;
  }

  function calcTotalTargetProduced(activitiesArray){
    let totalTarget = 0;

    for (let i = 0; i < activitiesArray.length; i++) {
      totalTarget += activitiesArray[i].targetProduced;
    }
    return totalTarget;
  }

  function calcProdPerActivity(activitiesArray) {
    let activityEfficiencyArray = [];
    let timelineArray = [];
    let totalAmountProduced = 0;
    let totalTarget = 0;
    let totalTimeWorked = 0;
    let totalTargetTimeWorked = 0;

    for (let i = 0; i < activitiesArray.length; i++) {
      let dwedSplitted = activitiesArray[i].date.toString().split("/");
      let d = new Date(dwedSplitted[2], dwedSplitted[1] - 1, dwedSplitted[0]);
      let day = d.toLocaleDateString("en-EN", { weekday: "long" }).toString();

      totalAmountProduced += activitiesArray[i].amountProduced;
      totalTarget += Math.round(activitiesArray[i].targetProduced);
      let activityEfficiency = Math.round((activitiesArray[i].amountProduced / activitiesArray[i].targetProduced) * 100 * 10) / 10;

      activityEfficiencyArray.push({
        name: (i + 1).toString(),
        Productivity: !activityEfficiency ? 0.0 : activityEfficiency,
        Produced: Math.round(activitiesArray[i].amountProduced),
        Target: Math.round(activitiesArray[i].targetProduced),
        TargetPercentage: day !== props.t("Productivity.Saturday") && day !== props.t("Productivity.Sunday") ? 100.0 : 0.0, //insert exceptions -> holidays etc.
        Day: `${day}, ${activitiesArray[i].date}`
      });
    }

    let totalProductivity = Math.round((totalAmountProduced / totalTarget) * 100 * 10) / 10;
    return [totalAmountProduced, totalTarget, totalProductivity, activityEfficiencyArray];
  }

  function renderEmployeesProductivity() {
    const keyHeight = 25
    const baseHeight = 300
    const perTaskLegendHeight = finalProductivityPerTaskArray.length * keyHeight
    const perTaskChartHeight = baseHeight + perTaskLegendHeight
    const perTaskXAxisHeight = baseHeight - 10 + perTaskLegendHeight
    
    const perDivisionLegendHeight = finalProductivityPerDivisionArray.length * keyHeight
    const perDivisionChartHeight = baseHeight + perDivisionLegendHeight
    const perDivisionXAxisHeight = baseHeight - 10 + perDivisionLegendHeight

    const perCustomerLegendHeight = finalProductivityPerCustomerArray.length * keyHeight
    const perCustomerChartHeight = baseHeight + perCustomerLegendHeight
    const perCustomerXAxisHeight = baseHeight - 10 + perCustomerLegendHeight


    if (!dailyWorkEfficiencyBool.current) {
      employeesGET();
      dailyWorkEfficiencyBool.current = true;
    }
    return (
      
      <div className={classes.paperPosition}>
        {inputsm ? (
          // #region MOBILE
          <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              className={classes.paperPropsMobile}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3} style={{paddingBottom: "12px"}}>
                  <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin" }}>{props.t("Productivity.FilterSettings")}</Typography>
                  </Grid>

                  <Grid className="employee-change" item xs={12} style={{ paddingBottom: "0px" }}>
                    <TextField
                      fullWidth
                      select
                      label={props.t("Productivity.Employee")}
                      margin="dense"
                      value={inputData.employeeName}
                      onChange={event => handleInputDataChange(event)}
                      variant="outlined"
                      disabled={isLoadingFilterSettingsEmployee}
                      >
                      {listOfEmployees.length > 0 && insertMenuItems()}
                    </TextField>
                    {isLoadingFilterSettingsEmployee && <div className="employee-change-loading">
                      <CircularProgress size={20}/>
                    </div>}
                  </Grid>

                  <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="mui-pickers-date"
                      label={props.t("Productivity.StartDate")}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={inputData.startDate}
                      onChange={(event, date) => {
                        handleStartDatePickerChange(event, date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="mui-pickers-date"
                      label={props.t("Productivity.EndDate")}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={inputData.endDate}
                      onChange={(event, date) => {
                        handleEndDatePickerChange(event, date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>

              <div className="date-button-container">
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      let today = new Date();
                      let start = startOfWeek(today, { weekStartsOn: 1 });
                      let end = endOfWeek(today, { weekStartsOn: 1 });
                      handleStartEndDatePickerChange(start, end);
                    }}
                  >
                    {props.t("Productivity.Week")}
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      let today = new Date();
                      let start = startOfMonth(today);
                      let end = endOfMonth(today);
                      handleStartEndDatePickerChange(start, end);
                    }}
                  >
                    {props.t("Productivity.Month")}
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      let today = new Date();
                      let start = startOfYear(today);
                      let end = endOfYear(today);
                      handleStartEndDatePickerChange(start, end);
                    }}
                  >
                    {props.t("Productivity.Year")}
                  </Button>
                </div>

                <div className="search-button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    className="no-shadow"
                    disabled={loading || !inputData.employeeName}
                    onClick={async () => {
                      await productivityPerEmployeeGET()
                    }}>
                    {props.t("Productivity.Search")}
                  </Button>
                </div>
            </Paper>
          </Grid>

          {!inputData.employeeName || Object.keys(dailyWorkEfficiencyData || {}).length === 0 ? (
          <Grid item xs={12}>
            <Paper
              className={classes.paperPropsMobile}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
            <Typography variant="h5" className={classes.subTitleText}>
            {props.t("Productivity.SelectEmployee")}
            </Typography>
            </Paper>
          </Grid>) : (

            <React.Fragment>

          <Grid item xs={12}>
            <Paper
              className={classes.paperPropsMobile}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="h6" style={{ display: "inline-block",fontSize: minWidth ? "1.25rem" : "5vmin"  }}>
                {props.t("Productivity.Productivity")}
                </Typography>
              </Grid>

              {loading ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: 20,

                      paddingBottom: 5,
                      border: "1px solid rgba(225, 225, 225, 1)",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ marginBottom: 0, backgroundColor: blue[500], padding: "10px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                    >
                      <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4vmin", color: "white" }}>
                      {props.t("Productivity.TotalQuantities")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ padding: "10px", paddingRight: "0px"}}>
                      <Typography variant="button" style={{fontSize: minWidth ? "0.875rem" : "3vmin"}}>{props.t("Productivity.Produced").toUpperCase()}</Typography>
                      <br />
                      <Typography variant="h6"  style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{Math.round(unitsProduced.current)}</Typography>
                      <Typography variant="caption">{props.t("Productivity.Units")}</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      style={{
                        padding: "10px", paddingRight: "0px"
                      }}
                    >
                      <Typography variant="button" style={{fontSize: minWidth ? "0.875rem" : "3vmin"}}>{props.t("Productivity.Target").toUpperCase()}</Typography>
                      <br />
                      <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{Math.round(unitsTarget.current)}</Typography>
                      <Typography variant="caption">{props.t("Productivity.Units")}</Typography>
                    </Grid>

                    <Grid item xs={4} style={{ padding: "10px", paddingLeft: "0px"}}>
                      <Typography variant="button" style={{fontSize: minWidth ? "0.875rem" : "3vmin"}}>{props.t("Productivity.Productivity").toUpperCase()}</Typography>
                      <br />
                      <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{Math.round((unitsProduced.current / unitsTarget.current) * 100 * 10) / 10 || 0}%</Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: 0,

                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      border: "1px solid rgba(225, 225, 225, 1)"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 0,
                        backgroundColor: blue[500],
                        padding: "10px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      <Typography variant="h6" style={{fontSize: minWidth ? "1.1rem" : "4vmin", color: "white" }}>
                      {props.t("Productivity.Overview")}
                      </Typography>
                    </Grid>

                    <div style={{ padding: "10px" }}>
                      <ResponsiveContainer width="100%" height={300}>
                        <AreaChart data={finalWorkEfficiencyArray} margin={{ top: 0, right: 0, left: -5, bottom: 20 }}>
                          <XAxis dataKey="name">
                            <Label
                              width={30}
                              position="center"
                              content={
                                <text x="50%" y="290" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                  <tspan alignmentBaseline="middle" fontSize="14">
                                  {props.t("Productivity.TimeDays").toUpperCase()}
                                  </tspan>
                                </text>
                              }
                            />
                          </XAxis>
                          <YAxis>
                            <Label
                              position="center"
                              content={
                                <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                  <tspan x="36" dy="0.355em">
                                  {props.t("Productivity.ProductivityPercentage").toUpperCase()}
                                  </tspan>
                                </text>
                              }
                            />
                          </YAxis>
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip content={<ProductivityTooltip />} />

                          <Area
                            name={props.t("Productivity.ProductivityTargetPercentage")}
                            type="monotone"
                            dataKey="TargetPercentage"
                            stroke="rgba(200, 200, 200, 1)"
                            fill="rgba(230, 230, 230, 0.5)"
                          />
                          <Area name="Productivity [%]" type="monotone" dataKey="Productivity" stroke="#8884d8" fill="#8884d8" />
                          <Legend verticalAlign="top" height={50}/>
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>

                    <Grid item xs={12} style={{ marginTop: 0, padding: "10px" }}>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{color: blue[500]}}/>}>
                          <Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                          {props.t("Productivity.RawProductivityData")}
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Table size="small" style={{display: "inline-block", overflowX: "auto"}}>
                            <TableHead>
                              <TableRow>{insertProdTableHead()}</TableRow>
                            </TableHead>
                            <TableBody>{insertProdTableRows()}</TableBody>
                          </Table>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              className={classes.paperPropsMobile}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin", display: "inline-block", marginBottom: "0px" }}>
                {props.t("Productivity.Timeline")}
                </Typography>
              </Grid>

              {loading ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: 20,
                      paddingBottom: 5,
                      border: "1px solid rgba(225, 225, 225, 1)",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ marginBottom: 0, backgroundColor: blue[500], padding: "10px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                    >
                      <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4vmin", color: "white" }}>
                      {props.t("Productivity.TotalWorkingTime")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ padding: "10px", paddingRight: "0px" }}>
                      <Typography variant="button" style={{fontSize: minWidth ? "0.875rem" : "3vmin"}}>{props.t("Productivity.Worked").toUpperCase()}</Typography>
                      <br />
                      <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{Math.round(timeSpentWorking.current)}</Typography>
                      <Typography variant="caption">{props.t("Productivity.Minutes")}</Typography>
                    </Grid>

                    <Grid item xs={4} style={{ padding: "10px", paddingRight: "0px" }}>
                      <Typography variant="button" style={{fontSize: minWidth ? "0.875rem" : "3vmin"}}>{props.t("Productivity.Target").toUpperCase()}</Typography>
                      <br />
                      <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{Math.round(targetTimeSpentWorking.current)}</Typography>
                      <Typography variant="caption">{props.t("Productivity.Minutes")}</Typography>
                    </Grid>

                    <Grid item xs={4} style={{ padding: "10px", paddingLeft: "0px" }}>
                      <Typography variant="button" style={{fontSize: minWidth ? "0.875rem" : "3vmin"}}>{props.t("Productivity.Progression").toUpperCase()}</Typography>
                      <br />
                      <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{Math.round((timeSpentWorking.current / targetTimeSpentWorking.current) * 100 * 10) / 10}%</Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: 0,

                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      border: "1px solid rgba(225, 225, 225, 1)"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 0,
                        backgroundColor: blue[500],
                        padding: "10px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4vmin", color: "white" }}>
                      {props.t("Productivity.Overview")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      {/* <div style={{ border: "1px solid rgba(225, 225, 225, 1)", backgroundColor: "rgba(225, 225, 225, 0.2)" }}> */}
                      <div style={{ padding: "10px" }}>
                        <ResponsiveContainer width="100%" height={300}>
                          <AreaChart data={timeSpentWorkingArray} margin={{ top: 0, right: 0, left: -5, bottom: 20 }}>
                            <defs>
                              <linearGradient id="timeline" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.9} />
                                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                              </linearGradient>
                            </defs>
                            <XAxis dataKey="name">
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y="290" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("Productivity.TimeDays").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="36" dy="0.355em">
                                    {props.t("Productivity.ProgressionPercentage").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </YAxis>
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip content={<TimelineTooltip />} />

                            <Area
                              name={props.t("Productivity.ProgressionTargetPercentage")}
                              type="monotone"
                              dataKey="TargetPercentage"
                              stroke="rgba(200, 200, 200, 1)"
                              fill="rgba(230, 230, 230, 0.5)"
                            />
                            <Area name={props.t("HomeUser.ProgressionPercentage")} type="monotone" dataKey="Efficiency" stroke="#82ca9d" fill="#82ca9d" />
                            <Legend verticalAlign="top" height={45} />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 0, padding: "10px" }}>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{color: blue[500]}}/>}>
                          <Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                          {props.t("Productivity.RawTimelineData")}
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Table size="small" style={{display: "inline-block", overflowX: "auto"}}>
                            <TableHead>
                              <TableRow>{insertTimelineTableHead()}</TableRow>
                            </TableHead>
                            <TableBody>{insertTimelineTableRows()}</TableBody>
                          </Table>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              className={classes.paperPropsMobile}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              {/* <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="h6">Productivity per customer</Typography>
              </Grid> */}

              <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin", display: "inline-block", marginBottom: "0px" }}>
                {props.t("Productivity.ProductivityPerCustomer")}
                </Typography>
                {/* {enlargePaperProductivityCharts ? (
                  <IconButton onClick={event => setEnlargePaperProductivityCharts(false)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <IndeterminateCheckBox style={{color: blue[500]}}/>
                    </IconButton>
                ) : (
                 
                    <IconButton onClick={event => setEnlargePaperProductivityCharts(true)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <LaunchIcon style={{color: blue[500]}}/>
                    </IconButton>
                )} */}
              </Grid>

              {loading ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: 0,
                      paddingBottom: 5,
                      border: "1px solid rgba(225, 225, 225, 1)",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 10,
                        backgroundColor: blue[500],
                        padding: "10px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4vmin", color: "white" }}>
                      {props.t("Productivity.Overview")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <div style={{ padding: "10px" }}>
                        <ResponsiveContainer width="100%" height={perCustomerChartHeight}>
                          <BarChart
                            data={finalProductivityPerCustomerArray}
                            margin={{
                              top: 0,
                              right: 0,
                              left: -5,
                              bottom: 20
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name">
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y={perCustomerXAxisHeight} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("Productivity.Customers").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="0" dy="0.355em">
                                    {props.t("Productivity.ProductivityPercentage").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </YAxis>
                            <Tooltip content={<ProductivityPerTaskTooltip />} />
                            <Legend
                              payload={finalProductivityPerCustomerArray.map((entry, index) => ({
                                id: entry.name,
                                type: "square",
                                value: entry.name,
                                color: colors[index]
                              }))}
                              verticalAlign="top"
                              height={perCustomerLegendHeight}
                            />

                            <Bar dataKey="Productivity">
                              {finalProductivityPerCustomerArray.map((entry, index) => {
                                return <Cell fill={colors[index]} key={colors[index]} />;
                              })}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Paper>
          </Grid>

         

          <Grid item xs={12}>
            <Paper
              className={classes.paperPropsMobile}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin", display: "inline-block", marginBottom: "0px" }}>
                {props.t("Productivity.ProdPerDivision")}
                </Typography>
                {/* {enlargePaperProductivityCharts ? (
                  <IconButton onClick={event => setEnlargePaperProductivityCharts(false)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <IndeterminateCheckBox style={{color: blue[500]}}/>
                    </IconButton>
                ) : (
                 
                    <IconButton onClick={event => setEnlargePaperProductivityCharts(true)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <LaunchIcon style={{color: blue[500]}}/>
                    </IconButton>
                )} */}
              </Grid>

              {loading ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: 0,
                      paddingBottom: 5,
                      border: "1px solid rgba(225, 225, 225, 1)",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 10,
                        backgroundColor: blue[500],
                        padding: "10px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4vmin", color: "white" }}>
                      {props.t("Productivity.Overview")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <div style={{ padding: "10px" }}>
                        <ResponsiveContainer width="100%" height={perDivisionChartHeight}>
                          <BarChart
                            data={finalProductivityPerDivisionArray}
                            margin={{
                              top: 0,
                              right: 0,
                              left: -5,
                              bottom: 20
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name">
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y={perDivisionXAxisHeight} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("Productivity.Divisions").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="0" dy="0.355em">
                                    {props.t("Productivity.ProductivityPercentage").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </YAxis>
                            <Tooltip content={<ProductivityPerTaskTooltip />} />
                            <Legend
                              payload={finalProductivityPerDivisionArray.map((entry, index) => ({
                                id: entry.name,
                                type: "square",
                                value: entry.name,
                                color: colors[index]
                              }))}
                              verticalAlign="top"
                              height={perDivisionLegendHeight}
                            />

                            <Bar dataKey="Productivity">
                              {finalProductivityPerDivisionArray.map((entry, index) => {
                                return <Cell fill={colors[index]} key={colors[index]} />;
                              })}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              className={classes.paperPropsMobile}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin", display: "inline-block", marginBottom: "0px" }}>
                {props.t("Productivity.ProdPerTask")}
                </Typography>
                {/* {enlargePaperProductivityCharts ? (
                  <IconButton onClick={event => setEnlargePaperProductivityCharts(false)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <IndeterminateCheckBox style={{color: blue[500]}}/>
                    </IconButton>
                ) : (
                 
                    <IconButton onClick={event => setEnlargePaperProductivityCharts(true)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <LaunchIcon style={{color: blue[500]}}/>
                    </IconButton>
                )} */}
              </Grid>

              {loading ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: 0,
                      paddingBottom: 5,
                      border: "1px solid rgba(225, 225, 225, 1)",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 10,
                        backgroundColor: blue[500],
                        padding: "10px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4vmin", color: "white" }}>
                      {props.t("Productivity.Overview")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <div style={{ padding: "10px" }}>
                        <ResponsiveContainer width="100%" height={perTaskChartHeight}>
                          <BarChart
                            data={finalProductivityPerTaskArray}
                            margin={{
                              top: 0,
                              right: 0,
                              left: -5,
                              bottom: 20
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name">
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y={perTaskXAxisHeight} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("Productivity.Tasks").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="0" dy="0.355em">
                                    {props.t("Productivity.ProductivityPercentage")}
                                    </tspan>
                                  </text>
                                }
                              />
                            </YAxis>
                            <Tooltip content={<ProductivityPerTaskTooltip />} />
                            <Legend
                              payload={finalProductivityPerTaskArray.map((entry, index) => ({
                                id: entry.name,
                                type: "square",
                                value: entry.name,
                                color: colors[index]
                              }))}
                              verticalAlign="top"
                              height={perTaskLegendHeight}
                            />

                            <Bar dataKey="Productivity">
                              {finalProductivityPerTaskArray.map((entry, index) => {
                                return <Cell fill={colors[index]} key={colors[index]} />;
                              })}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Paper>
          </Grid>

          </React.Fragment>
          )}
        </Grid>
        // #endregion
        ) : (
          // #region DESKTOP
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" >{props.t("Productivity.FilterSettings")}</Typography>
                  </Grid>

                  <Grid className="employee-change" item xs={12}>
                    <TextField
                      fullWidth
                      select
                      label={props.t("Productivity.Employee")}
                      value={inputData.employeeName}
                      onChange={event => handleInputDataChange(event)}
                      variant="outlined"
                      disabled={isLoadingFilterSettingsEmployee}
                      >
                      {listOfEmployees.length > 0 && insertMenuItems()}
                    </TextField>
                    {isLoadingFilterSettingsEmployee && <div className="employee-change-loading">
                      <CircularProgress size={20}/>
                    </div>}
                  </Grid>

                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="mui-pickers-date"
                      label={props.t("Productivity.StartDate")}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={inputData.startDate}
                      onChange={(event, date) => {
                        handleStartDatePickerChange(event, date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      id="mui-pickers-date"
                      label={props.t("Productivity.EndDate")}
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      value={inputData.endDate}
                      onChange={(event, date) => {
                        handleEndDatePickerChange(event, date);
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>

              <div className="date-button-container">
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      let today = new Date();
                      let start = startOfWeek(today, { weekStartsOn: 1 });
                      let end = endOfWeek(today, { weekStartsOn: 1 });
                      handleStartEndDatePickerChange(start, end);
                    }}
                  >
                    {props.t("Productivity.Week")}
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      let today = new Date();
                      let start = startOfMonth(today);
                      let end = endOfMonth(today);
                      handleStartEndDatePickerChange(start, end);
                    }}
                  >
                    {props.t("Productivity.Month")}
                  </Button>

                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={event => {
                      let today = new Date();
                      let start = startOfYear(today);
                      let end = endOfYear(today);
                      handleStartEndDatePickerChange(start, end);
                    }}
                  >
                    {props.t("Productivity.Year")}
                  </Button>
                </div>
                <div className="search-button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    className="no-shadow button-padding"
                    disabled={loading || !inputData.employeeName}
                    onClick={async () => {
                      await productivityPerEmployeeGET()
                    }}>
                    {props.t("Productivity.Search")}
                  </Button>
                </div>
            </Paper>
          </Grid>

          {!inputData.employeeName || Object.keys(dailyWorkEfficiencyData || {}).length === 0 ? (
          <Grid item xs={12}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
            <Typography variant="h5" className={classes.subTitleText}>
            {props.t("Productivity.SelectEmployee")}
            </Typography>
            </Paper>
          </Grid>) : (

            <React.Fragment>

          <Grid item xs={enlargePaper ? 12 : 6}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="h6" style={{ display: "inline-block", marginBottom: "15px" }}>
                {props.t("Productivity.ProductivityText")}
                </Typography>
                {enlargePaper ? (
                  <IconButton onClick={event => setEnlargePaper(false)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <IndeterminateCheckBox style={{color: blue[500]}}/>
                    </IconButton>
                ) : (
                 
                    <IconButton onClick={event => setEnlargePaper(true)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <LaunchIcon style={{color: blue[500]}}/>
                    </IconButton>
                )}
              </Grid>

              {loading ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: 20,

                      paddingBottom: 5,
                      border: "1px solid rgba(225, 225, 225, 1)",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ marginBottom: 10, backgroundColor: blue[500], padding: "10px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                    >
                      <Typography variant="h6" style={{ fontSize: "1.1rem", color: "white" }}>
                      {props.t("Productivity.TotalQuantities")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ padding: "10px" }}>
                      <Typography variant="button">{props.t("Productivity.Produced").toUpperCase()}</Typography>
                      <br />
                      <Typography variant="h6">{Math.round(unitsProduced.current)}</Typography>
                      <Typography variant="caption">{props.t("Productivity.Units")}</Typography>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      style={{
                        padding: "10px"
                      }}
                    >
                      <Typography variant="button">{props.t("Productivity.Target").toUpperCase()}</Typography>
                      <br />
                      <Typography variant="h6">{Math.round(unitsTarget.current)}</Typography>
                      <Typography variant="caption">{props.t("Productivity.Units")}</Typography>
                    </Grid>

                    <Grid item xs={4} style={{ padding: "10px" }}>
                      <Typography variant="button">{props.t("Productivity.Productivity").toUpperCase()}</Typography>
                      <br />
                      <Typography variant="h6">{Math.round((unitsProduced.current / unitsTarget.current) * 100 * 10) / 10 || 0}%</Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: 20,

                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      border: "1px solid rgba(225, 225, 225, 1)"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 10,
                        backgroundColor: blue[500],
                        padding: "10px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      <Typography variant="h6" style={{ fontSize: "1.1rem", color: "white" }}>
                      {props.t("Productivity.Overview")}
                      </Typography>
                    </Grid>

                    <div style={{ padding: "10px" }}>
                      <ResponsiveContainer width="100%" height={300}>
                        <AreaChart data={finalWorkEfficiencyArray} margin={{ top: 0, right: 0, left: -5, bottom: 20 }}>
                          <XAxis dataKey="name">
                            <Label
                              width={30}
                              position="center"
                              content={
                                <text x="50%" y="290" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                  <tspan alignmentBaseline="middle" fontSize="14">
                                  {props.t("Productivity.TimeDays").toUpperCase()}
                                  </tspan>
                                </text>
                              }
                            />
                          </XAxis>
                          <YAxis>
                            <Label
                              position="center"
                              content={
                                <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                  <tspan x="36" dy="0.355em">
                                  {props.t("Productivity.ProductivityPercentage").toUpperCase()}
                                  </tspan>
                                </text>
                              }
                            />
                          </YAxis>
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip content={<ProductivityTooltip />} />

                          <Area
                            name={props.t("Productivity.ProductivityTargetPercentage")}
                            type="monotone"
                            dataKey="TargetPercentage"
                            stroke="rgba(200, 200, 200, 1)"
                            fill="rgba(230, 230, 230, 0.5)"
                          />
                          <Area name="Productivity [%]" type="monotone" dataKey="Productivity" stroke="#8884d8" fill="#8884d8" />
                          <Legend verticalAlign="top" height={enlargePaper ? 36 : 50} />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>

                    <Grid item xs={12} style={{ marginTop: 10, padding: "10px" }}>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{color: blue[500]}}/>}>
                          <Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                          {props.t("Productivity.RawProductivityData")}
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Table size="small" style={{display: tableWidth ? "table" : "inline-block", overflowX: "auto"}}>
                            <TableHead>
                              <TableRow>{insertProdTableHead()}</TableRow>
                            </TableHead>
                            <TableBody>{insertProdTableRows()}</TableBody>
                          </Table>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Paper>
          </Grid>

          <Grid item xs={enlargePaper ? 12 : 6}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="h6" style={{ display: "inline-block", marginBottom: "15px" }}>
                {props.t("Productivity.Timeline")}
                </Typography>
                {enlargePaper ? (
                  <IconButton onClick={event => setEnlargePaper(false)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <IndeterminateCheckBox style={{color: blue[500]}}/>
                    </IconButton>
                ) : (
                 
                    <IconButton onClick={event => setEnlargePaper(true)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <LaunchIcon style={{color: blue[500]}}/>
                    </IconButton>
                )}
              </Grid>

              {loading ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: 20,
                      paddingBottom: 5,
                      border: "1px solid rgba(225, 225, 225, 1)",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ marginBottom: 10, backgroundColor: blue[500], padding: "10px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                    >
                      <Typography variant="h6" style={{ fontSize: "1.1rem", color: "white" }}>
                      {props.t("Productivity.TotalWorkingTime")}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ padding: "10px" }}>
                      <Typography variant="button">{props.t("Productivity.Worked").toUpperCase()}</Typography>
                      <br />
                      <Typography variant="h6">{Math.round(timeSpentWorking.current)}</Typography>
                      <Typography variant="caption">{props.t("Productivity.Minutes")}</Typography>
                    </Grid>

                    <Grid item xs={4} style={{ padding: "10px" }}>
                      <Typography variant="button">{props.t("Productivity.Target").toUpperCase()}</Typography>
                      <br />
                      <Typography variant="h6">{Math.round(targetTimeSpentWorking.current)}</Typography>
                      <Typography variant="caption">{props.t("Productivity.Minutes")}</Typography>
                    </Grid>

                    <Grid item xs={4} style={{ padding: "10px" }}>
                      <Typography variant="button">{props.t("Productivity.Progression").toUpperCase()}</Typography>
                      <br />
                      <Typography variant="h6">{Math.round((timeSpentWorking.current / targetTimeSpentWorking.current) * 100 * 10) / 10}%</Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      marginBottom: 20,

                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      border: "1px solid rgba(225, 225, 225, 1)"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 10,
                        backgroundColor: blue[500],
                        padding: "10px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      <Typography variant="h6" style={{ fontSize: "1.1rem", color: "white" }}>
                      {props.t("Productivity.Overview")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      {/* <div style={{ border: "1px solid rgba(225, 225, 225, 1)", backgroundColor: "rgba(225, 225, 225, 0.2)" }}> */}
                      <div style={{ padding: "10px" }}>
                        <ResponsiveContainer width="100%" height={300}>
                          <AreaChart data={timeSpentWorkingArray} margin={{ top: 0, right: 0, left: -5, bottom: 20 }}>
                            <defs>
                              <linearGradient id="timeline" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.9} />
                                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                              </linearGradient>
                            </defs>
                            <XAxis dataKey="name">
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y="290" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("Productivity.TimeDays").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="36" dy="0.355em">
                                    {props.t("Productivity.ProgressionPercentage").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </YAxis>
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip content={<TimelineTooltip />} />

                            <Area
                              name={props.t("HomeUser.ProgressionTarget")}
                              type="monotone"
                              dataKey="TargetPercentage"
                              stroke="rgba(200, 200, 200, 1)"
                              fill="rgba(230, 230, 230, 0.5)"
                            />
                            <Area name={props.t("Productivity.ProgressionPercentage")} type="monotone" dataKey="Efficiency" stroke="#82ca9d" fill="#82ca9d" />
                            <Legend verticalAlign="top" height={enlargePaper ? 36 : 50} />
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10, padding: "10px" }}>
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{color: blue[500]}}/>}>
                          <Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                          {props.t("Productivity.RawTimelineData")}
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Table size="small" style={{display: tableWidth ? "table" : "inline-block", overflowX: "auto"}}>
                            <TableHead>
                              <TableRow>{insertTimelineTableHead()}</TableRow>
                            </TableHead>
                            <TableBody>{insertTimelineTableRows()}</TableBody>
                          </Table>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Paper>
          </Grid>

          <Grid item xs={enlargePaperProductivityCharts ? 12 : 4}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              {/* <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="h6">Productivity per customer</Typography>
              </Grid> */}

              <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="h6" style={{ display: "inline-block", marginBottom: "15px" }}>
                {enlargePaperProductivityCharts ? props.t("Productivity.ProductivityPerCustomer") : props.t("Productivity.ProdDotPerCustomer")}
                </Typography>
                {enlargePaperProductivityCharts ? (
                  <IconButton onClick={event => setEnlargePaperProductivityCharts(false)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <IndeterminateCheckBox style={{color: blue[500]}}/>
                    </IconButton>
                ) : (
                 
                    <IconButton onClick={event => setEnlargePaperProductivityCharts(true)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <LaunchIcon style={{color: blue[500]}}/>
                    </IconButton>
                )}
              </Grid>

              {loading ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: 20,
                      paddingBottom: 5,
                      border: "1px solid rgba(225, 225, 225, 1)",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 10,
                        backgroundColor: blue[500],
                        padding: "10px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      <Typography variant="h6" style={{ fontSize: "1.1rem", color: "white" }}>
                      {props.t("Productivity.Overview")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <div style={{ padding: "10px" }}>
                        <ResponsiveContainer width="100%" height={perCustomerChartHeight}>
                          <BarChart
                            data={finalProductivityPerCustomerArray}
                            margin={{
                              top: 0,
                              right: 0,
                              left: -5,
                              bottom: 20
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name">
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y={perCustomerXAxisHeight} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("Productivity.Customers").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="0" dy="0.355em">
                                    {props.t("Productivity.ProductivityPercentage").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </YAxis>
                            <Tooltip content={<ProductivityPerTaskTooltip />} />
                            <Legend
                              payload={finalProductivityPerCustomerArray.map((entry, index) => ({
                                id: entry.name,
                                type: "square",
                                value: entry.name,
                                color: colors[index]
                              }))}
                              verticalAlign="top"
                              height={perCustomerLegendHeight}
                            />

                            <Bar dataKey="Productivity">
                              {finalProductivityPerCustomerArray.map((entry, index) => {
                                return <Cell fill={colors[index]} key={colors[index]} />;
                              })}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Paper>
          </Grid>

         

          <Grid item xs={enlargePaperProductivityCharts ? 12 : 4}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="h6" style={{ display: "inline-block", marginBottom: "15px" }}>
                {enlargePaperProductivityCharts ? props.t("Productivity.ProdPerDivision") : props.t("Productivity.ProdDotPerDivision")}
                </Typography>
                {enlargePaperProductivityCharts ? (
                  <IconButton onClick={event => setEnlargePaperProductivityCharts(false)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <IndeterminateCheckBox style={{color: blue[500]}}/>
                    </IconButton>
                ) : (
                 
                    <IconButton onClick={event => setEnlargePaperProductivityCharts(true)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <LaunchIcon style={{color: blue[500]}}/>
                    </IconButton>
                )}
              </Grid>

              {loading ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: 20,
                      paddingBottom: 5,
                      border: "1px solid rgba(225, 225, 225, 1)",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 10,
                        backgroundColor: blue[500],
                        padding: "10px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      <Typography variant="h6" style={{ fontSize: "1.1rem", color: "white" }}>
                      {props.t("Productivity.Overview")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <div style={{ padding: "10px" }}>
                        <ResponsiveContainer width="100%" height={perDivisionChartHeight}>
                          <BarChart
                            data={finalProductivityPerDivisionArray}
                            margin={{
                              top: 0,
                              right: 0,
                              left: -5,
                              bottom: 20
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name">
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y={perDivisionXAxisHeight} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("Productivity.Divisions").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="0" dy="0.355em">
                                    {props.t("Productivity.ProductivityPercentage").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </YAxis>
                            <Tooltip content={<ProductivityPerTaskTooltip />} />
                            <Legend
                              payload={finalProductivityPerDivisionArray.map((entry, index) => ({
                                id: entry.name,
                                type: "square",
                                value: entry.name,
                                color: colors[index]
                              }))}
                              verticalAlign="top"
                              height={perDivisionLegendHeight}
                            />

                            <Bar dataKey="Productivity">
                              {finalProductivityPerDivisionArray.map((entry, index) => {
                                return <Cell fill={colors[index]} key={colors[index]} />;
                              })}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Paper>
          </Grid>

          <Grid item xs={enlargePaperProductivityCharts ? 12 : 4}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}
            >
              <Grid item xs={12} style={{ padding: "10px" }}>
                <Typography variant="h6" style={{ display: "inline-block", marginBottom: "15px" }}>
                {enlargePaperProductivityCharts ? props.t("Productivity.ProdPerTask") : props.t("Productivity.ProdDotPerTask")}
                </Typography>
                {enlargePaperProductivityCharts ? (
                  <IconButton onClick={event => setEnlargePaperProductivityCharts(false)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <IndeterminateCheckBox style={{color: blue[500]}}/>
                    </IconButton>
                ) : (
                 
                    <IconButton onClick={event => setEnlargePaperProductivityCharts(true)} style={{display: "inline-block", float: "right", top: "-8px"}}>
                      <LaunchIcon style={{color: blue[500]}}/>
                    </IconButton>
                )}
              </Grid>

              {loading ? (
                <Grid item xs={12} align="center">
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: 20,
                      paddingBottom: 5,
                      border: "1px solid rgba(225, 225, 225, 1)",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px"
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 10,
                        backgroundColor: blue[500],
                        padding: "10px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      <Typography variant="h6" style={{ fontSize: "1.1rem", color: "white" }}>
                      {props.t("Productivity.Overview")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <div style={{ padding: "10px" }}>
                        <ResponsiveContainer width="100%" height={perTaskChartHeight}>
                          <BarChart
                            data={finalProductivityPerTaskArray}
                            margin={{
                              top: 0,
                              right: 0,
                              left: -5,
                              bottom: 20
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name">
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y={perTaskXAxisHeight} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("Productivity.Tasks").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="0" dy="0.355em">
                                    {props.t("Productivity.ProductivityPercentage").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </YAxis>
                            <Tooltip content={<ProductivityPerTaskTooltip />} />
                            <Legend
                              payload={finalProductivityPerTaskArray.map((entry, index) => ({
                                id: entry.name,
                                type: "square",
                                value: entry.name,
                                color: colors[index]
                              }))}
                              verticalAlign="top"
                              height={perTaskLegendHeight}
                            />

                            <Bar dataKey="Productivity">
                              {finalProductivityPerTaskArray.map((entry, index) => {
                                return <Cell fill={colors[index]} key={colors[index]} />;
                              })}
                            </Bar>
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Paper>
          </Grid>

          </React.Fragment>
          )}
        </Grid>
        // #endregion
        )}
      </div>
    );
  }

  const opacity = 0.6;

  const colors = [
    `rgba(230, 25, 75, ${opacity})`,
    `rgba(60, 180, 75, ${opacity})`,
    `rgba(225, 225, 25, ${opacity})`,
    `rgba(67, 99, 216, ${opacity})`,
    `rgba(245, 130, 49, ${opacity})`,
    `rgba(145,30, 180, ${opacity})`,
    `rgba(66, 212, 244, ${opacity})`,
    `rgba(240, 50, 230, ${opacity})`,
    `rgba(191, 239, 69, ${opacity})`,
    `rgba(250, 190, 190, ${opacity})`,
    `rgba(70, 153, 144, ${opacity})`,
    `rgba(230, 190, 255, ${opacity})`,
    `rgba(154, 99, 36, ${opacity})`,
    `rgba(255, 250, 200, ${opacity})`,
    `rgba(128, 0, 0, ${opacity})`,
    `rgba(170, 255, 195, ${opacity})`,
    `rgba(128, 128, 0, ${opacity})`,
    `rgba(255, 216, 177, ${opacity})`,
    `rgba(0, 0, 117, ${opacity})`
  ];


  function renderProductivityHome() {
    return (
      <div className={classes.background}>
        <div className={classes.paperPositionHome}>
          <Paper
            className={inputsm ? classes.paperPropsMobile : classes.paperProps}
            classes={{
              rounded: classes.paperShape,
              elevation1: classes.paperShadow
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5" className={classes.title}>
                  {props.t("Productivity.ProductivityText")}
                  <AssessmentIcon className={classes.iconStyle} />
                </Typography>

                <Typography variant="h5" className={classes.subTitle}>
                {props.t("Productivity.ProductivityInsight")}
                </Typography>
              </Grid>

              {/* <Grid
                item
                xs={12}
                style={{
                  marginBottom: 20,
                  paddingBottom: 5,
                  border: "1px solid rgba(225, 225, 225, 1)",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                  width: "490px"
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontSize: "1.1rem",
                    color: "white",
                    marginBottom: 10,
                    backgroundColor: blue[500],
                    padding: "10px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px"
                  }}
                >
                  Tables
                </Typography>
              </Grid> */}
            </Grid>
          </Paper>
        </div>
      </div>
    );
  }

  return (
    <div>{renderProductivity()}</div>
    // <div className={classes.launchPage}>
    //   {areaChart()}
    // </div>
  );
}

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] },
    secondary: { main: "#FFFFFF" }
  }
});

const drawerWidth = 250;
const minToolbarHeight = 64;
const tabsHeight = 64;

const useStyles = makeStyles(theme => ({
  tab: {
    minHeight: 64
  },
  appBarPosition: {
    marginTop: minToolbarHeight,

    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth,
      marginTop: minToolbarHeight
    }
  },
  launchPage: {
    paddingLeft: "40px",
    paddingTop: `calc(${minToolbarHeight}px + 40px)`,

    [theme.breakpoints.up("lg")]: {
      paddingLeft: `calc(${drawerWidth}px + 100px)`,
      paddingTop: `calc(${minToolbarHeight}px + 40px)`
    }
  },

  background: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    marginTop: -130,

    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      width: "100%",
    },

    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    }
  },

  paperPosition: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    marginBottom: "16px",
    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth + 32,
      marginRight: "32px",
      marginTop: "32px",
      marginBottom: "32px",
    }
  },
  paperPositionHome: {
    marginLeft: "16px",
    marginRight: "16px",
    paddingTop: `calc(${minToolbarHeight}px + ${tabsHeight}px)`,

    [theme.breakpoints.up("lg")]: {
      marginLeft: `calc(${drawerWidth}px + 32px)`,
      marginRight: "32px",
      paddingTop: `calc(${minToolbarHeight}px + ${tabsHeight}px)`
    }
  },

  paperShape: {
    borderRadius: 8
  },
  paperProps: {
    padding: "32px",
    [theme.breakpoints.up("sm")]: {
      padding: "32px"
    }
  },
  paperPropsMobile: {
    padding: "16px",
    [theme.breakpoints.up("sm")]: {
      padding: "32px",
    }
  },
  tab1: {
    minWidth: 80,
    width: 80
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 500,
    paddingLeft: "0px",
    paddingTop: "0px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
      paddingLeft: "7px",
      paddingTop: "10px",
      fontWeight: 500
    }
  },
  subTitle: {
    fontSize: "1rem",
    marginBottom: 10, 
    paddingLeft: "0px", 
    paddingTop: "10px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 20, 
      fontSize: "1rem",
      paddingLeft: "10px", 
      paddingTop: "10px"
    }
  },
  subTitleText: {
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    }
  },
  customTabs: {
    "& .MuiTabScrollButton-root": {
      width: "32px",
    }
  },
  iconStyle: {
    position: "relative",
    left: "10px",
    fontSize: "unset",
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      fontSize: "unset"
    }
  },
  cellStyling: {
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "0.75rem"
  },
  headerStyling: {
    paddingTop: "0px",
    paddingBottom: "5px",
    fontSize: "0.875rem"
  },
  cellStylingSmall: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "0.75rem"
  },
  headerStylingSmall: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "0.75rem"
  },
}));

export default withRouter(Productvity);

// import React, { PureComponent } from 'react';

// export default class Example extends PureComponent {
//   static jsfiddleUrl = 'https://jsfiddle.net/alidingling/c1rLyqj1/';

//   render() {
//     return (
//       <AreaChart
//         width={500}
//         height={400}
//         data={data}
//         margin={{
//           top: 10, right: 30, left: 0, bottom: 0,
//         }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="name" />
//         <YAxis />
//         <Tooltip />
//         <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
//         <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
//         <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />
//       </AreaChart>
//     );
//   }
// }
