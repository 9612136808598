import React, { Component } from "react";
import "./App.css";
import Axios from "axios";
import { createTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const API = process.env.REACT_APP_BACKEND_BASE_URL;
const QUERY = "/values";
const mytheme = createTheme({});
const styles = {
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: "center"
  },
  textField: {
    width: 200
  }
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testData: null,
      testData2: [],
      isLoading: false,
      error: null,
      IsToggledOn: true,
      factory: "",
      field1: "",
      field2: "",
      firstName: "",
      lastName: "",
      factoryId: ""
    };

    this.GetRequest = this.GetRequest.bind(this);
    this.PostRequest = this.PostRequest.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
  }

  async GetRequest() {
    this.setState({ isLoading: true });

    try {
      let result = await Axios.get(API + QUERY);
      this.setState({ testData: result.data, isLoading: false });
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  async PostRequest() {
    this.setState({ isLoading: true });
    this.setState({ IsToggledOn: false });
    try {
      await Axios.post(API + QUERY, {
        FullName: "react fromreact",
        DateOfBirth: "01-01-4000",
        FactoryFactoryId: "1",
        FactoryFactory: null,
        Dailytimesheet: null
      });
    } catch (error) {
      this.setState({ error });
    }
    this.setState({ IsToggledOn: true });
  }

  HandleChange = input => event => {
    this.setState({ [input]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { firstName, lastName, factoryId, field1, field2 } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>xs12</Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>xs6</Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>xs6</Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>xs3</Paper>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(App);
