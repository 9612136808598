/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
import { Button, CircularProgress, createMuiTheme, Grid, Paper, TextField, Typography, useMediaQuery } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, Redirect, withRouter, useHistory } from "react-router-dom";
import bgImage from "./../bg_1.jfif";
import bgLandscape from "./../bg_1.jfif";
//import MuiThemeProvider from "@material-ui/styles/MuiThemeProvider";
import Alert from '@material-ui/lab/Alert';

function PasswordRecovery(props) {
  props.currentPathCallbackProp(props.location.pathname);
  props.headerNameCallbackProp("");

  const classes = useStyles(props);
  const [fields, setFields] = useState({
    userName: "",
    password: "",
    showPassword: false
  });
  const fieldsRef = useRef()
  const emailSentRef = useRef()

  const [redirect, setRedirect] = useState(false);
  const addressExtension = "/Authentication";
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory()
  const inputsm = useMediaQuery(theme.breakpoints.down("sm"));
  const minWidth = useMediaQuery("(min-width:400px)");

  useEffect(() => {
    window.addEventListener("keypress", keyPress)
    return () => {
      window.removeEventListener("keypress", keyPress)
    }
  }, [])

  useEffect(() => {
    fieldsRef.current = fields;
    emailSentRef.current = emailSent
  }, [fields, emailSent])

  function keyPress(event) {
    if(event.key === "Enter") {
      if(emailSentRef.current) {
        continuePress()
      } else {
        recoverPOST()
      }
    }
  }

  function continuePress() {
    history.push("/")
    setEmailSent(false)
    emailSentRef.current = false
  }

  function handleFieldChange(event, name) {
    setFields({ ...fields, [name]: event.target.value.trim() });
  }

  function handleClickShowPassword() {
    setFields({ ...fields, showPassword: !fields.showPassword });
  }

  async function recoverPOST() {
    setLoading(true);

    if (
      !new RegExp(
        /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/
      ).test(fieldsRef.current.password)
    ) {
      setErrorMessage(props.t("RegisterPage.ValidEmailAddress"));
      setShowErrorMessage(true);
      setLoading(false);
      return;
    }

    const address = props.baseAddressProp + addressExtension + "/RecoverPassword";
    try {
      let result = await Axios.post(address, {
        UserName: fieldsRef.current.userName ?? "",
        Email: fieldsRef.current.password ?? "",
      });
      setEmailSent(true);
      setLoading(false);
      setShowErrorMessage(false);
    } catch (error) {
      setLoading(false);
      if (error.response.data === "One or more fields are empty.") {
        setErrorMessage(props.t("RegisterPage.Error1"));
        setShowErrorMessage(true);
      }
    }
  }

  async function loginPOST(index) {
    setShowErrorMessage(false);
    
    const address = props.baseAddressProp + addressExtension + "/Login";
    try {
      let result = await Axios.post(address, {
        UserName: fields.userName,
        Password: fields.password
      });

      if (result.data.token != null) {
        // props.loggedInCallbackProp(true);
        props.tokenCallbackProp(result.data.token);
        props.userDataCallbackProp(result.data);
        setRedirect(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.data === "Username or password is not valid.") {
        setErrorMessage(props.t("SignInPage.Invalid"));
        setShowErrorMessage(true);
      } else {
        setErrorMessage(props.t("SignInPage.Unable"));
        setShowErrorMessage(true);
      }
    }
  }

  async function testGET() {
    try {
      let result = await Axios.get(props.baseAddressProp + "/Authentication", { headers: { Authorization: "Bearer " + props.tokenProp } });
    } catch (error) {}
  }

  function renderEmailSent()
  {
    return (
      <React.Fragment>
        {inputsm ? (
          // #region MOBILE
          <ThemeProvider theme={theme}>
            <div className={classes.backgroundLandscape} style={{backgroundPosition: "40% 100%"}}>
              <div className={classes.paperPositionMobile}>
                <Paper
                  className={classes.paperPropsMobile}
                  classes={{
                    rounded: classes.paperShape,
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12} />

                    <Grid item xs={12} style={{ display: "flex",
                      border: "1px solid rgb(34, 150, 243)",
                      borderRadius: "4px",
                      backgroundColor: "rgba(34, 150, 243, 0.1)",
                      marginBottom: "8px",
                      paddingTop: "12px",
                      paddingBottom: "12px" }}>
                      <Typography variant="h4" style={{fontSize: minWidth ? "1.75rem" : "6.5vmin", textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>{props.t("PasswordRecovery.PasswordRequest")}</Typography>
                    </Grid>

                    <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px", marginTop: "20px" }}>
                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", display: "inline" }}>
                    {props.t("PasswordRecovery.RecoverySuccess1")}
                    </Typography>
                    </Grid>

                    <Alert severity="info" style={{ paddingTop: "0px", marginTop: "20px" }}>
                      <Typography variant="h6" style={{ fontSize: minWidth ? "0.8rem" : "3vmin", display: "inline" }}>
                      {props.t("PasswordRecovery.RecoverySuccess2")}
                      </Typography>
                    </Alert>

                    <Grid item xs={12}>
                      <div className={classes.buttons}>
                        <Link to={"/"} style={{ textDecoration: "none", marginTop: "20px" }} key={"previous"}>
                          <Button variant="contained" color="primary" className={`${classes.buttonSpacing} no-shadow button-padding`}>
                            {props.t("PasswordRecovery.Continue")}
                          </Button>
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </div>
          </ThemeProvider>
        ) : (
          // #endregion
          // #region DESKTOP
          <ThemeProvider theme={theme}>
            <div className={classes.backgroundLandscape}>
              <div className={classes.paperPosition}>
                <Paper
                  className={classes.paperProps}
                  classes={{
                    rounded: classes.paperShape,
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{ display: "flex",
                      border: "1px solid rgb(34, 150, 243)",
                      borderRadius: "4px",
                      backgroundColor: "rgba(34, 150, 243, 0.1)",
                      marginBottom: "8px",
                      paddingTop: "12px",
                      paddingBottom: "12px" }}>
                      <Typography variant="h4" style={{textAlign: "center", marginLeft: "auto", marginRight: "auto"}} className={"header4"}>{props.t("PasswordRecovery.PasswordRequest")}</Typography>
                    </Grid>

                  <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px", marginTop: "20px" }}>
                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", display: "inline" }}>
                      {props.t("PasswordRecovery.RecoverySuccess1")}
                    </Typography>
                  </Grid>

                  <Alert severity="info" style={{ paddingTop: "0px", marginTop: "20px" }}>
                    <Typography variant="h6" style={{ fontSize: minWidth ? "0.8rem" : "4vmin", display: "inline" }}>
                      {props.t("PasswordRecovery.RecoverySuccess2")}
                    </Typography>
                  </Alert>

                    <Grid item xs={12} style={{marginTop: "20px"}}>
                      <div className={classes.buttons}>
                        <Link to={"/"} style={{ textDecoration: "none" }} key={"previous"}>
                          <Button variant="contained" color="primary" className={`${classes.buttonSpacing} no-shadow button-padding`}>
                            {props.t("PasswordRecovery.Continue")}
                          </Button>
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </div>
          </ThemeProvider>
          // #endregion
        )}
      </React.Fragment>
    );
  }


  if (redirect) {
    return <Redirect to={"/Home"} style={{ textDecoration: "none" }} key={"ToHome"} />;
  } else {
    return (
      <React.Fragment>
      {emailSent ? (renderEmailSent()) : 
        (
      <React.Fragment>
        {inputsm ? (
          // #region MOBILE
          <ThemeProvider theme={theme}>
            <div className={classes.backgroundLandscape} style={{backgroundPosition: "40% 100%"}}>
              <div className={classes.paperPositionMobile}>
                <Paper
                  className={classes.paperPropsMobile}
                  classes={{
                    rounded: classes.paperShape,
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12} />
                    <Grid item xs={12} style={{ display: "flex" }}>
                      <Typography variant="h4" className="header4">
                      {props.t("PasswordRecovery.PasswordRecoveryText")}
                      </Typography>

                    </Grid>

                    <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px", marginTop: "20px" }}>
                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", display: "inline", fontWeight: "400" }}>
                    {props.t("PasswordRecovery.PasswordRecoverySubText1")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", color: "#2296f3", display: "inline" }}>
                    {props.t("PasswordRecovery.PasswordRecoverySubText2")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", display: "inline", fontWeight: "400" }}>
                    {props.t("PasswordRecovery.PasswordRecoverySubText3")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", color: "#2296f3", display: "inline" }}>
                    {props.t("PasswordRecovery.PasswordRecoverySubText4")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", display: "inline", fontWeight: "400" }}>
                    {props.t("PasswordRecovery.PasswordRecoverySubText5")}
                    </Typography>
                  </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="outlined-name"
                        label={props.t("SignInPage.Username")}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={fields.userName}
                        style={{ marginBottom: "8px" }}
                        onChange={event => handleFieldChange(event, "userName")}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: "16px" }}>
                      <TextField
                        id="outlined-adornment-email"
                        variant="outlined"
                        label={props.t("PasswordRecovery.EmailAddress")}
                        fullWidth
                        value={fields.password}
                        margin="dense"
                        onChange={event => handleFieldChange(event, "password")}
                      />
                    </Grid>

                    {showErrorMessage ? (
                      <Grid item xs={12} style={{ marginBottom: "12px" }}>
                        <Alert severity="error">
                            <b>{props.t("SignInPage.Error")} </b>
                            {errorMessage}
                          </Alert>
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <div className={classes.buttons}>
                        <Link to={"/"} style={{ textDecoration: "none" }} key={"previous"}>
                          <Button variant="outlined" color="primary" className={`${classes.buttonSpacing} button-padding`}>
                            {props.t("RegisterPage.Back")}
                          </Button>
                        </Link>

                        <Button variant="contained" color="primary" onClick={recoverPOST} style={{ marginLeft: "8px" }} className={"no-shadow button-padding"} disabled={loading}>
                        {props.t("PasswordRecovery.Send")}
                        {loading && <CircularProgress size={24} style={{top: "17%", left: "31%", position: "absolute"}} />}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </div>
          </ThemeProvider>
        ) : (
          // #endregion
          // #region DESKTOP
          <ThemeProvider theme={theme}>
            <div className={classes.backgroundLandscape}>
              <div className={classes.paperPosition}>
                <Paper
                  className={classes.paperProps}
                  classes={{
                    rounded: classes.paperShape,
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{ display: "flex" }}>
                      <Typography variant="h4" className="header4">{props.t("PasswordRecovery.PasswordRecoveryText")}</Typography>
                    </Grid>

                  <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "0px", marginTop: "20px" }}>
                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", display: "inline", fontWeight: "400" }}>
                      {props.t("PasswordRecovery.PasswordRecoverySubText1")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", color: "#2296f3", display: "inline" }}>
                    {props.t("PasswordRecovery.PasswordRecoverySubText2")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", display: "inline", fontWeight: "400" }}>
                    {props.t("PasswordRecovery.PasswordRecoverySubText3")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", color: "#2296f3", display: "inline" }}>
                    {props.t("PasswordRecovery.PasswordRecoverySubText4")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", display: "inline", fontWeight: "400" }}>
                    {props.t("PasswordRecovery.PasswordRecoverySubText5")}
                    </Typography>
                  </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="outlined-name"
                        label={props.t("SignInPage.Username")}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        value={fields.userName}
                        onChange={event => handleFieldChange(event, "userName")}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: "16px" }}>
                      <TextField
                        id="outlined-adornment-password"
                        variant="outlined"
                        label={props.t("PasswordRecovery.EmailAddress")}
                        fullWidth
                        value={fields.password}
                        margin="normal"
                        onChange={event => handleFieldChange(event, "password")}
                      />
                    </Grid>

                    {showErrorMessage ? (
                      <Grid item xs={12} style={{ marginBottom: "12px" }}>
                        <Alert severity="error">
                            <b>{props.t("SignInPage.Error")}</b>
                            {errorMessage}
                          </Alert>
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <div className={classes.buttons}>
                        <Link to={"/"} style={{ textDecoration: "none" }} key={"previous"}>
                          <Button variant="outlined" color="primary" className={`${classes.buttonSpacing} no-shadow button-padding`}>
                            {props.t("RegisterPage.Back")}
                          </Button>
                        </Link>

                        <Button variant="contained" color="primary" onClick={recoverPOST} style={{ marginLeft: "8px" }} disabled={loading} className={"no-shadow button-padding"}>
                        {props.t("PasswordRecovery.Send")}
                        {loading && <CircularProgress size={24} style={{top: "17%", left: "31%", position: "absolute"}} />}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            </div>
          </ThemeProvider>
          // #endregion
        )}
      </React.Fragment>
        )}
    </React.Fragment>
    );
  }
}

const minToolbarHeight = 64;

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] }
  }
});

const useStyles = makeStyles(theme => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },

  paperPositionMobile: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "16px",
    marginRight: "16px",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
    }
  },

  paperPosition: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "15%",
    marginRight: "15%",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    //paddingTop: `calc(${minToolbarHeight}px + 15%)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      marginRight: "25%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "32%",
      marginRight: "32%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "38%",
      marginRight: "38%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    }
  },
  paperShape: {
    borderRadius: 8
  },
  paperShadow: {
    boxShadow: "0 10px 45px -10px rgba(0, 0, 0, 0.2)"
  },
  paperProps: {
    padding: "32px",
  },
  paperPropsMobile: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },

  // launchPage: {
  //   paddingLeft: "40px",
  //   paddingTop: `calc(${minToolbarHeight}px + 40px)`,

  //   [theme.breakpoints.up("sm")]: {
  //     paddingLeft: `calc(${drawerWidth}px + 100px)`,
  //     paddingTop: `calc(${minToolbarHeight}px + 40px)`
  //   }
  // },

  backgroundLandscape: {
    backgroundImage: `url(${bgLandscape})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    width: "100%",
    height: "100vh",
    overflow: "auto",
    backgroundColor: "#213e54",
  },

  background: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "200%",
    height: "100vh",

    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      width: "195%"
    },

    [theme.breakpoints.up("md")]: {
      width: "130%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%"
    }
  }

  // registerText:{
  //   marginRight: "60%",
  //   fontSize: "3.0rem",

  //   [theme.breakpoints.up("sm")]: {
  //     marginRight: "60%",
  //     fontSize: "3.0rem"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     marginRight: "30%",
  //     fontSize: "3.4rem"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     marginRight: "0%",
  //     fontSize: "3.75rem"
  //   }
  // },

  // timeText:{
  //   fontSize: "1.2rem",

  //   [theme.breakpoints.up("sm")]: {
  //     marginRight: "50%",
  //     fontSize: "1.2rem"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     marginRight: "60%",
  //     fontSize: "1.35rem"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     marginRight: "0%",
  //     fontSize: "1.5rem"
  //   }
  // },

  // borderMargin:{
  //   width: "30%",
  //   [theme.breakpoints.up("sm")]: {
  //     width: "30%"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     width: "40%"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     width: "50%"
  //   }
  // },
}));

export default withRouter(PasswordRecovery);
