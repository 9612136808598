/* eslint-disable */
import DateFnsUtils from "@date-io/date-fns";
import { AppBar, InputBase, IconButton, Tooltip, Zoom, Badge, Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Paper, Tab, Table, TableBody, TableCell, TableRow, Tabs, TextField, Typography, useMediaQuery, CircularProgress, TablePagination } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import HowToReg from "@material-ui/icons/HowToReg";
import LastPage from "@material-ui/icons/LastPage";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import Remove from "@material-ui/icons/Remove";
import DenseIcon from "@material-ui/icons/Reorder";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SettingsIcon from "@material-ui/icons/Settings";
import StorageIcon from "@material-ui/icons/Storage";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FileUploadIcon from "@material-ui/icons/InsertDriveFile";
import RefreshIcon from "@material-ui/icons/Refresh";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import Axios from "axios";
import { addMinutes } from "date-fns/esm";
import MaterialTable, { MTableAction, MTableActions, MTableCell, MTableHeader, MTableToolbar} from "material-table";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import bgImage from "./../bgImagev5.svg";
import tablesInfoImage from "./../TablesInfo.png";
import "./Database.css";
import * as cloneDeep from "lodash/cloneDeep";
import CustomSnackbar from "./CustomSnackbar";
import CustomSnackbarError from "./CustomSnackbarError";
import AlertIcon from "@material-ui/icons/ErrorOutline";
import Alert from '@material-ui/lab/Alert';

function Database(props) {
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteForever {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{color: "rgb(117, 117, 117)", paddingBottom: "0px", paddingTop: "0px"}}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{margin: denseMode ? "9px" : "0px"}} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const refTest = React.createRef();
  const editRef = forwardRef((props, ref) => <Edit {...props} ref={refTest} style={{color: editMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)"}}/>);

  const forwardRefTest = function forwardRef(props, ref){
    return (<Edit {...props} ref={refTest} style={{color: editMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)"}}/>)
  }

  const editIconVar = function test()
  {
    return(<Edit style={{color: editMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)"}}/>)
  }

  props.headerNameProp(props.t("Database.DatabaseText"));
  props.currentPathCallbackProp(props.location.pathname);
  const emptyDataMessage = props.t("Database.NoData");
  const classes = useStyles(props);
  const pageOptions = [5, 10, 25, 50, 100];
  var [defaultPageNumbers, setDefaultPageNumbers] = useState(parseInt(window.localStorage.getItem("DefaultPageNumbers")) || 10);
  const addressExtension = "/Database";
  const [loading, setLoading] = useState(false);
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [loadingDivisions, setLoadingDivisions] = useState(false);
  const [loadingTimeRegistration, setLoadingTimeRegistration] = useState(false);
  const [loadingPendingRegistrations, setLoadingPendingRegistrations] = useState(false);
  const [uploadingData, setUploadingData] = useState(false);
  const [tab, setTab] = useState(0);
  const tabLabels = [<StorageIcon />, props.t("Database.Employees"), props.t("Database.Customers"), props.t("Database.Divisions"), props.t("Database.Activities"), props.t("Database.TimeRegistration"), props.t("Database.PendingRegistrations")];
  const [dbSettings, setDbSettings] = useState(false);
  const timesheetTableStateRef = useRef(false);
  const customerTableStateRef = useRef(false);
  const divisionTableStateRef = useRef(false);
  const activityTableStateRef = useRef(false);
  const bgColorTimesheetLabels = "rgba(250, 250, 250, 1)";
  const fontSize = "14px";
  const inputsm = useMediaQuery(theme.breakpoints.down("sm"));
  const minWidth600 = useMediaQuery(theme.breakpoints.up("sm"));
  const minWidth = useMediaQuery("(min-width:400px)");
  const maxWidth = useMediaQuery("(max-width:400px)");
  const [editMode, setEditMode] = useState(false);
  const [denseMode, setDenseMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const snackbarMessage = props.t("Database.UploadSuccessful");
  const snackbarErrorMessage = props.t("Database.UploadFailed");
  const autoHideDuration = 2500;
  const [state, setState] = useState({
    columns: [{ title: "Factory ID", field: "factoryId", type: "numeric" }, { title: "Factory", field: "name" }],
    data: []
  });
  const fileRef = useRef(null)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageNumbers);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRows, setTotalRows] = useState(10);
  const [activitySearchString, setActivitySearchString] = useState("");


  const [pageTimeRegistration, setPageTimeRegistration] = useState(0);
  const [rowsPerPageTimeRegistration, setRowsPerPageTimeRegistration] = useState(defaultPageNumbers);
  const [totalRowsTimeRegistration, setTotalRowsTimeRegistration] = useState(10);
  const [timeRegistrationSearchString, setTimeRegistrationSearchString] = useState("");

  useEffect(()=>{
    activitiesGET();
  }, [page, rowsPerPage]);

  useEffect(()=>{
    timesheetGET();
  }, [pageTimeRegistration, rowsPerPageTimeRegistration]);

  const [fileInfo, setFileInfo] = useState({
    files: "",
    fileNames: [],
    fileCount: 0,
  })

  const [info, setInfo] = useState({
    customerOptions: [],
    divisionOptions: [],
    activityOptions: [],
    employeeOptions: []
  });

  if(!defaultPageNumbers)
    window.localStorage.setItem("DefaultPageNumbers", 10)


  useEffect(()=>{
    let container = document.getElementById("container");
    let importFile = document.getElementById("importFile");
    container.addEventListener("dragenter", dragenter, false);
    container.addEventListener("dragleave", dragleave, false);
    container.addEventListener("dragover", dragover, false);
    container.addEventListener("drop", drop, false);

    // if(fileRef && fileRef.current) {
    //   fileRef.current.addEventListener("change", handleFiles, false);
    // }
  }, []);

  function RedirectToDoc(event){
    window.location.href = "https://docs.google.com/document/d/1WLRAyuOO1ePbKFuqZqznqfKsASF9qLpnksKjrNT6g9M/edit?usp=sharing";
  }

  function closeSnackbarCallback() {
    setShowSnackbar(false);
    setShowErrorSnackbar(false);
  }

  function dragenter(event){
    event.preventDefault();
    event.stopPropagation();
    let container = document.getElementById("container");
    container.classList.add("highlight");
  }

  function dragleave(event){
    event.preventDefault();
    event.stopPropagation();
    let container = document.getElementById("container");
    container.classList.remove("highlight");
  }

  function dragover(event){
    event.preventDefault();
    event.stopPropagation();
    let container = document.getElementById("container");
    container.classList.add("highlight");
  }

  function drop(event){
    event.preventDefault();
    event.stopPropagation();
    let container = document.getElementById("container");
    container.classList.remove("highlight");
    let files = event.dataTransfer.files;
    let names = [...fileInfo.fileNames];
    ([...files]).forEach((element, index, array)=>{
      names.push(element.name);
    });

    setFileInfo(prevState => {
      let updatedState = {...prevState};
      updatedState.files = files;
      updatedState.fileCount = files.length;
      updatedState.fileNames = names;
      return {...updatedState}
    });
  }

  function handleFiles(event) {
    let files = event.target.files;
    let names = [...fileInfo.fileNames];
    ([...files]).forEach((element, index, array)=>{
      names.push(element.name);
    });

    setFileInfo(prevState => {
      let updatedState = {...prevState};
      updatedState.files = files;
      updatedState.fileCount = files.length;
      updatedState.fileNames = names;
      return {...updatedState}
    });
  }

  function showFileUploadIcons(){
    let buffer = [];
    for(let i = 0; i < fileInfo.fileCount; i++){
      buffer.push(<FileUploadIcon style={{ position: "relative", fontSize: "3rem"}} key={i}/>);
    }
    return(<ul style={{padding: "12px", margin: "0px 3px", color: "rgb(29, 111, 66)" }}>{buffer}</ul>)
  }

  function showFileNames(){
    let buffer = [];
    fileInfo.fileNames.forEach((element)=>{
      buffer.push(<Typography variant="h6" style={{ fontSize: minWidth600 ? "0.85rem" : "2.3vmin" }} key={element}>{element}</Typography>);
    });

    return(<ul style={{padding: "0px", margin: "3px 0px", color :"rgba(33, 150, 243, 1)"}}>{buffer}</ul>)
  }

  async function startUploading(event){
    setUploadingData(true);
    let fileArray = [...fileInfo.files];
    let boolArr = [];
    for(let i = 0; i < fileArray.length; i++){
      boolArr.push(await uploadFileToServer(fileArray[i]));
    }

    if(boolArr.includes(true)){
      setShowSnackbar(true)
    }

    setUploadingData(false);
    setFileInfo(prevState => {
      let updatedState = {...prevState};
      updatedState.files = "";
      updatedState.fileCount = 0;
      updatedState.fileNames = [];
      return {...updatedState}
    });
  }

  async function uploadFileToServer(file){
    let url = props.baseAddressProp + addressExtension + "/PostExcel";
    let formData = new FormData();
    formData.append("file", file);
    let request = new Request(`${process.env.REACT_APP_BACKEND_BASE_URL}${url}`, {
      method: "POST",
      // mode: "no-cors",
      body: formData,
      credentials: "include",
    });

    try{
      const response = await fetch(request);
      const result = await response.text();
      if(response.status === 500){
        setShowErrorSnackbar(true);
        return false;
      }
    }catch(error){
      setShowErrorSnackbar(true);
      return false
    }

    if(fileInfo.fileCount > 1){
      setFileInfo(prevState => {
        let updatedState = {...prevState};
        updatedState.fileCount = updatedState.fileCount - 1;
        return {...updatedState}
      });
    }
    return true;
  }
  
  useEffect(()=>
  {
    setDivisionAdd(prevState => {return {...prevState, ["customerData"]: divisionAdd.customerData}});
    setLoading(false);
  }, [info]);

  const tableTheme = createMuiTheme({
    palette: {
      primary: { main: blue[500] },
      secondary: { main: blue[500] }
    },
    overrides: {
      // MuiTableCell: {
      //   root: {
      //     "&:hover": {
      //       backgroundColor: "rgba(33, 150, 243, 0.25)"
      //     }
      //   }
      // },
      MuiIconButton: {
        root: {
          padding: denseMode ? "0x" : "9px"
        }
      },
      PrivateSwitchBase:{
        root:{
          padding: denseMode ? "0px" : "9px"
        }
      }
    }
  });

  const [employeesTableState, setEmployeesTableState] = useState({
    columns: [
      { title: props.t("Database.EmployeeID"), field: "employeeId", type: "numeric", editable:"never"  },
      { title: props.t("Database.Username"), field: "userName", editable:"never" },
      { title: props.t("Database.FirstName"), field: "firstName", editable:"never" },
      { title: props.t("Database.LastName"), field: "lastName", editable:"never" },
      { title: props.t("Database.EmailAddress"), field: "email", editable:"never" },
      { title: props.t("Database.Dob"), field: "dateOfBirth", editable:"never" },
      { title: props.t("Database.LeaveDaysLeft"), field: "paidLeaveDays", type:"numeric", editable:"always" },
      { title: props.t("Database.Role"), field: "role", editable:"never"}
    ],
    data: []
  });
  const [customerTableState, setCustomerTableState] = useState({
    columns: [
      { title: props.t("Database.CustomerID"), field: "customerId", type: "numeric", editable:"never" }, 
      { title: props.t("Database.CustomerName"), field: "customerName" }],
    data: []
  });
  const [divisionTableState, setDivisionTableState] = useState({
    columns: [
      { title: props.t("Database.DivisionID"), field: "divisionId", type: "numeric", editable:"never" },
      { title: props.t("Database.DivisionName"), field: "divisionName" },
      { title: props.t("Database.CustomerID"), field: "customerCustomer.customerName", editable:"never" }
      // { title: props.t("Database.CustomerID"), field: "customerCustomerId", type: "numeric", editable:"never" }
    ],
    data: []
  });
  const [activitiesTableState, setActivitiesTableState] = useState({
    columns: [
      { title: props.t("Database.ActivityID"), field: "activityId", type: "numeric", editable:"never" },
      { title: props.t("Database.ActivityName"), field: "activityName" },
      { title: props.t("Database.Target"), field: "target", type: "numeric" },
      { title: props.t("Database.DivisionName"), field: "division.divisionName", editable:"never" },
      { title: props.t("Database.DivisionName"), field: "division.customerCustomer.customerName", editable:"never" },
      // { title: props.t("Database.DivisionID"), field: "divisionDivisionId", type: "numeric", editable:"never" },
      // { title: props.t("Database.CustomerID"), field: "divisionCustomerCustomerId", type: "numeric", editable:"never" }
    ],
    data: []
  });

  const [dateStartValue, setDateStartValue] = useState(new Date());

  const [customerAdd, setCustomerAdd] = useState({
    customerID: "",
    customerName: ""
  });

  const [divisionAdd, setDivisionAdd] = useState({
    divisionID: "",
    divisionName: "",
    customerID: "",
    customerName: "",
    customerData: "",
  });

  const [activityAdd, setActivityAdd] = useState({
    activityID: "",
    activityName: "",
    target: "",
    divisionID: "",
    customerID: "",
    currentDivisionOptions: [],
    divisionData: "",
    customerData: "",
    customerName: ""
  });

  const [timesheetAdd, setTimesheetAdd] = useState({
    timesheetID: "",
    dateStart: new Date(),
    dateEnd: new Date(),
    quantity: "",
    employeeID: "",
    activityID: "",
    divisionID: "",
    customerID: "",
    currentDivisionOptions: [],
    currentActivityOptions: [],
    customerData: "",
    divisionData: "",
    activityData: "",
    employeeData: ""

  });

  useEffect(()=>{
  }, [divisionAdd.customerData]);

  const [timesheetTableState, setTimesheetTableState] = useState({
    columns: [
      { title: "ID", field: "dailyTimeSheetId" },
      { title: props.t("Database.DateStart"), field: "dateStart" },
      { title: props.t("Database.DateEnd"), field: "dateEnd" },
      { title: props.t("Database.Quantity"), field: "amount" },
      { title: props.t("Database.Username"), field: "employeeEmployee.userName"},
      { title: props.t("Database.ActivityName"), field: "activities.activityName", editable:"never" },
      { title: props.t("Database.DivisionName"), field: "activities.division.divisionName", editable:"never" },
      { title: props.t("Database.CustomerName"), field: "activities.division.customerCustomer.customerName", editable:"never" },
      // { title: props.t("Database.ActivityID"), field: "activitiesActivityId", type: "numeric" },
      // { title: props.t("Database.DivisionID"), field: "activitiesDivisionDivisionId", type: "numeric" },
      // { title: props.t("Database.CustomerID"), field: "activitiesDivisionCustomerCustomerId", type: "numeric" }
    ],
    data: []
  });

  useEffect(() => {
    if (customerTableStateRef.current === false) {
      customerTableStateRef.current = true;
      return;
    }
    let c = { ...customerAdd };
  
    if(customerTableState.data.length <= 0){
      return;
    }

    let maxValue = 0;
    for(let i = 0; i < customerTableState.data.length; i++){
      if(customerTableState.data[i]["customerId"] > maxValue)
        maxValue = customerTableState.data[i]["customerId"];
    }
    ++maxValue;

    setCustomerAdd(prevState => {return {...prevState, ["customerID"]: maxValue, ["customerName"]: customerTableState.data[customerTableState.data.length - 1]["customerName"]}});
    setLoading(false);
  }, [customerTableState]);

  const [pendingRegistrationsTableState, setPendingRegistrationsTableState] = useState({
    columns: [
      { title: props.t("Database.ID"), field: "pendingRegistrationsId", type: "numeric", editable: "never" },
      { title: props.t("Database.Username"), field: "userName", editable: "never" },
      { title: props.t("Database.FirstName"), field: "firstName", editable: "never" },
      { title: props.t("Database.LastName"), field: "lastName", editable: "never" },
      { title: props.t("Database.EmailAddress"), field: "email", editable: "never" },
      { title: props.t("Database.Dob"), field: "dateOfBirth", editable: "never" },
      { title: props.t("Database.Role"), field: "role", lookup: { u: "User", a: "Admin", dba: "Database admin" } }
      // { title: "Password Hash", field: "passwordHash" },
      // { title: "Password Salt", field: "passwordSalt" }
    ],
    data: []
  });
  const [removeErrorMessage, setRemoveErrorMessage] = useState(false);
  const [duplicateIDErrorMessage, setDuplicateIDErrorMessage] = useState(false);
  const [IDGreaterThan0, setIDGreaterThan0] = useState(false);
  const [EndTimeGreater, setEndTimeGreater] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [showTimesheetAdd, setShowTimesheetAdd] = useState(false);

  const defaultMaterialTheme = createMuiTheme({});

  useEffect(() => {
    employeesGET();
    customersGET();
    divisionsGET();
    activitiesGET();
    //timesheetGET();
    pendingRegistrationsGET();
  }, []);

  useEffect(() => {
    setDuplicateIDErrorMessage(false);
    setRemoveErrorMessage(false);
  }, [state]);

  function handleClickOpen() {
    setOpenDialog(true);
  }

  function handleClose() {
    setOpenDialog(false);
  }

  function insertTabs() {
    let items = [];
    for (let i = 0; i < tabLabels.length; i++) {
      if (i === 0) {
        items.push(<Tab label={tabLabels[i]} className={classes.tab} key={i} classes={{ root: classes.tab1 }} />);
      } else if (tabLabels[i] === props.t("Database.PendingRegistrations")) {
        items.push(
          <Tab
            label={
              <Badge badgeContent={pendingRegistrationsTableState.data.length} classes={{ badge: classes.badge }}>
                {tabLabels[i]}
              </Badge>
            }
            className={classes.tab}
            key={i}
          />
        );
      } else {
        items.push(<Tab label={tabLabels[i]} className={classes.tab} key={i} />);
      }
    }
    return items;
  }

  function handleChange(event, newValue) {
    switch (newValue) {
      case 1: {
        employeesGET();
        break;
      }
      case 2: {
        customersGET();
        break;
      }

      case 3: {
        divisionsGET();
        break;
      }

      case 4: {
        setRowsPerPage(parseInt(window.localStorage.getItem("DefaultPageNumbers")) || 10);
        activitiesGET();
        break;
      }

      case 5: {
        setRowsPerPageTimeRegistration(parseInt(window.localStorage.getItem("DefaultPageNumbers")) || 10);
        timesheetGET();
        break;
      }

      case 6: {
        pendingRegistrationsGET();
        break;
      }
      
      default:
        break;
    }

    setDbSettings(false);
    setDuplicateIDErrorMessage(false);
    setIDGreaterThan0(false);
    setRemoveErrorMessage(false);
    setShowErrorSnackbar(false);
    setTab(newValue);
  }

  function cannotRemoveItemMessage() {
    return (
      <Alert severity="error">
        <b>
          <em>{props.t("Database.Error10")}</em>
        </b>
        . {props.t("Database.Error11")}
      </Alert>
    );
  }

  function cannotAddDuplicateIDMessage() {
    return (
      <Alert severity="error">
        <b>
          <em>{props.t("Database.Error10")}</em>
        </b>
        . {props.t("Database.Error20")}
      </Alert>
    );
  }

  function IDGreaterThan0Message() {
    return (
      <Alert severity="error">
        <b>
          <em>{props.t("Database.Error10")}</em>
        </b>
        . {props.t("Database.Error30")}
      </Alert>
    );
  }

  function EndTimeGreaterMessage() {
    return (
      <Alert severity="error">
        <b>
          <em>{props.t("Database.Error10")}</em>
        </b>
        . {props.t("TimeRegistration.EndTimeError")}
      </Alert>
    );
  }

  //----------------------------------------------//

  async function resetAnnualLeavePOST() {
    setLoadingEmployees(true);
    const address = props.baseAddressProp + addressExtension + "/PostResetAnnualLeaveEmployees";
    try {
      await Axios.post(address);
      employeesGET();
    } catch (error) {
      if(error.response.status === 404) {
        setShowErrorSnackbar(true);
      }
      setLoadingEmployees(false);
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
    }
  }

  //----------------------------------------------//

  async function employeesGET() {
    setLoadingEmployees(true);
    const address = props.baseAddressProp + addressExtension + "/GetEmployeesInfo";
    try {
      let result = await Axios.get(address);
      let c = employeesTableState;
      c.data = result.data;

      for (let i = 0; i < c.data.length; i++) {
        //c.data[i].dateOfBirth = c.data[i].dateOfBirth.match(new RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2}/));
        c.data[i].dateOfBirth = new Date(c.data[i].dateOfBirth).toLocaleString("en-GB", { year: "numeric", month: "numeric", day: "numeric" });
      }

      setEmployeesTableState(c);

      let employeeOptions = [];
      for(let i = 0; i < result.data.length; i++)
      {
        employeeOptions.push({employeeId: result.data[i].employeeId, employeeUsername: result.data[i].userName});
      }

      setInfo(prevState => {return {...prevState, ["employeeOptions"]: employeeOptions}});
      setTimesheetAdd(prevState => {return {...prevState, ["divisionData"]: "", ["customerData"]: "", ["activityData"]: ""}});
      setActivityAdd(prevState => {return {...prevState, ["divisionData"]: "", ["customerData"]: ""}});
      setDivisionAdd(prevState => {return {...prevState, ["customerData"]: ""}});

      setLoadingEmployees(false);
    } catch (error) {
      setLoadingEmployees(false);
    }
  }


  async function employeesDELETE(employeeId, data) {
    setLoadingEmployees(true);
    const address = props.baseAddressProp + addressExtension + "/DeleteEmployeesInfo" + "?employeeId=" + employeeId;
    try {
      await Axios.delete(address);
      setEmployeesTableState({ ...employeesTableState, data });
      setLoadingEmployees(false);
    } catch (error) {
      setDuplicateIDErrorMessage(false);
      setIDGreaterThan0(false);
      setRemoveErrorMessage(true);
      setLoadingEmployees(false);
    }
  }

  async function employeesMultipleDELETE(ids, data) {
    if(ids.length === 0)
      return null;

      setLoadingEmployees(true);
    const address = props.baseAddressProp + addressExtension + "/DeleteMultipleEmployeesInfo" + "?data=" + JSON.stringify(ids);
    try {
      await Axios.delete(address);
      setEmployeesTableState({ ...employeesTableState, data });
      setLoadingEmployees(false);
    } catch (error) {
      setDuplicateIDErrorMessage(false);
      setIDGreaterThan0(false);
      setRemoveErrorMessage(true);
      setLoadingEmployees(false);
    }
  }

  //----------------------------------------------//

  async function customersGET() {
    
    setLoadingCustomers(true);
    const address = props.baseAddressProp + addressExtension + "/GetCustomersInfo";
    try {
      let result = await Axios.get(address);
      let c = { ...customerTableState };
      c.data = result.data;
      setCustomerTableState(c);

      let customerOptions = [];
      for(let i = 0; i < result.data.length; i++)
      {
        customerOptions.push({customerId: result.data[i].customerId, customerName: result.data[i].customerName});
      }

      setInfo(prevState => {return {...prevState, ["customerOptions"]: customerOptions}});
      // setTimesheetAdd(prevState => {return {...prevState, ["divisionData"]: activityAdd.currentDivisionOptions[activityAdd.currentDivisionOptions.length - 1], ["customerData"]: info.customerOptions[info.customerOptions.length - 1], ["activityData"]: ""}});
      // setActivityAdd(prevState => {return {...prevState, ["divisionData"]: activityAdd.currentDivisionOptions[activityAdd.currentDivisionOptions.length - 1], ["customerData"]: info.customerOptions[info.customerOptions.length - 1]}});
      //setDivisionAdd(prevState => {return {...prevState, ["customerData"]: ""}});

      //let test = divisionAdd.customerData;
      setDivisionAdd(prevState => {return {...prevState, ["customerData"]: ""}});
      setActivityAdd(prevState => {return {...prevState, ["customerData"]: "", ["divisionData"]: "", ["currentDivisionOptions"]: []}});
      setTimesheetAdd(prevState => {return {...prevState, ["customerData"]: "", ["divisionData"]: "", ["activityData"]: "", ["currentDivisionOptions"]: [], ["currentActivityOptions"]: []}});
      setLoadingCustomers(false);
    } catch (error) {
      let c = { ...customerTableState };
      c.data = [];
      setCustomerTableState(c);
      setLoadingCustomers(false);
    }
  }

  async function customersPOST(customerId, customerName) {
    if (customerId <= 0) {
      setIDGreaterThan0(true);
      return;
    }

    setLoadingCustomers(true);
    const address = props.baseAddressProp + addressExtension + "/PostCustomersInfo";
    try {
      await Axios.post(address, {
        CustomerId: customerId,
        CustomerName: customerName,
      });
      customersGET();
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(false);
    } catch (error) {
      setLoadingCustomers(false);
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(true);
    }
  }

  async function customerNameChangePUT(customerId, customerName, newData, oldData) {

    setLoadingCustomers(true);
    const address = props.baseAddressProp + addressExtension + "/PutChangeCustomerName" + "?customerId=" + customerId + "&customerName=" + customerName;
    try {
      await Axios.put(address);
      customersGET();
      // setCustomerTableState(prevState=>{
      //   let updatedState = {...prevState};
      //   let index = updatedState.data.indexOf(oldData);
      //   updatedState.data[index] = newData;
      //   return{...updatedState};
      // });
      // setLoadingCustomers(false);
    } catch (error) {
      setLoadingCustomers(false);
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(true);
    }
  }

  async function divisionNameChangePUT(divisionId, divisionName, newData, oldData) {

    setLoadingDivisions(true);
    const address = props.baseAddressProp + addressExtension + "/PutChangeDivisionName" + "?divisionId=" + divisionId + "&divisionName=" + divisionName;
    try {
      await Axios.put(address);
      divisionsGET();
      // setDivisionTableState(prevState=>{
      //   let updatedState = {...prevState};
      //   let index = updatedState.data.indexOf(oldData);
      //   updatedState.data[index] = newData;
      //   return{...updatedState};
      // });
      // setLoadingDivisions(false);
    } catch (error) {
      setLoadingDivisions(false);
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(true);
    }
  }

  async function activityNameChangePUT(activityId, activityName, newData, oldData) {
    setLoadingActivities(true);
    const address = props.baseAddressProp + addressExtension + "/PutChangeActivityName" + "?activityId=" + activityId + "&activityName=" + activityName + "&target=" + newData.target;
    try {
      await Axios.put(address);
      activitiesGET();
      // setActivitiesTableState(prevState=>{
      //   let updatedState = {...prevState};
      //   let index = updatedState.data.indexOf(oldData);
      //   updatedState.data[index] = newData;
      //   return{...updatedState};
      // });
      // setLoadingActivities(false);
    } catch (error) {
      setLoadingActivities(false);
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(true);
    }
  }


  async function employeeLeaveDaysLeftPUT(employeeId, paidLeaveDays) {
    if (paidLeaveDays < 0) {
      //setIDGreaterThan0(true);
      return;
    }

    setLoadingCustomers(true);
    const address = props.baseAddressProp + addressExtension + "/PutLeaveDaysLeft" + "?employeeId=" + employeeId + "&leaveDaysLeft=" + paidLeaveDays;
    try {
      await Axios.put(address);
      employeesGET();
    } catch (error) {
      setLoadingCustomers(false);
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(true);
    }
  }

  async function customersDELETE(customerId, data) {
    setLoadingCustomers(true);
    const address = props.baseAddressProp + addressExtension + "/DeleteCustomersInfo" + "?customerId=" + customerId;
    try {
      await Axios.delete(address);
      setCustomerTableState({ ...customerTableState, data });
      setLoadingCustomers(false);
      customersGET();
      divisionsGET();
      // activitiesGET();
    } catch (error) {
      setLoadingCustomers(false);
      setDuplicateIDErrorMessage(false);
      setIDGreaterThan0(false);
      setRemoveErrorMessage(true);
    }
  }

  async function customersMultipleDELETE(ids, data) {
    setLoadingCustomers(true);
    const address = props.baseAddressProp + addressExtension + "/DeleteMultipleCustomersInfo" + "?data=" + JSON.stringify(ids);
    try {
      await Axios.delete(address);
      setCustomerTableState({ ...customerTableState, data });
      setLoadingCustomers(false);
      customersGET();
      divisionsGET();
      // activitiesGET();
    } catch (error) {
      setLoadingCustomers(false);
      setDuplicateIDErrorMessage(false);
      setIDGreaterThan0(false);
      setRemoveErrorMessage(true);
    }
  }

  //----------------------------------------------//

  async function divisionsGET() {
    
    setLoadingDivisions(true);
    const address = props.baseAddressProp + addressExtension + "/GetDivisionsInfo";
    try {
      let result = await Axios.get(address);
      let c = { ...divisionTableState };
      c.data = result.data;
      setDivisionTableState(c);

      let divisionOptions = [];
      //let maxValue = 0;

      // let maxValue = 0;
      //                         for(let i = 0; i < customerTableState.data.length; i++){
      //                           if(customerTableState.data[i]["customerId"] > maxValue)
      //                             maxValue = customerTableState.data[i]["customerId"];
      //                         }
      //                         ++maxValue;

      let maxValue = 1;
      let index = 0;

      for(let i = 0; i < result.data.length; i++)
      {
        divisionOptions.push({customerId: result.data[i].customerCustomerId, divisionId: result.data[i].divisionId, divisionName: result.data[i].divisionName});
        if(result.data[i].divisionId > maxValue)
        {
          maxValue = result.data[i].divisionId;
          index = i;
        }
      }


  
      setInfo(prevState => {return {...prevState, ["divisionOptions"]: divisionOptions}});

      
      //setDivisionAdd(prevState => {return {...prevState, ["customerData"]: ""}});
      setActivityAdd(prevState => {return {...prevState, ["customerData"]: "", ["divisionData"]: "", ["currentDivisionOptions"]: []}});
      setTimesheetAdd(prevState => {return {...prevState, ["customerData"]: "", ["divisionData"]: "", ["activityData"]: "", ["currentDivisionOptions"]: [], ["currentActivityOptions"]: []}});
      //setDivisionAdd(prevState => {return {...prevState, ["customerData"]: ""}});
      // setActivityAdd(prevState => {return {...prevState, ["divisionData"]: ""}});
      // setTimesheetAdd(prevState => {return {...prevState, ["divisionData"]: "", ["activityData"]: ""}});


      // setTimesheetAdd(prevState => {return {...prevState, ["divisionData"]: activityAdd.currentDivisionOptions[activityAdd.currentDivisionOptions.length - 1], ["customerData"]: info.customerOptions[info.customerOptions.length - 1], ["activityData"]: ""}});
      // setActivityAdd(prevState => {return {...prevState, ["divisionData"]: activityAdd.currentDivisionOptions[activityAdd.currentDivisionOptions.length - 1], ["customerData"]: info.customerOptions[info.customerOptions.length - 1]}});

      // if(divisionAdd.customerData === "" || divisionAdd.customerData.length <= 0)
      //   setDivisionAdd(prevState => {return {...prevState, ["customerData"]: info.customerOptions.length <= 0 ? "" : result.data[index].customerCustomer,
      //   ["divisionName"]: result.data[index].divisionName}});

      // if(divisionAdd.customerData === "" || divisionAdd.customerData.length <= 0)
      //   setDivisionAdd(prevState => {return {...prevState, ["customerData"]: info.customerOptions.length <= 0 ? "" : result.data[index].customerCustomer,
      //   ["divisionName"]: result.data[index].divisionName}});
      
      setLoadingDivisions(false);
    } catch (error) {
      let c = { ...divisionTableState };
      c.data = [];
      setDivisionTableState(c);
      setLoadingDivisions(false);
    }
  }

  async function divisionsPOST(divisionId, divisionName, customerData) {
    if (divisionId <= 0 || customerData.customerId <= 0) {
      setIDGreaterThan0(true);
      return;
    }

    setLoadingDivisions(true);
    const address = props.baseAddressProp + addressExtension + "/PostDivisionsInfo";
    try {
      await Axios.post(address, {
        DivisionId: divisionId,
        DivisionName: divisionName,
        CustomerCustomerId: customerData.customerId,
      });
      divisionsGET();
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(false);
    } catch (error) {
      setLoadingDivisions(false);
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(true);
    }
  }

  async function divisionsDELETE(divisionId, data) {
    setLoadingDivisions(true);
    const address = props.baseAddressProp + addressExtension + "/DeleteDivisionsInfo" + "?divisionId=" + divisionId;
    try {
      await Axios.delete(address);
      setDivisionTableState({ ...divisionTableState, data });
      divisionsGET();
      setLoadingDivisions(false);
    } catch (error) {
      setLoadingDivisions(false);
      setDuplicateIDErrorMessage(false);
      setIDGreaterThan0(false);
      setRemoveErrorMessage(true);
    }
  }

  async function divisionsMultipleDELETE(ids, data) {
    setLoadingDivisions(true);
    const address = props.baseAddressProp + addressExtension + "/DeleteMultipleDivisionsInfo" + "?data=" + JSON.stringify(ids);
    try {
      await Axios.delete(address);
      setDivisionTableState({ ...divisionTableState, data });
      divisionsGET();
      setLoadingDivisions(false);
    } catch (error) {
      setLoadingDivisions(false);
      setDuplicateIDErrorMessage(false);
      setIDGreaterThan0(false);
      setRemoveErrorMessage(true);
    }
  }

  //----------------------------------------------//

  async function activitiesGET() {
    setLoadingActivities(true);
    let p = page + 1;
    const address = props.baseAddressProp + addressExtension + "/GetActivitiesInfo" + "?pageIndex=" + p + "&pageSize=" + rowsPerPage + "&searchString=" + activitySearchString;
   
    try {
      let result = await Axios.get(address);
      let resultData =  result.data.activityItems;
      let c = { ...activitiesTableState };
      let activityOptions = [];
      c.data = resultData;
      setActivitiesTableState(c);
      setTotalPages(result.data.totalPages);
      setTotalRows(result.data.count);

      for(let i = 0; i < resultData.length; i++) {
        activityOptions.push({customerId: resultData[i].divisionCustomerCustomerId, divisionId: resultData[i].divisionDivisionId, activityId: resultData[i].activityId,  activityName: resultData[i].activityName});
      }
  
      setInfo(prevState => {return {...prevState, ["activityOptions"]: activityOptions}});
      setTimesheetAdd(prevState => {return {...prevState, ["customerData"]: "", ["divisionData"]: "", ["activityData"]: "", ["currentDivisionOptions"]: [], ["currentActivityOptions"]: []}});
      setLoadingActivities(false);
    } catch (error) {
      let c = { ...activitiesTableState };
      c.data = [];
      setActivitiesTableState(c);
      setLoadingActivities(false);
    }
  }

  async function activitiesPOST(activityName, target, divisionData, customerName) {
    if (divisionData.divisionId <= 0 || divisionData.customerId <= 0) {
      setIDGreaterThan0(true);
      return;
    }

    setLoadingActivities(true);
    const address = props.baseAddressProp + addressExtension + "/PostActivitiesInfo";
    try {
      await Axios.post(address, {
        ActivityId: 0,
        ActivityName: activityName,
        Target: target,
        DivisionDivisionId: divisionData.divisionId,
        DivisionCustomerCustomerId: divisionData.customerId,
      });

      activitiesGET();
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(false);
    } catch (error) {
      setLoadingActivities(false);
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(true);
    }
  }

  async function activitiesDELETE(activityId, data) {
    setLoadingActivities(true);
    const address = props.baseAddressProp + addressExtension + "/DeleteActivitiesInfo" + "?activityId=" + activityId;
    try {
      await Axios.delete(address);
      setActivitiesTableState({ ...activitiesTableState, data });
      activitiesGET();
      //setLoadingActivities(false);
    } catch (error) {
      setLoadingActivities(false);
      setDuplicateIDErrorMessage(false);
      setIDGreaterThan0(false);
      setRemoveErrorMessage(true);
    }
  }

  async function activitiesMultipleDELETE(ids, data) {
    setLoadingActivities(true);
    const address = props.baseAddressProp + addressExtension + "/DeleteMultipleActivitiesInfo" + "?data=" + JSON.stringify(ids);
    try {
      await Axios.delete(address);
      setActivitiesTableState({ ...activitiesTableState, data });
      activitiesGET();
      //setLoadingActivities(false);
    } catch (error) {
      setLoadingActivities(false);
      setDuplicateIDErrorMessage(false);
      setIDGreaterThan0(false);
      setRemoveErrorMessage(true);
    }
  }

  //----------------------------------------------//

  async function timesheetGET() {
    setLoadingTimeRegistration(true);
    let p = pageTimeRegistration + 1;
    const address = props.baseAddressProp + addressExtension + "/GetTimesheetInfo" + "?pageIndex=" + p + "&pageSize=" + rowsPerPageTimeRegistration + "&searchString=" + timeRegistrationSearchString;;
    try {
      let result = await Axios.get(address);
      let c = { ...timesheetTableState };
      c.data = result.data.timesheetItems;
      for (let i = 0; i < c.data.length; i++) {
        c.data[i].dateStart = new Date(c.data[i].dateStart).toLocaleString("en-GB");
        c.data[i].dateEnd = new Date(c.data[i].dateEnd).toLocaleString("en-GB");
      }
      setTimesheetTableState(c);
      setTotalPages(result.data.totalPages);
      setTotalRowsTimeRegistration(result.data.count);
      setLoadingTimeRegistration(false);
    } catch (error) {
      let c = { ...timesheetTableState };
      c.data = [];
      setTimesheetTableState(c);
      setLoadingTimeRegistration(false);
    }
  }

  async function timesheetPOST(dateStart, dateEnd, amount, employeeId, activityId, divisionId, customerId, activityName, divisionName, customerName) {
    if (activityId <= 0 || divisionId <= 0 || customerId <= 0) {
      setIDGreaterThan0(true);
      return;
    }

    if(addMinutes(dateStart, 1) >= dateEnd)
    {
      setEndTimeGreater(true);
      return;
    }
      
    dateStart = dateStart.toLocaleTimeString("nl-NL", { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false });
    dateEnd = dateEnd.toLocaleTimeString("nl-NL", { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false });

    setLoadingTimeRegistration(true);
    const address = props.baseAddressProp + addressExtension + "/PostTimesheetInfo";
    try {
      await Axios.post(address, {
        DailyTimeSheetId: 0,
        DateStart: dateStart,
        DateEnd: dateEnd,
        Amount: amount,
        Leave: 0,
        EmployeeEmployeeId: employeeId,
        ActivitiesActivityId: activityId,
        ActivitiesDivisionDivisionId: divisionId,
        ActivitiesDivisionCustomerCustomerId: customerId,
      });
      timesheetGET();
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(false);
      setEndTimeGreater(false);
    } catch (error) {
      setLoadingTimeRegistration(false);
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(true);
      setEndTimeGreater(false);
    }
  }

  async function timesheetDELETE(timesheetId, data) {
    setLoadingTimeRegistration(true);
    const address = props.baseAddressProp + addressExtension + "/DeleteTimesheetInfo" + "?timesheetId=" + timesheetId;
    try {
      await Axios.delete(address);
      setTimesheetTableState({ ...timesheetTableState, data });
      timesheetGET();
      // setLoadingTimeRegistration(false);
    } catch (error) {
      setLoadingTimeRegistration(false);
      setDuplicateIDErrorMessage(false);
      setIDGreaterThan0(false);
      setRemoveErrorMessage(true);
    }
  }

  async function timesheetMultipleDELETE(ids, data) {
    setLoadingTimeRegistration(true);
    const address = props.baseAddressProp + addressExtension + "/DeleteMultipleTimesheetInfo" + "?data=" + JSON.stringify(ids);
    try {
      await Axios.delete(address);
      setTimesheetTableState({ ...timesheetTableState, data });
      timesheetGET();
      // setLoadingTimeRegistration(false);
    } catch (error) {
      setLoadingTimeRegistration(false);
      setDuplicateIDErrorMessage(false);
      setIDGreaterThan0(false);
      setRemoveErrorMessage(true);
    }
  }

  //----------------------------------------------//

  async function pendingRegistrationsGET() {
    setLoadingPendingRegistrations(true);
    const address = props.baseAddressProp + addressExtension + "/GetPendingRegistrationsInfo";
    try {
      let result = await Axios.get(address);
      let c = pendingRegistrationsTableState;
      c.data = result.data;

      for (let i = 0; i < c.data.length; i++) {
        c.data[i].dateOfBirth = new Date(c.data[i].dateOfBirth).toLocaleString("en-GB", { year: "numeric", month: "numeric", day: "numeric" });
      }

      setPendingRegistrationsTableState(c);
      setLoadingPendingRegistrations(false);
    } catch (error) {
      setLoadingPendingRegistrations(false);
    }
  }

  //pendingRegistrationsPOST(rowData.userName, rowData.pendingRegistrationsId, data);

  async function pendingRegistrationsPOST(userName, pendingRegistrationsId, role, data) {
    setLoadingPendingRegistrations(true);
    const address = props.baseAddressProp + addressExtension + "/ApproveUser";
    try {
      let actualRole;
      switch(role)
      {
        case "a": {actualRole = "Admin"; break;}
        case "u": {actualRole = "User"; break;}
        case "dba": {actualRole = "Database admin"; break;}
      }
      //let actualRole = role === "a" ? "Admin" : "User";
      let result = await Axios.post(address, {
        UserName: userName,
        Role: actualRole
      });

      if (result.status === 200) {
        pendingRegistrationsDELETE(pendingRegistrationsId, data);
        employeesGET();
      }

      setLoadingPendingRegistrations(false);
    } catch (error) {
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(true);
      setLoadingPendingRegistrations(false);
    }
  }

  async function pendingRegistrationsMultiplePOST(userName, pendingRegistrationsId, role, data) {
    setLoadingPendingRegistrations(true);
    const address = props.baseAddressProp + addressExtension + "/ApproveUsers";
    try {
      for(let i = 0; i < role.length; i++){
        //role[i] = role[i] === "a" ? "Admin" : "User";
        switch(role[i])
        {
          case "a": {role[i] = "Admin"; break;}
          case "u": {role[i] = "User"; break;}
          case "dba": {role[i] = "Database admin"; break;}
        }
      }
      let result = await Axios.post(address, {
        UserName: userName, //this is an array
        Role: role
      });

      if (result.status === 200) {
        pendingRegistrationsMultipleDELETE(pendingRegistrationsId, data);
        employeesGET();
      }

      setLoadingPendingRegistrations(false);
    } catch (error) {
      setRemoveErrorMessage(false);
      setIDGreaterThan0(false);
      setDuplicateIDErrorMessage(true);
      setLoadingPendingRegistrations(false);
    }
  }

  async function pendingRegistrationsDELETE(pendingRegistrationsId, data) {
    setLoadingPendingRegistrations(true);
    const address = props.baseAddressProp + addressExtension + "/DeletePendingRegistrationsInfo" + "?pendingRegistrationsId=" + pendingRegistrationsId;
    try {
      await Axios.delete(address);
      setPendingRegistrationsTableState({ ...pendingRegistrationsTableState, data });
      setLoadingPendingRegistrations(false);
    } catch (error) {
      setDuplicateIDErrorMessage(false);
      setIDGreaterThan0(false);
      setRemoveErrorMessage(true);
      setLoadingPendingRegistrations(false);
    }
  }

  async function pendingRegistrationsMultipleDELETE(ids, data) {
    setLoadingPendingRegistrations(true);
    const address = props.baseAddressProp + addressExtension + "/DeletePendingRegistrationsMultipleInfo" + "?data=" + JSON.stringify(ids);
    try {
      await Axios.delete(address);
      setPendingRegistrationsTableState({ ...pendingRegistrationsTableState, data });
      setLoadingPendingRegistrations(false);
    } catch (error) {
      setDuplicateIDErrorMessage(false);
      setIDGreaterThan0(false);
      setRemoveErrorMessage(true);
      setLoadingPendingRegistrations(false);
    }
  }


  // async function activitiesMultipleDELETE(ids, data) {
  //   setLoading(true);
  //   const address = props.baseAddressProp + addressExtension + "/DeleteMultipleActivitiesInfo" + "?data=" + JSON.stringify(ids);
  //   try {
  //     await Axios.delete(address);
  //     setActivitiesTableState({ ...activitiesTableState, data });
  //     activitiesGET();
  //   } catch (error) {
  //     setLoading(false);
  //     setDuplicateIDErrorMessage(false);
  //     setIDGreaterThan0(false);
  //     setRemoveErrorMessage(true);
  //   }
  // }


  //----------------------------------------------//

  function loadingMessage() {
    return { loading } ? "Attempting to connect to the database..." : "Could not connect to the database.";
  }

  function renderDatabaseHome() {
    return (
      <div>
        <div className={classes.background}>
          <div className={classes.paperPositionHome} style={{paddingTop: "96px", marginTop: "32px" }}>
            <ThemeProvider theme={theme}>
              <Paper
                className={inputsm ? classes.paperPropsMobile : classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h2" className={classes.title}>
                      {props.t("Database.DatabaseText")}
                      <StorageIcon className={classes.iconStyle} />
                    </Typography>

                    <Typography variant="h5" className={classes.subTitle}>
                      {props.t("Database.Populate")}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      // marginBottom: 20,
                      // paddingBottom: 5,
                      border: "1px solid rgba(225, 225, 225, 1)",
                      // borderTopLeftRadius: "8px",
                      // borderTopRightRadius: "8px",
                      borderRadius: "8px",
                      width: "490px"
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontSize: "1.1rem",
                        color: "white",
                        marginBottom: 10,
                        backgroundColor: blue[500],
                        padding: "10px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      {props.t("Database.Tables")}
                    </Typography>
                    <img src={tablesInfoImage} className={classes.tableImageStyle} draggable="false"/>
                  </Grid>
                </Grid>
              </Paper>
            </ThemeProvider>
          </div>
          {renderDatabaseDragDrop()}
          {renderDatabaseSettings()}
        </div>
      </div>
    );
  }

  //----------------------------------------------//

  function renderDatabaseSettings() {
    return (
      <div>
        {inputsm ? (
            // #region MOBILE
        <div className={classes.paperPositionHome} style={{paddingBottom: "16px"}}>
        <ThemeProvider theme={theme}>
          <Paper
            className={inputsm ? classes.paperPropsMobile : classes.paperProps}
            classes={{
              rounded: classes.paperShape,
            }}
          >
            <Grid container>
              <Grid container direction="row" alignItems="center" style={{marginBottom: "10px"}}>
                <Grid item>
                <Typography variant="h2" className={classes.title}>
                  {props.t("Database.Settings")}
                  <SettingsIcon className={classes.iconStyle} />
                </Typography>
                </Grid>
{/* 
                <Grid item>
                  <SettingsIcon style={{ position: "relative", top: "3px", left: "5px", fontSize: minWidth600 ? "2.5rem" : "6vmin" }} />
                </Grid> */}
                </Grid>
                <Grid container style={{border: "1px solid rgba(225, 225, 225, 1)", borderRadius: "8px", padding: "10px"}}>
                  <Grid item style={{width: "100%"}}>
                    <Typography variant="h5" className={classes.subTitle} style={{ display: "flex" }}>
                    {props.t("Database.RowsPerTable")}
                    </Typography>
                  </Grid>
                  <Grid item style={{width: "100%"}}>
                      <TextField
                        fullWidth
                        select
                        label={props.t("Database.Rows")}
                        value={defaultPageNumbers}
                        onChange={event => {
                          setDefaultPageNumbers(event.target.value); 
                          window.localStorage.setItem("DefaultPageNumbers", event.target.value);}}
                        variant="outlined"
                        style={{ marginTop: 8 }}
                        margin="normal"
                        inputProps={{ style: {fontSize: "8px" } }}
                      >
                        {insertMenuItems(pageOptions)}
                      </TextField>
                   </Grid>
              </Grid>
            </Grid>
          </Paper>
        </ThemeProvider>
      </div>
      // #endregion
        ) :
        (
          // #region DESKTOP
          <div className={`${classes.paperPositionHome}`} style={{paddingBottom: "32px"}}>
          <ThemeProvider theme={theme}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
              }}
            >
              <Grid container>
                <Grid container direction="row" alignItems="center" style={{marginBottom: "10px"}}>
                  <Grid item>
                    <Typography variant="h2" className={classes.title}>
                      {props.t("Database.Settings")}
                    <SettingsIcon className={classes.iconStyle} />
                    </Typography>
                  </Grid>
  
                  {/* <Grid item>
                    <SettingsIcon style={{ position: "relative", top: "3px", left: "5px", fontSize: "2.5rem" }} />
                  </Grid> */}
                  </Grid>
  
                  <Grid container style={{border: "1px solid rgba(225, 225, 225, 1)", borderRadius: "8px", padding: "10px"}}>
                    <Grid item style={{width: "50%"}}>
                      <Typography variant="h5" className={classes.subTitle} style={{ display: "flex", position: "relative", alignItems: "center", height: "100%"}}>
                      {props.t("Database.RowsPerTable")}
                      </Typography>
                    </Grid>

                    <Grid item style={{width: "50%", marginLeft: "auto", marginRight: "0px"}}>
                      <TextField
                        fullWidth
                        select
                        label={props.t("Database.Rows")}
                        value={defaultPageNumbers}
                        onChange={event => {setDefaultPageNumbers(event.target.value); window.localStorage.setItem("DefaultPageNumbers", event.target.value);}}
                        variant="outlined"
                        style={{ marginTop: 8 }}
                        margin="normal"
                        inputProps={{ style: {fontSize: "8px" } }}
                      >
                        {insertMenuItems(pageOptions)}
                      </TextField>
                   </Grid>
                </Grid>
              </Grid>
            </Paper>
          </ThemeProvider>
        </div>
        // #endregion
        )}
      </div>
    );
  }

  function renderDatabaseDragDrop() {
    return (
      <div>
        {inputsm ? (
            // #region MOBILE
        <div className={classes.paperPositionHome}>
          
        <ThemeProvider theme={theme}>
          <Paper
            className={inputsm ? classes.paperPropsMobile : classes.paperProps}
            classes={{
              rounded: classes.paperShape,
            }}
          >
            <Grid container>
                <Grid container direction="row" alignItems="center" style={{display: "flex", flexDirection: "row"}}>
                  <Grid item>
                    <Typography variant="h5" className={classes.title}>
                    {props.t("Database.FileUploader")}
                    <CloudUploadIcon className={classes.iconStyle} />
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "auto", marginRight: "0px"}}>
                    <AlertIcon className="AlertIcon" onClick={event=>RedirectToDoc(event)} style={{fontSize: "1.25rem"}}/>
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center">
                  <Grid item>
                  <Typography variant="h5" className={classes.subTitle}>
                  {`${props.t("Database.FileUploaderInfo")} ${props.t("Database.FileButton")}`}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item style={{width: "100%"}}>
                    <div className="Container" id="container">
                      {showFileUploadIcons()}
                      <div className="choose-file-container">
                        <Button variant="contained"
                          style={{minWidth: "135px", boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"}}
                          className="button-padding"
                          color="secondary"
                          onClick={() => fileRef.current.click()}>
                          {props.t("Database.SelectFiles")}
                        </Button>
                        <input className="choose-file" ref={fileRef} onChange={(e) => handleFiles(e)} type="file" id="input" multiple/>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center" style={{marginBottom: "10px"}}>
                <Grid item>
                  {showFileNames()}
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center" style={{marginBottom: "10px"}}>
                  <Grid item>
                  <Typography variant="h6" style={{ fontSize: minWidth600 ? "0.85rem" : "2.3vmin" }}>
                    {props.t("Database.FileUploaderSupport")}
                    </Typography>
                  </Grid>
                </Grid>

                {/* <div className="choose-file-container">
                  <Button variant="contained"
                    style={{minWidth: "135px"}}
                    color="secondary"
                    onClick={() => fileRef.current.click()}>
                    {props.t("Database.SelectFiles")}
                  </Button>
                  <input className="choose-file" ref={fileRef} onChange={(e) => handleFiles(e)} type="file" id="input" multiple/>
                </div> */}

                <Grid container direction="row" alignItems="center" style={{marginBottom: "10px"}}>
                    <Grid item xs={12} style={{width: "100%"}}>
                      <div className={classes.buttons}>
                        <Button variant="contained"
                          color="primary"
                          style={{ marginTop: 15, marginRight: 15, width: "100%"}}
                          disabled={fileInfo.fileCount === 0 || uploadingData}
                          onClick={event => startUploading(event)}>
                          {uploadingData ? props.t("Database.UploadingData") : props.t("Database.StartUploading")}
                          {uploadingData && <CircularProgress size={24} style={{ top: "17%", position: "absolute" }} />}
                        </Button>
                      </div>
                    </Grid>
                </Grid>
              </Grid>

              <div>
              <CustomSnackbar showSnackbarProp={showSnackbar} closeSnackbarCallbackProp={closeSnackbarCallback} snackbarMessageProp={snackbarMessage} autoHideDurationProp={autoHideDuration}/>
            </div>

            <div>
              <CustomSnackbarError showSnackbarProp={showErrorSnackbar} closeSnackbarCallbackProp={closeSnackbarCallback} snackbarMessageProp={snackbarErrorMessage} autoHideDurationProp={autoHideDuration}/>
            </div>
          </Paper>
        </ThemeProvider>
      </div>
      // #endregion
        ) :
        (
          // #region DESKTOP
          <div className={classes.paperPositionHome}>
          <ThemeProvider theme={theme}>
            <Paper
              className={classes.paperProps}
              classes={{
                rounded: classes.paperShape,
              }}
            >
              <Grid container>
                <Grid container direction="row" alignItems="center" style={{display: "flex", flexDirection: "row"}}>
                  <Grid item>
                    <Typography variant="h2" className={classes.title}>
                    {props.t("Database.FileUploader")}
                    <CloudUploadIcon className={classes.iconStyle} />
                    </Typography>
                  </Grid>
  
                  {/* <Grid item>
                    <CloudUploadIcon style={{ position: "relative", top: "3px", left: "10px", fontSize: "2.5rem" }} />
                  </Grid> */}

                  <Grid item style={{ marginLeft: "auto", marginRight: "0px"}}>
                    <AlertIcon className="AlertIcon" onClick={event=>RedirectToDoc(event)}/>
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center">
                  <Grid item>
                  <Typography variant="h5" className={classes.subTitle}>
                    {`${props.t("Database.FileUploaderInfo")}`}
                    </Typography>
                  </Grid>
                </Grid>

                
                <Grid container>
                  <Grid item style={{width: "100%"}}>
                    <div className="Container" id="container">
                      {showFileUploadIcons()}
                      <div className="choose-file-container">
                        <Button variant="contained"
                          style={{minWidth: "135px", boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"}}
                          className="button-padding"
                          color="secondary"
                          onClick={() => fileRef.current.click()}>
                          {props.t("Database.SelectFiles")}
                        </Button>
                        <input className="choose-file" ref={fileRef} onChange={(e) => handleFiles(e)} type="file" id="input" multiple/>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center" style={{marginBottom: "10px"}}>
                <Grid item>
                  {showFileNames()}
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center" style={{marginBottom: "10px"}}>
                  <Grid item>
                  <Typography variant="h6" style={{ fontSize: "0.85rem" }}>
                    {props.t("Database.FileUploaderSupport")}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center" style={{marginBottom: "10px"}}>

                    <Grid item xs={12} style={{width: "100%"}}>
                      <div className={classes.buttons}>
                        <Button variant="contained"
                          color="primary"
                          style={{ marginTop: 15, marginRight: 15, width: "100%"}}
                          disabled={fileInfo.fileCount === 0 || uploadingData}
                          onClick={event => startUploading(event)}>
                          {uploadingData ? props.t("Database.UploadingData") : props.t("Database.StartUploading")}
                          {uploadingData && <CircularProgress size={24} style={{ top: "17%", position: "absolute" }} />}
                        </Button>
                      </div>
                    </Grid>
                </Grid>
              </Grid>

              <div>
              <CustomSnackbar showSnackbarProp={showSnackbar} closeSnackbarCallbackProp={closeSnackbarCallback} snackbarMessageProp={snackbarMessage} autoHideDurationProp={autoHideDuration}/>
            </div>

            <div>
              <CustomSnackbarError showSnackbarProp={showErrorSnackbar} closeSnackbarCallbackProp={closeSnackbarCallback} snackbarMessageProp={snackbarErrorMessage} autoHideDurationProp={autoHideDuration}/>
            </div>

            </Paper>
          </ThemeProvider>
        </div>
        // #endregion
        )}
      </div>
    );
  }

  function insertMenuItems(arg) {
    let items = [];
    for (let i = 0; i < arg.length; i++) {

      items.push(
        <MenuItem value={arg[i]} key={i}>
          {arg[i]}
        </MenuItem>
      );
    }
    return items;
  }

  //----------------------------------------------//

  function renderEmployeesTable() {
    const tableColumns = {
      columns: [

        { title: props.t("Database.EmployeeID"), field: "employeeId", type: "numeric", editable:"never"  },
      { title: props.t("Database.Username"), field: "userName", editable:"never" },
      { title: props.t("Database.FirstName"), field: "firstName", editable:"never" },
      { title: props.t("Database.LastName"), field: "lastName", editable:"never" },
      { title: props.t("Database.EmailAddress"), field: "email", editable:"never" },
      { title: props.t("Database.Dob"), field: "dateOfBirth", editable:"never" },
      { title: props.t("Database.LeaveDaysLeft"), field: "paidLeaveDays", type:"numeric", editable:"always" },
      { title: props.t("Database.Role"), field: "role", editable:"never"}
      ],
    };

    return (
      <React.Fragment>
        <div className={classes.paperPosition}>
          <Paper
            className={inputsm ? classes.paperPropsMobile : classes.paperProps}
            classes={{
              rounded: classes.paperShape,
            }}
          >
            <MuiThemeProvider theme={tableTheme}>
            <MaterialTable
              components={{
                Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm || denseMode ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />,
                Action: props => <MTableAction classes={{ root: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />,
                Actions: props => <MTableActions classes={{ root: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />,
              }}
              title={props.t("Database.EmployeesTable").toUpperCase()}
              columns={tableColumns.columns}
              data={employeesTableState.data}
              icons={tableIcons}

              actions={[                
                {
                  hidden:editMode,
                  tooltip: props.t("Database.DeleteSelected"),
                  icon: ()=><tableIcons.Delete style={{margin: denseMode ? "9px" : "0px"}}/>,
                  onClick: (event, tableData) => {
                    let arr = [];
                    let data = [...employeesTableState.data];
                    let counter = 0;

                    for(let i = 0; i < tableData.length; i++){
                      if(tableData[i].userName === props.userDataProp.userName){
                        continue;
                      }
                      data.splice(data.indexOf(tableData[i]), 1);
                      arr.push(tableData[i].employeeId);
                      counter++;
                    }

                    if(counter === 1){
                      employeesDELETE(tableData[0].employeeId, data);
                      return null;
                    }

                    employeesMultipleDELETE(arr, data);
                  }
                },

                {
                  tooltip: props.t("Database.EditMode"),
                  icon: ()=><Edit style={{color: editMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    let edit = editMode;
                    setEditMode(!edit);
                    
                  }
                },

                {
                  tooltip: props.t("Database.DenseMode"),
                  icon: ()=><DenseIcon style={{color: denseMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    let dense = denseMode;
                    setDenseMode(!dense);
                  }
                },

                {
                  tooltip: props.t("Database.RefreshTable"),
                  icon: ()=><RefreshIcon style={{color: "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    employeesGET();
                  }
                }
              ]}

              editable={
                editMode ? 
                {
                  onRowUpdate: (newData, oldData, a) =>
                    new Promise((resolve, reject) => {
                    const data = [...employeesTableState.data];
                    const index = data.indexOf(oldData);
                    data[index] = newData;
                    employeeLeaveDaysLeftPUT(newData.employeeId, newData.paidLeaveDays);
                    resolve();
                  })
                }
                : (null)
              }

              onRowClick={(event, row) => {}}
              options={{
                selection: !editMode,
                exportButton: true,
                filtering: false,
                search: true,
                searchFieldStyle:{
                  padding: "0px"
                },
                sorting: true,
                pageSize: defaultPageNumbers,
                pageSizeOptions: pageOptions,
                showTitle: inputsm ? false : true,
                rowStyle: (rowData, index) => {
                  if(rowData.userName === props.userDataProp.userName){
                    return {backgroundColor: "rgba(33, 150, 243, 0.5)"};
                  }
                  return { backgroundColor: index % 2 === 0 ? "rgba(242, 242, 242, 1)" : "#FFFFFF" };
                },
              }}
              style={{ boxShadow: "none" }}
              localization={{
                pagination:{
                  labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                  labelRowsSelect: props.t("Database.Rows"),
                  firstTooltip: props.t("EditEntries.FirstPage"),
                  previousTooltip: props.t("EditEntries.PreviousPage"),
                  nextTooltip: props.t("EditEntries.NextPage"),
                  lastTooltip: props.t("EditEntries.LastPage"),
                },
                toolbar:{
                  nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                  searchPlaceholder: props.t("EditEntries.Search"),
                  searchTooltip: props.t("EditEntries.Search"),
                  exportTitle: props.t("EditEntries.Export"),
                  exportName: props.t("EditEntries.Export")
                },
                body: {
                  editRow: {
                    deleteText: ""
                  },
                  emptyDataSourceMessage: emptyDataMessage
                }
              }}
              isLoading={loadingEmployees}
            />
            </MuiThemeProvider>

            <div>{removeErrorMessage ? cannotRemoveItemMessage() : null}</div>
            <div>{duplicateIDErrorMessage ? cannotAddDuplicateIDMessage() : null}</div>
            <div>{IDGreaterThan0 ? IDGreaterThan0Message() : null}</div>

            {loadingEmployees ? (
            <div style={{ marginTop: 20 }}>
                        <Alert severity="info">
                        <b>
                          <em>{props.t("Database.PleaseWait")}</em>
                        </b>
                        {props.t("Database.TakeAWhile")}
                        </Alert>
                </div>) : null}

                  <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginTop: 15, marginRight: 15}}
                          onClick={event => setRedirect(true)}
                        >
                          {props.t("Database.AddUser")}
                        </Button>
                            <Button variant="outlined" color="primary" style={{ marginTop: 15}} onClick={handleClickOpen}>
                            {props.t("Database.ResetLeaveDays")}
                        </Button>
                        <Dialog
                          open={openDialog}
                          onClose={handleClose}>
                            <DialogTitle>
                            {props.t("Database.ResetQuestion")}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                              {props.t("Database.ResetInfo")}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose} color="primary">
                              {props.t("Database.Decline")}
                              </Button>
                              <Button onClick={event=>{resetAnnualLeavePOST(); setOpenDialog(false)}} color="primary">
                              {props.t("Database.Accept")}
                              </Button>
                            </DialogActions>
                          </Dialog>
                      </Grid>

          </Paper>
          <div>
            <CustomSnackbarError 
              showSnackbarProp={showErrorSnackbar} 
              closeSnackbarCallbackProp={closeSnackbarCallback} 
              snackbarMessageProp={`${props.t("Database.TheYear")} ${new Date().getFullYear()} ${props.t("Database.YearNotSpecified")}`} 
              autoHideDurationProp={autoHideDuration}/>
          </div>
        </div>
      </React.Fragment>
    );
  }

  //----------------------------------------------//

  function renderCustomersTable() {

    const tableColumns = {
      columns: [
        { title: props.t("Database.CustomerID"), field: "customerId", type: "numeric", editable:"never" }, 
        { title: props.t("Database.CustomerName"), field: "customerName" }
      ],
    };

    return (
      <React.Fragment>
        <div className={classes.paperPosition}>
          <Paper
            className={inputsm ? classes.paperPropsMobile : classes.paperProps}
            classes={{
              rounded: classes.paperShape,
            }}
          >
            <MuiThemeProvider theme={tableTheme}>
            <MaterialTable
              components={{
                Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm || denseMode ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />
              }}
              title={props.t("Database.CustomersTable").toUpperCase()}
              columns={tableColumns.columns}
              data={customerTableState.data}
              icons={tableIcons}
              actions={[
                {
                  hidden:editMode,
                  tooltip: props.t("Database.DeleteSelected"),
                  icon: ()=><tableIcons.Delete style={{margin: denseMode ? "9px" : "0px"}}/>,
                  onClick: (event, tableData) => {
                    let arr = [];
                    let data = [...customerTableState.data];
                    let counter = 0;

                    for(let i = 0; i < tableData.length; i++){
                      data.splice(data.indexOf(tableData[i]), 1);
                      arr.push(tableData[i].customerId);
                      counter++;
                    }

                    if(counter === 1){
                      customersDELETE(tableData[0].customerId, data);
                      return null;
                    }

                    customersMultipleDELETE(arr, data);
                  }
                },

                {
                  tooltip: props.t("Database.EditMode"),
                  icon: ()=><Edit style={{color: editMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    let edit = editMode;
                    setEditMode(!edit);
                  }
                },

                {
                  tooltip: props.t("Database.DenseMode"),
                  icon: ()=><DenseIcon style={{color: denseMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    let dense = denseMode;
                    setDenseMode(!dense);
                  }
                },

                {
                  tooltip: props.t("Database.RefreshTable"),
                  icon: ()=><RefreshIcon style={{color: "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    customersGET();
                  }
                }
              ]}

              editable={
                editMode ? 
                {
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                  customerNameChangePUT(newData.customerId, newData.customerName, newData, oldData);
                  resolve();
                })
                } : (null)
              }

              onRowClick={(event, row) => {}}
              options={{
                selection: !editMode,
                exportButton: true,
                filtering: false,
                search: true,
                sorting: true,
                pageSize: defaultPageNumbers,
                pageSizeOptions: pageOptions,
                showTitle: inputsm ? false : true,
                rowStyle: (rowData, index) => {
                  return { backgroundColor: index % 2 === 0 ? "rgba(242, 242, 242, 1)" : "#FFFFFF" };
                }
              }}
              style={{ boxShadow: "none" }}
              localization={{
                pagination:{
                  labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                  labelRowsSelect: props.t("Database.Rows"),
                  firstTooltip: props.t("EditEntries.FirstPage"),
                  previousTooltip: props.t("EditEntries.PreviousPage"),
                  nextTooltip: props.t("EditEntries.NextPage"),
                  lastTooltip: props.t("EditEntries.LastPage"),
                },
                toolbar:{
                  nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                  searchPlaceholder: props.t("EditEntries.Search"),
                  searchTooltip: props.t("EditEntries.Search"),
                  exportTitle: props.t("EditEntries.Export"),
                  exportName: props.t("EditEntries.Export")
                },
                body: {
                  editRow: {
                    deleteText: ""
                  },
                  addTooltip: "Add row",
                  deleteToolTip: "Delete row",
                  editToolTip: "Edit row",
                  emptyDataSourceMessage: emptyDataMessage
                }
              }}
              isLoading={loadingCustomers}
            />
            </MuiThemeProvider>

            <div>{removeErrorMessage ? cannotRemoveItemMessage() : null}</div>
            <div>{duplicateIDErrorMessage ? cannotAddDuplicateIDMessage() : null}</div>
            <div>{IDGreaterThan0 ? IDGreaterThan0Message() : null}</div>

            <React.Fragment>
            {loadingCustomers ? (
            <div style={{ marginTop: 20 }}>
                        <Alert severity="info">
                        <b>
                          <em>{props.t("Database.PleaseWait")}</em>
                        </b>
                        {props.t("Database.TakeAWhile")}
                        </Alert>
                </div>) : null}

              <Grid
                item
                xs={12}
                style={{
                  marginTop: "25px",
                  display: "flex",
                  overflow: "auto",
                  padding: "20px",
                  border: "1px solid rgba(220, 220, 220, 1)",
                  backgroundColor: "rgba(240, 240, 240, 1)"
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Table size="small" style={{ display: "flex" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" style={{ padding: "0px" }} classes={{ root: classes.table }}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={customerAdd.customerName.trim() === "" || loadingCustomers === true ? true : false}
                            style={{ height: "fit-content", marginRight: "15px", marginTop: "0px", height: "40px", boxShadow: "unset" }}
                            onClick={event => {
                              let maxValue = 0;
                              for(let i = 0; i < customerTableState.data.length; i++){
                                if(customerTableState.data[i]["customerId"] > maxValue)
                                  maxValue = customerTableState.data[i]["customerId"];
                              }
                              ++maxValue;
                              customersPOST(maxValue, customerAdd.customerName);
                            }}
                          >
                            <AddIcon />
                          </Button>
                        </TableCell>

                        <TableCell align="left" style={{ padding: "0px" }} classes={{ root: classes.table }}>
                          <TextField
                            label={props.t("Database.CustomerName")}
                            variant="outlined"
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels }}
                            inputProps={{ style: { width: "110px",padding: "12px", fontSize: fontSize } }}
                            InputLabelProps={{ shrink: true }}
                            value={customerAdd.customerName}
                            onChange={event => {
                              setCustomerAdd(prevState=>{return{...prevState, ["customerName"]: event.target.value}});
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </MuiPickersUtilsProvider>
              </Grid>
            </React.Fragment>
          </Paper>
        </div>
      </React.Fragment>
    );
  }

  //----------------------------------------------//

  function renderDivisionsTable() {
    const tableColumns = {
      columns: [
        { title: props.t("Database.DivisionID"), field: "divisionId", type: "numeric", editable:"never" },
        { title: props.t("Database.DivisionName"), field: "divisionName" },
        { title: props.t("Database.CustomerName"), field: "customerCustomer.customerName", editable:"never" }
        // { title: props.t("Database.CustomerID"), field: "customerCustomerId", type: "numeric", editable:"never" }
      ],
    };

    return (
      <React.Fragment>
        <div className={classes.paperPosition}>
          <Paper
            className={inputsm ? classes.paperPropsMobile : classes.paperProps}
            classes={{
              rounded: classes.paperShape,
            }}
          >
            <MuiThemeProvider theme={tableTheme}>
            <MaterialTable
              components={{
                Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm || denseMode ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />
              }}
              title={props.t("Database.DivisionsTable").toUpperCase()}
              columns={tableColumns.columns}
              data={divisionTableState.data}
              icons={tableIcons}
              actions={[
                {
                  hidden:editMode,
                  tooltip: props.t("Database.DeleteSelected"),
                  icon: ()=><tableIcons.Delete style={{margin: denseMode ? "9px" : "0px"}}/>,
                  onClick: (event, tableData) => {
                    let arr = [];
                    let data = [...divisionTableState.data];
                    let counter = 0;

                    for(let i = 0; i < tableData.length; i++){
                      data.splice(data.indexOf(tableData[i]), 1);
                      arr.push(tableData[i].divisionId);
                      counter++;
                    }

                    if(counter === 1){
                      divisionsDELETE(tableData[0].divisionId, data);
                      return null;
                    }

                    divisionsMultipleDELETE(arr, data);
                  }
                },
                {
                  tooltip: props.t("Database.EditMode"),
                  icon: ()=><Edit style={{color: editMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    let edit = editMode;
                    setEditMode(!edit);
                  }
                },

                {
                  tooltip: props.t("Database.DenseMode"),
                  icon: ()=><DenseIcon style={{color: denseMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    let dense = denseMode;
                    setDenseMode(!dense);
                  }
                },

                {
                  tooltip: props.t("Database.RefreshTable"),
                  icon: ()=><RefreshIcon style={{color: "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    divisionsGET();
                  }
                }
              ]}

              editable={
                editMode ? 
                {
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                  divisionNameChangePUT(newData.divisionId, newData.divisionName, newData, oldData);
                  resolve();
                })
                } : (null)
              }


              onRowClick={(event, row) => {}}
              options={{
                selection: !editMode,
                exportButton: true,
                filtering: false,
                search: true,
                sorting: true,
                pageSize: defaultPageNumbers,
                pageSizeOptions: pageOptions,
                showTitle: inputsm ? false : true,
                rowStyle: (rowData, index) => {
                  return { backgroundColor: index % 2 === 0 ? "rgba(242, 242, 242, 1)" : "#FFFFFF" };
                }
              }}
              style={{ boxShadow: "none" }}
              localization={{
                pagination:{
                  labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                  labelRowsSelect: props.t("Database.Rows"),
                  firstTooltip: props.t("EditEntries.FirstPage"),
                  previousTooltip: props.t("EditEntries.PreviousPage"),
                  nextTooltip: props.t("EditEntries.NextPage"),
                  lastTooltip: props.t("EditEntries.LastPage"),
                },
                toolbar:{
                  nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                  searchPlaceholder: props.t("EditEntries.Search"),
                  searchTooltip: props.t("EditEntries.Search"),
                  exportTitle: props.t("EditEntries.Export"),
                  exportName: props.t("EditEntries.Export")
                },
                body: {
                  editRow: {
                    deleteText: ""
                  },
                  addTooltip: "Add row",
                  deleteToolTip: "Delete row",
                  editToolTip: "Edit row",
                  emptyDataSourceMessage: emptyDataMessage
                }
              }}
              isLoading={loadingDivisions}
            />
            </MuiThemeProvider>

            <div>{removeErrorMessage ? cannotRemoveItemMessage() : null}</div>
            <div>{duplicateIDErrorMessage ? cannotAddDuplicateIDMessage() : null}</div>
            <div>{IDGreaterThan0 ? IDGreaterThan0Message() : null}</div>

            <React.Fragment>

            {loadingDivisions ? (
            <div style={{ marginTop: 20 }}>
                        <Alert severity="info">
                        <b>
                          <em>{props.t("Database.PleaseWait")}</em>
                        </b>
                        {props.t("Database.TakeAWhile")}
                        </Alert>
                </div>) : null}

              <Grid
                item
                xs={12}
                style={{
                  marginTop: "25px",
                  display: "flex",
                  overflow: "auto",
                  padding: "20px",
                  border: "1px solid rgba(220, 220, 220, 1)",
                  backgroundColor: "rgba(240, 240, 240, 1)"
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Table size="small" style={{ display: "flex" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" style={{ padding: "0px" }} classes={{ root: classes.table }}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={divisionAdd.customerData === "" || divisionAdd.divisionName.trim() === "" || loadingDivisions === true ? true : false}
                            style={{ height: "fit-content", marginRight: "15px", marginTop: "0px", height: "40px", boxShadow: "unset" }}
                            onClick={event => {
                              let maxValue = 0;
                              for(let i = 0; i < divisionTableState.data.length; i++){
                                if(divisionTableState.data[i]["divisionId"] > maxValue)
                                  maxValue = divisionTableState.data[i]["divisionId"];
                              }
                              ++maxValue;
                              divisionsPOST(maxValue, divisionAdd.divisionName, divisionAdd.customerData);
                            }}
                          >
                            <AddIcon />
                          </Button>
                        </TableCell>

                        <TableCell align="left" style={{ padding: "0px" }} classes={{ root: classes.table }}>
                          <TextField
                            select
                            label={props.t("Database.CustomerName")}
                            variant="outlined"
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels, width: "130px" }}
                            inputProps={{ style: { width: "140px", padding: "9.5px", fontSize: fontSize } }}
                            InputLabelProps={{ height: "100px", padding: "9.5px", shrink: true }}
                            SelectProps={{
                              MenuProps: {
                                padding: "9.5px"
                              }
                              }}
                            value={divisionAdd.customerData}
                            onChange={event => {
                              let newDivisionOptions = [];
                              for(let i = 0; i < info.divisionOptions.length; i++)
                              {
                                if(info.divisionOptions[i].customerId === event.target.value.customerId)
                                  newDivisionOptions.push(info.divisionOptions[i]);
                              }
                              setDivisionAdd(prevState => {return {...prevState, ["currentDivisionOptions"]: newDivisionOptions, 
                              ["divisionData"]: "", ["customerData"]: event.target.value}});
                              
                            }}
                          >
                            {[insertCustomerItems(props)]}
                            </TextField>
                        </TableCell>

                        <TableCell align="left" style={{ padding: "0px" }} classes={{ root: classes.table }}>
                          <TextField
                            label={props.t("Database.DivisionName")}
                            variant="outlined"
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels }}
                            inputProps={{ style: { width: "110px", padding: "12px", fontSize: fontSize } }}
                            InputLabelProps={{ shrink: true }}
                            value={divisionAdd.divisionName}
                            onChange={event => {
                              setDivisionAdd({ ...divisionAdd, ["divisionName"]: event.target.value });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </MuiPickersUtilsProvider>
              </Grid>
            </React.Fragment>
          </Paper>
        </div>
      </React.Fragment>
    );
  }

  function insertCustomerItems(props) {
    if (info.customerOptions.length <= 0){
      return [<MenuItem disabled label>{props.t("Database.Empty")}</MenuItem>];
    }
    let items = [];
    for (let k = 0; k < info.customerOptions.length; k++) {
      items.push(
        <MenuItem value={info.customerOptions[k]} key={k}>
          {info.customerOptions[k].customerName}
        </MenuItem>
      );
    }
    return items;
  }

  function insertDivisionItems(props) {
    if (activityAdd.currentDivisionOptions.length <= 0){
      return [<MenuItem disabled label>{props.t("Database.Empty")}</MenuItem>];
    }
    let items = [];
    for (let k = 0; k < activityAdd.currentDivisionOptions.length; k++) {
      
      items.push(
        <MenuItem value={activityAdd.currentDivisionOptions[k]} key={k}>
          {activityAdd.currentDivisionOptions[k].divisionName}
        </MenuItem>
      );
    }
    return items;
  }

  function insertDivisionItemsTimesheet(props) {
    if (timesheetAdd.currentDivisionOptions.length <= 0){
      return [<MenuItem disabled label>{props.t("Database.Empty")}</MenuItem>];
    }
    let items = [];
    for (let k = 0; k < timesheetAdd.currentDivisionOptions.length; k++) {
      
      items.push(
        <MenuItem value={timesheetAdd.currentDivisionOptions[k]} key={k}>
          {timesheetAdd.currentDivisionOptions[k].divisionName}
        </MenuItem>
      );
    }
    return items;
  }

  function insertActivityItems(props) {
    if (timesheetAdd.currentActivityOptions.length <= 0){
      return [<MenuItem disabled label>{props.t("Database.Empty")}</MenuItem>];
    }
    let items = [];
    for (let k = 0; k < timesheetAdd.currentActivityOptions.length; k++) {
      
      items.push(
        <MenuItem value={timesheetAdd.currentActivityOptions[k]} key={k}>
          {timesheetAdd.currentActivityOptions[k].activityName}
        </MenuItem>
      );
    }
    return items;
  }

  function insertEmployeeItems()
  {
    if (info.employeeOptions.length <= 0){
      return;
    }
    let items = [];
    for (let k = 0; k < info.employeeOptions.length; k++) {
      
      items.push(
        <MenuItem value={info.employeeOptions[k]} key={k}>
          {info.employeeOptions[k].employeeUsername}
        </MenuItem>
      );
    }
    return items;
  }


  //----------------------------------------------//

  function renderActivitiesTable() {
    const tableColumns = {
      columns: [
        { title: props.t("Database.ActivityID"), field: "activityId", type: "numeric", editable:"never" },
        { title: props.t("Database.ActivityName"), field: "activityName" },
        { title: props.t("Database.Target"), field: "target", type: "numeric" },
        { title: props.t("Database.DivisionName"), field: "division.divisionName", editable:"never" },
        { title: props.t("Database.CustomerName"), field: "division.customerCustomer.customerName", editable:"never" },
        // { title: props.t("Database.DivisionID"), field: "divisionDivisionId", type: "numeric", editable:"never" },
        // { title: props.t("Database.CustomerID"), field: "divisionCustomerCustomerId", type: "numeric", editable:"never" }
      ],
    };

    return (
      <React.Fragment>
        <div className={classes.paperPosition}>
          <Paper
            className={inputsm ? classes.paperPropsMobile : classes.paperProps}
            classes={{
              rounded: classes.paperShape,
            }}
          >

            <Dialog
              open={openDialog}
              onClose={handleClose}>
                <DialogTitle>
                {props.t("Database.DeleteHeader")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                  {props.t("Database.DeleteParagraph")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                  {props.t("Database.Decline")}
                  </Button>
                  <Button onClick={event=>{setOpenDialog(false)}} color="primary">
                  {props.t("Database.Accept")}
                  </Button>
                </DialogActions>
            </Dialog>
                          
            <MuiThemeProvider theme={tableTheme}>
            <MaterialTable
              components={{
                Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm || denseMode ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />,
                Pagination: props => 
                <TablePagination {...props} 
                  rowsPerPageOptions={pageOptions} 
                  count={totalRows} //number of rows
                  rowsPerPage={rowsPerPage}
                  page={page} 
                  onChangePage={(event, newPage) => {setPage(newPage);}}
                  onChangeRowsPerPage={(event) => { props.onChangeRowsPerPage(event); setRowsPerPage(parseInt(event.target.value, 10)); setPage(0);}}/>
              }}
              title={props.t("Database.ActivitiesTable").toUpperCase()}
              columns={tableColumns.columns}
              data={activitiesTableState.data}
            //   data={query =>
            //     new Promise((resolve, reject) => {
            //       const address = props.baseAddressProp + addressExtension + "/GetActivitiesInfo";
            //       let result = await Axios.get(address);
            //       let c = { ...activitiesTableState };
            //       c.data = result.data;
            //       setActivitiesTableState(c);
            //         // prepare your data and then call resolve like this:
            //         resolve({
            //             data: // your data array
            //             page: // current page number
            //             totalCount: // total row number
            //         });
            //     })
            // }
              icons={tableIcons}
              actions={[
                {
                  hidden:editMode,
                  tooltip: props.t("Database.DeleteSelected"),
                  icon: ()=><tableIcons.Delete style={{margin: denseMode ? "9px" : "0px"}}/>,
                  onClick: (event, tableData) => {
                    let arr = [];
                    let data = [...activitiesTableState.data];
                    let counter = 0;

                    for(let i = 0; i < tableData.length; i++){
                      data.splice(data.indexOf(tableData[i]), 1);
                      arr.push(tableData[i].activityId);
                      counter++;
                    }

                    if(counter === 1){
                      activitiesDELETE(tableData[0].activityId, data);
                      return null;
                    }

                    activitiesMultipleDELETE(arr, data);
                  }
                },
                {
                  tooltip: props.t("Database.EditMode"),
                  icon: ()=><Edit style={{color: editMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    let edit = editMode;
                    setEditMode(!edit);
                  }
                },

                {
                  tooltip: props.t("Database.DenseMode"),
                  icon: ()=><DenseIcon style={{color: denseMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    let dense = denseMode;
                    setDenseMode(!dense);
                  }
                },

                {
                  tooltip: props.t("Database.RefreshTable"),
                  icon: ()=><RefreshIcon style={{color: "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    activitiesGET();
                  }
                }
              ]}

              editable={
                editMode ? 
                {
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                  activityNameChangePUT(newData.activityId, newData.activityName, newData, oldData);
                  resolve();
                })
                } : (null)
              }

              // editable={{
              //   onRowDelete: oldData =>
              //     new Promise(resolve => {
              //       resolve();
              //       const data = [...activitiesTableState.data];
              //       data.splice(data.indexOf(oldData), 1);
              //       activitiesDELETE(oldData.activityId, data);
              //     })
              // }}
              onRowClick={(event, row) => {}}
              options={{
                selection: !editMode,
                exportButton: true,
                filtering: false,
                search: false,
                sorting: false,
                pageSize: rowsPerPage,
                pageSizeOptions: pageOptions,
                showTitle: inputsm ? false : true,
                rowStyle: (rowData, index) => {
                  return { backgroundColor: index % 2 === 0 ? "rgba(242, 242, 242, 1)" : "#FFFFFF" };
                }
              }}
              style={{ boxShadow: "none" }}
              localization={{
                pagination:{
                  labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                  labelRowsSelect: props.t("Database.Rows"),
                  firstTooltip: props.t("EditEntries.FirstPage"),
                  previousTooltip: props.t("EditEntries.PreviousPage"),
                  nextTooltip: props.t("EditEntries.NextPage"),
                  lastTooltip: props.t("EditEntries.LastPage"),
                },
                toolbar:{
                  nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                  // searchPlaceholder: props.t("EditEntries.Search"),
                  // searchTooltip: props.t("EditEntries.Search"),
                  exportTitle: props.t("EditEntries.Export"),
                  exportName: props.t("EditEntries.Export")
                },
                body: {
                  editRow: {
                    deleteText: ""
                  },
                  addTooltip: "Add row",
                  deleteToolTip: "Delete row",
                  editToolTip: "Edit row",
                  emptyDataSourceMessage: emptyDataMessage
                }
              }}
              isLoading={loadingActivities}
            />
            </MuiThemeProvider>

            <div>{removeErrorMessage ? cannotRemoveItemMessage() : null}</div>
            <div>{duplicateIDErrorMessage ? cannotAddDuplicateIDMessage() : null}</div>
            <div>{IDGreaterThan0 ? IDGreaterThan0Message() : null}</div>

            <React.Fragment>
              {loadingActivities ? (
            <div style={{ marginTop: 20 }}>
                        <Alert severity="info">
                        <b>
                          <em>{props.t("Database.PleaseWait")}</em>
                        </b>
                        {props.t("Database.TakeAWhile")}
                        </Alert>
                </div>) : null}
              <div className="search-bar">
              <InputBase
                  style={{padding: "12px"}}
                  placeholder={props.t("Database.SearchByActivityName")}
                  value={activitySearchString}
                  inputProps={{ 'aria-label': props.t("Database.SearchByActivityName") }}
                  onChange={event => setActivitySearchString(event.target.value.trim())}
                />
                <Tooltip title={props.t("Repairs.Search")} TransitionComponent={Zoom}>
                  <IconButton type="submit" aria-label="search" onClick={event => activitiesGET()}>
                    <Search />
                  </IconButton>
                </Tooltip>
                </div>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: "13px",
                  display: "flex",
                  overflow: "auto",
                  padding: "20px",
                  border: "1px solid rgba(220, 220, 220, 1)",
                  backgroundColor: "rgba(240, 240, 240, 1)"
                }}
              >

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Table size="small" style={{ display: "flex" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" style={{ width: "70px", padding: "0px" }} classes={{ root: classes.table }}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={activityAdd.divisionData === "" || activityAdd.target < 0 || activityAdd.target.toString().trim() === "" || activityAdd.activityName.trim() === "" || activityAdd.customerData === "" || loadingActivities === true ? true : false}
                            style={{ height: "fit-content", marginRight: "15px", marginTop: "0px", height: "40px", boxShadow: "unset" }}
                            onClick={event => {
                              // let maxValue = 0;
                              // for(let i = 0; i < activitiesTableState.data.length; i++){
                              //   if(activitiesTableState.data[i]["activityId"] > maxValue)
                              //     maxValue = activitiesTableState.data[i]["activityId"];
                              // }
                              // ++maxValue;

                              activitiesPOST(
                                
                                activityAdd.activityName,
                                activityAdd.target,
                                activityAdd.divisionData,
                                activityAdd.customerName
                              );
                            }}
                          >
                            <AddIcon />
                          </Button>
                        </TableCell>

                        <TableCell align="left" style={{ padding: "0px" }} classes={{ root: classes.table }}>
                          <TextField
                            select
                            disabled={loadingActivities}
                            label={props.t("Database.CustomerName")}
                            variant="outlined"
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels, width: "130px" }}
                            inputProps={{ style: { width: "140px", fontSize: fontSize } }}
                            InputLabelProps={{ shrink: true }}
                            value={activityAdd.customerData}
                            onChange={event => {

                              let newDivisionOptions = [];
                              for(let i = 0; i < info.divisionOptions.length; i++)
                              {
                                if(info.divisionOptions[i].customerId === event.target.value.customerId)
                                  newDivisionOptions.push(info.divisionOptions[i]);
                              }
                              setActivityAdd(prevState => {return {...prevState, ["currentDivisionOptions"]: newDivisionOptions, 
                              ["divisionData"]: "", ["customerData"]: event.target.value, ["customerName"]: event.target.value.customerName}});
                            }}
                          >
                            {insertCustomerItems(props)}
                            </TextField>
                        </TableCell>

                        <TableCell align="left" style={{ width: "70px", padding: "0px" }} classes={{ root: classes.table }}>
                        <TextField
                            select
                            disabled={loadingActivities}
                            label={props.t("Database.DivisionName")}
                            variant="outlined"
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels, width: "130px" }}
                            inputProps={{ style: { fontSize: fontSize } }}
                            InputLabelProps={{ shrink: true }}
                            value={activityAdd.divisionData}
                            onChange={event => {
                              setActivityAdd(prevState => {return {...prevState, ["divisionData"]: event.target.value}});
                            }}
                          >
                            {insertDivisionItems(props)}
                            </TextField>
                        </TableCell>

                        <TableCell align="left" style={{ width: "77px", padding: "0px" }} classes={{ root: classes.table }}>
                          <TextField
                            disabled={loadingActivities}
                            label={props.t("Database.ActivityName")}
                            variant="outlined"
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels }}
                            inputProps={{ style: { padding: "12px", width: "110px", fontSize: fontSize } }}
                            InputLabelProps={{ shrink: true }}
                            value={activityAdd.activityName}
                            onChange={event => {
                              setActivityAdd({ ...activityAdd, ["activityName"]: event.target.value });
                            }}
                          />
                        </TableCell>

                        <TableCell align="left" style={{ width: "70px", padding: "0px" }} classes={{ root: classes.table }}>
                          <TextField
                            disabled={loadingActivities}
                            label={props.t("Database.Target")}
                            variant="outlined"
                            type="number"
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels }}
                            inputProps={{ style: { padding: "12px", width: "133px", fontSize: fontSize } }}
                            InputLabelProps={{ shrink: true }}
                            value={activityAdd.target}
                            onChange={event => {
                              setActivityAdd({ ...activityAdd, ["target"]: event.target.value });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </MuiPickersUtilsProvider>
              </Grid>
            </React.Fragment>
          </Paper>
        </div>
      </React.Fragment>
    );
  }

  //------------------------------//

  function renderTimesheetTable() {
    const tableColumns = {
      columns: [
        { title: "ID", field: "dailyTimeSheetId" },
        { title: props.t("TimeRegistration.StartTime"), field: "dateStart" },
        { title: props.t("TimeRegistration.EndTime"), field: "dateEnd" },
        { title: props.t("Database.Quantity"), field: "amount" },
        { title: props.t("Database.Username"), field: "employeeEmployee.userName"},
        { title: props.t("Database.ActivityName"), field: "activities.activityName", editable:"never" },
        { title: props.t("Database.DivisionName"), field: "activities.division.divisionName", editable:"never" },
        { title: props.t("Database.CustomerName"), field: "activities.division.customerCustomer.customerName", editable:"never" },
        // { title: props.t("Database.ActivityID"), field: "activitiesActivityId", type: "numeric" },
        // { title: props.t("Database.DivisionID"), field: "activitiesDivisionDivisionId", type: "numeric" },
        // { title: props.t("Database.CustomerID"), field: "activitiesDivisionCustomerCustomerId", type: "numeric" }
      ],
    };

    return (
      <React.Fragment>
        <div className={classes.paperPosition}>
          <Paper
            className={inputsm ? classes.paperPropsMobile : classes.paperProps}
            classes={{
              rounded: classes.paperShape,
            }}
          >
            <MuiThemeProvider theme={tableTheme}>
            <MaterialTable
              components={{
                Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm || denseMode ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />,
                Pagination: props => 
                <TablePagination {...props} 
                  rowsPerPageOptions={pageOptions} 
                  count={totalRowsTimeRegistration} //number of rows
                  rowsPerPage={rowsPerPageTimeRegistration}
                  page={pageTimeRegistration} 
                  onChangePage={(event, newPage) => {setPageTimeRegistration(newPage);}}
                  onChangeRowsPerPage={(event) => { props.onChangeRowsPerPage(event); setRowsPerPageTimeRegistration(parseInt(event.target.value, 10)); setPageTimeRegistration(0);}}/>
              }}
              title={props.t("Database.TimeRegistrationTable").toUpperCase()}
              columns={tableColumns.columns}
              data={timesheetTableState.data}
              icons={tableIcons}
              actions={[
                {
                  tooltip: props.t("Database.DeleteSelected"),
                  icon: ()=><tableIcons.Delete style={{margin: denseMode ? "9px" : "0px"}}/>,
                  onClick: (event, tableData) => {
                    let arr = [];
                    let data = [...timesheetTableState.data];
                    let counter = 0;

                    for(let i = 0; i < tableData.length; i++){
                      data.splice(data.indexOf(tableData[i]), 1);
                      arr.push(tableData[i].dailyTimeSheetId);
                      counter++;
                    }

                    if(counter === 1){
                      timesheetDELETE(tableData[0].dailyTimeSheetId, data);
                      return null;
                    }

                    timesheetMultipleDELETE(arr, data);
                  }
                },

                {
                  tooltip: props.t("Database.DenseMode"),
                  icon: ()=><DenseIcon style={{color: denseMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    let dense = denseMode;
                    setDenseMode(!dense);
                  }
                },

                {
                  tooltip: props.t("Database.RefreshTable"),
                  icon: ()=><RefreshIcon style={{color: "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                  isFreeAction: true,
                  onClick:(event, tableData)=>{
                    timesheetGET();
                  }
                }
              ]}
              // editable={{
              //   onRowDelete: oldData =>
              //     new Promise(resolve => {
              //       resolve();
              //       const data = [...timesheetTableState.data];
              //       data.splice(data.indexOf(oldData), 1);
              //       timesheetDELETE(oldData.dailyTimeSheetId, data);
              //     })
              // }}
              onRowClick={(event, row) => {}}
              options={{
                selection: true,
                exportButton: true,
                filtering: false,
                search: false,
                sorting: false,
                pageSize: rowsPerPageTimeRegistration,
                pageSizeOptions: pageOptions,
                showTitle: inputsm ? false : true,
                rowStyle: (rowData, index) => {
                  return { backgroundColor: index % 2 === 0 ? "rgba(242, 242, 242, 1)" : "#FFFFFF" };
                }
              }}
              style={{ boxShadow: "none" }}
              localization={{
                pagination:{
                  labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                  labelRowsSelect: props.t("Database.Rows"),
                  firstTooltip: props.t("EditEntries.FirstPage"),
                  previousTooltip: props.t("EditEntries.PreviousPage"),
                  nextTooltip: props.t("EditEntries.NextPage"),
                  lastTooltip: props.t("EditEntries.LastPage"),
                },
                toolbar:{
                  nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                  // searchPlaceholder: props.t("EditEntries.Search"),
                  // searchTooltip: props.t("EditEntries.Search"),
                  exportTitle: props.t("EditEntries.Export"),
                  exportName: props.t("EditEntries.Export")
                },
                body: {
                  editRow: {
                    deleteText: ""
                  },
                  addTooltip: "Add row",
                  deleteToolTip: "Delete row",
                  editToolTip: "Edit row",
                  emptyDataSourceMessage: emptyDataMessage
                }
              }}
              isLoading={loadingTimeRegistration}
            />
            </MuiThemeProvider>

            <div>{removeErrorMessage ? cannotRemoveItemMessage() : null}</div>
            <div>{duplicateIDErrorMessage ? cannotAddDuplicateIDMessage() : null}</div>
            <div>{IDGreaterThan0 ? IDGreaterThan0Message() : null}</div>
            <div>{EndTimeGreater ? EndTimeGreaterMessage() : null}</div>

            <React.Fragment>

            {loadingTimeRegistration ? (
            <div style={{ marginTop: 20 }}>
                        <Alert severity="info">
                        <b>
                          <em>{props.t("Database.PleaseWait")}</em>
                        </b>
                        {props.t("Database.TakeAWhile")}
                        </Alert>
                </div>) : null}

                <div className="search-bar">
              <InputBase
                  style={{padding: "12px"}}
                  placeholder={props.t("Database.SearchByUserName")}
                  value={timeRegistrationSearchString}
                  inputProps={{ 'aria-label': props.t("Database.SearchByUserName") }}
                  onChange={event => setTimeRegistrationSearchString(event.target.value.trim())}
                />
                <Tooltip title={props.t("Repairs.Search")} TransitionComponent={Zoom}>
                  <IconButton type="submit" aria-label="search" onClick={event => timesheetGET()}>
                    <Search />
                  </IconButton>
                </Tooltip>
                </div>

              <Grid
                item
                xs={12}
                style={{
                  marginTop: "13px",
                  display: "flex",
                  overflow: "auto",
                  padding: "20px",
                  border: "1px solid rgba(220, 220, 220, 1)",
                  backgroundColor: "rgba(240, 240, 240, 1)"
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Table size="small" style={{ display: "flex" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" style={{ padding: "0px" }} classes={{ root: classes.table }}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={timesheetAdd.divisionData === "" || timesheetAdd.activityData === "" || timesheetAdd.employeeData === "" || timesheetAdd.quantity < 0 || timesheetAdd.quantity.toString().trim() === ""  || timesheetAdd.customerData === "" || loadingTimeRegistration === true ? true : false}
                            style={{ height: "fit-content", marginRight: "15px", marginTop: "0px", height: "37px", boxShadow: "unset" }}
                            onClick={event => {

                              // let maxValue = 0;
                              // for(let i = 0; i < timesheetTableState.data.length; i++){
                              //   if(timesheetTableState.data[i]["dailyTimeSheetId"] > maxValue)
                              //     maxValue = timesheetTableState.data[i]["dailyTimeSheetId"];
                              // }
                              // ++maxValue;

                              timesheetPOST(
                                timesheetAdd.dateStart,
                                timesheetAdd.dateEnd,
                                timesheetAdd.quantity,
                                timesheetAdd.employeeData.employeeId,
                                timesheetAdd.activityData.activityId,
                                timesheetAdd.divisionData.divisionId,
                                timesheetAdd.divisionData.customerId,
                                timesheetAdd.activityData.activityName,
                                timesheetAdd.divisionData.divisionName,
                                timesheetAdd.customerData.customerName
                              );
                            }}
                          >
                            <AddIcon />
                          </Button>
                        </TableCell>


                        <TableCell align="left" style={{ padding: "0px" }} classes={{ root: classes.table }}>
                          <TextField
                            select
                            disabled={loadingTimeRegistration}
                            label={props.t("Database.CustomerName")}
                            variant="outlined"
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels, width: "130px" }}
                            inputProps={{ style: { width: "140px", fontSize: fontSize } }}
                            InputLabelProps={{ shrink: true }}
                            value={timesheetAdd.customerData}
                            onChange={event => {

                              let newDivisionOptions = [];
                              for(let i = 0; i < info.divisionOptions.length; i++)
                              {
                                if(info.divisionOptions[i].customerId === event.target.value.customerId)
                                  newDivisionOptions.push(info.divisionOptions[i]);
                              }

                              // setActivityAdd(prevState => {return {...prevState, ["currentDivisionOptions"]: newDivisionOptions, 
                              // ["divisionData"]: "", ["customerData"]: event.target.value, ["customerName"]: event.target.value.customerName}});

                              setTimesheetAdd(prevState => {return {...prevState, ["currentDivisionOptions"]: newDivisionOptions, 
                              ["divisionData"]: "", ["customerData"]: event.target.value, ["customerName"]: event.target.value.customerName,
                              ["activityData"]: "", ["currentActivityOptions"]: ""}});

                              // setTimesheetAdd(prevState => {return {...prevState, ["activityData"]: "", ["currentActivityOptions"]: ""}});
                            }}
                          >
                            {insertCustomerItems(props)}
                            </TextField>
                        </TableCell>

                        <TableCell align="left" style={{ width: "70px", padding: "0px" }} classes={{ root: classes.table }}>
                        <TextField
                            select
                            disabled={loadingTimeRegistration}
                            label={props.t("Database.DivisionName")}
                            variant="outlined"
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels, width: "130px" }}
                            inputProps={{ style: { fontSize: fontSize } }}
                            InputLabelProps={{ shrink: true }}
                            value={timesheetAdd.divisionData}
                            onChange={event => {
                              // setActivityAdd(prevState => {return {...prevState, ["divisionData"]: event.target.value}});

                              let newActivityOptions = [];
                              for(let i = 0; i < info.activityOptions.length; i++)
                              {
                                if(info.activityOptions[i].divisionId === event.target.value.divisionId)
                                newActivityOptions.push(info.activityOptions[i]);
                              }
                              setTimesheetAdd(prevState => {return {...prevState, ["currentActivityOptions"]: newActivityOptions,
                              ["activityData"]: "", ["divisionData"]: event.target.value}});
                            }}
                          >
                            {insertDivisionItemsTimesheet(props)}
                            </TextField>
                        </TableCell>

                        <TableCell align="left" style={{ width: "70px", padding: "0px" }} classes={{ root: classes.table }}>
                        <TextField
                            select
                            disabled={loadingTimeRegistration}
                            label={props.t("Database.ActivityName")}
                            variant="outlined"
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels, width: "130px" }}
                            inputProps={{ style: {width: "140px", fontSize: fontSize } }}
                            InputLabelProps={{ shrink: true }}
                            value={timesheetAdd.activityData}
                            onChange={event => {
                              setTimesheetAdd(prevState => {return {...prevState, ["activityData"]: event.target.value}});
                            }}
                          >
                            {insertActivityItems(props)}
                            </TextField>
                        </TableCell>

                        <TableCell align="left" style={{ width: "70px", padding: "0px" }} classes={{ root: classes.table }}>
                        <TextField
                            select
                            disabled={loadingTimeRegistration}
                            label={props.t("Database.Username")}
                            variant="outlined"
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels, width: "160px" }}
                            inputProps={{ style: { fontSize: fontSize } }}
                            InputLabelProps={{ shrink: true }}
                            value={timesheetAdd.employeeData}
                            onChange={event => {
                              setTimesheetAdd(prevState => {return {...prevState, ["employeeData"]: event.target.value}});
                            }}
                          >
                            {insertEmployeeItems()}
                            </TextField>
                        </TableCell>

                        <TableCell align="left" style={{ padding: "0px" }} classes={{ root: classes.table }}>
                          <TextField
                            disabled={loadingTimeRegistration}
                            label={props.t("Database.Quantity")}
                            variant="outlined"
                            type="number"
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels }}
                            inputProps={{ style: { width: "70px", fontSize: fontSize } }}
                            InputLabelProps={{ shrink: true }}
                            value={timesheetAdd.quantity}
                            onChange={event => {
                              setTimesheetAdd({ ...timesheetAdd, ["quantity"]: event.target.value });
                            }}
                          />
                        </TableCell>

                        <TableCell align="left" style={{ padding: "0px" }} classes={{ root: classes.table }}>
                          <KeyboardDateTimePicker
                            disabled={loadingTimeRegistration}
                            InputProps={{ style: { width: "220px", fontSize: "14px", paddingRight: "0px" } }}
                            variant="inline"
                            ampm={false}
                            label={props.t("TimeRegistration.StartTime")}
                            value={timesheetAdd.dateStart}
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels }}
                            inputVariant="outlined"
                            margin="dense"
                            format="dd/MM/yyyy HH:mm"
                            onChange={(a, b) => {
                              let current = { ...timesheetAdd };
                              current["dateStart"] = a;
                              setTimesheetAdd(current);
                            }}
                            
                          />
                        </TableCell>
                        <TableCell align="left" style={{ padding: "0px" }} classes={{ root: classes.table }}>
                          <KeyboardDateTimePicker
                            disabled={loadingTimeRegistration}
                            InputProps={{ style: { width: "220px", fontSize: "14px", paddingRight: "0px" } }}
                            variant="inline"
                            ampm={false}
                            label={props.t("TimeRegistration.EndTime")}
                            margin="dense"
                            style={{ margin: "5px", backgroundColor: bgColorTimesheetLabels, fontSize: fontSize }}
                            value={timesheetAdd.dateEnd}
                            inputVariant="outlined"
                            onChange={(a, b) => {
                              let current = { ...timesheetAdd };
                              current["dateEnd"] = a;
                              setTimesheetAdd(current);
                            }}
                            format="dd/MM/yyyy HH:mm"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </MuiPickersUtilsProvider>
              </Grid>
            </React.Fragment>
          </Paper>
        </div>
      </React.Fragment>
    );
  }

  //------------------------------//

  function renderPendingRegistrationsTable() {
    const tableColumns = {
      columns: [
        { title: props.t("Database.ID"), field: "pendingRegistrationsId", type: "numeric", editable: "never" },
        { title: props.t("Database.Username"), field: "userName", editable: "never" },
        { title: props.t("Database.FirstName"), field: "firstName", editable: "never" },
        { title: props.t("Database.LastName"), field: "lastName", editable: "never" },
        { title: props.t("Database.EmailAddress"), field: "email", editable: "never" },
        { title: props.t("Database.Dob"), field: "dateOfBirth", editable: "never" },
        { title: props.t("Database.Role"), field: "role", lookup: { u: "User", a: "Admin", dba: "Database admin" } }
      ],
    };
    return (
      <React.Fragment>
        <div className={classes.paperPosition}>
          <Paper
            className={inputsm ? classes.paperPropsMobile : classes.paperProps}
            classes={{
              rounded: classes.paperShape,
            }}
          >
            <MuiThemeProvider theme={tableTheme}>
            <MaterialTable
              components={{
                Toolbar: props => <MTableToolbar classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }} {...props} />,
                Cell: props => <MTableCell classes={{ root: inputsm || denseMode ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />
              }}
              title={props.t("Database.PendingRegistrationsTable").toUpperCase()}
              columns={tableColumns.columns}
              data={pendingRegistrationsTableState.data}
              icons={tableIcons}
              // actions={[
              //   {
              //     tooltip: 'Delete selected',
              //     icon: tableIcons.Delete,
              //     onClick: (event, tableData) => {
              //       let arr = [];
              //       let data = [...activitiesTableState.data];
              //       let counter = 0;

              //       for(let i = 0; i < tableData.length; i++){
              //         data.splice(data.indexOf(tableData[i]), 1);
              //         arr.push(tableData[i].activityId);
              //         counter++;
              //       }

              //       if(counter === 1){
              //         activitiesDELETE(tableData[0].activityId, data);
              //         return null;
              //       }

              //       activitiesMultipleDELETE(arr, data);
              //     }
              //   }
              // ]}
              
            
              actions={[
                {
                  icon: ()=><HowToReg style={{margin: denseMode ? "9px" : "0px"}}/>,
                  tooltip: props.t("Database.ApproveSelected"),
                  iconProps: {
                    fontSize: "1.8rem"
                  },
                  onClick: (event, tableData) => {
                    let userName = [];
                    let pendingRegistrationsId = [];
                    let role = [];
                    let data = [...pendingRegistrationsTableState.data];
                    let counter = 0;

                    for(let i = 0; i < tableData.length; i++){
                      data.splice(data.indexOf(tableData[i]), 1);
                      userName.push(tableData[i].userName);
                      pendingRegistrationsId.push(tableData[i].pendingRegistrationsId);
                      role.push(tableData[i].role);
                      counter++;
                    }

                    if(counter === 1){
                      pendingRegistrationsPOST(tableData[0].userName, tableData[0].pendingRegistrationsId, tableData[0].role, data);
                      return null;
                    }

                    pendingRegistrationsMultiplePOST(userName, pendingRegistrationsId,  role, data);



                    //   data.splice(data.indexOf(rowData), 1);
                    //   pendingRegistrationsPOST(rowData.userName, rowData.pendingRegistrationsId, rowData.role, data);
                    }
                  },

                  {
                    tooltip: props.t("Database.DeleteSelected"),
                    icon: ()=><tableIcons.Delete style={{margin: denseMode ? "9px" : "0px"}}/>,
                    onClick: (event, tableData) => {
                      let arr = [];
                      let data = [...pendingRegistrationsTableState.data];
                      let counter = 0;
  
                      for(let i = 0; i < tableData.length; i++){
                        data.splice(data.indexOf(tableData[i]), 1);
                        arr.push(tableData[i].pendingRegistrationsId);
                        counter++;
                      }
  
                      if(counter === 1){
                        pendingRegistrationsDELETE(tableData[0].pendingRegistrationsId, data);
                        return null;
                      }
  
                      pendingRegistrationsMultipleDELETE(arr, data);
                    }
                  },

                  {
                    tooltip: props.t("Database.ChangeRole"),
                    icon: ()=><PersonPinIcon style={{margin: denseMode ? "9px" : "0px"}}/>,
                    onClick: (event, tableData) => {
                        const data = [...pendingRegistrationsTableState.data];
                        for(let i = 0; i < tableData.length; i++){
                          const index = data.indexOf(tableData[i]);

                          switch(tableData[i].role){
                            case "u": {data[index].role = "a"; break;}
                            case "a": {data[index].role = "dba"; break;}
                            case "dba": {data[index].role = "u"; break;}
                          }
                        }
                        // const index = data.indexOf(oldData);
                        // data[index] = newData;
                        setPendingRegistrationsTableState({ ...pendingRegistrationsTableState, data });
                    }
                  },

                  // {
                  //   tooltip: "Editable",
                  //   isFreeAction: true,
                  //   icon: PersonPinIcon,
                  //   onClick: (event, tableData) => {
                      
                  //       // const data = [...pendingRegistrationsTableState.data];

                  //       // for(let i = 0; i < tableData.length; i++){
                  //       //   const index = data.indexOf(tableData[i]);

                  //       //   switch(tableData[i].role){
                  //       //     case "u": {data[index].role = "a"; break;}
                  //       //     case "a": {data[index].role = "u"; break;}
                  //       //   }
                  //       // }
                  //       // // const index = data.indexOf(oldData);
                  //       // // data[index] = newData;
                  //       // setPendingRegistrationsTableState({ ...pendingRegistrationsTableState, data });
                  //   }
                  // },

                  {
                    tooltip: props.t("Database.DenseMode"),
                    icon: ()=><DenseIcon style={{color: denseMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                    isFreeAction: true,
                    onClick:(event, tableData)=>{
                      let dense = denseMode;
                      setDenseMode(!dense);
                    }
                  },

                  {
                    tooltip: props.t("Database.RefreshTable"),
                    icon: ()=><RefreshIcon style={{color: "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px"}}/>,
                    isFreeAction: true,
                    onClick:(event, tableData)=>{
                      pendingRegistrationsGET();
                    }
                  }
                
              ]}

              onRowClick={(event, row) => {}}
              options={{
                selection: true,
                exportButton: true,
                filtering: false,
                search: true,
                sorting: true,
                pageSize: defaultPageNumbers,
                pageSizeOptions: pageOptions,
                showTitle: inputsm ? false : true,
                rowStyle: (rowData, index) => {
                  return { backgroundColor: index % 2 === 0 ? "rgba(242, 242, 242, 1)" : "#FFFFFF" };
                }
              }}
              style={{ boxShadow: "none" }}
              localization={{
                header:{
                  actions: props.t("EditEntries.Actions")
                },
                pagination:{
                  labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                  labelRowsSelect: props.t("Database.Rows"),
                  firstTooltip: props.t("EditEntries.FirstPage"),
                  previousTooltip: props.t("EditEntries.PreviousPage"),
                  nextTooltip: props.t("EditEntries.NextPage"),
                  lastTooltip: props.t("EditEntries.LastPage"),
                },
                toolbar:{
                  nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                  searchPlaceholder: props.t("EditEntries.Search"),
                  searchTooltip: props.t("EditEntries.Search"),
                  exportTitle: props.t("EditEntries.Export"),
                  exportName: props.t("EditEntries.Export")
                },
                body: {
                  editRow: {
                    deleteText: ""
                  },
                  addTooltip: "Add row",
                  deleteToolTip: "Delete row",
                  editToolTip: "Edit row",
                  emptyDataSourceMessage: emptyDataMessage,
                  
                }
              }}
              isLoading={loadingPendingRegistrations}
            />
            </MuiThemeProvider>

            <div>{removeErrorMessage ? cannotRemoveItemMessage() : null}</div>
            <div>{duplicateIDErrorMessage ? cannotAddDuplicateIDMessage() : null}</div>
            <div>{IDGreaterThan0 ? IDGreaterThan0Message() : null}</div>
            {loadingPendingRegistrations ? (
            <div style={{ marginTop: 20 }}>
                        <Alert severity="info">
                        <b>
                          <em>{props.t("Database.PleaseWait")}</em>
                        </b>
                        {props.t("Database.TakeAWhile")}
                        </Alert>
                </div>) : null}
          </Paper>
        </div>
      </React.Fragment>
    );
  }

  if (redirect) {
    return <Redirect to={"/Register"} key={"ToRegisterFromDatabase"} />;
  } else {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <div className={classes.appBarPosition}>
            <AppBar position="static" style={{ boxShadow: "none", height: 64 }}>
              <Tabs
                classes={{root: classes.customTabs}}
                value={tab}
                onChange={(event, value) => {
                  handleChange(event, value);
                }}
                variant="scrollable"
                scrollButtons="on"
                TabIndicatorProps={{
                  style: {
                    height: tab === 0 ? "0px" : "2px"
                  }
                }}
              >
                {insertTabs()}

                
              </Tabs>
            </AppBar>
          </div>

          {dbSettings ? (
            renderDatabaseSettings()
          ) : (
            <div>
              {tab === 0 ? renderDatabaseHome() : null}
              {/* {tab === 8 ? renderDatabaseSettings() : null} */}
              {tab === 1 ? renderEmployeesTable() : null}
              {tab === 2 ? renderCustomersTable() : null}
              {tab === 3 ? renderDivisionsTable() : null}
              {tab === 4 ? renderActivitiesTable() : null}
              {tab === 5 ? renderTimesheetTable() : null}
              {tab === 6 ? renderPendingRegistrationsTable() : null}
            </div>
          )}
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] },
    secondary: { main: "#FFFFFF" }
  }
});

const theme2 = createMuiTheme({
  palette: {
    primary: { main: blue[500] },
    secondary: { main: blue[500] }
  },
  // overrides: {
  //   MuiTableCell: {
  //     root: {
  //       "&:hover": {
  //         backgroundColor: "rgba(33, 150, 243, 0.25)"
  //       }
  //     }
  //   },
  // }
});



const drawerWidth = 250;
const minToolbarHeight = 64;
const tabsHeight = 64;

const useStyles = makeStyles(theme => ({
  appBarPosition: {
    marginTop: minToolbarHeight,

    [theme.breakpoints.up("lg")]: {
      // marginLeft: `calc(${drawerWidth}px + 5%)`,
      marginLeft: drawerWidth,
      marginTop: minToolbarHeight
    }
  },
  rootAction: {
    paddingBottom: "0px",
    paddingTop: "0px"
  },
  table: {
    borderBottom: "0px"
  },
  customTabs: {
    "& .MuiTabScrollButton-root": {
      width: "32px",
    }
  },
  paperPosition: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    marginBottom: "16px",

    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth + 32,
      marginRight: "32px",
      marginTop: "32px",
      marginBottom: "32px",
    }
  },

  searchfield:{
    padding: "0px"
  },

  spacer:{
    flex: "1 1 0%"
  },

  paperPositionHome: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth + 32,
      marginRight: "32px",
      marginTop: "32px",
    }
  },

  paperPositionUser: {
    marginLeft: "10%",
    marginRight: "10%",
    marginTop: `calc(${minToolbarHeight}px + 5%)`
    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: `calc(${drawerWidth}px + 10%)`,
    //   marginRight: "10%",
    //   marginTop: `calc(${minToolbarHeight}px + 5%)`
    // }
  },

  toolbarRegular: {
    minHeight: 300
  },
  tab: {
    minHeight: 64
  },

  paperShape: {
    borderRadius: 8
  },
  paperShadow: {
    // boxShadow: "0 10px 45px -10px rgba(0, 0, 0, 0.2)"
  },
  paperProps: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // [theme.breakpoints.up("sm")]: {
      // paddingLeft: theme.spacing(5),
      // paddingRight: theme.spacing(5),
      // paddingTop: theme.spacing(5),
      // paddingBottom: theme.spacing(3)
    // }
  },
  paperPropsMobile: {
    padding: "16px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "32px",
      paddingRight: "32px",
      paddingTop: "32px",
      paddingBottom: "32px",
    }
  },
  test: {
    paddingRight: "0px",
    paddingLeft: "10px",
  },
  testDense: {
    paddingRight: "0px",
    paddingLeft: "2px",
  },
  t1: {
    width: 500
  },
  buttonProps: {
    marginTop: 23
  },
  badge: {
    backgroundColor: "#19222b",
    top: "-7px",
    right: "0px"
  },

  background: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    marginTop: -130,

    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      width: "100%",
    },

    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%"
    }
  },

  launchPage: {
    paddingLeft: "40px",
    paddingTop: `calc(${minToolbarHeight}px + 40px)`,

    [theme.breakpoints.up("lg")]: {
      paddingLeft: `calc(${drawerWidth}px + 100px)`,
      paddingTop: `calc(${minToolbarHeight}px + 40px)`
    }
  },
  tab1: {
    minWidth: 80,
    width: 80
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 500,
    paddingLeft: "0px",
    paddingTop: "0px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
      paddingLeft: "7px",
      paddingTop: "10px",
      fontWeight: 500
    }
  },
  subTitle: {
    fontSize: "1rem",
    marginBottom: 10, 
    paddingLeft: "0px", 
    paddingTop: "10px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 20, 
      fontSize: "1rem",
      paddingLeft: "10px", 
      paddingTop: "10px"
    }
  },
  iconStyle: {
    position: "relative",
    left: "10px",
    fontSize: "unset",
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      fontSize: "unset"
    }
  },
  tableImageStyle:{
    width: "70vmin",
    padding:"16px",
    [theme.breakpoints.up("sm")]:{
      width: "300px", 
      // padding: "32px",
    }
  },
  header:{
    fontSize: "5vmin"
  },
  cellStyling: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "0.875rem"
  },
  headerStyling: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "0.875rem"
  },
  cellStylingSmall: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "0.75rem"
  },
  headerStylingSmall: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "0.75rem"
  },
}));

export default withRouter(Database);
