/* eslint-disable no-useless-concat */
import DateFnsUtils from "@date-io/date-fns";
import { AppBar, Button, CircularProgress, createMuiTheme, Grid, MenuItem, Paper, Tab, Tabs, TextField, Typography, useMediaQuery } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import BuildIcon from "@material-ui/icons/Build";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Edit from "@material-ui/icons/Edit";
import DenseIcon from "@material-ui/icons/Reorder";
import SaveAlt from "@material-ui/icons/SaveAlt";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import Axios from "axios";
import { startOfMonth } from "date-fns";
import { endOfMonth, endOfWeek, endOfYear, startOfWeek, startOfYear } from "date-fns/esm";
import MaterialTable, { MTableBodyRow, MTableCell, MTableHeader, MTableToolbar } from "material-table";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import bgImage from "./../bgImagev5.svg";
import "./Repairs.css"

function Repairs(props) {
  props.currentPathCallbackProp(props.location.pathname);
  props.headerNameProp(props.t("Repairs.RepairsText"));

  const classes = useStyles(props);
  const bgColorTimesheetLabels = "#f3f3f3";
  const today = new Date();
  const tabLabels = [<BuildIcon />, props.t("Repairs.SubmittedQuantities"), props.t("Productivity.Totals")];
  const pageOptions = [5, 10, 25, 50, 100];
  const employeesGETRef = useRef(false);
  const repairsGETRef = useRef(false);
  const addressExtension = "/Repairs";
  const emptyDataMessage = props.t("Repairs.NotFound");
  const defaultPageNumbers = 10;
  const inputsm = useMediaQuery(theme.breakpoints.down("sm"));
  const minWidth = useMediaQuery("(min-width:400px)");
  const [tab, setTab] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [listOfEmployees, setListOfEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [totals, setTotals] = useState();
  const [denseMode, setDenseMode] = useState(false);
  const [repairsGETCalled, setRepairsGETCalled] = useState(false);
  const [repairsTotalsGETCalled, setRepairsTotalsGETCalled] = useState(false);
  const [inputData, setInputData] = useState({
    employeeName: "",
    startDate: new Date(today.getFullYear(), today.getMonth(), 1, 0),
    endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0, 0)
  });
  const [inputDataTotals, setInputDataTotals] = useState({
    startDate: new Date(today.getFullYear(), today.getMonth(), 1, 0),
    endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0, 0)
  });
  const [employeeInfo, setEmployeeInfo] = useState({
    employeeId: ""
  });
  const [repairsTableState, setRepairsTableState] = useState({
    columns: [
      { title: props.t("Repairs.ID"), field: "id", editable: "never" },
      { title: props.t("Repairs.DateStart"), field: "dateStart", editable: "never" },
      { title: props.t("Repairs.DateEnd"), field: "dateEnd", editable: "never" },
      { title: props.t("Repairs.Customer"), field: "customerName", editable: "never" },
      { title: props.t("Repairs.Division"), field: "divisionName", editable: "never" },
      { title: props.t("Repairs.Activity"), field: "activityName", editable: "never" },
      {
        title: props.t("Repairs.Quantity"),
        field: "quantity",
        type: "numeric"
        // cellStyle: {
        //   backgroundColor: "rgba(237, 237, 237, 1)"
        // }
      },
      { title: props.t("EditEntries.EditHistory"), field: "editHistory", editable: "never" }
    ],
    data: [],
    notFound: true
  });

  const [repairTotalsTableState, setRepairTotalsTableState] = useState({
    columns: [
      { title:  props.t("Productivity.EmployeeID"), field: "employeeID", editable: "never" },
      { title: props.t("Repairs.EmployeeName"), field: "employeeName", editable: "never" },
      { title: props.t("Repairs.NumberOfRepairs"), field: "editsAmount", editable: "never" }
    ],
    data: []
  });

  const opacity = 0.6;
  const colors = [
    `rgba(230, 25, 75, ${opacity})`,
    `rgba(60, 180, 75, ${opacity})`,
    `rgba(225, 225, 25, ${opacity})`,
    `rgba(67, 99, 216, ${opacity})`,
    `rgba(245, 130, 49, ${opacity})`,
    `rgba(145,30, 180, ${opacity})`,
    `rgba(66, 212, 244, ${opacity})`,
    `rgba(240, 50, 230, ${opacity})`,
    `rgba(191, 239, 69, ${opacity})`,
    `rgba(250, 190, 190, ${opacity})`,
    `rgba(70, 153, 144, ${opacity})`,
    `rgba(230, 190, 255, ${opacity})`,
    `rgba(154, 99, 36, ${opacity})`,
    `rgba(255, 250, 200, ${opacity})`,
    `rgba(128, 0, 0, ${opacity})`,
    `rgba(170, 255, 195, ${opacity})`,
    `rgba(128, 128, 0, ${opacity})`,
    `rgba(255, 216, 177, ${opacity})`,
    `rgba(0, 0, 117, ${opacity})`
  ];

  const themeTable = createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(33, 150, 243, 0.25)"
          }
        }
      }
    }
  });

  const [checked, setChecked] = React.useState(false);

  const tableTheme = createMuiTheme({
    palette: {
      primary: { main: blue[500] },
      secondary: { main: blue[500] }
    },
    overrides: {
      // MuiTableCell: {
      //   root: {
      //     "&:hover": {
      //       backgroundColor: "rgba(33, 150, 243, 0.25)"
      //     }
      //   }
      // },
      MuiIconButton: {
        root: {
          padding: denseMode ? "0x" : "9px"
        }
      },
      PrivateSwitchBase: {
        root: {
          padding: denseMode ? "0px" : "9px"
        }
      }
    }
  });

  function handleChange2() {
    setChecked(prev => !prev);
  }

  useEffect(() => {
    setLoadingTableData(false);
  }, [repairsTableState]);

  async function employeesGET() {
    const address = props.baseAddressProp + "/Database" + "/GetEmployeesInfo";
    try {
      let result = await Axios.get(address);
      setListOfEmployees(result.data);
    } catch (error) {}
  }

  async function repairsGET(employeeId, timeStart, timeEnd) {
    if (!timeStart || !timeEnd || isNaN(timeStart.getTime()) || isNaN(timeStart.getTime())) return null;
    setRepairsGETCalled(true)
    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address =
      props.baseAddressProp + "/Repairs" + "/GetEmployeeRepairInfoTime" + "?employeeId=" + employeeId + "&timeStart=" + timeStart + "&timeEnd=" + timeEnd;

    setLoadingTableData(true);
    try {
      let result = await Axios.get(address);
      let repairsTable = { ...repairsTableState };
      repairsTable.data = result.data;
      for (let i = 0; i < result.data.length; i++) {
        let amounts = [];
        let amountsString;
        for (let j = 0; j < result.data[i].editHistory.length; j++) {
          let value = result.data[i].editHistory[j].amount;
          if (value) {
            amounts.push(value);
            if (!amountsString) {
              amountsString = value.toString();
            } else {
              amountsString = amountsString + ", " + value.toString();
            }
          }
        }
        if (amounts.length <= 1) {
          amountsString = "";
        }
        repairsTable.data[i].editHistory = amountsString;
      }
      repairsTable.notFound = false
      setRepairsTableState(repairsTable);
    } catch (error) {
      let repairsTable = { ...repairsTableState };
      repairsTable.data = [];
      repairsTable.notFound = true
      setRepairsTableState(repairsTable);
    }
  }

  async function repairsTotalsGET(timeStart, timeEnd) {
    if (!timeStart || !timeEnd || isNaN(timeStart.getTime()) || isNaN(timeStart.getTime())) return null;

    setRepairsTotalsGETCalled(true)
    timeStart = timeStart.toLocaleDateString("nl-NL");
    timeEnd = timeEnd.toLocaleDateString("nl-NL");
    const address = props.baseAddressProp + "/Repairs" + "/GetTotalRepairsPerEmployee" + "?timeStart=" + timeStart + "&timeEnd=" + timeEnd;

    setLoadingTableData(true);
    try {
      let result = await Axios.get(address);
      let repairTotalsTable = { ...repairTotalsTableState };
      repairTotalsTable.data = result.data;
      (repairTotalsTable.data).sort((a, b)=>b.editsAmount - a.editsAmount);
      setRepairTotalsTableState(repairTotalsTable);
    } catch (error) {
      let repairTotalsTable = { ...repairTotalsTableState };
      repairTotalsTable.data = [];
      // repairTotalsTable.notFound = true
      setRepairTotalsTableState(repairTotalsTable);
    }
    setLoadingTableData(false);
  }

  async function timesheetTableEntryPOST(newData) {
    const address = props.baseAddressProp + addressExtension + "/PostTimesheetTableEntry";
    try {
      await Axios.post(address, {
        TimesheetId: newData.id,
        Quantity: newData.quantity
      });
      repairsGET(employeeInfo.employeeId, inputData.startDate, inputData.endDate);
    } catch (error) {}
  }

  async function timesheetTableEntryDELETE(timesheetId, data) {
    const address = props.baseAddressProp + "/Database" + "/DeleteTimesheetInfo" + "?timesheetId=" + timesheetId;
    try {
      await Axios.delete(address);
      setRepairsTableState({ ...repairsTableState, data });
      setShowSnackbar(true);
    } catch (error) {
    }
  }

  function handleChange(event, newValue) {
    setTab(newValue);
  }

  function handleInputDataChange(event, name) {
    setEmployeeInfo(event.target.value);
    setInputData({ ...inputData, [name]: event.target.value });
  }

  function handleStartDatePickerChange(date) {
    setInputData({ ...inputData, ["startDate"]: date });
  }

  function handleEndDatePickerChange(date) {
    setInputData({ ...inputData, ["endDate"]: date });
  }

  function handleStartDatePickerTotalsChange(date) {
    setInputDataTotals({ ...inputDataTotals, ["startDate"]: date });
  }

  function handleEndDatePickerTotalsChange(date) {
    setInputDataTotals({ ...inputDataTotals, ["endDate"]: date });
  }

  function handleStartEndDatePickerChange(start, end) {
    let data = { ...inputData };
    data["startDate"] = start;
    data["endDate"] = end;
    setInputData(data);
  }

  function insertTabs() {
    let items = [];
    for (let i = 0; i < tabLabels.length; i++) {
      if (i === 0) {
        items.push(<Tab label={tabLabels[i]} className={classes.tab} key={i} classes={{ root: classes.tab1 }} />);
      } else {
        items.push(<Tab label={tabLabels[i]} className={classes.tab} key={i} />);
      }
    }
    return items;
  }

  function insertMenuItems(arg) {
    let items = [];
    for (let i = 0; i < arg.length; i++) {
      items.push(
        <MenuItem value={arg[i]} key={i}>
          {`[${arg[i].employeeId}] ${arg[i].firstName} ${arg[i].lastName}`}
        </MenuItem>
      );
    }
    return items;
  }

  function weekMonthYear(employeeId, startDate, endDate) {
    if (!employeeId) {
      return null;
    }
    // dailyProductivityGET(employeeId, startDate, endDate);
    // productivityPerTaskGET(employeeId, startDate, endDate);

    //timesheet get with date etc..
  }

  function renderRepairs() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <div className={classes.appBarPosition}>
            <AppBar position="static" style={{ boxShadow: "none", minHeight: 64 }}>
              <Tabs
                classes={{root: classes.customTabs}}
                value={tab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                TabIndicatorProps={{
                  style: {
                    height: tab === 0 ? "0px" : "2px"
                  }
                }}
              >
                {insertTabs()}
              </Tabs>
            </AppBar>
          </div>
          {tab === 0 ? renderLaunchPage() : null}
          {tab === 1 ? renderRepairsPage() : null}
          {tab === 2 ? renderTotalsPage() : null}
        </ThemeProvider>
      </div>
    );
  }

  function closeSnackbarCallback() {
    setShowSnackbar(false);
  }

  function renderLaunchPage() {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.background}>
          <div className={classes.paperPositionHome}>
            <Paper
              className={inputsm ? classes.paperPropsMobile : classes.paperProps}
              classes={{
                rounded: classes.paperShape,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h2" className={classes.title}>
                    {props.t("Repairs.RepairsText")}
                    <BuildIcon className={classes.iconStyle} />
                  </Typography>

                  <Typography variant="h5" className={classes.subTitle}>
                    {props.t("Repairs.AlterQuantities")}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </div>
      </ThemeProvider>
    );
  }

  function renderRepairsPage() {
    if (!employeesGETRef.current) {
      employeesGET();
      employeesGETRef.current = true;
    }
    return (
      <div className={classes.paperPosition}>
        {inputsm ? (
          // #region MOBILE
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                className={inputsm ? classes.paperPropsMobile : classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                      <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin" }}>
                        {props.t("Repairs.FilterSettings")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                      <TextField
                        fullWidth
                        select
                        label={props.t("Repairs.Employee")}
                        margin="dense"
                        value={inputData.employeeName}
                        onChange={event => handleInputDataChange(event, "employeeName")}
                        variant="outlined"
                      >
                        {insertMenuItems(listOfEmployees)}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                      <KeyboardDatePicker
                        fullWidth
                        id="mui-pickers-date"
                        label={props.t("Repairs.StartDate")}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="dense"
                        value={inputData.startDate}
                        onChange={(event, date) => {
                          handleStartDatePickerChange(event, date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <KeyboardDatePicker
                        fullWidth
                        id="mui-pickers-date"
                        label={props.t("Repairs.EndDate")}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="dense"
                        value={inputData.endDate}
                        onChange={(event, date) => {
                          handleEndDatePickerChange(event, date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>

                <Grid container style={{ paddingTop: "12px" }}>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={event => {
                        const today = new Date();
                        const start = startOfWeek(today, { weekStartsOn: 1 });
                        const end = endOfWeek(today, { weekStartsOn: 1 });
                        setInputData({...inputData, 
                          startDate: start,
                          endDate: end
                        });
                      }}
                    >
                      {props.t("Repairs.Week")}
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={event => {
                        const today = new Date();
                        const start = startOfMonth(today);
                        const end = endOfMonth(today);
                        setInputData({...inputData, 
                          startDate: start,
                          endDate: end
                        });
                      }}
                    >
                      {props.t("Repairs.Month")}
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={event => {
                        const today = new Date();
                        const start = startOfYear(today);
                        const end = endOfYear(today);
                        setInputData({...inputData, 
                          startDate: start,
                          endDate: end
                        });
                      }}
                    >
                      {props.t("Repairs.Year")}
                    </Button>
                  </Grid>
                </Grid>

                <div className="search-button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    className="no-shadow"
                    disabled={loadingTableData || !employeeInfo.employeeId}
                    onClick={event => {
                      repairsGET(employeeInfo.employeeId, inputData.startDate, inputData.endDate)
                    }}>
                    {props.t("Productivity.Search")}
                  </Button>
                </div>
              </Paper>
            </Grid>

            {!inputData.employeeName || !repairsGETCalled ? (
              <Grid item xs={12}>
                <Paper
                  className={inputsm ? classes.paperPropsMobile : classes.paperProps}
                  classes={{
                    rounded: classes.paperShape,
                  }}
                >
                  <Typography variant="h5" className={classes.subTitleText}>
                    {props.t("Repairs.NoEmployeeSelected")}
                  </Typography>
                </Paper>
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item xs={12}>
                  <Paper
                    className={inputsm ? classes.paperPropsMobile : classes.paperProps}
                    classes={{
                      rounded: classes.paperShape,
                    }}
                  >
                    {loadingTableData ? (
                      <Grid item xs={12} align="center">
                        <div style={{ position: "relative" }}>
                          <CircularProgress />
                        </div>
                      </Grid>
                    ) : (
                      <React.Fragment>
                        {repairsGETCalled && repairsTableState.data.length == 0 ? (
                          <Grid item xs={12}>
                            <Typography variant="h5" className={classes.subTitleText}>
                              {props.t("Repairs.NotFound")}
                            </Typography>
                          </Grid>) : (
                        <Grid item xs={12}>
                          {renderTable(repairsTableState.data)}
                        </Grid>)}
                      </React.Fragment>
                    )}

                    {loading ? (
                      <Grid item xs={12} align="center">
                        <div style={{ position: "relative" }}>
                          <CircularProgress />
                        </div>
                      </Grid>
                    ) : (
                      <React.Fragment />
                    )}
                  </Paper>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        ) : (
          // #endregion
          // #region DESKTOP
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Paper
                className={classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{props.t("Repairs.FilterSettings")}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        select
                        label={props.t("Repairs.Employee")}
                        value={inputData.employeeName}
                        onChange={event => handleInputDataChange(event, "employeeName")}
                        variant="outlined"
                      >
                        {insertMenuItems(listOfEmployees)}
                      </TextField>
                    </Grid>

                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        id="mui-pickers-date"
                        label={props.t("Repairs.StartDate")}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        value={inputData.startDate}
                        onChange={(event, date) => {
                          handleStartDatePickerChange(event, date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        id="mui-pickers-date"
                        label={props.t("Repairs.EndDate")}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        value={inputData.endDate}
                        onChange={(event, date) => {
                          handleEndDatePickerChange(event, date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>

                <Grid container style={{marginTop: "8px"}}>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={event => {
                        const today = new Date();
                        const start = startOfWeek(today, { weekStartsOn: 1 });
                        const end = endOfWeek(today, { weekStartsOn: 1 });
                        setInputData({...inputData, 
                          startDate: start,
                          endDate: end
                        });
                      }}
                    >
                      {props.t("Repairs.Week")}
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={event => {
                        const today = new Date();
                        const start = startOfMonth(today);
                        const end = endOfMonth(today);
                        setInputData({...inputData, 
                          startDate: start,
                          endDate: end
                        });
                      }}
                    >
                      {props.t("Repairs.Month")}
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={event => {
                        const today = new Date();
                        const start = startOfYear(today);
                        const end = endOfYear(today);
                        setInputData({...inputData, 
                          startDate: start,
                          endDate: end
                        });
                      }}
                    >
                      {props.t("Repairs.Year")}
                    </Button>
                  </Grid>
                </Grid>

                <div className="search-button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    className="no-shadow button-padding"
                    disabled={loadingTableData || !employeeInfo.employeeId}
                    onClick={event => {
                      repairsGET(employeeInfo.employeeId, inputData.startDate, inputData.endDate)
                    }}>
                    {props.t("Productivity.Search")}
                  </Button>
                </div>
              </Paper>
            </Grid>

            {!inputData.employeeName || !repairsGETCalled ? (
              <Grid item xs={12}>
                <Paper
                  className={classes.paperProps}
                  classes={{
                    rounded: classes.paperShape,
                  }}
                >
                  <Typography variant="h5" className={classes.subTitleText}>
                    {props.t("Repairs.NoEmployeeSelected")}
                  </Typography>
                </Paper>
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item xs={12}>
                  <Paper
                    className={classes.paperProps}
                    classes={{
                      rounded: classes.paperShape,
                    }}
                  >
                    {loadingTableData ? (
                      <Grid item xs={12} align="center">
                        <div style={{ position: "relative" }}>
                          <CircularProgress />
                        </div>
                      </Grid>
                    ) : (
                      <React.Fragment>
                        {repairsGETCalled && repairsTableState.data.length == 0 ? (
                          <Grid item xs={12}>
                            <Typography variant="h5" className={classes.subTitleText}>
                              {props.t("Repairs.NotFound")}
                            </Typography>
                          </Grid>) : (
                        <Grid item xs={12}>
                          {renderTable(repairsTableState.data)}
                        </Grid>)}
                      </React.Fragment>
                    )}

                    {loading ? (
                      <Grid item xs={12} align="center">
                        <div style={{ position: "relative" }}>
                          <CircularProgress />
                        </div>
                      </Grid>
                    ) : (
                      <React.Fragment />
                    )}
                  </Paper>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
          // #endregion
        )}
      </div>
    );
  }

  function renderTotalsPage() {
    return (
      <div className={classes.paperPosition}>
        {inputsm ? (
          // #region MOBILE
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                className={inputsm ? classes.paperPropsMobile : classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                      <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin" }}>
                        {props.t("Repairs.FilterSettings")}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                      <KeyboardDatePicker
                        fullWidth
                        id="mui-pickers-date"
                        label={props.t("Repairs.StartDate")}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="dense"
                        value={inputDataTotals.startDate}
                        onChange={(event, date) => {
                          handleStartDatePickerTotalsChange(event, date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <KeyboardDatePicker
                        fullWidth
                        id="mui-pickers-date"
                        label={props.t("Repairs.EndDate")}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="dense"
                        value={inputDataTotals.endDate}
                        onChange={(event, date) => {
                          handleEndDatePickerTotalsChange(event, date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>

                <Grid container style={{ paddingTop: "12px" }}>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={event => {
                        const today = new Date();
                        const start = startOfWeek(today, { weekStartsOn: 1 });
                        const end = endOfWeek(today, { weekStartsOn: 1 });
                        setInputDataTotals({...inputDataTotals, 
                          startDate: start,
                          endDate: end
                        });
                      }}
                    >
                      {props.t("Repairs.Week")}
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={event => {
                        const today = new Date();
                        const start = startOfMonth(today);
                        const end = endOfMonth(today);
                        setInputDataTotals({...inputDataTotals, 
                          startDate: start,
                          endDate: end
                        });
                      }}
                    >
                      {props.t("Repairs.Month")}
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={event => {
                        const today = new Date();
                        const start = startOfYear(today);
                        const end = endOfYear(today);
                        setInputDataTotals({...inputDataTotals, 
                          startDate: start,
                          endDate: end
                        });
                      }}
                    >
                      {props.t("Repairs.Year")}
                    </Button>
                  </Grid>
                </Grid>

                <div className="search-button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    className="no-shadow"
                    disabled={loadingTableData}
                    onClick={event => {
                      repairsTotalsGET(inputDataTotals.startDate, inputDataTotals.endDate)
                    }}>
                    {props.t("Productivity.Search")}
                  </Button>
                </div>
              </Paper>
            </Grid>

            {!repairsTotalsGETCalled ? (
              <Grid item xs={12}>
                <Paper
                  className={inputsm ? classes.paperPropsMobile : classes.paperProps}
                  classes={{
                    rounded: classes.paperShape,
                  }}
                >
                  <Typography variant="h5" className={classes.subTitleText}>
                    {props.t("Repairs.PressSearch")}
                  </Typography>
                </Paper>
              </Grid>
            ) : (

            <React.Fragment>
              <Grid item xs={12}>
                <Paper
                  className={inputsm ? classes.paperPropsMobile : classes.paperProps}
                  classes={{
                    rounded: classes.paperShape,
                  }}
                >
                  {loadingTableData ? (
                    <Grid item xs={12} align="center">
                      <div style={{ position: "relative" }}>
                        <CircularProgress />
                      </div>
                    </Grid>
                  ) : (
                    <React.Fragment>
                      {repairsTotalsGETCalled && repairTotalsTableState.data.length == 0 ? (
                        <Grid item xs={12}>
                          <Typography variant="h5" className={classes.subTitleText}>
                            {props.t("Repairs.NotFound")}
                          </Typography>
                        </Grid>) : (
                        <Grid item xs={12}>
                          {renderTotalsChart()}
                        </Grid>)}
                    </React.Fragment>
                  )}

                  {loading ? (
                    <Grid item xs={12} align="center">
                      <div style={{ position: "relative" }}>
                        <CircularProgress />
                      </div>
                    </Grid>
                  ) : (
                    <React.Fragment />
                  )}
                </Paper>
              </Grid>
            </React.Fragment>
            )}
          </Grid>
        ) : (
          // #endregion
          // #region DESKTOP
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Paper
                className={classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{props.t("Repairs.FilterSettings")}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        id="mui-pickers-date"
                        label={props.t("Repairs.StartDate")}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        value={inputDataTotals.startDate}
                        onChange={(event, date) => {
                          handleStartDatePickerTotalsChange(event, date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        id="mui-pickers-date"
                        label={props.t("Repairs.EndDate")}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        value={inputDataTotals.endDate}
                        onChange={(event, date) => {
                          handleEndDatePickerTotalsChange(event, date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>

                <Grid container style={{marginTop: "8px"}}>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={event => {
                        const today = new Date();
                        const start = startOfWeek(today, { weekStartsOn: 1 });
                        const end = endOfWeek(today, { weekStartsOn: 1 });
                        setInputDataTotals({...inputDataTotals, 
                          startDate: start,
                          endDate: end
                        });
                      }}
                    >
                      {props.t("Repairs.Week")}
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={event => {
                        const today = new Date();
                        const start = startOfMonth(today);
                        const end = endOfMonth(today);
                        setInputDataTotals({...inputDataTotals, 
                          startDate: start,
                          endDate: end
                        });
                      }}
                    >
                      {props.t("Repairs.Month")}
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={event => {
                        const today = new Date();
                        const start = startOfYear(today);
                        const end = endOfYear(today);
                        setInputDataTotals({...inputDataTotals, 
                          startDate: start,
                          endDate: end
                        });
                      }}
                    >
                      {props.t("Repairs.Year")}
                    </Button>
                  </Grid>
                </Grid>

                <div className="search-button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    className="no-shadow button-padding"
                    disabled={loadingTableData}
                    onClick={event => {
                      repairsTotalsGET(inputDataTotals.startDate, inputDataTotals.endDate)
                    }}>
                    {props.t("Productivity.Search")}
                  </Button>
                </div>
              </Paper>
            </Grid>

            {!repairsTotalsGETCalled ? (
              <Grid item xs={12}>
                <Paper
                  className={inputsm ? classes.paperPropsMobile : classes.paperProps}
                  classes={{
                    rounded: classes.paperShape,
                  }}
                >
                  <Typography variant="h5" className={classes.subTitleText}>
                    {props.t("Repairs.PressSearch")}
                  </Typography>
                </Paper>
              </Grid>
            ) : (

            <React.Fragment>
              <Grid item xs={12}>
                <Paper
                  className={classes.paperProps}
                  classes={{
                    rounded: classes.paperShape,
                  }}
                >
                  {loadingTableData ? (
                    <Grid item xs={12} align="center">
                      <div style={{ position: "relative" }}>
                        <CircularProgress />
                      </div>
                    </Grid>
                  ) : (
                    <React.Fragment>
                      {repairsTotalsGETCalled && repairTotalsTableState.data.length == 0 ? (
                        <Grid item xs={12}>
                          <Typography variant="h5" className={classes.subTitleText}>
                            {props.t("Repairs.NotFound")}
                          </Typography>
                        </Grid>) : (
                        <Grid item xs={12}>
                          {renderTotalsChart()}
                        </Grid>)}
                    </React.Fragment>
                  )}

                  {loading ? (
                    <Grid item xs={12} align="center">
                      <div style={{ position: "relative" }}>
                        <CircularProgress />
                      </div>
                    </Grid>
                  ) : (
                    <React.Fragment />
                  )}
                </Paper>
              </Grid>
            </React.Fragment>
            )}
          </Grid>
          // #endregion
        )}
      </div>
    );
  }

  function renderTotalsChart() {
    // if(!repairTotalsTableState.data 
    //   || (Array.isArray(repairTotalsTableState.data) && repairTotalsTableState.data.length === 0)) {
    //   return (
    //     <React.Fragment>
    //       <Typography variant="body2">
    //         {`${props.t("Repairs.NotFound")}`}
    //       </Typography>
    //     </React.Fragment>
    //   )
    // }

    return (
      <React.Fragment>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={repairTotalsTableState.data}
            margin={{
              top: 0,
              right: 25,
              left: 10,
              bottom: 30
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="id">
              <Label
                width={30}
                position="center"
                content={
                  <text x="50%" y="280" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                    <tspan alignmentBaseline="middle" fontSize="14">
                      {props.t("Productivity.EmployeeID").toUpperCase()}
                    </tspan>
                  </text>
                }
              />
            </XAxis>
            <YAxis>
              <Label
                position="center"
                content={
                  <text x="0" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                    <tspan x="36" dy="1.5em">
                      { props.t("Repairs.NumberOfRepairs")}
                    </tspan>
                  </text>
                }
              />
            </YAxis>
            <Tooltip content={<TotalsTooltip />} />
            <Legend
              payload={[
                {
                  id: "Repairs per employee",
                  type: "square",
                  value: props.t("Repairs.RepairsPerEmployee"),
                  color: colors[0]
                }
              ]}
              verticalAlign="top"
              height={36}
            />

            <Bar dataKey="editsAmount" fill={colors[0]}>
              {/* {dataHolder[item].map((entry, index) => {
                                return <Cell fill={colors[index]} key={colors[index]} />;
                              })} */}
            </Bar>
          </BarChart>
        </ResponsiveContainer>

        <div style={{ overflowX: "auto" }}>
       
            <MaterialTable
              components={{
                Toolbar: props => (
                  <MTableToolbar
                    classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }}
                    {...props}
                  />
                ),
                Cell: props => <MTableCell classes={{ root: inputsm ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
                Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />
              }}
              columns={repairTotalsTableState.columns}
              data={repairTotalsTableState.data}
              
              onRowClick={(event, row) => {}}
              options={{
                exportButton: true,
                filtering: false,
                search: true,
                sorting: true,
                pageSize: 5,
                pageSizeOptions: pageOptions,
                padding: "dense",
                showTitle: false,
                rowStyle: (rowData, index) => {
                  return { backgroundColor: index % 2 === 0 ? "rgba(242, 242, 242, 1)" : "#FFFFFF" };
                }
              }}
              style={{ boxShadow: "none" }}
              localization={{
                pagination: {
                  labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
                  labelRowsSelect: props.t("Database.Rows"),
                  firstTooltip: props.t("EditEntries.FirstPage"),
                  previousTooltip: props.t("EditEntries.PreviousPage"),
                  nextTooltip: props.t("EditEntries.NextPage"),
                  lastTooltip: props.t("EditEntries.LastPage")
                },
                toolbar: {
                  nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
                  searchPlaceholder: props.t("EditEntries.Search"),
                  searchTooltip: props.t("EditEntries.Search"),
                  exportTitle: props.t("EditEntries.Export"),
                  exportName: props.t("EditEntries.Export")
                },
                body: {
                  editRow: {
                    deleteText: ""
                  },
                  addTooltip: "Add row",
                  deleteToolTip: "Delete row",
                  editToolTip: "Edit row",
                  emptyDataSourceMessage: emptyDataMessage
                }
              }}
            />
    
        </div>
      </React.Fragment>
    );
  }

  const TotalsTooltip = ({ active, payload, label }) => {
    if (!repairTotalsTableState.data || !payload) return null;

    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid rgba(225, 225, 225, 1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            padding: "10px"
          }}
        >
          <Typography variant="button" style={{ color: blue[500], textTransform: "capitalize" }} className="label">{`[${payload[0].payload.employeeID}] ${
            payload[0].payload.employeeName
          }
          `}</Typography>
          <br />
          <Typography variant="caption" style={{ color: colors[0], fontWeight: 600 }}>
            {" "}
            {`${props.t("Repairs.NumberOfRepairs")}: ${payload[0].payload.editsAmount}`}
          </Typography>
        </div>
      );
    }
    return null;
  };

  function renderTable(tableData) {
    return (
      <MuiThemeProvider theme={tableTheme}>
        <MaterialTable
          components={{
            Toolbar: props => (
              <MTableToolbar
                classes={{ root: classes.test, searchField: inputsm ? classes.searchfield : "unset", spacer: inputsm ? classes.spacer : "unset" }}
                {...props}
              />
            ),
            Cell: props => <MTableCell classes={{ root: inputsm || denseMode ? classes.cellStylingSmall : classes.cellStyling }} {...props} />,
            Header: props => <MTableHeader classes={{ header: inputsm ? classes.headerStylingSmall : classes.cellStyling }} {...props} />,
            Row: props => (
              <React.Fragment>
                <MTableBodyRow {...props} />
              </React.Fragment>
            )
          }}
          title={props.t("Repairs.SubmittedQuantities").toUpperCase()}
          columns={repairsTableState.columns}
          data={repairsTableState.data}
          icons={{
            Edit: forwardRef((props, ref) => (
              <Edit {...props} ref={ref} style={{ color: "rgb(117, 117, 117)", paddingBottom: "0px", paddingTop: "0px" }} />
            )),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={{ margin: denseMode ? "9px" : "0px" }} />),
            Delete: forwardRef((props, ref) => <DeleteForever {...props} ref={ref} style={{ color: "rgb(117, 117, 117)" }} />)
          }}
          actions={[
            {
              tooltip: props.t("Database.DenseMode"),
              icon: () => <DenseIcon style={{ color: denseMode ? "rgb(34, 150, 243)" : "rgb(117, 117, 117)", margin: denseMode ? "9px" : "0px" }} />,
              isFreeAction: true,
              onClick: (event, tableData) => {
                let dense = denseMode;
                setDenseMode(!dense);
              }
            }
          ]}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...repairsTableState.data];
                const index = data.indexOf(oldData);
                data[index] = newData;
                setRepairsTableState({ ...repairsTableState, data });
                timesheetTableEntryPOST(newData);
                //repairsTablePOST()
                resolve();
              }),

            onRowDelete: oldData =>
            new Promise(resolve => {
              resolve();
              const data = [...repairsTableState.data];
              data.splice(data.indexOf(oldData), 1);
              timesheetTableEntryDELETE(oldData.id, data);
            })
          }}
          onRowClick={(event, row) => {
            let history = showHistory;
            setShowHistory(!history);
            setCurrentRow(row.id);
          }}
          options={{
            exportButton: true,
            filtering: false,
            search: true,
            sorting: true,
            showTitle: inputsm ? false : true,
            pageSize: defaultPageNumbers,
            pageSizeOptions: pageOptions,
            rowStyle: (rowData, index) => {
              //return { backgroundColor: index % 2 === 0 ? "rgba(242, 242, 242, 1)" : "#FFFFFF" }; //rowData.editHistory ? "rgba(33, 150, 243, 0.25)" :
              return {
                background:
                  index % 2 === 0
                    ? rowData.editHistory
                      ? "linear-gradient(45deg, rgba(242, 242, 242,1), rgba(85, 243, 33, 0.3))"
                      : "rgba(242, 242, 242, 1)"
                    : rowData.editHistory
                    ? "linear-gradient(45deg, rgba(255, 255, 255,1), rgba(85, 243, 33, 0.3))"
                    : "rgba(255, 255, 255, 1)"
              }; //rowData.editHistory ? "rgba(33, 150, 243, 0.25)" :
            }
          }}
          style={{ boxShadow: "none" }}
          localization={{
            header: {
              actions: props.t("EditEntries.Actions")
            },
            pagination: {
              labelDisplayedRows: `{from}-{to} ${props.t("EditEntries.Of").toLowerCase()} {count}`,
              labelRowsSelect: props.t("Database.Rows"),
              firstTooltip: props.t("EditEntries.FirstPage"),
              previousTooltip: props.t("EditEntries.PreviousPage"),
              nextTooltip: props.t("EditEntries.NextPage"),
              lastTooltip: props.t("EditEntries.LastPage")
            },
            toolbar: {
              nRowsSelected: inputsm ? "" : `${props.t("Database.ItemsSelected")} {0}.`,
              searchPlaceholder: props.t("EditEntries.Search"),
              searchTooltip: props.t("EditEntries.Search"),
              exportTitle: props.t("EditEntries.Export"),
              exportName: props.t("EditEntries.Export")
            },
            body: {
              editRow: {
                deleteText: ""
              },
              addTooltip: "Add row",
              deleteToolTip: "Delete row",
              editToolTip: "Edit row",
              emptyDataSourceMessage: emptyDataMessage
            }
          }}
          isLoading={loading}
        />
      </MuiThemeProvider>
    );
  }

  return renderRepairs();
}

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] },
    secondary: { main: "#FFFFFF" }
  }
});

const drawerWidth = 250;
const minToolbarHeight = 64;
const tabsHeight = 64;

const useStyles = makeStyles(theme => ({
  cellStyling: {
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: "0.875rem"
  },
  headerStyling: {
    paddingTop: "0px",
    paddingBottom: "5px",
    fontSize: "0.875rem"
  },
  cellStylingSmall: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "0.75rem"
  },
  headerStylingSmall: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "0.75rem"
  },
  tab: {
    minHeight: 64
  },
  appBarPosition: {
    marginTop: minToolbarHeight,

    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth,
      marginTop: minToolbarHeight
    }
  },
  launchPage: {
    paddingLeft: "40px",
    paddingTop: `calc(${minToolbarHeight}px + 40px)`,

    [theme.breakpoints.up("lg")]: {
      paddingLeft: `calc(${drawerWidth}px + 100px)`,
      paddingTop: `calc(${minToolbarHeight}px + 40px)`
    }
  },
  background: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    marginTop: -130,

    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      width: "100%"
    },

    [theme.breakpoints.up("md")]: {
      width: "100%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%"
    }
  },
  paperPosition: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    marginBottom: "16px",
    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth + 32,
      marginRight: "32px",
      marginTop: "32px",
      marginBottom: "32px",
    }
  },
  paperPositionHome: {
    marginLeft: "16px",
    marginRight: "16px",
    paddingTop: `calc(${minToolbarHeight}px + ${tabsHeight}px)`,

    [theme.breakpoints.up("lg")]: {
      marginLeft: `calc(${drawerWidth}px + 32px)`,
      marginRight: "32px",
      paddingTop: `calc(${minToolbarHeight}px + ${tabsHeight}px)`
    }
  },
  paperShape: {
    borderRadius: 8
  },
  paperProps: {
    padding: "32px",
    [theme.breakpoints.up("sm")]: {
      padding: "32px"
    }
  },
  paperPropsMobile: {
    padding: "16px",
    [theme.breakpoints.up("sm")]: {
      padding: "32px",
    }
  },
  tab1: {
    minWidth: 80,
    width: 80
  },
  buttonProgress: {
    position: "absolute"
  },
  bg: {
    backgroundColor: "#FFFFFF"
  },
  titlePadding: {
    paddingRight: 0,
    paddingLeft: 0
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 500,
    paddingLeft: "0px",
    paddingTop: "0px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
      paddingLeft: "7px",
      paddingTop: "10px",
      fontWeight: 500
    }
  },
  subTitle: {
    fontSize: "1rem",
    marginBottom: 10, 
    paddingLeft: "0px", 
    paddingTop: "10px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 20, 
      fontSize: "1rem",
      paddingLeft: "10px", 
      paddingTop: "10px"
    }
  },
  subTitleText: {
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    }
  },
  customTabs: {
    "& .MuiTabScrollButton-root": {
      width: "32px",
    }
  },
  iconStyle: {
    position: "relative",
    left: "10px",
    fontSize: "unset",
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      fontSize: "unset"
    }
  },

  searchfield: {
    padding: "0px"
  },

  spacer: {
    flex: "1 1 0%"
  },
  test: {
    paddingLeft: "0px"
  }
}));

export default withRouter(Repairs);
