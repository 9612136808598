import CssBaseline from "@material-ui/core/CssBaseline";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { BrowserRouter, Switch, useLocation } from "react-router-dom";
import i18n from "../i18n";
import AnnualLeave from "./AnnualLeave";
import Database from "./Database";
import EditEntries from "./EditEntries";
import Header from "./Header";
import Home from "./Home";
import Login from "./Login";
import MyAccount from "./MyAccount";
import PasswordRecovery from "./PasswordRecovery";
import PrivateRoute from "./PrivateRoute";
import Productivity from "./Productivity";
import PublicRoute from "./PublicRoute";
import Register from "./Register";
import RegisterRoute from "./RegisterRoute";
import Repairs from "./Repairs";
import ResetPassword from "./ResetPassword";
import SidePanel from "./SidePanel";
import Timesheet from "./Timesheet";
import RerouteUnauthenticated from "./RerouteUnauthenticated"

function Dashboard({ t }) {
  const [headerName, setHeaderName] = useState("");

  function headerNameCallback(arg) {
    setHeaderName(arg);
  }

  function displayLoggedInComponents() {
    const item = sessionStorage?.getItem("userData")
    const userData = item ? JSON.parse(sessionStorage?.getItem("userData")) : {}
    const keys = Object.keys(userData)
    const necessaryKeys = ["dob", "email", "firstName", "lastName", "id", "role", "token", "userName"]
    let display = true
    for(const i of necessaryKeys) {
      let found = false
      for(const j of keys) {
        if(i == j) {
          found = true
        }
      }
      if(!found) {
        display = false
      }
    }
    return display
  }

  const drawerWidth = 250;
  const drawerColor = "#1b9be2";
  const minToolbarHeight = 64;
  const baseAddress = "/api";
  const mainThemeColor = "#1b9be2";

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [darkTheme, setDarkTheme] = useState(false);
  const [token, setToken] = useState("");
  const [loggedIn, setLoggedIn] = useState(displayLoggedInComponents());
  const [currentPath, setCurrentPath] = useState("");
  const [userData, setUserData] = useState({});
  const [holidays, setHolidays] = useState([]);

  // useEffect(() => {
  //   sessionStorage.setItem("loggedIn", JSON.stringify(loggedIn));
  // }, [loggedIn]);

  useEffect(() => {
    window.scrollTo(0, 0)
    const item = sessionStorage?.getItem("userData")
    if(item) {
      setUserData(JSON.parse(item))
    }
  }, [])

  useEffect(() => {
    sessionStorage.setItem("userData", JSON.stringify(userData));
  }, [userData]);

  function changeLanguage(language) {
    i18n.changeLanguage(language);
  }

  function tokenCallback(token) {
    setToken(token);
  }

  function loggedInCallback(value) {
    setLoggedIn(value);
  }

  function currentPathCallback(path) {
    setCurrentPath(path);
  }

  function userDataCallback(data) {
    if(Object.keys(data ?? {}).length > 0) {
      setUserData({
        id: data.id,
        userName: data.userName,
        firstName: data.firstName,
        lastName: data.lastName,
        role: data.role,
        token: data.token,
        paidLeaveDays: data.paidLeaveDays,
        totalPaidLeaveDays: data.totalPaidLeaveDays,
        dob: data.dob,
        email: data.email
      });
    }
  }

  function holidaysCallback(data) {
    setHolidays(data);
  }

  function paidLeaveCallback(data) {
    let current = userData;
    current["paidLeaveDays"] = data;
    setUserData(current);
  }

  function emptyUserData() {
    setUserData({});
  }

  function toggleTheme() {
    setDarkTheme(!darkTheme);
  }

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen);
  }

  function getDrawerOpen() {}

  return (
    <React.Fragment>
    <BrowserRouter>
      <ScrollToTop />
      <React.Fragment>
        <CssBaseline />
        <RerouteUnauthenticated 
          baseAddressProp={baseAddress}
          loggedInCallbackProp={loggedInCallback}
          emptyUserDataProp={emptyUserData}>
        </RerouteUnauthenticated>
        <Header
          toggleDrawerProp={toggleDrawer}
          darkThemeProp={darkTheme}
          toggleThemeProp={toggleTheme}
          drawerWidthProp={drawerWidth}
          drawerColorProp={drawerColor}
          minToolbarHeightProp={minToolbarHeight}
          headerNameProp={headerName}
          loggedInProp={loggedIn}
          loggedInCallbackProp={loggedInCallback}
          currentPathProp={currentPath}
          userDataProp={userData}
          userDataCallbackProp={userDataCallback}
          emptyUserDataProp={emptyUserData}
          changeLanguageProp={changeLanguage}
          baseAddressProp={baseAddress}
          t={t}
        />
        {(loggedIn && userData.role === "Admin") || userData.role === "Database admin" ? (
          <SidePanel toggleDrawerProp={toggleDrawer} drawerOpenProp={drawerOpen} t={t} userDataProp={userData} />
        ) : null}
        <Switch>
          <PublicRoute
            component={Login}
            loggedIn={loggedIn}
            restricted={true}
            exact
            path="/"
            tokenProp={token}
            tokenCallbackProp={tokenCallback}
            baseAddressProp={baseAddress}
            loggedInProp={loggedIn}
            loggedInCallbackProp={loggedInCallback}
            currentPathCallbackProp={currentPathCallback}
            userDataCallbackProp={userDataCallback}
            emptyUserDataProp={emptyUserData}
            headerNameCallbackProp={headerNameCallback}
            t={t}
          />

          <PublicRoute
            component={PasswordRecovery}
            loggedIn={loggedIn}
            restricted={true}
            path="/PasswordRecovery"
            tokenProp={token}
            tokenCallbackProp={tokenCallback}
            baseAddressProp={baseAddress}
            loggedInProp={loggedIn}
            loggedInCallbackProp={loggedInCallback}
            currentPathCallbackProp={currentPathCallback}
            userDataCallbackProp={userDataCallback}
            emptyUserDataProp={emptyUserData}
            headerNameCallbackProp={headerNameCallback}
            t={t}
          />

          <PublicRoute
            component={ResetPassword}
            loggedIn={loggedIn}
            user={userData.role}
            path="/ResetPassword/:token"
            currentPathCallbackProp={currentPathCallback}
            baseAddressProp={baseAddress}
            loggedInProp={loggedIn}
            t={t}
          />
          <RegisterRoute
            component={Register}
            loggedIn={loggedIn}
            user={userData.role}
            path="/Register"
            currentPathCallbackProp={currentPathCallback}
            baseAddressProp={baseAddress}
            loggedInProp={loggedIn}
            t={t}
          />
          <PrivateRoute
            component={Home}
            loggedIn={loggedIn}
            path="/Home"
            drawerWidthProp={drawerWidth}
            baseAddressProp={baseAddress}
            minToolbarHeightProp={minToolbarHeight}
            headerNameProp={headerNameCallback}
            currentPathCallbackProp={currentPathCallback}
            userDataProp={userData}
            userDataCallbackProp={userDataCallback}
            paidLeaveCallbackProp={paidLeaveCallback}
            t={t}
            holidaysCallbackProp={holidaysCallback}
            holidaysProp={holidays}
            loggedInCallbackProp={loggedInCallback}
            emptyUserDataProp={emptyUserData}
          />
          <PrivateRoute
            component={Database}
            loggedIn={loggedIn}
            path="/Database"
            baseAddressProp={baseAddress}
            headerNameProp={headerNameCallback}
            currentPathCallbackProp={currentPathCallback}
            userDataProp={userData}
            t={t}
          />
          <PrivateRoute
            component={Timesheet}
            loggedIn={loggedIn}
            path="/Timesheet"
            drawerOpenProp={drawerOpen}
            drawerWidthProp={drawerWidth}
            minToolbarHeightProp={minToolbarHeight}
            getDrawerOpenProp={getDrawerOpen}
            baseAddressProp={baseAddress}
            mainThemeColorProp={mainThemeColor}
            headerNameProp={headerNameCallback}
            currentPathCallbackProp={currentPathCallback}
            userDataProp={userData}
            t={t}
            holidaysProp={holidays}
          />
          <PrivateRoute
            component={MyAccount}
            loggedIn={loggedIn}
            path="/MyAccount"
            baseAddressProp={baseAddress}
            headerNameProp={headerNameCallback}
            userDataProp={userData}
            userDataCallbackProp={userDataCallback}
            t={t}
          />
          <PrivateRoute
            component={Productivity}
            loggedIn={loggedIn}
            path="/Productivity"
            currentPathCallbackProp={currentPathCallback}
            headerNameProp={headerNameCallback}
            userDataProp={userData}
            baseAddressProp={baseAddress}
            t={t}
          />
          <PrivateRoute
            component={AnnualLeave}
            loggedIn={loggedIn}
            path="/AnnualLeave"
            currentPathCallbackProp={currentPathCallback}
            headerNameProp={headerNameCallback}
            userDataProp={userData}
            baseAddressProp={baseAddress}
            t={t}
          />
          <PrivateRoute
            component={Repairs}
            loggedIn={loggedIn}
            path="/Repairs"
            currentPathCallbackProp={currentPathCallback}
            headerNameProp={headerNameCallback}
            userDataProp={userData}
            baseAddressProp={baseAddress}
            t={t}
          />

          <PrivateRoute
            component={EditEntries}
            loggedIn={loggedIn}
            path="/EditEntries"
            currentPathCallbackProp={currentPathCallback}
            headerNameProp={headerNameCallback}
            userDataProp={userData}
            baseAddressProp={baseAddress}
            t={t}
          />
        </Switch>
      </React.Fragment>
    </BrowserRouter>
    </React.Fragment>
  );
}

const ScrollToTop = ({children}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
}

export default withNamespaces()(Dashboard);
