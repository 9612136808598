import React from "react";
import { Route, Redirect } from "react-router-dom";

function RegisterRoute({ component: Component, loggedIn, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        loggedIn === true && user === "User" ? <Redirect to={{ pathname: "/Home", state: { from: props.location } }} /> : <Component {...rest} {...props} />
      }
    />
  );
}

export default RegisterRoute;
