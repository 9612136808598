import { Redirect, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Axios from "axios";

const RerouteUnauthenticated = (props) => {
  const [redirectUnauthenticated, setRedirectUnauthenticated] = useState(false)
  const location = useLocation();
  
  useEffect(() => {
    if(!new RegExp(/\/resetpassword\/.+/).test(location.pathname.toLowerCase())) {
      routeProtectionGET(props, rerouteUnauthenticated, setRedirectUnauthenticated)
    }
  }, [])

  if(redirectUnauthenticated) {
    return (<Redirect to={"/"}/>)
  }
  return <></>
}

const routeProtectionGET = async (props, callback, stateCallback) => {
  const address = props.baseAddressProp + "/Authentication" + "/RouteProtection";
  try {
    const result = await Axios.get(address)
    callback(props, result, stateCallback)
  } catch(error) {
    callback(props, error.response, stateCallback)
  }
}

const rerouteUnauthenticated = (props, value, stateCallback) => {
  if(value?.status === 401) {
    props.loggedInCallbackProp(false);
    props.emptyUserDataProp();
    // sessionStorage.setItem("loggedIn", JSON.stringify(false));
    sessionStorage.setItem("userData", JSON.stringify({}));
    stateCallback(true)
  }
}

export default RerouteUnauthenticated