import { Button, CircularProgress, createMuiTheme, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DescriptionIcon from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider } from "@material-ui/styles";
import Axios from "axios";
import { isSameDay } from "date-fns/esm";
import React, { useEffect, useRef, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Area, AreaChart, CartesianGrid, Label, Legend, PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import bgImageMobile from "./../bgimageMobile.svg";
import bgImage from "./../bg_1.jfif";
import "./RechartsStyle.css";
import { routeProtectionGET, rerouteUnauthenticated } from "../other/utils"
import "./Home.css"

function Home(props) {
  const classes = useStyles(props);
  const [redirect, setRedirect] = useState(false);
  const [redirectToEditEntries, setRedirectToEditEntries] = useState(false);
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [dailyWorkEfficiencyData, setDailyWorkEfficiencyData] = useState("");
  const dailyWorkEfficiencyBool = useRef(false);
  const finalWorkEfficiencyArrayBool = useRef(false);
  const [loading, setLoading] = useState(true);
  const [finalWorkEfficiencyArray, setFinalWorkEfficiencyArray] = useState([]);
  const unitsProduced = useRef(0);
  const unitsTarget = useRef(0);
  const [timeSpentWorkingArray, setTimeSpentWorkingArray] = useState([]);
  const timeSpentWorking = useRef(0);
  const targetTimeSpentWorking = useRef(0);
  const productivityTableHead = [props.t("HomeUser.TimeDays"), props.t("HomeUser.ProducedUnits"), props.t("HomeUser.TargetUnits"), props.t("HomeUser.ProductivityPercentage")];
  const timelineTableHead = [props.t("HomeUser.TimeDays"), props.t("HomeUser.WorkingTimeMinutes"), props.t("HomeUser.TargetMinutes"), props.t("HomeUser.ProgressionPercentage")];
  const minWidth = useMediaQuery("(min-width:400px)");

  const theme = createMuiTheme({
    palette: {
      primary: { main: blue[500] },
      secondary: { main: "#9f9f9f" }
    }
  });
  const inputsm = useMediaQuery(theme.breakpoints.down("sm"));
  const inputmd = useMediaQuery(theme.breakpoints.down("md"));
  
  //between 350 and 600? mobile use desktop font size
  //larger 600? desktop - desktop font size
  //smaller 350? mobile - mobile font size

  const theme2 = useTheme();
  const md = useMediaQuery(theme2.breakpoints.up("lg"));

  const data2 = [
    {
      name: "18-24",
      uv: 31.47,
      pv: 70,
      qv: 70,
      rv: 60,
      fill: "#2196f3"
    }
  ];

  props.headerNameProp("");
  props.currentPathCallbackProp(props.location.pathname);

  useEffect(() => {
    holidaysGET();
    
  }, [dailyWorkEfficiencyData]);

  useEffect(() => {
    if (!finalWorkEfficiencyArrayBool.current) {
      finalWorkEfficiencyArrayBool.current = true;
      return;
    }

    setLoading(false);
  }, [finalWorkEfficiencyArray]);

  async function dailyWorkEfficiencyGET(employeeId) {
    setLoading(true);
    const address = props.baseAddressProp + "/Timesheet" + "/GetDailyWorkEfficiency" + "?employeeId=" + employeeId;
    try {
      let result = await Axios.get(address);

      props.paidLeaveCallbackProp(result.data["paidLeaveDaysLeft"]);
      setDailyWorkEfficiencyData(result.data["dailyWorkEfficiencyData"]);
    } catch (error) {
      setLoading(false);
    }
  }

  async function holidaysGET(){
    const address = props.baseAddressProp + "/AnnualLeave" + "/GetHolidays";
    let holidays = [];
    try{
      let result = await Axios.get(address);
      let paidLeaveDays = 0;

      for(let i = 0; i < result.data.length; i++){
        holidays.push(new Date(result.data[i]["date"]));
      }

      props.holidaysCallbackProp(holidays);
      
    } catch(error){}
    workEfficiency(holidays);
  }

  function workEfficiency(holidays) {
    if (!dailyWorkEfficiencyData) {
      return;
    }

    let workEfficiencyArray = [];
    let workDayMinutes = 465.0;
    let totalAmountProduced = 0;
    let totalTarget = 0;
    let timelineArray = [];
    let totalTimeWorked = 0;
    let totalTargetTimeWorked = 0;
    let currentDate = new Date();

    for (let i = 0; i < dailyWorkEfficiencyData.length; i++) {
      let holiday = false;
      workDayMinutes = 465.0;
      var day = new Date(currentDate.getFullYear(), currentDate.getMonth(), i + 1).toLocaleDateString("en-EN", { weekday: "long" }).toString();

      for(let j = 0; j < holidays.length; j++){  
        if(isSameDay(new Date(currentDate.getFullYear(), currentDate.getMonth(), i + 1), holidays[j])){
          holiday = true;
        }
      }

      totalAmountProduced += dailyWorkEfficiencyData[i].amountProduced;
      totalTarget += dailyWorkEfficiencyData[i].targetProduced;
      let workEfficiency = Math.round((dailyWorkEfficiencyData[i].amountProduced / dailyWorkEfficiencyData[i].targetProduced) * 100 * 10) / 10;

      workEfficiencyArray.push({
        name: (i + 1).toString(),
        Productivity: !workEfficiency ? 0.0 : workEfficiency,
        Produced: dailyWorkEfficiencyData[i].amountProduced,
        Target: dailyWorkEfficiencyData[i].targetProduced === 0 ? 0 : dailyWorkEfficiencyData[i].targetProduced,
        TargetPercentage: (day !== props.t("Productivity.Saturday") && day !== props.t("Productivity.Sunday") && dailyWorkEfficiencyData[i].targetProduced !== 0)? 100.0 : 0.0,
        Day: day,
        t: 1,
        leave: dailyWorkEfficiencyData[i].leave,
        isHoliday: holiday
      });

      totalTimeWorked += dailyWorkEfficiencyData[i].timeSpent;

      if (day !== props.t("Productivity.Saturday") && day !== props.t("Productivity.Sunday")) {
        totalTargetTimeWorked += workDayMinutes;
      } else {
        workDayMinutes = 0.0;
      }

      let timeEfficiency = Math.round((dailyWorkEfficiencyData[i].timeSpent / workDayMinutes) * 100 * 10) / 10;

      timelineArray.push({
        name: (i + 1).toString(),
        Efficiency: !timeEfficiency ? 0.0 : timeEfficiency,
        TimeSpent: dailyWorkEfficiencyData[i].timeSpent,
        Target: holiday ? 0.0 : workDayMinutes,
        TargetPercentage: day !== props.t("Productivity.Saturday") && day !== props.t("Productivity.Sunday") && holiday !== true? 100.0 : 0.0,
        Day: day,
        leave: dailyWorkEfficiencyData[i].leave,
        isHoliday: holiday
      });
    }
    unitsProduced.current = totalAmountProduced;
    unitsTarget.current = totalTarget;
    setFinalWorkEfficiencyArray(workEfficiencyArray);

    timeSpentWorking.current = totalTimeWorked;
    targetTimeSpentWorking.current = totalTargetTimeWorked;
    setTimeSpentWorkingArray(timelineArray);
  }

  const ProductivityTooltip = ({ active, payload, label }) => {
    if (active) {
      if (!label) return null;
      let currentDate = new Date();
      let today = new Date(currentDate.getFullYear(), currentDate.getMonth(), label).toLocaleDateString("en-EN", { weekday: "long" }) + ", " + label.toString();
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid rgba(225, 225, 225, 1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            padding: "10px"
          }}
        >
          <Typography variant="button" style={{ color: blue[500], textTransform: "capitalize" }} className="label">{`${today}`}</Typography>
          <br />
          <div>
            {payload[0].payload.isHoliday ? (
              <React.Fragment>
              <Typography variant="caption" style={{color: "rgba(22, 160, 31, 1)", fontWeight: 500}}>{"Holiday"}</Typography>
              <br />
              </React.Fragment>
            ) : (payload[0].payload.leave === 1 ? (
              <React.Fragment>
              <Typography variant="caption" style={{color: "rgba(243, 177, 71, 1)", fontWeight: 500}}>{"Paid leave"}</Typography>
              <br />
              </React.Fragment>
            ) : (payload[0].payload.leave === 2 ? (
              <React.Fragment>
              <Typography variant="caption" style={{color: "rgba(243, 177, 71, 1)", fontWeight: 500}}>{"Sent home"}</Typography>
              <br />
              </React.Fragment>
            ) : (null)))}
          </div>
          <Typography variant="caption" style={{ color: "#8884d8" }}>
            {" "}
            {`${props.t("HomeUser.Productivity")}: ${payload[0].payload.Productivity}%`}
          </Typography>
          <br />
          <Typography variant="caption">{`${props.t("HomeUser.Produced")}: ${payload[0].payload.Produced} ${props.t("HomeUser.Units")}`}</Typography>
          <br />
          <Typography variant="caption">{`${props.t("HomeUser.Target")}: ${Math.round(payload[0].payload.Target)} ${props.t("HomeUser.Units")}`}</Typography>
          <br />
          
          
        </div>
      );
    }
    return null;
  };

  const TimelineTooltip = ({ active, payload, label }) => {
    if (active) {
      if (!label) return null;
      let currentDate = new Date();
      let today = new Date(currentDate.getFullYear(), currentDate.getMonth(), label).toLocaleDateString("en-EN", { weekday: "long" }) + ", " + label.toString();
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "1px solid rgba(225, 225, 225, 1)",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "8px",
            padding: "10px"
          }}
        >
          <Typography variant="button" style={{ color: blue[500], textTransform: "capitalize" }} className="label">{`${today}`}</Typography>
          <br />
          <div>
            {payload[0].payload.isHoliday ? (
              <React.Fragment>
              <Typography variant="caption" style={{color: "rgba(22, 160, 31, 1)", fontWeight: 500}}>{"Holiday"}</Typography>
              <br />
              </React.Fragment>
            ) : (payload[0].payload.leave === 1 ? (
              <React.Fragment>
              <Typography variant="caption" style={{color: "rgba(243, 177, 71, 1)", fontWeight: 500}}>{"Paid leave"}</Typography>
              <br />
              </React.Fragment>
            ) : (payload[0].payload.leave === 2 ? (
              <React.Fragment>
              <Typography variant="caption" style={{color: "rgba(243, 177, 71, 1)", fontWeight: 500}}>{"Sent home"}</Typography>
              <br />
              </React.Fragment>
            ) : (null)))}
          </div>
          <Typography variant="caption" style={{ color: "#82ca9d" }}>
            {" "}
            {`${props.t("HomeUser.Progression")}: ${payload[0].payload.Efficiency}%`}
          </Typography>
          <br />
          <Typography variant="caption">{`${props.t("HomeUser.WorkingTime")}: ${Math.round(payload[0].payload.TimeSpent)} ${props.t("HomeUser.Minutes").toLowerCase()}`}</Typography>
          <br />
          <Typography variant="caption">{`${props.t("HomeUser.Target")}: ${Math.round(payload[0].payload.Target)} ${props.t("HomeUser.Minutes").toLowerCase()}`}</Typography>
          <br />
        </div>
      );
    }
    return null;
  };

  function insertProdTableHead() {
    let tableHead = [];
    productivityTableHead.map((value, index) => {
      tableHead.push(
        <TableCell align="right" key={value}>
          {value}
        </TableCell>
      );
    });
    return tableHead;
  }

  function insertTimelineTableHead() {
    let tableHead = [];
    timelineTableHead.map((value, index) => {
      if(value === "Working time [minutes]"){
        tableHead.push(
          <TableCell align="right" key={value} style={{minWidth: "145px", maxWidth: "145px"}}>
            {value}
          </TableCell>
        );
      } else{
        tableHead.push(
          <TableCell align="right" key={value}>
            {value}
          </TableCell>
        );
      }
      
    });
    return tableHead;
  }

  function insertProdTableRows() {
    let tableRow = [];
    finalWorkEfficiencyArray.map((value, index) => {
      let bgColor = "inherit";

      if(value.Day === "Saturday" || value.Day === "Sunday"){
        bgColor = "rgba(242, 242, 242, 1)";
      } else if(value.leave === 1 || value.leave === 2){
        bgColor = "rgba(243, 177, 71, 0.75)";
      }

      if(value.isHoliday){
        bgColor ="rgba(22, 160, 31, 0.63)";
      }

      tableRow.push(
        <TableRow key={value.name} style={{ backgroundColor: bgColor }}>
          <TableCell align="right" style={{fontSize: "0.75rem", paddingTop: "5px", paddingBottom: "5px", minWidth: "125px", maxWidth: "125px"}}>
            {value.Day}, {value.name}
          </TableCell>
          <TableCell align="right" style={{fontSize: "0.75rem", paddingTop: "5px", paddingBottom: "5px"}}>{value.Day !== "Saturday" && value.Day !== "Sunday" ? Math.round(value.Produced) : ""}</TableCell>
          <TableCell align="right" style={{fontSize: "0.75rem", paddingTop: "5px", paddingBottom: "5px"}}>{value.Day !== "Saturday" && value.Day !== "Sunday" ? Math.round(value.Target) : ""}</TableCell>
          <TableCell align="right" style={{fontSize: "0.75rem", paddingTop: "5px", paddingBottom: "5px"}}>{value.Day !== "Saturday" && value.Day !== "Sunday" ? value.Productivity : ""}</TableCell>
        </TableRow>
      );
    });
    return tableRow;
  }

  function insertTimelineTableRows() {
    let tableRow = [];
    timeSpentWorkingArray.map((value, index) => {
      let bgColor = "inherit";

      if(value.Day === "Saturday" || value.Day === "Sunday"){
        bgColor = "rgba(242, 242, 242, 1)";
      } else if(value.leave === 1 || value.leave === 2){
        bgColor = "rgba(243, 177, 71, 0.75)";
      }

      if(value.isHoliday){
        bgColor ="rgba(22, 160, 31, 0.63)";
      }

      tableRow.push(
        <TableRow
          key={value.name}
          style={{
            // background: (value.Day !== "Saturday" && value.Day !== "Sunday") ? "inherit" :
            //   "repeating-linear-gradient( 315deg,rgba(230, 230, 230, 0.5),rgba(230, 230, 230, 0.5) 10px,rgba(190, 190, 190, 0.5) 10px,rgba(190, 190, 190, 0.5) 20px)"
            backgroundColor: bgColor
          }}
        >
          <TableCell align="right" style={{fontSize: "0.75rem", paddingTop: "5px", paddingBottom: "5px", minWidth: "125px", maxWidth: "125px"}}>
            {value.Day}, {value.name}
          </TableCell>
          <TableCell align="right" style={{fontSize: "0.75rem", paddingTop: "5px", paddingBottom: "5px"}}>{value.Day !== "Saturday" && value.Day !== "Sunday" ? Math.round(value.TimeSpent) : ""}</TableCell>
          <TableCell align="right" style={{fontSize: "0.75rem", paddingTop: "5px", paddingBottom: "5px"}}>{value.Day !== "Saturday" && value.Day !== "Sunday" ? Math.round(value.Target) : ""}</TableCell>
          <TableCell align="right" style={{fontSize: "0.75rem", paddingTop: "5px", paddingBottom: "5px"}}>{value.Day !== "Saturday" && value.Day !== "Sunday" ? value.Efficiency : ""}</TableCell>
        </TableRow>
      );
    });
    return tableRow;
  }

  // name: (i + 1).toString(),
  //       Productivity: !workEfficiency ? 0.0 : workEfficiency,
  //       Produced: dailyWorkEfficiencyData[i].amountProduced,
  //       Target: dailyWorkEfficiencyData[i].targetProduced,
  //       TargetPercentage: day !== "Saturday" && day !== "Sunday" ? 100.0 : 0.0

  //setFinalWorkEfficiencyArray

  function launchPageAdmin() {
    return (
      <div>
        <div className={classes.background}>
          <div className={classes.launchPageAdmin}>
            <Grid item xs={12} style={{marginBottom: "36px"}}>
              <Typography variant="h5" style={{color: "#FFFFFF", display: "inline-block"}} className={classes.helloText}>
                {props.t("HomeAdmin.Hello")}
              </Typography>

              <Typography variant="h5" style={{color: "#FFFFFF", paddingLeft: "6px", fontWeight: 450, display: "inline-block"}} className={classes.helloText}>
                {props.userDataProp.firstName}!
              </Typography>
            </Grid>

              <Grid item xs={12} style={{marginBottom: "12px"}}>
                <Typography variant="h2" style={{color: "#FFFFFF", fontWeight: "400"}} className={classes.welcomeTextAdmin}>
                  {props.t("HomeAdmin.WelcomeAdmin")}
                </Typography>
              </Grid>
            
              <Grid item xs={12}>
                <Typography variant="h4" style={{ color: "#FFFFFF", fontWeight: "300"}} className={classes.subTitleText}>
                {props.t("HomeAdmin.UseSidePanel")}
                </Typography>
              </Grid>
          </div>
        </div>
      </div>
    );
  }

  function radialChart() {
    let circleSize = 100;
    if (loading) return null;

    return (
      <div style={{ paddingTop: inputsm ? "0px" : "12px" }}>
        <ResponsiveContainer height={inputsm ? 175 : 200} width="100%">
          <RadialBarChart
            width={circleSize}
            height={circleSize}
            cx="50%"
            cy="50%"
            innerRadius="85%"
            outerRadius="100%"
            barSize={20}
            data={data2}
            startAngle={90}
            endAngle={-270}
          >
            <PolarAngleAxis type="number" domain={[0, props.userDataProp.totalPaidLeaveDays]} angleAxisId={0} tick={false} />
            <RadialBar
              background={true}
              clockWise
              dataKey="paidLeaveDays"
              data={[{ paidLeaveDays: props.userDataProp.paidLeaveDays, totalPaidLeaveDays: props.userDataProp.totalPaidLeaveDays }]}
              cornerRadius={circleSize / 2}
              fill="#2196f3"
            />
            <text
              x="50%"
              y="54%"
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
              style={{ fontSize: inputsm ? "3.75rem" : inputmd ? "5vmin" : "3.75rem", fontFamily: "Roboto", fontWeight: 400, lineHeight: 1.6, letterSpacing: "0.0075em" }}
            >
              {props.userDataProp.paidLeaveDays} <div style={{ fontSize: "1.5rem" }}>/24</div>
            </text>
          </RadialBarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  function launchPageUser() {
    props.headerNameProp("Dashboard");

    if (!dailyWorkEfficiencyBool.current && props.userDataProp.id) {
      dailyWorkEfficiencyGET(props.userDataProp.id);
      dailyWorkEfficiencyBool.current = true;
    }

    return (
      <div>
        {inputsm ? (
          // #region MOBILE
          <div className={classes.launchPageUserMobile}>
            <Grid item xs={12} style={{marginTop: "0px", marginBottom: "22px", padding: "12px", paddingLeft: "0px", borderRadius: "8px", border: "1px solid rgba(34, 150, 243, 1)", background: "rgba(34, 150, 243, 0.1)"}}>
          <Typography variant="h5" style={{fontSize: minWidth ? "1.5rem" : "6vmin", paddingLeft: "12px", display: "inline-block"}}>
            {props.t("HomeUser.Hello")}
          </Typography>
          <Typography variant="h5" style={{fontSize: minWidth ? "1.5rem" : "6vmin", paddingLeft: "6px", fontWeight: "450", display: "inline-block"}}>
           {props.userDataProp.firstName}!
          </Typography>
          </Grid>
          <Grid container spacing={3} alignItems="stretch" direction="row">
            <Grid item xs={12}>
              <Paper
                className={classes.paperPropsMobile}
                classes={{
                  rounded: classes.paperShape,
                  elevation1: classes.paperShadow,
                  root: classes.paperRoot
                }}
              >
                <Grid container direction="row" alignItems="center" style={{marginBottom: "25px"}}>
                  <Grid item>
                    <Typography variant="h5" style={{ fontSize: "1.25rem", color: "#FFFFFF"}}>
                    {props.t("HomeUser.TimeRegistration")}
                    </Typography>
                  </Grid>

                  <Grid item>
                  <DescriptionIcon style={{ position: "relative", top: "3px", left: "8px", fontSize: "1.75rem", color: "#FFFFFF" }} />
                  </Grid>
                </Grid>
                
                <Grid item xs={12}>
                  <Typography variant="h5" style={{ fontSize: "2rem", color: "#FFFFFF",
                        borderRadius: "4px",
                        fontWeight: "400"}}>
                    {props.t("HomeUser.RegisterYourWorkTimes")}
                  </Typography>
                </Grid>
                

                {/* <Grid item xs={12}
                 
                  className={classes.borderMargin}
                  style={{ marginTop: 12, border: "1px solid rgba(65, 166, 247, 1)", borderRadius: "4px", width: "100%", backgroundColor: "rgba(66, 166, 247, 0.2)"}}
                > */}
                  <Grid item xs={12}>
                  <Button
                  //fullWidth
                      variant="contained"
                      style={{ marginTop: "20px", backgroundColor: "#f5f5f5", width:"-webkit-fill-available"}}
                      className="no-shadow button-padding"
                      onClick={event => setRedirect(true)}
                    >
                      {props.t("HomeUser.GetStarted")}
                      <KeyboardArrowRightIcon style={{ paddingBottom: 2 }} />
                    </Button>
                  </Grid>
                {/* </Grid> */}
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper
              className={classes.paperPropsMobile}
              classes={{
                rounded: classes.paperShape,
                elevation1: classes.paperShadow
              }}>

            <Grid container direction="row" alignItems="center">
                  <Grid item>
                  <Typography variant="h6" style={{display: "inline-block", fontSize: "1.25rem"}}>{props.t("HomeUser.EditEntries")}</Typography>
                  </Grid>

                  <Grid item>
                  <EditIcon style={{ position: "relative", top: "3px", left: "8px", fontSize: "1.75rem"}} />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "12px", width: "-webkit-fill-available"}}
                        className="no-shadow button-padding"
                        onClick={event => setRedirectToEditEntries(true)}
                      >
                        {props.t("HomeUser.Edit")}
                        <EditIcon style={{ paddingBottom: 2, marginLeft: "5px"}} />
                    </Button>
                      </Grid>
              </Paper>

            </Grid>

            <Grid item xs={12}>
              <Paper
                className={classes.paperPropsMobile}
                classes={{
                  rounded: classes.paperShape,
                  elevation1: classes.paperShadow
                }}
                style={{ paddingBottom: "12px", height: "100%" }}
              >
                <div>
                </div>
                
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                  <Typography variant="h6" style={{display: "inline-block", fontSize: "1.25rem"}}>{props.t("HomeUser.AnnualLeave")}</Typography>
                  </Grid>

                  <Grid item>
                  <DateRangeIcon style={{ position: "relative", top: "3px", left: "8px", fontSize: "1.75rem"}} />
                  </Grid>
                </Grid>

                {loading ? (
                  <Grid item xs={12} align="center" style={{ height: "100%", paddingBottom: "75px" }}>
                    <div style={{ position: "relative", top: "50%" }}>
                      <CircularProgress />
                    </div>
                  </Grid>
                ) : (
                  <div>
                    
                    {radialChart()}

                    <div style={{ textAlign: "center" }}>
                      <Typography variant="button" style={{ fontSize: minWidth ? "0.875rem" : "3.5vmin", display: "inline-block", paddingTop: "0px" }}>
                      {props.t("HomeUser.Of")} {props.userDataProp.totalPaidLeaveDays} {props.t("HomeUser.LeaveDaysLeft")}
                      </Typography>
                    </div>
                  </div>
                )}
              </Paper>
            </Grid>

            <Grid item xs={md ? 6 : 12}>
              <Paper
                className={classes.paperPropsMobile}
                classes={{
                  rounded: classes.paperShape,
                  elevation1: classes.paperShadow
                }}
              >

                <Grid container style={{ padding: "10px", paddingLeft: "0px", paddingTop: "0px" }}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                    <Typography variant="h6" style={{display: "inline-block", fontSize: minWidth ? "1.25rem" : "5vmin"}}>{props.t("HomeUser.Productivity")}</Typography>
                    </Grid>

                    <Grid item>
                    <AssessmentOutlinedIcon style={{ position: "relative", top: "3px", left: "8px", fontSize: minWidth ? "1.75rem" : "6vmin"}} />
                    </Grid>

                    <Typography variant="h6" style={{ color: blue[500], marginBottom: 0, fontSize: minWidth ? "1rem" : "4vmin", display: "inline-block", float:"right", paddingTop: "4px", marginLeft: "auto", marginRight: "0px" }}>
                    {monthNames[new Date().getMonth()]}, {new Date().getFullYear()}
                  </Typography>
                  </Grid>
                </Grid>

                {loading ? (
                  <Grid item xs={12} align="center">
                    <div style={{ position: "relative" }}>
                      <CircularProgress />
                    </div>
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Grid
                      container
                      spacing={0}
                      style={{
                        marginBottom: 20,
                        // paddingTop: 5,
                        paddingBottom: 5,
                        border: "1px solid rgba(225, 225, 225, 1)",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                        // backgroundColor: "rgba(225, 225, 225, 0.2)",
                        // borderRadius: "8px"
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ marginBottom: 0, backgroundColor: blue[500], padding: "10px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                      >
                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4vmin", color: "white" }}>
                        {props.t("HomeUser.TotalQuantities")}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ padding: "10px", paddingRight: "0px" }}>
                        <Typography variant="button" style={{fontSize: minWidth ? "0.875rem" : "3vmin"}}>{props.t("HomeUser.Produced").toUpperCase()}</Typography>
                        <br />
                        <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{unitsProduced.current}</Typography>
                        <Typography variant="caption">{props.t("HomeUser.Units")}</Typography>
                      </Grid>

                      {/* unitsProduced.current = totalAmountProduced;
    unitsTarget.current = totalTarget;
    setFinalWorkEfficiencyArray(workEfficiencyArray);

    timeSpentWorking.current = totalTimeWorked;
    targetTimeSpentWorking.current = totalTargetTimeWorked;
    setTimeSpentWorkingArray(timelineArray); */}

                      <Grid
                        item
                        xs={4}
                        style={{
                          padding: "10px", paddingRight: "0px"
                        }}
                      >
                        <Typography variant="button" style={{fontSize: minWidth ? "0.875rem" : "3vmin"}}>{props.t("HomeUser.Target").toUpperCase()}</Typography>
                        <br />
                        <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{Math.round(unitsTarget.current)}</Typography>
                        <Typography variant="caption">{props.t("HomeUser.Units")}</Typography>
                      </Grid>

                      <Grid item xs={4} style={{ padding: "10px", paddingLeft: "0px" }}>
                        <Typography variant="button" style={{fontSize: minWidth ? "0.875rem" : "3vmin"}}>{props.t("HomeUser.Productivity").toUpperCase()}</Typography>
                        <br />
                        <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{Math.round((unitsProduced.current / unitsTarget.current) * 100 * 10) / 10 || 0}%</Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 0,

                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        border: "1px solid rgba(225, 225, 225, 1)"
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: 0,
                          backgroundColor: blue[500],
                          padding: "10px",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px"
                        }}
                      >
                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4vmin", color: "white" }}>
                        {props.t("HomeUser.Overview")}
                        </Typography>
                      </Grid>
                      {/* <div style={{ border: "1px solid rgba(225, 225, 225, 1)", backgroundColor: "rgba(225, 225, 225, 0.2)" }}> */}
                      <div style={{ padding: "10px" }}>
                        <ResponsiveContainer width="100%" height={300}>
                          <AreaChart data={finalWorkEfficiencyArray} margin={{ top: 0, right: 0, left: -5, bottom: 20 }}>
                            {/* <defs>
                              <linearGradient id="workEfficiency" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                              </linearGradient>
                            </defs> */}
                            <XAxis dataKey="name">
                              {/* <Label value="TIME [DAYS]" offset={0} position="insideBottom" style={{ textAnchor: "middle" }} /> */}
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y="290" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("HomeUser.TimeDays").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="36" dy="0.355em">
                                    {props.t("HomeUser.ProductivityPercentage").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />

                              {/* <Label value="EFFICIENCY [%]" offset={0} position="Left" angle={-90} textAnchor="middle" />
                              <Label value="EFFICIENCY [%]" offset={0} position="Left" angle={-90} textAnchor="middle" /> */}
                            </YAxis>
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip content={<ProductivityTooltip />} />
                            {/* <Area type="monotone" dataKey="Target" stroke="#e8d0b5" fill="#e8d0b5" /> */}

                            <Area
                              name={props.t("HomeUser.ProductivityTarget")}
                              type="monotone"
                              dataKey="TargetPercentage"
                              stroke="rgba(200, 200, 200, 1)"
                              fill="rgba(230, 230, 230, 0.5)"
                            />
                            <Area name={props.t("HomeUser.ProductivityPercentage")} type="monotone" dataKey="Productivity" stroke="#8884d8" fill="#8884d8" />
                            <Legend verticalAlign="top" height={45} />
                            {/* <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" /> */}
                            {/* <Legend verticalAlign="middle"  */}
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>

                      <Grid item xs={12} style={{ marginTop: 0, padding: "10px" }}>
                        <ExpansionPanel>
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                            {props.t("HomeUser.RawProductivityData")}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Table style={{display: "inline-block", overflowX: "auto"}}>
                              <TableHead>
                                <TableRow>{insertProdTableHead()}</TableRow>
                              </TableHead>
                              <TableBody>{insertProdTableRows()}</TableBody>
                            </Table>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Paper>
            </Grid>

            <Grid item xs={md ? 6 : 12}>
              <Paper
                className={classes.paperPropsMobile}
                classes={{
                  rounded: classes.paperShape,
                  elevation1: classes.paperShadow
                }}
              >

                <Grid container style={{ padding: "10px", paddingLeft: "0px", paddingTop: "0px" }}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                    <Typography variant="h6" style={{display: "inline-block", fontSize: minWidth ? "1.25rem" : "5vmin"}}>{props.t("HomeUser.Timeline")}</Typography>
                    </Grid>

                    <Grid item>
                    <TimelineIcon style={{ position: "relative", top: "3px", left: "8px", fontSize: minWidth ? "1.75rem" : "6vmin"}} />
                    </Grid>

                    <Typography variant="h6" style={{ color: blue[500], marginBottom: 0, fontSize: minWidth ? "1rem" : "4vmin", display: "inline-block", float:"right", paddingTop: "4px", marginLeft: "auto", marginRight: "0px" }}>
                    {monthNames[new Date().getMonth()]}, {new Date().getFullYear()}
                  </Typography>
                  </Grid>
                </Grid>


                {/* <Grid item xs={12} style={{ padding: "10px", paddingLeft: "0px", paddingTop: "0px" }}>
                  <Typography variant="h6" style={{display: "inline-block"}}>Timeline</Typography>
                  <Typography variant="h6" style={{ color: blue[500], marginBottom: 0, fontSize: "4vmin", display: "inline-block", float: "right", paddingTop: "4px" }}>
                    {monthNames[new Date().getMonth()]}, {new Date().getFullYear()}
                  </Typography>
                </Grid> */}

                {loading ? (
                  <Grid item xs={12} align="center">
                    <div style={{ position: "relative" }}>
                      <CircularProgress />
                    </div>
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Grid
                      container
                      spacing={0}
                      style={{
                        marginBottom: 20,
                        paddingBottom: 5,
                        border: "1px solid rgba(225, 225, 225, 1)",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ marginBottom: 0, backgroundColor: blue[500], padding: "10px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                      >
                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4vmin", color: "white" }}>
                        {props.t("HomeUser.TotalWorkingTime")}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ padding: "10px", paddingRight: "0px" }}>
                        <Typography variant="button" style={{fontSize: minWidth ? "0.875rem" : "3vmin"}}>{props.t("HomeUser.Worked").toUpperCase()}</Typography>
                        <br />
                        <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{Math.round(timeSpentWorking.current)}</Typography>
                        <Typography variant="caption">{props.t("HomeUser.Minutes").toLowerCase()}</Typography>
                      </Grid>

                      <Grid item xs={4} style={{ padding: "10px"}}>
                        <Typography variant="button" style={{fontSize: minWidth ? "0.875rem" : "3vmin"}}>{props.t("HomeUser.Target").toUpperCase()}</Typography>
                        <br />
                        <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{Math.round(targetTimeSpentWorking.current)}</Typography>
                        <Typography variant="caption">{props.t("HomeUser.Minutes").toLowerCase()}</Typography>
                      </Grid>

                      <Grid item xs={4} style={{ padding: "10px", paddingLeft: "0px" }}>
                        <Typography variant="button" style={{fontSize: minWidth ? "0.875rem" : "3vmin"}}>{props.t("HomeUser.Progression").toUpperCase()}</Typography>
                        <br />
                        <Typography variant="h6" style={{fontSize: minWidth ? "1.25rem" : "5vmin"}}>{Math.round((timeSpentWorking.current / targetTimeSpentWorking.current) * 100 * 10) / 10}%</Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 0,

                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        border: "1px solid rgba(225, 225, 225, 1)"
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: 0,
                          backgroundColor: blue[500],
                          padding: "10px",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px"
                        }}
                      >
                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4vmin", color: "white" }}>
                        {props.t("HomeUser.Overview")}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        {/* <div style={{ border: "1px solid rgba(225, 225, 225, 1)", backgroundColor: "rgba(225, 225, 225, 0.2)" }}> */}
                        <div style={{ padding: "10px" }}>
                          <ResponsiveContainer width="100%" height={300}>
                            <AreaChart data={timeSpentWorkingArray} margin={{ top: 0, right: 0, left: -5, bottom: 20 }}>
                              <defs>
                                <linearGradient id="timeline" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.9} />
                                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                </linearGradient>
                              </defs>
                              <XAxis dataKey="name">
                                <Label
                                  width={30}
                                  position="center"
                                  content={
                                    <text
                                      x="50%"
                                      y="290"
                                      fill="#3d405c"
                                      className="recharts-text recharts-label"
                                      textAnchor="middle"
                                      dominantBaseline="central"
                                      style={{fontSize: minWidth ? "3.0rem" : "10vmin"}}
                                    >
                                      <tspan alignmentBaseline="middle" fontSize="14">
                                      {props.t("HomeUser.TimeDays").toUpperCase()}
                                      </tspan>
                                    </text>
                                  }
                                />
                              </XAxis>
                              <YAxis>
                                <Label
                                  position="center"
                                  content={
                                    <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                      <tspan x="36" dy="0.355em">
                                      {props.t("HomeUser.ProgressionPercentage").toUpperCase()}
                                      </tspan>
                                    </text>
                                  }
                                />
                              </YAxis>
                              <CartesianGrid strokeDasharray="3 3" />
                              <Tooltip content={<TimelineTooltip />} />

                              <Area
                                name={props.t("HomeUser.ProgressionTarget")}
                                type="monotone"
                                dataKey="TargetPercentage"
                                stroke="rgba(200, 200, 200, 1)"
                                fill="rgba(230, 230, 230, 0.5)"
                              />
                              <Area name={props.t("HomeUser.ProgressionPercentage")} type="monotone" dataKey="Efficiency" stroke="#82ca9d" fill="#82ca9d" />
                              <Legend verticalAlign="top" height={45} />
                            </AreaChart>
                          </ResponsiveContainer>
                        </div>
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 0, padding: "10px" }}>
                        <ExpansionPanel>
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                            {props.t("HomeUser.RawTimelineData")}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Table style={{display: "inline-block", overflowX: "auto"}}>
                              <TableHead>
                                <TableRow>{insertTimelineTableHead()}</TableRow>
                              </TableHead>
                              <TableBody>{insertTimelineTableRows()}</TableBody>
                            </Table>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
        // #endregion

        ) : (
          // #region DESKTOP
        
        <div className={classes.launchPageUser}>
          <Grid item xs={12} style={{marginTop: "0px", marginBottom: "32px", padding: "12px", paddingLeft: "0px", borderRadius: "8px", border: "1px solid rgba(34, 150, 243, 1)", background: "rgba(34, 150, 243, 0.1)"}}>
            <Typography variant="h5" style={{paddingLeft: "40px", display: "inline-block"}}>
            {props.t("HomeUser.Hello")}
            </Typography>
            <Typography variant="h5" style={{paddingLeft: "6px", fontWeight: "450", display: "inline-block"}}>
            {props.userDataProp.firstName}!
            </Typography>
          </Grid>

          <Grid container spacing={inputmd ? 3 : 4} alignItems="stretch" direction="row">
            <Grid item xs={9}>
              <Paper
                className={classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                  elevation1: classes.paperShadow,
                  root: classes.paperRoot
                }}
              >
                <Grid container direction="row" alignItems="center" style={{marginBottom: "25px"}}>
                  <Grid item>
                    <Typography variant="h5" style={{color: "#FFFFFF"}} className={classes.timeText}>
                    {props.t("HomeUser.TimeRegistration")}
                    </Typography>
                  </Grid>

                  <Grid item>
                  <DescriptionIcon style={{ position: "relative", top: "3px", left: "8px", fontSize: "1.75rem", color: "#FFFFFF" }} />
                  </Grid>
                </Grid>



                <Typography variant="h5" style={{ color: "#FFFFFF", fontSize: "2.5rem", fontWeight: "400" }}>
                  {props.t("HomeUser.RegisterYourWorkTimes")}
                  {/* <DescriptionIcon style={{ position: "relative", top: "10px", left: "10px", fontSize: "unset" }} /> */}
                </Typography>
                <Grid
                  container
                  spacing={0}
                  // className={classes.borderMargin}
                  // style={{ marginTop: 25, border: "1px solid rgba(65, 166, 247, 1)", borderRadius: "4px" }}
                >
                  <Grid item style={{marginTop: 18}}>
                    <Button
                      variant="contained"
                      style={{ marginTop: 20, marginBottom: 20}}
                      className="no-shadow button-padding"
                      classes={{root: classes.buttonHover}}
                      onClick={event => setRedirect(true)}
                    >
                      {props.t("HomeUser.GetStarted")}
                      <KeyboardArrowRightIcon style={{ paddingBottom: 2 }} />
                    </Button>
                    
                    
                      <Button
                        variant="contained"
                        style={{ marginLeft: 20, marginTop: 20, marginBottom: 20}}
                        className="no-shadow button-padding"
                        classes={{root: classes.buttonHover}}
                        onClick={event => setRedirectToEditEntries(true)}
                      >
                        {props.t("HomeUser.EditEntries")}
                        <EditIcon style={{ paddingBottom: 2, marginLeft: "4px"}} />
                      </Button>
                    
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Paper
                // className={classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                  elevation1: classes.paperShadow
                  // root: classes.paperRoot
                }}
                style={{ padding:  inputmd ? "10px" : "20px", height: "100%" }}
              >
                {loading ? (
                  <Grid item xs={12} align="center" style={{ height: "100%" }}>
                    <div style={{ position: "relative", top: "50%" }}>
                      <CircularProgress />
                    </div>
                  </Grid>
                ) : (
                  <div>
                    {radialChart()}

                    <div style={{ textAlign: "center" }}>
                      <Typography variant="button" style={{ display: "inline-block", paddingTop: inputmd ? "0px" : "10px" }}>
                      {props.t("HomeUser.Of")} {props.userDataProp.totalPaidLeaveDays} {props.t("HomeUser.LeaveDaysLeft")}
                      </Typography>
                    </div>
                  </div>
                )}
              </Paper>
            </Grid>

            <Grid item xs={md ? 6 : 12}>
              <Paper
                className={classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                  elevation1: classes.paperShadow
                }}
              >

                <Grid container style={{ padding: "10px", paddingLeft: "0px", paddingTop: "0px" }}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                    <Typography variant="h6" style={{display: "inline-block"}}>{props.t("HomeUser.Productivity")}</Typography>
                    </Grid>

                    <Grid item>
                    <AssessmentOutlinedIcon style={{ position: "relative", top: "3px", left: "8px", fontSize: "1.75rem"}} />
                    </Grid>

                    <Typography variant="h6" style={{ color: blue[500], marginBottom: 0, fontSize: "1.0rem", display: "inline-block", float:"right", paddingTop: "4px", marginLeft: "auto", marginRight: "0px" }}>
                    {monthNames[new Date().getMonth()]}, {new Date().getFullYear()}
                  </Typography>
                  </Grid>
                </Grid>

                {loading ? (
                  <Grid item xs={12} align="center">
                    <div style={{ position: "relative" }}>
                      <CircularProgress />
                    </div>
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Grid
                      container
                      spacing={0}
                      style={{
                        marginBottom: 20,
                        // paddingTop: 5,
                        paddingBottom: 5,
                        border: "1px solid rgba(225, 225, 225, 1)",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                        // backgroundColor: "rgba(225, 225, 225, 0.2)",
                        // borderRadius: "8px"
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ marginBottom: 10, backgroundColor: blue[500], padding: "10px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                      >
                        <Typography variant="h6" style={{ fontSize: "1.1rem", color: "white" }}>
                        {props.t("HomeUser.TotalQuantities")}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ padding: "10px" }}>
                        <Typography variant="button">{props.t("HomeUser.Produced").toUpperCase()}</Typography>
                        <br />
                        <Typography variant="h6">{unitsProduced.current}</Typography>
                        <Typography variant="caption">{props.t("HomeUser.Units")}</Typography>
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        style={{
                          padding: "10px"
                        }}
                      >
                        <Typography variant="button">{props.t("HomeUser.Target").toUpperCase()}</Typography>
                        <br />
                        <Typography variant="h6">{Math.round(unitsTarget.current)}</Typography>
                        <Typography variant="caption">{props.t("HomeUser.Units")}</Typography>
                      </Grid>

                      <Grid item xs={4} style={{ padding: "10px" }}>
                        <Typography variant="button">{props.t("HomeUser.Productivity").toUpperCase()}</Typography>
                        <br />
                        <Typography variant="h6">{Math.round((unitsProduced.current / unitsTarget.current) * 100 * 10) / 10 || 0}%</Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 0,

                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        border: "1px solid rgba(225, 225, 225, 1)"
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: 10,
                          backgroundColor: blue[500],
                          padding: "10px",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px"
                        }}
                      >
                        <Typography variant="h6" style={{ fontSize: "1.1rem", color: "white" }}>
                        {props.t("HomeUser.Overview")}
                        </Typography>
                      </Grid>
                      {/* <div style={{ border: "1px solid rgba(225, 225, 225, 1)", backgroundColor: "rgba(225, 225, 225, 0.2)" }}> */}
                      <div style={{ padding: "10px" }}>
                        <ResponsiveContainer width="100%" height={300}>
                          <AreaChart data={finalWorkEfficiencyArray} margin={{ top: 0, right: 0, left: -5, bottom: 20 }}>
                            {/* <defs>
                              <linearGradient id="workEfficiency" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                              </linearGradient>
                            </defs> */}
                            <XAxis dataKey="name">
                              {/* <Label value="TIME [DAYS]" offset={0} position="insideBottom" style={{ textAnchor: "middle" }} /> */}
                              <Label
                                width={30}
                                position="center"
                                content={
                                  <text x="50%" y="290" fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
                                    <tspan alignmentBaseline="middle" fontSize="14">
                                    {props.t("HomeUser.TimeDays").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />
                            </XAxis>
                            <YAxis>
                              <Label
                                position="center"
                                content={
                                  <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                    <tspan x="36" dy="0.355em">
                                    {props.t("HomeUser.ProductivityPercentage").toUpperCase()}
                                    </tspan>
                                  </text>
                                }
                              />

                              {/* <Label value="EFFICIENCY [%]" offset={0} position="Left" angle={-90} textAnchor="middle" />
                              <Label value="EFFICIENCY [%]" offset={0} position="Left" angle={-90} textAnchor="middle" /> */}
                            </YAxis>
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip content={<ProductivityTooltip />} />
                            {/* <Area type="monotone" dataKey="Target" stroke="#e8d0b5" fill="#e8d0b5" /> */}

                            <Area
                              name={props.t("HomeUser.ProductivityTarget")}
                              type="monotone"
                              dataKey="TargetPercentage"
                              stroke="rgba(200, 200, 200, 1)"
                              fill="rgba(230, 230, 230, 0.5)"
                            />
                            <Area name={props.t("HomeUser.ProductivityPercentage")} type="monotone" dataKey="Productivity" stroke="#8884d8" fill="#8884d8" />
                            <Legend verticalAlign="top" height={36} />
                            {/* <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" /> */}
                            {/* <Legend verticalAlign="middle"  */}
                          </AreaChart>
                        </ResponsiveContainer>
                      </div>

                      <Grid item xs={12} style={{ marginTop: 10, padding: "10px" }}>
                        <ExpansionPanel>
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                            {props.t("HomeUser.RawProductivityData")}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Table style={{overflowX: "auto"}}>
                              <TableHead>
                                <TableRow>{insertProdTableHead()}</TableRow>
                              </TableHead>
                              <TableBody>{insertProdTableRows()}</TableBody>
                            </Table>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Paper>
            </Grid>

            <Grid item xs={md ? 6 : 12}>
              <Paper
                className={classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                  elevation1: classes.paperShadow
                }}
              >
                {/* <Grid item xs={12} style={{ padding: "10px" }}>
                  <Typography variant="h6">Timeline</Typography>
                  <Typography variant="h6" style={{ color: blue[500], marginBottom: 20, fontSize: "1.0rem" }}>
                    {monthNames[new Date().getMonth()]}, {new Date().getFullYear()}
                  </Typography>
                </Grid> */}

                <Grid container style={{ padding: "10px", paddingLeft: "0px", paddingTop: "0px" }}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                    <Typography variant="h6" style={{display: "inline-block"}}>{props.t("HomeUser.Timeline")}</Typography>
                    </Grid>

                    <Grid item>
                    <TimelineIcon style={{ position: "relative", top: "3px", left: "8px", fontSize: "1.75rem"}} />
                    </Grid>

                    <Typography variant="h6" style={{ color: blue[500], marginBottom: 0, fontSize: "1.0rem", display: "inline-block", float:"right", paddingTop: "4px", marginLeft: "auto", marginRight: "0px" }}>
                    {monthNames[new Date().getMonth()]}, {new Date().getFullYear()}
                  </Typography>
                  </Grid>
                </Grid>

                {loading ? (
                  <Grid item xs={12} align="center">
                    <div style={{ position: "relative" }}>
                      <CircularProgress />
                    </div>
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Grid
                      container
                      spacing={0}
                      style={{
                        marginBottom: 20,
                        paddingBottom: 5,
                        border: "1px solid rgba(225, 225, 225, 1)",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ marginBottom: 10, backgroundColor: blue[500], padding: "10px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                      >
                        <Typography variant="h6" style={{ fontSize: "1.1rem", color: "white" }}>
                        {props.t("HomeUser.TotalWorkingTime")}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ padding: "10px" }}>
                        <Typography variant="button">{props.t("HomeUser.Worked").toUpperCase()}</Typography>
                        <br />
                        <Typography variant="h6">{Math.round(timeSpentWorking.current)}</Typography>
                        <Typography variant="caption">{props.t("HomeUser.Minutes").toLowerCase()}</Typography>
                      </Grid>

                      <Grid item xs={4} style={{ padding: "10px" }}>
                        <Typography variant="button">{props.t("HomeUser.Target").toUpperCase()}</Typography>
                        <br />
                        <Typography variant="h6">{Math.round(targetTimeSpentWorking.current)}</Typography>
                        <Typography variant="caption">{props.t("HomeUser.Minutes").toLowerCase()}</Typography>
                      </Grid>

                      <Grid item xs={4} style={{ padding: "10px" }}>
                        <Typography variant="button">{props.t("HomeUser.Progression").toUpperCase()}</Typography>
                        <br />
                        <Typography variant="h6">{Math.round((timeSpentWorking.current / targetTimeSpentWorking.current) * 100 * 10) / 10}%</Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 0,

                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        border: "1px solid rgba(225, 225, 225, 1)"
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: 10,
                          backgroundColor: blue[500],
                          padding: "10px",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px"
                        }}
                      >
                        <Typography variant="h6" style={{ fontSize: "1.1rem", color: "white" }}>
                        {props.t("HomeUser.Overview")}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        {/* <div style={{ border: "1px solid rgba(225, 225, 225, 1)", backgroundColor: "rgba(225, 225, 225, 0.2)" }}> */}
                        <div style={{ padding: "10px" }}>
                          <ResponsiveContainer width="100%" height={300}>
                            <AreaChart data={timeSpentWorkingArray} margin={{ top: 0, right: 0, left: -5, bottom: 20 }}>
                              <defs>
                                <linearGradient id="timeline" x1="0" y1="0" x2="0" y2="1">
                                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.9} />
                                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                </linearGradient>
                              </defs>
                              <XAxis dataKey="name">
                                <Label
                                  width={30}
                                  position="center"
                                  content={
                                    <text
                                      x="50%"
                                      y="290"
                                      fill="#3d405c"
                                      className="recharts-text recharts-label"
                                      textAnchor="middle"
                                      dominantBaseline="central"
                                    >
                                      <tspan alignmentBaseline="middle" fontSize="14">
                                      {props.t("HomeUser.TimeDays").toUpperCase()}
                                      </tspan>
                                    </text>
                                  }
                                />
                              </XAxis>
                              <YAxis>
                                <Label
                                  position="center"
                                  content={
                                    <text x="36" y="95" transform="rotate(-90, 36, 125)" className="recharts-text recharts-label" textAnchor="middle">
                                      <tspan x="36" dy="0.355em">
                                      {props.t("HomeUser.ProgressionPercentage").toUpperCase()}
                                      </tspan>
                                    </text>
                                  }
                                />
                              </YAxis>
                              <CartesianGrid strokeDasharray="3 3" />
                              <Tooltip content={<TimelineTooltip />} />

                              <Area
                                name={props.t("HomeUser.ProgressionTarget")}
                                type="monotone"
                                dataKey="TargetPercentage"
                                stroke="rgba(200, 200, 200, 1)"
                                fill="rgba(230, 230, 230, 0.5)"
                              />
                              <Area name={props.t("HomeUser.Progression")} type="monotone" dataKey="Efficiency" stroke="#82ca9d" fill="#82ca9d" />
                              <Legend verticalAlign="top" height={36} />
                            </AreaChart>
                          </ResponsiveContainer>
                        </div>
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 10, padding: "10px" }}>
                        <ExpansionPanel>
                          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" style={{ fontSize: "0.9rem" }}>
                            {props.t("HomeUser.RawTimelineData")}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Table style={{overflowX: "auto"}}>
                              <TableHead>
                                <TableRow>{insertTimelineTableHead()}</TableRow>
                              </TableHead>
                              <TableBody>{insertTimelineTableRows()}</TableBody>
                            </Table>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
        // #endregion
        )}
      </div>
    );
  }

  function redirectToTimesheet() {
    return <Redirect to={"/Timesheet"} style={{ textDecoration: "none" }} key={"ToHome"} />;
  }

  if (redirect) {
    return <Redirect to={"/Timesheet"} style={{ textDecoration: "none" }} key={"ToTimesheet"} />;
  }
  else if(redirectToEditEntries){
    return <Redirect to={"/EditEntries"}  style={{ textDecoration: "none" }} key={"ToEditEntries"} />;
  } 
  else {
    return (
      <ThemeProvider theme={theme}>
        <div>{props.userDataProp.role === "Admin" || props.userDataProp.role === "Database admin" ? launchPageAdmin() : launchPageUser()}</div>
      </ThemeProvider>
    );
  }
}

const drawerWidth = 250;
const minToolbarHeight = 64;

const useStyles = makeStyles(theme => ({
  // monthStyle:{
  //   fontSize: "20px"
  // },

  timeText: {
    fontSize: "1.2rem",
    color: "#FFFFFF"
  },

  buttonHover: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    "&:hover":{
      backgroundColor: "rgba(232, 154, 17, 1)",
      color: "#FFFFFF"
    }
  },

  paperRoot: {
    backgroundColor: blue[500]
  },

  paperShape: {
    borderRadius: 8
  },
  paperShadow: {
    boxShadow: "0 10px 45px -10px rgba(0, 0, 0, 0.2)"
  },
  paperProps: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  paperPropsMobile: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3)
    }
  },

  launchPageUser: {
    marginLeft: "32px",
    marginRight: "32px",
    paddingTop: minToolbarHeight + 32,
    // `calc(${minToolbarHeight}px + 40px)`,

    [theme.breakpoints.up("sm")]: {
      // paddingLeft: `calc(${drawerWidth}px + 100px)`,
      // paddingTop: "10%"
    }
  },

  launchPageUserMobile: {
    paddingLeft: "5%",
    paddingRight: "5%",
    marginTop: `calc(${minToolbarHeight}px + 22px)`,
  },

  launchPage: {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: `calc(${minToolbarHeight}px + 40px)`,

    [theme.breakpoints.up("sm")]: {
      paddingLeft: `calc(${drawerWidth}px + 100px)`,
      paddingTop: `calc(${minToolbarHeight}px + 40px)`
    }
  },

  launchPageAdmin: {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: `calc(${minToolbarHeight}px + 20px)`,

    [theme.breakpoints.up("lg")]: {
      paddingLeft: `calc(${drawerWidth}px + 100px)`,
      paddingTop: `calc(${minToolbarHeight}px + 40px)`
    }
  },

  backgroundUser: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    // height: "100vh",
    height: "300px"

    // [theme.breakpoints.up("sm")]: {
    //   backgroundSize: "cover",
    //   width: "195%"
    // },

    // [theme.breakpoints.up("md")]: {
    //   width: "130%"
    // },
    // [theme.breakpoints.up("lg")]: {
    //   width: "100%"
    // }
  },

  background: {
    backgroundImage: `url(${bgImageMobile})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    width: "100%",
    height: "100vh",

    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${bgImageMobile})`,
      backgroundSize: "cover",
      width: "100%"
    },

    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${bgImageMobile})`,
      width: "100%"
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: `url(${bgImageMobile})`,
      width: "100%"
    }
  },

  helloText: {
    fontSize: "4vmin",

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4rem"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem"
    }
  },

  welcomeText: {
    marginRight: "0%",
    fontSize: "3.0rem",

    [theme.breakpoints.up("sm")]: {
      marginRight: "60%",
      fontSize: "3.0rem"
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "30%",
      fontSize: "3.4rem"
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "0%",
      fontSize: "3.75rem"
    }
  },

  welcomeTextAdmin: {
    width: "100%",
    fontSize: "9vmin",
    marginTop: "20px",

    [theme.breakpoints.up("sm")]: {
      marginRight: "60%",
      fontSize: "3.0rem"
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "30%",
      fontSize: "3.4rem"
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "0%",
      fontSize: "3.75rem"
    }
  },

  subTitleText: {
    marginRight: "0%",
    fontSize: "1.5rem",
    fontWeight: "300",
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      marginRight: "20px",
      fontSize: "1.5rem",
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "0%",
      fontSize: "2rem",
      width: "100%"
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "0%",
      fontSize: "2rem",
      width: "100%"
    }
  },

  timeText: {
    fontSize: "1.25rem",
  },

  borderMargin: {
    width: "30%",
    [theme.breakpoints.up("sm")]: {
      width: "30%"
    },
    [theme.breakpoints.up("md")]: {
      width: "40%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%"
    }
  },

  borderMarginAdmin: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "30%"
    },
    [theme.breakpoints.up("md")]: {
      width: "40%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%"
    }
  }

  // launchPage: {
  //   paddingLeft: "5%",
  //   // paddingRight: "5%",
  //   paddingTop: `calc(${minToolbarHeight}px + 5%)`,

  //   [theme.breakpoints.up("sm")]: {
  //     paddingLeft: `calc(${drawerWidth}px + 100px)`,
  //     // PaddingRight: "10%",
  //     paddingTop: `calc(${minToolbarHeight}px + 40px)`
  //   }
  // },

  // background: {
  //   backgroundImage: `url(${bgImage})`,
  //   backgroundSize: "100% 90%",
  //   backgroundRepeat: "no-repeat",
  //   width: "160%",
  //   height: "100vh",

  //   [theme.breakpoints.up("sm")]: {
  //     backgroundSize: "100% 90%",
  //     width: "145%"
  //   },

  //   [theme.breakpoints.up("md")]: {
  //     width: "130%"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     width: "100%"
  //   }
  // },

  // registerText: {
  //   marginRight: "50%",
  //   [theme.breakpoints.up("sm")]: {
  //     marginRight: "50%"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     marginRight: "60%"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     marginRight: "0%"
  //   }
  // },

  // borderMargin: {
  //   width: "50%",
  //   [theme.breakpoints.up("sm")]: {
  //     width: "40%"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     width: "40%"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     width: "50%"
  //   }
  // }
}));

export default withRouter(Home);
