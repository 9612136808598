import { SwipeableDrawer } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import AssessmentIcon from "@material-ui/icons/AssessmentOutlined";
import BuildIcon from "@material-ui/icons/Build";
import DateRangeIcon from "@material-ui/icons/DateRange";
import StorageIcon from "@material-ui/icons/Storage";
import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import "typeface-roboto";
import ponefaLogo from "../ponefaLogo.png";

function SidePanel(props) {
  const classes = useStyles();
  const [tabState, setTabState] = useState(-1);
  const iOS = (typeof window === "undefined") && /iPad|iPhone|iPod/.test(navigator.userAgent);

  function changeTabState(arg) {
    setTabState(arg);
  }

  const categories = [
    {
      name: props.t("SidePanel.DataIO").toUpperCase(),
      children: [
        { name: props.t("SidePanel.AnnualLeave"), icon: <DateRangeIcon />, path: "/AnnualLeave", item: 0 },
        { name: props.t("SidePanel.Database"), icon: <StorageIcon />, path: "/Database", item: 1 },
        { name: props.t("SidePanel.Repairs"), icon: <BuildIcon />, path: "/Repairs", item: 2 }
        // { name: "Time Registration", icon: <DescriptionIcon />, path: "/Timesheet", item: 3 }

        // { name: "Login", icon: <DnsIcon/>, path: "/Login", item: 2}
      ]
    },
    {
      name: props.t("SidePanel.Analytics").toUpperCase(),
      children: [
        { name: props.t("SidePanel.Productivity"), icon: <AssessmentIcon />, path: "/Productivity", item: 3 }
        // { name: "Charts 2", icon: <AssessmentIcon />, path: "/", item: 3 },
        // { name: "Charts 3", icon: <AssessmentIcon />, path: "/", item: 4 }
      ]
    }
  ];

  function test(event, item) {
    setTabState(item);
  }

  const drawer = (
    <div>
      <List className={classes.list}>
        <Link to={"/Home"} style={{ textDecoration: "none" }} key={"Home"}>
          <ListItem
            className={classes.header}
            classes={{
              root: classes.ponefaLogoRoot,
              gutters: classes.ponefaLogoGutters
            }}
            style={{ minHeight: minToolbarHeight, paddingLeft: "0px" }}
          >
            {/* <ListItemIcon className={classes.itemIcon}>
            <img src={ponefaLogo} alt="ponefa-logo" className={classes.ponefaLogo} />
          </ListItemIcon> */}
            {/* <Typography variant="button" style={{color: "#FFFFFF"}}>
            Insert logo
            </Typography> */}
            <img
              src={ponefaLogo}
              alt="Ponefa Logo"
              style={{ maxHeight: "50px", marginLeft: "auto", marginRight: "auto", transition: "all 0.2s ease-in" }}
              className={classes.imageHover}
            />
            {/* //    filter: drop-shadow(0px 0px 10px rgba(34, 150, 243, 0.75)); */}
          </ListItem>
        </Link>

        {categories.map(i => (
          <React.Fragment key={Math.random().toString(36).substr(2, 9)}>

            <div>
            {(props.userDataProp.role === "Database admin" && i.name === props.t("SidePanel.Analytics").toUpperCase()) ? (null) : (
            <React.Fragment>
            <ListItem className={classes.categoryHeader}>
              <ListItemText classes={{ primary: classes.categoryHeaderText }}>{i.name}</ListItemText>
            </ListItem>
            </React.Fragment>
            )}
            </div>

            {i.children.map(j => (
              <div key={Math.random().toString(36).substr(2, 9)}>
              {(props.userDataProp.role === "Database admin" && j.name === props.t("SidePanel.Productivity")) ? (null) : (
              <React.Fragment>
              <Link to={j.path} style={{ textDecoration: "none" }} key={j.name}>
                <ListItem
                  button
                  dense
                  key={j.name}
                  className={j.item === tabState ? classes.activeItem : classes.item}
                  onClick={(event, value) => test(event, j.item)}
                >
                  <ListItemIcon className={classes.itemIcon}>{j.icon}</ListItemIcon>
                  <ListItemText classes={{ primary: classes.itemText }}>{j.name}</ListItemText>
               
                </ListItem>
              </Link>
              </React.Fragment>
              )}
            </div>
            ))}
            {/* <Divider
              variant="middle"
              className={classes.divider}
              classes={{ root: classes.dividerRoot }}
            /> */}
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <SwipeableDrawer
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            variant="temporary"
            open={props.drawerOpenProp}
            onClose={props.toggleDrawerProp}
            onOpen={props.toggleDrawerProp}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <SwipeableDrawer
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={props.toggleDrawerProp}
            onOpen={props.toggleDrawerProp}
            variant="permanent"
            open
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
      </nav>
    </div>
  );
}

const drawerWidth = 250;

const drawerColor = "#19222b"; //"#19212b";
const drawerSecondaryColor = "#26303c";
const drawerActiveColor = "#4fc3f7";
const color = "rgba(255, 255, 255, 0.8)";
const dividerColor = "#404854";
const minToolbarHeight = 64;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  imageHover: {
    "&:hover": {
      filter: "drop-shadow(0px 0px 13px rgba(255, 22, 0, 0.75))",
      transition: "all 0.2s ease-in"
    }
  },

  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    background: blue[500],
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: drawerColor,
    "& *": {
      color
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  theme1: {
    background: drawerColor, //"linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
    border: 0,
    borderRadius: 0,
    boxShadow: "0 3px 5px 2px rgba(33, 150, 243, 0.3)",
    color: "white",
    borderColor: "yellow !important",
    borderWidth: "5px",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  theme2: {
    background: "linear-gradient(45deg, #000000 30%, #21CBF3 90%)",
    border: 0,
    borderRadius: 0,
    boxShadow: "0 3px 5px 2px rgba(33, 150, 243, 0.3)",
    color: "white",
    borderColor: "yellow !important",
    borderWidth: "5px",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  list: {
    padding: 0
  },
  header: {
    background: drawerSecondaryColor,
    // boxShadow: `0px 1px 0px 0px ${dividerColor}`,
    maxHeight: minToolbarHeight
  },
  itemIcon: {
    margin: 0
  },
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // background: drawerSecondaryColor,
    // boxShadow: (`0px 1px 0px 0px ${dividerColor}`, `inset 0px -1px 0px 0px ${dividerColor}`),

    boxShadow: `0 -1px 0px 0px ${dividerColor}` //,
    //0 -1px 0px 0px ${dividerColor}`
  },
  categoryHeaderText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.common.white,
    "&$textDense": {
      fontSize: 14,
      fontWeight: 500
    }
  },
  item: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:hover": {
      background: drawerSecondaryColor, //"rgba(255, 255, 255, 0.1)",
      "& *": {
        color: drawerActiveColor
      }
    }
  },

  activeItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: drawerSecondaryColor, //"rgba(255, 255, 255, 0.1)",
    "& *": {
      color: drawerActiveColor
    }

    // "&:hover": {
    //   background: "rgba(255, 255, 255, 0.1)",
    //   "& *": {
    //     color: drawerActiveColor
    //   }
    // }
  },

  // activeItem: {
  //   "& *": {
  //     color: drawerActiveColor
  //   }
  // },
  itemIcon: {
    margin: 0
  },

  itemText: {
    fontSize: 14,
    fontWeight: 400
  },
  itemTextDisabled: {
    fontSize: 14,
    fontWeight: 400
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  ponefaLogo: {
    maxWidth: "60%"
  },
  ponefaStuff: {
    paddingTop: 0,
    paddingBottom: 0
  },
  ponefaLogoRoot: {
    paddingTop: 0,
    paddingBottom: 0
  },
  ponefaLogoGutters: {
    paddingLeft: "5%",
    paddingRight: 0
  },
  toolbarRegular: {
    minHeight: minToolbarHeight
  },
  dividerRoot: {
    backgroundColor: dividerColor
  }
}));

export default withRouter(SidePanel);
