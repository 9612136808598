import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import UserForm from "./components/UserForm";
import Dashboard from "./components/Dashboard";
import { I18nextProvider } from "react-i18next";
import "./i18n";
import Axios from "axios";

Axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL
Axios.defaults.withCredentials = true
ReactDOM.render(<Dashboard />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
