/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
import DateFnsUtils from "@date-io/date-fns";
import { Button, CircularProgress, createMuiTheme, Grid, IconButton, InputAdornment, Paper, TextField, Typography, useMediaQuery } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import Axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import bgImage from "./../bg_1.jfif";
import bgLandscape from "./../bg_1.jfif";
import CustomSnackbar from "./CustomSnackbar";
import Alert from '@material-ui/lab/Alert';
import "./Register.css"

function Register(props) {
  props.currentPathCallbackProp(props.location.pathname);
  const classes = useStyles(props);
  const [fields, setFields] = useState({
    firstName: "",
    lastName: "",
    dob: format(new Date(), "yyyy/MM/dd"),
    userName: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
    email: ""
  });
  const addressExtension = "/Authentication";
  const snackbarMessage = props.t("RegisterPage.SnackbarMessage");
  const autoHideDuration = 2500;
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [sucessfulMessage, setSuccessfulMessage] = useState("");
  const [successfulRegistration, setSuccessfulRegistration] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputsm = useMediaQuery(theme.breakpoints.down("sm"));
  const minWidth = useMediaQuery("(min-width:400px)");

  useEffect(() => {
    window.addEventListener("keypress", keyPress)
    return () => {
      window.removeEventListener("keypress", keyPress)
    }
  }, [])

  function keyPress(event) {
    if(event.key === "Enter") {
      registerPOST()
    }
  }

  function redirectToLogin() {
    return <Redirect to={"/"} style={{ textDecoration: "none" }} key={"ToLogin"} />;
  }

  function handleFieldChange(event, name) {
    setFields({ ...fields, [name]: event.target.value.trim() });
  }

  function handleClickShowPassword() {
    setFields({ ...fields, showPassword: !fields.showPassword });
  }

  function handleDateChange(event, date) {
    setFields({ ...fields, dob: date });
  }

  function closeSnackbarCallback() {
    setShowSnackbar(false);
  }

  function checkErrorMessages() {
    for (var i in fields) {
      if (fields[i] === "") {
        setErrorMessage(props.t("RegisterPage.Error1"));
        setSuccessful(false);
        setShowErrorMessage(true);
        return true;
      }
    }

    if (fields["userName"].length > 15) {
      setErrorMessage(props.t("RegisterPage.Error2"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (
      !new RegExp(
        /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/
      ).test(fields["email"])
    ) {
      setErrorMessage(props.t("RegisterPage.ValidEmailAddress"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (fields["password"].length <= 7) {
      setErrorMessage(props.t("RegisterPage.Error3"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (!new RegExp(/[0-9]/).test(fields["password"])) {
      setErrorMessage(props.t("RegisterPage.Error4"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (!new RegExp(/[A-Z]/).test(fields["password"])) {
      setErrorMessage(props.t("RegisterPage.Error5"));
      setShowErrorMessage(true);
      return true;
    }

    if (!new RegExp(/[!#@$%^&*)(+=._-]/).test(fields["password"])) {
      setErrorMessage(props.t("RegisterPage.Error6"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (fields.password != fields.confirmPassword) {
      setErrorMessage(props.t("RegisterPage.Error7"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (fields["firstName"].length > 35) {
      setErrorMessage(props.t("RegisterPage.Error8"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (fields["lastName"].length > 35) {
      setErrorMessage(props.t("RegisterPage.Error9"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    setShowErrorMessage(false);
    return false;
  }

  async function registerPOST() {
    if (checkErrorMessages()) {
      return;
    }
    setLoading(true);
    const address = props.baseAddressProp + addressExtension + "/Register";
    try {
      let result = await Axios.post(address, {
        UserName: fields.userName,
        Password: fields.password,
        FirstName: fields.firstName,
        LastName: fields.lastName,
        DateOfBirth: fields.dob,
        Email: fields.email
      });

      setSuccessful(true);
      setSuccessfulRegistration(true);
      setLoading(false);
    } catch (error) {
      setSuccessful(false);
      
      if (error.response?.data === "This username already exists") {
        setErrorMessage(props.t("RegisterPage.Error10"));
        setShowErrorMessage(true);
      }
      else if(error.response?.data === "This email already exists") {
        setErrorMessage(props.t("RegisterPage.Error12"));
        setShowErrorMessage(true);
      } else {
        setErrorMessage(props.t("RegisterPage.Error11"));
        setShowErrorMessage(true);
      }
      setLoading(false);
    }
  }

  function registrationSuccessfull() {
    return (
      <ThemeProvider theme={theme}>
        {inputsm ? (
          // #region MOBILE
          <div className={classes.backgroundLandscape} style={{backgroundPosition: "40% 100%"}}>
            <div className={classes.paperPositionMobileFixed}>
              <Paper
                className={classes.paperPropsMobile}
                classes={{
                  rounded: classes.paperShape,
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      border: "1px solid rgb(34, 150, 243)",
                      borderRadius: "4px",
                      backgroundColor: "rgba(34, 150, 243, 0.1)",
                      marginBottom: "8px"
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{marginLeft: "auto", marginRight: "auto", textAlign: "center" }}
                      className="header4"
                    >
                      {props.t("RegisterPage.RegistrationSuccessful")}
                    </Typography>
                  </Grid>

                  <Alert severity="warning" style={{paddingLeft: "12px"}}>
                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", fontWeight: 550, display: "inline" }}>
                      {props.t("RegisterPage.Note")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", display: "inline", fontWeight: 400 }}>
                      {props.t("RegisterPage.Administrator")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", color: "#2296f3", display: "inline", fontWeight: 400 }}>
                      {props.t("RegisterPage.Approval")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", display: "inline", fontWeight: 400 }}>
                      {props.t("RegisterPage.Required")}
                    </Typography>
                  </Alert>

                  <Grid item xs={12} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <div style={{ display: "flex" }}>
                      <Link to={"/Database"} style={{ textDecoration: "none", width: "100%" }} key={"previous"}>
                        <Button variant="contained" color="primary" style={{ width: "100%" }} className={"no-shadow button-padding"}>
                          {props.t("RegisterPage.Continue")}
                        </Button>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </div>
        ) : (
          //# endregion
          // #region DESKTOP
          <div className={classes.backgroundLandscape}>
            <div className={props.loggedInProp ? classes.paperPositionSuccessful : classes.paperPositionSuccessfulNotLoggedIn}>
              <Paper
                className={classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      border: "1px solid rgb(34, 150, 243)",
                      borderRadius: "4px",
                      backgroundColor: "rgba(34, 150, 243, 0.1)",
                      marginBottom: "8px"
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}
                      className="header4"
                    >
                      {props.t("RegisterPage.RegistrationSuccessful")}
                    </Typography>
                  </Grid>

                  <Alert severity="warning" style={{paddingLeft: "12px"}}>
                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", fontWeight: 550, display: "inline" }}>
                      {props.t("RegisterPage.Note")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", display: "inline", fontWeight: 400 }}>
                      {props.t("RegisterPage.Administrator")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", color: "#2296f3", display: "inline", fontWeight: 400 }}>
                      {props.t("RegisterPage.Approval")}
                    </Typography>

                    <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", display: "inline", fontWeight: 400 }}>
                      {props.t("RegisterPage.Required")}
                    </Typography>
                  </Alert>

                  <Grid item xs={12} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <div style={{ display: "flex" }}>
                      <Link to={"/Database"} style={{ textDecoration: "none", marginLeft: "auto", marginRight: "0px" }} key={"previous"}>
                        <Button variant="contained" color="primary" className={"no-shadow button-padding"}>
                          {props.t("RegisterPage.Continue")}
                        </Button>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </div>
        )
        //# endregion
        }
      </ThemeProvider>
    );
  }

  return (
    <React.Fragment>
      {successfulRegistration ? (
        registrationSuccessfull()
      ) : (
        <React.Fragment>
          <div className={classes.backgroundLandscape} style={{backgroundPosition: `${inputsm ? "40% 100%" : "unset !important"}`}}>
            {inputsm ? (
              // #region MOBILE
              <ThemeProvider theme={theme}>
                <div className={props.loggedInProp ? classes.paperPositionLoggedIn : classes.paperPositionMobile}>
                  <Paper
                    className={classes.paperPropsMobile}
                    classes={{
                      rounded: classes.paperShape,
                    }}
                  >
                    <Grid container spacing={0} style={{flexDirection: "column"}}>
                      <Typography variant="h4" className="header4" style={{ marginBottom: "16px" }}>
                        {props.t("RegisterPage.SignUp")}
                      </Typography>

                      <Alert severity="warning" style={{marginBottom: "16px", paddingLeft: "12px"}}>
                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", fontWeight: 550, display: "inline" }}>
                          {props.t("RegisterPage.Note")}
                        </Typography>

                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", display: "inline", fontWeight: 400 }}>
                          {props.t("RegisterPage.Administrator")}
                        </Typography>

                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", color: "#2296f3", display: "inline", fontWeight: 400 }}>
                          {props.t("RegisterPage.Approval")}
                        </Typography>

                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", display: "inline", fontWeight: 400 }}>
                          {props.t("RegisterPage.Required")}
                        </Typography>
                      </Alert>

                      <Grid item xs={12} style={{ marginBottom: "5px" }}>
                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin" }}>
                          {props.t("RegisterPage.UserAccount")}
                        </Typography>
                      </Grid>
                      

                      <Grid item xs={12}>
                        <TextField
                          style={{ marginTop: 8 }}
                          id="outlined-userName"
                          label={props.t("RegisterPage.Username")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          value={fields.userName}
                          onChange={event => handleFieldChange(event, "userName")}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          style={{ marginTop: 8 }}
                          id="outlined-email"
                          label={props.t("Database.EmailAddress")}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          value={fields.email}
                          onChange={event => handleFieldChange(event, "email")}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-adornment-password"
                          variant="outlined"
                          type={fields.showPassword ? "text" : "password"}
                          label={props.t("RegisterPage.Password")}
                          fullWidth
                          value={fields.password}
                          margin="dense"
                          onChange={event => handleFieldChange(event, "password")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                                  {fields.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-adornment-password"
                          variant="outlined"
                          type={fields.showPassword ? "text" : "password"}
                          label={props.t("RegisterPage.ConfirmPassword")}
                          fullWidth
                          value={fields.confirmPassword}
                          margin="dense"
                          onChange={event => handleFieldChange(event, "confirmPassword")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                                  {fields.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          marginTop: 8,
                          marginBottom: 20,
                          color: "#757575",
                          padding: "12px",
                          border: "1px solid rgb(34, 150, 243)",
                          borderRadius: "4px",
                          backgroundColor: "rgba(34, 150, 243, 0.1)"
                        }}
                      >
                        <Typography variant="caption" style={{ fontSize: minWidth ? "0.75rem" : "3vmin" }}>
                          <em>
                            {props.t("RegisterPage.Requirement1")} <br />
                            {props.t("RegisterPage.Requirement2")} <br />
                            {props.t("RegisterPage.Requirement3")} <br />
                            {props.t("RegisterPage.Requirement4")}
                            <br />
                          </em>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 0, marginBottom: "5px" }}>
                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.25rem" : "5vmin" }}>
                          {props.t("RegisterPage.PersonalInfo")}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          style={{ marginTop: 8 }}
                          id="outlined-firstName"
                          label={props.t("RegisterPage.FirstName")}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={fields.firstName}
                          onChange={event => handleFieldChange(event, "firstName")}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-lastName"
                          label={props.t("RegisterPage.LastName")}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={fields.lastName}
                          onChange={event => handleFieldChange(event, "lastName")}
                        />
                      </Grid>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={12} classes={{ root: classes.timePickers }}>
                          <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            format="yyyy/MM/dd"
                            fullWidth
                            margin="dense"
                            id="mui-pickers-date"
                            label={props.t("RegisterPage.Dob")}
                            value={fields.dob}
                            onChange={(event, date) => handleDateChange(event, date)}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>

                      <Grid item xs={12} style={{ marginBottom: 20 }} />

                      <Grid item xs={12} style={{ marginBottom: "12px" }}>
                        {showErrorMessage ? (
                          <Alert severity="error">
                              <b>{props.t("RegisterPage.Error")} </b>
                              {errorMessage}
                            </Alert>
                        ) : successful ? (
                          <Typography variant="caption">
                            {props.t("RegisterPage.YourRegistrationHasBeen")} <b>{props.t("RegisterPage.Successful")}</b>.
                          </Typography>
                        ) : null}
                      </Grid>

                      <Grid item xs={12}>
                        <div style={{ display: "flex" }}>
                          {props.loggedInProp ? (
                            <Link to={"/Database"} style={{ textDecoration: "none", width: "50%" }} key={"table"}>
                              <Button variant="outlined" color="primary" style={{ width: "100%" }}>
                                {props.t("RegisterPage.Back")}
                              </Button>
                            </Link>
                          ) : (
                            <Link to={"/"} style={{ textDecoration: "none", width: "50%" }} key={"previous"}>
                              <Button variant="outlined" color="primary" style={{ width: "100%" }}>
                                {props.t("RegisterPage.Back")}
                              </Button>
                            </Link>
                          )}

                          <Button variant="contained" color="primary" onClick={registerPOST} style={{ width: "50%", marginLeft: "12px" }} className={"no-shadow button-padding"} disabled={loading}>
                            {props.t("RegisterPage.SignUp")}
                            {loading && <CircularProgress size={24} style={{top: "17%", position: "absolute"}} />}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </ThemeProvider>
            ) : (
              // #endregion
              // #region DESKTOP
              <ThemeProvider theme={theme}>
                <div className={props.loggedInProp ? classes.paperPositionLoggedIn : classes.paperPosition}>
                  <Paper
                    className={classes.paperProps}
                    classes={{
                      rounded: classes.paperShape,
                    }}
                  >
                    <Grid container spacing={0}>
                      <Typography variant="h4" style={{ marginBottom: 25 }} className="header4">
                        {props.t("RegisterPage.SignUp")}
                      </Typography>

                      <Alert severity="warning" style={{marginBottom: "16px", paddingLeft: "12px"}}>
                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", fontWeight: 550, display: "inline" }}>
                          {props.t("RegisterPage.Note")}
                        </Typography>

                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", display: "inline", fontWeight: 400 }}>
                          {props.t("RegisterPage.Administrator")}
                        </Typography>

                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", color: "#2296f3", display: "inline", fontWeight: 400 }}>
                          {props.t("RegisterPage.Approval")}
                        </Typography>

                        <Typography variant="h6" style={{ fontSize: minWidth ? "1.0rem" : "4vmin", paddingLeft: "6px", display: "inline", fontWeight: 400 }}>
                          {props.t("RegisterPage.Required")}
                        </Typography>
                      </Alert>

                      <Grid item xs={12} style={{ marginBottom: "5px" }}>
                        <Typography variant="h6">{props.t("RegisterPage.UserAccount")}</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          style={{ marginTop: 8 }}
                          id="outlined-userName"
                          label={props.t("RegisterPage.Username")}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          value={fields.userName}
                          onChange={event => handleFieldChange(event, "userName")}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          style={{ marginTop: 8 }}
                          id="outlined-email"
                          label={props.t("Database.EmailAddress")}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          value={fields.email}
                          onChange={event => handleFieldChange(event, "email")}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-adornment-password"
                          variant="outlined"
                          type={fields.showPassword ? "text" : "password"}
                          label={props.t("RegisterPage.Password")}
                          fullWidth
                          value={fields.password}
                          margin="normal"
                          onChange={event => handleFieldChange(event, "password")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                                  {fields.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-adornment-password"
                          variant="outlined"
                          type={fields.showPassword ? "text" : "password"}
                          label={props.t("RegisterPage.ConfirmPassword")}
                          fullWidth
                          value={fields.confirmPassword}
                          margin="normal"
                          onChange={event => handleFieldChange(event, "confirmPassword")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                                  {fields.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          marginTop: 8,
                          marginBottom: 20,
                          color: "#757575",
                          padding: "12px",
                          border: "1px solid rgb(34, 150, 243)",
                          borderRadius: "4px",
                          backgroundColor: "rgba(34, 150, 243, 0.1)"
                        }}
                      >
                        <Typography variant="caption" style={{ fontSize: minWidth ? "0.75rem" : "3vmin" }}>
                          <em>
                            {props.t("RegisterPage.Requirement1")} <br />
                            {props.t("RegisterPage.Requirement2")} <br />
                            {props.t("RegisterPage.Requirement3")} <br />
                            {props.t("RegisterPage.Requirement4")}
                            <br />
                          </em>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 0, marginBottom: "5px" }}>
                        <Typography variant="h6">{props.t("RegisterPage.PersonalInfo")}</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          style={{ marginTop: 8 }}
                          id="outlined-firstName"
                          label={props.t("RegisterPage.FirstName")}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          value={fields.firstName}
                          onChange={event => handleFieldChange(event, "firstName")}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="outlined-lastName"
                          label={props.t("RegisterPage.LastName")}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          value={fields.lastName}
                          onChange={event => handleFieldChange(event, "lastName")}
                        />
                      </Grid>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={12} classes={{ root: classes.timePickers }}>
                          <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            format="yyyy/MM/dd"
                            fullWidth
                            margin="normal"
                            id="mui-pickers-date"
                            label={props.t("RegisterPage.Dob")}
                            value={fields.dob}
                            onChange={(event, date) => handleDateChange(event, date)}
                            //   onChange={(event, date) => {
                            //     handleDatePickerChange(event, date, "dob");
                            //   }}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>

                      <Grid item xs={12} style={{ marginBottom: 20 }} />

                      <Grid item xs={12} style={{ marginBottom: "12px" }}>
                        {showErrorMessage ? (
                           <Alert severity="error">
                              <b>{props.t("RegisterPage.Error")} </b>
                              {errorMessage}
                            </Alert>
                        ) : successful ? (
                          <Typography variant="caption">
                            {props.t("RegisterPage.YourRegistrationHasBeen")} <b>{props.t("RegisterPage.Successful")}</b>.
                          </Typography>
                        ) : null}
                      </Grid>

                      <Grid item xs={12}>
                        <div className={classes.buttons}>
                          {props.loggedInProp ? (
                            <Link to={"/Database"} style={{ textDecoration: "none" }} key={"table"}>
                              <Button variant="outlined" color="primary" className={classes.buttonSpacing}>
                                {props.t("RegisterPage.Back")}
                              </Button>
                            </Link>
                          ) : (
                            <Link to={"/"} style={{ textDecoration: "none" }} key={"previous"}>
                              <Button variant="outlined" color="primary" className={`${classes.buttonSpacing} button-padding`}>
                                {props.t("RegisterPage.Back")}
                              </Button>
                            </Link>
                          )}

                          <Button variant="contained" color="primary" onClick={registerPOST} className={`${classes.buttonSpacing} button-padding no-shadow`} disabled={loading}>
                            {props.t("RegisterPage.SignUp")}
                            {loading && <CircularProgress size={24} style={{top: "17%", left: "35%", position: "absolute"}} />}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </ThemeProvider>
              // #endregion
            )}
          </div>
          <div>
            <CustomSnackbar
              showSnackbarProp={showSnackbar}
              closeSnackbarCallbackProp={closeSnackbarCallback}
              snackbarMessageProp={snackbarMessage}
              autoHideDurationProp={autoHideDuration}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

const drawerWidth = 250;
const minToolbarHeight = 64;

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] }
  }
});

const useStyles = makeStyles(theme => ({
  buttonSpacing: {
    marginLeft: theme.spacing(1)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  timePickers: {
    flexGrow: 1,
    maxWidth: "100%"
  },

  paperPositionSuccessful: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "15%",
    marginRight: "15%",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      marginRight: "25%"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `calc(${drawerWidth}px + 21%)`,
      marginRight: "21%"
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: `calc(${drawerWidth}px + 27%)`,
      marginRight: "27%"
    }
  },

  paperPositionSuccessfulNotLoggedIn: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "15%",
    marginRight: "15%",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    //paddingTop: `calc(${minToolbarHeight}px + 15%)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      marginRight: "25%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "32%",
      marginRight: "32%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "38%",
      marginRight: "38%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    }
  },

  paperPosition: {
    marginLeft: "15%",
    marginRight: "15%",
    paddingTop: `calc(${minToolbarHeight}px + 5%)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%",
      paddingTop: `calc(${minToolbarHeight}px + 5%)`
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      marginRight: "25%",
      paddingTop: `calc(${minToolbarHeight}px + 5%)`,
      marginBottom: "5%"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "32%",
      marginRight: "32%",
      paddingTop: `calc(${minToolbarHeight}px + 5%)`,
      marginBottom: "5%"
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "38%",
      marginRight: "38%",
      paddingTop: `calc(${minToolbarHeight}px + 5%)`,
      marginBottom: "5%"
    }
  },

  paperPositionMobile: {
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "10%",
    paddingTop: `calc(${minToolbarHeight}px + 15%)`
  },

  paperPositionLoggedIn: {
    marginLeft: "15%",
    marginRight: "15%",
    paddingTop: `calc(${minToolbarHeight}px + 5%)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%",
      paddingTop: `calc(${minToolbarHeight}px + 5%)`
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      marginRight: "25%",
      paddingTop: `calc(${minToolbarHeight}px + 5%)`,
      marginBottom: "5%"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `calc(${drawerWidth}px + 21%)`,
      marginRight: "21%",
      paddingTop: `calc(${minToolbarHeight}px + 5%)`,
      marginBottom: "5%"
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: `calc(${drawerWidth}px + 27%)`,
      marginRight: "27%",
      paddingTop: `calc(${minToolbarHeight}px + 5%)`,
      marginBottom: "5%"
    }
  },

  paperShape: {
    borderRadius: 4
  },
  paperShadow: {
    boxShadow: "0 10px 45px -10px rgba(0, 0, 0, 0.2)"
  },
  paperProps: {
    padding: "32px",
  },

  paperPropsMobile: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },

  // launchPage: {
  //   paddingLeft: "40px",
  //   paddingTop: `calc(${minToolbarHeight}px + 40px)`,

  //   [theme.breakpoints.up("sm")]: {
  //     paddingLeft: `calc(${drawerWidth}px + 100px)`,
  //     paddingTop: `calc(${minToolbarHeight}px + 40px)`
  //   }
  // },

  backgroundLandscape: {
    backgroundImage: `url(${bgLandscape})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    width: "100%",
    height: "100vh",
    overflow: "auto",
    backgroundColor: "#213e54",
  },

  background: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "200%",
    height: "100vh",

    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      width: "195%"
    },

    [theme.breakpoints.up("md")]: {
      width: "130%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%"
    }
  },

  paperPositionMobileFixed: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "16px",
    marginRight: "16px",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
    }
  },

  paperPositionFixed: {
    paddingTop: `calc(${minToolbarHeight}px)`,
    marginLeft: "15%",
    marginRight: "15%",
    height: "100%",
    display: "flex",
    justifiyContent: "center",
    alignItems: "center",
    //paddingTop: `calc(${minToolbarHeight}px + 15%)`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "20%",
      marginRight: "20%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "25%",
      marginRight: "25%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "32%",
      marginRight: "32%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "38%",
      marginRight: "38%"
      //paddingTop: `calc(${minToolbarHeight}px + 15%)`
    }
  }

  // registerText:{
  //   marginRight: "60%",
  //   fontSize: "3.0rem",

  //   [theme.breakpoints.up("sm")]: {
  //     marginRight: "60%",
  //     fontSize: "3.0rem"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     marginRight: "30%",
  //     fontSize: "3.4rem"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     marginRight: "0%",
  //     fontSize: "3.75rem"
  //   }
  // },

  // timeText:{
  //   fontSize: "1.2rem",

  //   [theme.breakpoints.up("sm")]: {
  //     marginRight: "50%",
  //     fontSize: "1.2rem"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     marginRight: "60%",
  //     fontSize: "1.35rem"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     marginRight: "0%",
  //     fontSize: "1.5rem"
  //   }
  // },

  // borderMargin:{
  //   width: "30%",
  //   [theme.breakpoints.up("sm")]: {
  //     width: "30%"
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     width: "40%"
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     width: "50%"
  //   }
  // },
}));

export default withRouter(Register);
