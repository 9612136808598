/* eslint-disable */
import DateFnsUtils from "@date-io/date-fns";
import { Button, CircularProgress, createMuiTheme, Grid, IconButton, LinearProgress, MenuItem, Paper, Step, StepConnector, StepLabel, Stepper, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, useMediaQuery, NativeSelect, Select } from "@material-ui/core";
import { blue, purple } from "@material-ui/core/colors";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import DescriptionIcon from "@material-ui/icons/Description";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import Axios from "axios";
import { isSameDay } from "date-fns/esm";
import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import "./../App.css";
import bgImage from "./../bg_1.jfif";
import CustomSnackbar from "./CustomSnackbar";
import CustomSnackbarError from "./CustomSnackbarError";
import Alert from '@material-ui/lab/Alert';
import "./test.css";
import "./Timesheet.css"


function Timesheet(props) {
  props.headerNameProp(props.t("TimeRegistration.TimeRegistrationText"));
  const classes = useStyles(props);
  const steps = [props.t("TimeRegistration.Tasks"), props.t("TimeRegistration.Confirmation")];
  const addressExtension = "/Timesheet";
  const snackbarMessage = props.t("TimeRegistration.Snackbar");
  const snackbarErrorMessage = props.t("TimeRegistration.SnackbarError");
  const autoHideDuration = 2500;
  const [selectedDateHolder, setSelectedDateHolder] = useState(new Date());
  const [selectedStartTimeHolder, setSelectedStartTimeDateHolder] = useState(new Date());
  const [selectedEndTimeHolder, setSelectedEndTimeHolder] = useState(new Date());
  const [currentStep, setCurrentStep] = useState(0);
  const [factories, setFactories] = useState([]);
  const [fieldsRequired, setFieldsRequired] = useState("");
  const [fullNames, setFullNames] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [activities, setActivities] = useState([]);
  const [divisionsOptions, setDivisionsOptions] = useState([]);
  const [activitiesOptions, setActivitiesOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingInForm, setLoadingInForm] = useState(false);
  const [factoryInfo, setFactoryInfo] = useState("");
  const [fullNameInfo, setFullNameInfo] = useState("");
  const [divisionsInfo, setDivisionsInfo] = useState([]);
  const [activitiesInfo, setActivitiesInfo] = useState([""]);
  const [datesInfo, setDatesInfo] = useState([""]);
  const [startTimesInfo, setStartTimesInfo] = useState([""]);
  const [endTimesInfo, setEndTimesInfo] = useState([""]);
  const [activityItems, setActivityItems] = useState([]);
  const [activityItemsConfirmation, setActivityItemsConfirmation] = useState([]);
  const [countActivityItems, setCountActivityItems] = useState(0);
  const [countDivisionsItems, setCountDivisionItems] = useState(0);
  const [userEfficiency, setUserEfficiency] = useState(0);
  const [timeEfficiency, setTimeEfficiency] = useState(0);
  const [workEfficiency, setWorkEfficiency] = useState(0);
  const [minutesSpent, setMinutesSpent] = useState(0);
  const [businessMinutes, setBusinessMinutes] = useState(0);
  const [unitsProduced, setUnitsProduced] = useState(0);
  const [optimalUnitsProduced, setOptimalUnitsProduced] = useState(0);
  const [progress, setProgress] = React.useState(0);
  const [progress2, setProgress2] = React.useState(0);
  const [launchPage, setLaunchPage] = useState(true);
  const [redirectDashboard, setRedirectDashboard] = useState(false);
  const [latestEntry, setLatestEntry] = useState();
  const [receivedLatestEntry, setReceivedLatestEntry] = useState(false);
  const [paidLeaveSwitch, setPaidLeaveSwitch] = useState(false);
  const [sentHomeSwitch, setSentHomeSwitch] = useState(false);
  const [collapseLeaveList, setCollapseLeaveList] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const successLatestEntry = useRef(false);

  const currencies = [
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'BTC',
      label: '฿',
    },
    {
      value: 'JPY',
      label: '¥',
    },
  ];

  const [currency, setCurrency] = useState({
    value: "EUR"
  });

  const selectRef = useRef()

  useEffect(() => {
    window.scrollTo(0, 0)
    if(selectRef.current) {
      selectRef.current.addEventListener("change", (e) => {
      })
    }
    
  }, [])

  const handleChangeTest = (event) => {
    // setCurrency(value => event)
    setCurrency({
      ...currency,
      value: event
    });
  };
  
  const minWidth = useMediaQuery("(min-width:400px)");

  function DoCollapse() {
    setCollapseLeaveList(!collapseLeaveList);
  }

  const initialSwitchCall = useRef(false);

  const [info, setInfo] = useState({
    paidLeaveI: [false],
    sentHomeI: [false],
    customersI: [""],
    divisionsI: [""],
    activitiesI: [""],
    amountI: [""],
    dateI: [new Date()],
    startTimeI: [createStartTime()],
    endTimeI: [new Date()]
  });
  const bgColorTimesheetLabels = "#f3f3f3";
  const bgColorpaidLeaveDisabled = "rgba(200, 200, 200, 1)";
  const theme2 = useTheme();

  const PurpleSwitch = withStyles({
    switchBase: {
      // color: purple[300],
      "&$checked": {
        color: purple[500]
      },
      "&$checked + $track": {
        backgroundColor: purple[500]
      }
    },
    checked: {},
    track: {}
  })(Switch);

  const lg = useMediaQuery(theme2.breakpoints.up("lg"));
  const md = useMediaQuery(theme2.breakpoints.up("md"));
  const sm = useMediaQuery(theme2.breakpoints.up("sm"));
  const xs = useMediaQuery(theme2.breakpoints.up("xs"));
  const inputsm = useMediaQuery(theme.breakpoints.down("sm"));

  function disableWeekends(date){
    let dateBool = false;
    for(let i = 0; i < props.holidaysProp.length; i++){      
      if(isSameDay(date, props.holidaysProp[i])){
        dateBool = true;
      }
    }

    if(date.getDay() === 0 || date.getDay() === 6){
      dateBool = true;
    }

    return dateBool;
  }

  function closeSnackbarCallback() {
    setShowSnackbar(false);
    setShowErrorSnackbar(false);
  }

  useEffect(()=>{
    if(currentStep === 2)
      setShowSnackbar(true)
  }, [currentStep])

  useEffect(() => {
    latestEntryGET(props.userDataProp.id);
    customersGET();
  }, []);

  useEffect(() => {


    updateActivityComponentsConfirmation();
  }, [md]);

  useEffect(() => {

    updateActivityComponents();
  }, [info.customersI, collapseLeaveList]);

  //load until all data has been fetched succesfully.
  const allowEfficiencyPage = useRef(false);
  useEffect(() => {
    if (allowEfficiencyPage.current===false) {
      return;
    }
    setLoading(false);
    setCurrentStep(currentStep => currentStep + 1);
    allowProgress.current = true;
    allowProgress2.current = true;
    setProgress(1);
    setProgress2(1);
  }, [optimalUnitsProduced]);

  // useEffect(() => {

  //   updateActivityComponents();
  // }, [activityItems]);

  function launchPageComponent() {
    props.headerNameProp("");
    props.currentPathCallbackProp(props.location.pathname);
    return (
      <div>
        <div className={classes.background}>
          <div className={classes.launchPage}>
            <Typography variant="h5" style={{ color: "#FFFFFF", paddingBottom: 25 }} className={classes.timeText}>
            {props.t("TimeRegistration.TimeRegistrationText")}
            </Typography>
            <Typography variant="h2" style={{ color: "#FFFFFF" }} className={classes.registerText}>
              <b>Register your work times</b>
              <DescriptionIcon style={{ position: "relative", top: "10px", left: "10px", fontSize: "unset" }} />
            </Typography>
            <Grid
              container
              spacing={0}
              className={classes.borderMargin}
              style={{ marginTop: 25, border: "1px solid rgba(65, 166, 247, 1)", borderRadius: "4px" }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={event => setLaunchPage(false)}
                  style={{ marginLeft: 20, marginTop: 20, marginBottom: 20, backgroundColor: "#f5f5f5" }}
                >
                  GET STARTED
                  <KeyboardArrowRightIcon style={{ paddingBottom: 2 }} />
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }

  function createStartTime() {
    let date = new Date();
    let newDate = new Date(date.getFullYear(), date.getMonth(), date.getDay(), 6);
    return newDate;
  }

  // function createEndTime(){
  //   let date = new Date();
  //   let newDate = new Date(date.getFullYear(), date.getMonth(), date.getDay(), 14);
  //   return newDate;
  // }

  var count = 0;

  // useEffect(() => {
  //   factoriesGET();
  // }, []);

  const allowProgress = useRef(false);
  React.useEffect(() => {
    if (allowProgress.current===false) {
      return;
    }



    function tick() {
      setProgress(oldProgress => (oldProgress >= workEfficiency ? workEfficiency : oldProgress + 1));
    }

    const timer = setInterval(tick, 20);
    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  const allowProgress2 = useRef(false);
  React.useEffect(() => {
    if (allowProgress2.current===false) {
      return;
    }
    function tick2() {
      setProgress2(oldProgress => (oldProgress >= timeEfficiency ? timeEfficiency : oldProgress + 1));
    }
    const timer = setInterval(tick2, 20);
    return () => {
      clearInterval(timer);
    };
  }, [progress2]);

  function Previous() {
    setCurrentStep(currentStep => currentStep - 1);
  }

  async function Next() {
    switch (currentStep) {
      case 0: {
        let blockNext = false;

        for (let key in info) {
          let obj = info[key];

          if (key==="endTimeI") {
            for (let i = 0; i < info[key].length; i++) {
              if (info[key][i]==="") {
                blockNext = true;
                setFieldsRequired(fieldsRequiredMessage("fields"));
              }
            }
          }

          if (!info["sentHomeI"][info["sentHomeI"].length - 2] && !info["paidLeaveI"][info["paidLeaveI"].length - 2]) {
            if (obj[obj.length - 2] === "") {
              blockNext = true;
              setFieldsRequired(fieldsRequiredMessage("fields"));
              //break;
            }
          }

          if(key==="amountI"){
            for(let i = 0; i < info[key].length; i++){
              if(info[key][i] < 0){
                blockNext = true;
                setFieldsRequired(fieldsRequiredMessage("amount"));
              }
            }
          }

          if(key==="dateI"){
            for(let i = 0; i < info[key].length; i++){

              if(info[key].length > activityItems.length){
                if(i===info[key].length - 1){
                  continue;
                }
              }

              let dayName = info[key][i].toLocaleDateString("en-GB", {weekday: "long"});
              if(dayName==="Saturday" || dayName==="Sunday"){
                blockNext = true;
                setFieldsRequired(fieldsRequiredMessage("weekend"));
              }
          }
          }
        }

        let number = 0;
        if(successLatestEntry.current)
          number = 0;
        else
          number = 1;

        for (let j = 0; j < info["startTimeI"].length - number; j++) {

          let startTime = String(info["startTimeI"][j]);
          let startTimestr = startTime.match(new RegExp(/[0-9]{2}:[0-9]{2}:[0-9]{2}/));
          let combined1 = "01/01/2000 " + startTimestr;
          let final1 = Date.parse(combined1);

          let endTime = String(info["endTimeI"][j]);
          let endTimestr = endTime.match(new RegExp(/[0-9]{2}:[0-9]{2}:[0-9]{2}/));
          let combined2 = "01/01/2000 " + endTimestr;
          let final2 = Date.parse(combined2);

          let minuteInMs = 1000 * 60;


          if (final1 + minuteInMs >= final2) {
            if (info["paidLeaveI"][j]) {
              continue;
            }
            blockNext = true;
            setFieldsRequired(fieldsRequiredMessage("endTime"));
          }
        }

        if (blockNext) {
          break;
        }

        setFieldsRequired(fieldsRequiredMessage(""));
        updateActivityComponentsConfirmation();
        setCurrentStep(currentStep => currentStep + 1);
        break;
      }

      case 1: {
        setLoading(true);
        var maxNumber = Math.max(0, info.activitiesI.length - 2);
        const lengthWithoutEmpty = info.activitiesI.filter(i => i !== "");
        for (let i = 0; i < lengthWithoutEmpty.length; i++) {
          if (info.paidLeaveI[i]===true || info.sentHomeI[i]===true) {
            await timesheetPOST2(i, maxNumber);
          } else {
            await timesheetPOST(i, maxNumber);
          }
        }
        break;
      }

      case 2: {
        break;
      }

      default:
        break;
    }
  }

  function fieldsRequiredMessage(arg) {
    switch (arg) {
      case "fields":
        return (
            <Alert severity="error">
              <div>
              {props.t("TimeRegistration.Error10")}{" "}
              {props.t("TimeRegistration.Error12")}
            </div>
          </Alert>
        );

      case "endTime":
        return (
          <Alert severity="error">
            <div>
              <b>
                <em>{props.t("TimeRegistration.Error20")}</em>
              </b>{" "}
              {props.t("TimeRegistration.Error21")}
            </div>
          </Alert>
        );

      case "amount":
        return(
          <Alert severity="error">
            <div>
              <b>
                <em>{props.t("TimeRegistration.Error30")}</em>
                </b>{" "}
                {props.t("TimeRegistration.Error31")}
            </div>
          </Alert>
        );

        case "weekend":
          
            return(
              <Alert severity="error">
                <div>
                {props.t("TimeRegistration.Error40")} {" "}
                <b>
                  <em>{props.t("TimeRegistration.Error41")}</em>
                  </b>{" "} {props.t("TimeRegistration.Error42")} <b><em>{props.t("TimeRegistration.Error43")}</em></b>{" "}
                  {props.t("TimeRegistration.Error44")}
                </div>
              </Alert>
            );

        case "":
            return(null);
    }
  }

  async function latestEntryGET(employeeId) {
    const address = props.baseAddressProp + addressExtension + "/GetLatestEntry" + "?employeeId=" + employeeId;

    try {
      let result = await Axios.get(address);
      setLatestEntry(result.data);
      let current = info;
      current.customersI[0] = result.data.customer;
      current.divisionsI[0] = result.data.division;
      current.activitiesI[0] = result.data.activity;
      current.amountI[0] = result.data.amount;
      setInfo(current);
      divisionsGET(result.data.customer);
      activitiesGET(result.data.customer, result.data.division);
      setReceivedLatestEntry(true);
      successLatestEntry.current = true;
    } catch (error) {
      setReceivedLatestEntry(true);
      setLoading(false);
      successLatestEntry.current = false;
    }
  }

  async function customersGET() {
    const address = props.baseAddressProp + addressExtension + "/GetCustomers";
    try {
      let result = await Axios.get(address);

      if (divisions.length > 0) {
        setCustomers([]);
      }
      setCustomers(result.data);
      setLoading(false);
    } catch (error) {}
  }

  useEffect(() => {
    updateActivityComponents();
  }, [divisionsOptions]);

  useEffect(() => {
    updateActivityComponents();
  }, [customers]);

  async function divisionsGET(customerName) {
    const address = props.baseAddressProp + addressExtension + "/GetDivisions" + "?customerName=" + customerName;
    try {
      let result = await Axios.get(address);
      if (divisions.length > 0) {
        setDivisions([]);
      }
      setDivisions(result.data);

      let currentDivisionOptions = [...divisionsOptions];
      let customerFound = false;
      for (let i = 0; i < currentDivisionOptions.length; i++) {
        if (Object.keys(currentDivisionOptions[i])===customerName) {
          customerFound = true;
        }
      }
      if (!customerFound) currentDivisionOptions.push({ [customerName]: result.data });

      setDivisionsOptions(currentDivisionOptions);
    } catch (error) {}
  }

  async function activitiesGET(customerName, divisionName) {
    const address = props.baseAddressProp + addressExtension + "/GetActivities" + "?customerName=" + customerName + "&divisionName=" + divisionName;
    try {
      let result = await Axios.get(address);
      let currentActivitiesOptions = [...activitiesOptions];
      let divisionFound = false;
      for (let i = 0; i < currentActivitiesOptions.length; i++) {
        if (Object.keys(currentActivitiesOptions[i])===divisionName) {
          divisionFound = true;
        }
      }
      if (!divisionFound) currentActivitiesOptions.push({ [divisionName]: result.data });

      setActivitiesOptions(currentActivitiesOptions);
    } catch (error) {}
    setLoading(false);
  }

  async function efficiencyGET(employeeId) {

    setLoading(true);
    const address = props.baseAddressProp + addressExtension + "/GetEfficiency" + "?employeeId=" + employeeId;
    try {
      let result = await Axios.get(address);



      let totalTimeEfficiency = result.data.totalTimeEfficiency | 0;
      let totalWorkEfficiency = result.data.totalWorkEfficiency | 0;
      let totalMinutesSpent = result.data.totalMinutesSpent | 0;
      let totalBusinessMinutes = result.data.totalBusinessMinutes | 0;
      let totalUnitsProduced = result.data.totalUnitsProduced | 0;
      let totalOptimalUnitsProduced = result.data.totalOptimalUnitsProduced | 0;
      allowEfficiencyPage.current = true;

      setTimeEfficiency(totalTimeEfficiency);
      setWorkEfficiency(totalWorkEfficiency);
      setMinutesSpent(totalMinutesSpent);
      setBusinessMinutes(totalBusinessMinutes);
      setUnitsProduced(totalUnitsProduced);
      setOptimalUnitsProduced(totalOptimalUnitsProduced);

      // setProgress(1);
      // setProgress2(1);

      //setUserEfficiency(parseInt(result.data.toFixed(2), 10));
    } catch (error) {}
    //setLoading(false);
  }

  async function timesheetPOST(index, maxNumber) {
    setLoading(true);
    const address = props.baseAddressProp + addressExtension + "/PostTimesheet";
    try {
      await Axios.post(address, {
        StartTime: info.startTimeI[index].toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" }),
        EndTime: info.endTimeI[index].toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" }),
        Date: info.dateI[index].toLocaleDateString("nl-NL"),
        Customer: info.customersI[index],
        Division: info.divisionsI[index],
        Activity: info.activitiesI[index],
        FirstName: props.userDataProp.firstName,
        LastName: props.userDataProp.lastName,
        UserName: props.userDataProp.userName,
        Role: props.userDataProp.role,
        Amount: info.amountI[index],
        Leave: 0
      });

      if (index === maxNumber) {
        efficiencyGET(props.userDataProp.id);
      }
    } catch (error) {
      setLoading(false)
      setShowErrorSnackbar(true)
    }
  }

  async function timesheetPOST2(index, maxNumber) {
    setLoading(true);
    const address = props.baseAddressProp + addressExtension + "/PostTimesheet";
    try {
      // info.paidLeaveI[i]===true || info.sentHomeI[i]===true
      if (info.paidLeaveI[index]) {
        let st = new Date(info.dateI[index].getFullYear(), info.dateI[index].getMonth(), info.dateI[index].getDate(), 6, 0, 0);
        let ed = new Date(info.dateI[index].getFullYear(), info.dateI[index].getMonth(), info.dateI[index].getDate(), 13, 45, 0);

        await Axios.post(address, {
          StartTime: st.toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" }),
          EndTime: ed.toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" }),
          Date: info.dateI[index].toLocaleDateString("nl-NL"),
          Customer: !latestEntry.customer ? customers[0] : latestEntry.customer,
          Division: !latestEntry.division ? divisions[0] : latestEntry.division,
          Activity: !latestEntry.activity ? activities[0] : latestEntry.activity,
          FirstName: props.userDataProp.firstName,
          LastName: props.userDataProp.lastName,
          UserName: props.userDataProp.userName,
          Role: props.userDataProp.role,
          Amount: 1, //amount = 1 means paid leave
          Leave: 1
        });
      } else if (info.sentHomeI[index]) {
        await Axios.post(address, {
          StartTime: info.startTimeI[index].toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" }),
          EndTime: info.endTimeI[index].toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" }),
          Date: info.dateI[index].toLocaleDateString("nl-NL"),
          Customer: !latestEntry.customer ? customers[0] : latestEntry.customer,
          Division: !latestEntry.division ? divisions[0] : latestEntry.division,
          Activity: !latestEntry.activity ? activities[0] : latestEntry.activity,
          FirstName: props.userDataProp.firstName,
          LastName: props.userDataProp.lastName,
          UserName: props.userDataProp.userName,
          Role: props.userDataProp.role,
          Amount: 0,
          Leave: 2
        });
      }

      if (index === maxNumber) {
        efficiencyGET(props.userDataProp.id);
      }
    } catch (error) {
      setLoading(false)
      setShowErrorSnackbar(true)
    }
  }

  useEffect(() => {
  }, [activitiesOptions]);

  async function GETnames(firstName, lastName) {
    const address = props.baseAddressProp + addressExtension + "?firstName=" + firstName + "&lastName=" + lastName;
    try {
      let result = await Axios.get(address);

    } catch (error) {}
  }

  function insertMenuItems(arg) {
    let items = [];
    for (let i = 0; i < arg.length; i++) {
      items.push(
        <MenuItem value={arg[i]} key={i}>
          {arg[i]}
        </MenuItem>
      );
    }
    return items;
  }

  function insertMenuItemsMobile(arg) {
    let items = [
      <option value="" key="" disabled selected hidden>Customer</option>
    ];
    for (let i = 0; i < arg.length; i++) {
      items.push(
        <option value={arg[i]} key={i}>
          {arg[i]}
        </option>
      );
    }
    return items;
  }

  function insertDivisionItems(customer) {
    if (divisionsOptions.length <= 0 || typeof customer === "undefined" || customer === "") {
      return;
    }
    let currentDivisionOptions;
    for (let j = 0; j < divisionsOptions.length; j++) {
      if (Object.keys(divisionsOptions[j]) == customer) {
        currentDivisionOptions = divisionsOptions[j][customer];
      }
    }
    if (typeof currentDivisionOptions == "undefined") {
      return;
    }
    let items = [];
    for (let k = 0; k < currentDivisionOptions.length; k++) {
      items.push(
        <MenuItem value={currentDivisionOptions[k]} key={k}>
          {currentDivisionOptions[k]}
        </MenuItem>
      );
    }
    return items;
  }

  function insertDivisionItemsMobile(customer) {
    if (divisionsOptions.length <= 0 || typeof customer === "undefined" || customer === "") {
      return;
    }
    let currentDivisionOptions;
    for (let j = 0; j < divisionsOptions.length; j++) {
      if (Object.keys(divisionsOptions[j]) == customer) {
        currentDivisionOptions = divisionsOptions[j][customer];
      }
    }
    if (typeof currentDivisionOptions == "undefined") {
      return;
    }
    let items = [
      <option value="" disabled selected hidden>Division</option>
    ];
    for (let k = 0; k < currentDivisionOptions.length; k++) {
      items.push(
        <option value={currentDivisionOptions[k]} key={k}>
          {currentDivisionOptions[k]}
        </option>
      );
    }
    return items;
  }

  function insertActivityItems(division) {
    if (activitiesOptions.length <= 0 || typeof division === "undefined" || division === "") {
      return;
    }

    let currentActivityOptions;

    for (let j = 0; j < activitiesOptions.length; j++) {
      if (Object.keys(activitiesOptions[j]) == division) {
        currentActivityOptions = activitiesOptions[j][division];
      }
    }

    if (typeof currentActivityOptions == "undefined") {
      return;
    }

    let items = [];
    for (let k = 0; k < currentActivityOptions.length; k++) {
      items.push(
        <MenuItem value={currentActivityOptions[k]} key={k}>
          {currentActivityOptions[k]}
        </MenuItem>
      );
    }
    return items;
  }

  function insertActivityItemsMobile(division) {
    if (activitiesOptions.length <= 0 || typeof division === "undefined" || division === "") {
      return;
    }

    let currentActivityOptions;

    for (let j = 0; j < activitiesOptions.length; j++) {
      if (Object.keys(activitiesOptions[j]) == division) {
        currentActivityOptions = activitiesOptions[j][division];
      }
    }

    if (typeof currentActivityOptions == "undefined") {
      return;
    }

    let items = [
      <option value="" disabled selected hidden>Activity</option>
    ];
    for (let k = 0; k < currentActivityOptions.length; k++) {
      items.push(
        <option value={currentActivityOptions[k]} key={k}>
          {currentActivityOptions[k]}
        </option>
      );
    }
    return items;
  }

  function findActivityItem(currentDivision) {}

  function loadingAnimation() {
    return (
      <div>
        <LinearProgress color="primary" />
        <Typography variant="caption">{props.t("TimeRegistration.Loading")}</Typography>
      </div>
    );
  }

  function handleDatesChange(input, index) {
    let current = [...datesInfo];
    current[index] = input;
    setDatesInfo(current);

  }

  function handleStartTimesChange(input, index) {
    let current = [...startTimesInfo];
    current[index] = input;
    setStartTimesInfo(current);
  }

  function handleEndTimesChange(input, index) {
    let current = [...endTimesInfo];
    current[index] = input;
    setEndTimesInfo(current);
  }

  function handleDatePickerChange(date, day, index) {
    let today = new Date();


    if (new Date(date).getTime() > today.getTime()) return null;


    let current = [...info.dateI];
    current[index] = date;

    setInfo({
      customersI: [...info.customersI],
      divisionsI: [...info.divisionsI],
      activitiesI: [...info.activitiesI],
      amountI: [...info.amountI],
      dateI: current,
      startTimeI: [...info.startTimeI],
      endTimeI: [...info.endTimeI],
      paidLeaveI: [...info.paidLeaveI],
      sentHomeI: [...info.sentHomeI]
    });
  }

  function handleStartTimePickerChange(date, index) {
    let current = [...info.startTimeI];
    current[index] = date;

    setInfo({
      customersI: [...info.customersI],
      divisionsI: [...info.divisionsI],
      activitiesI: [...info.activitiesI],
      amountI: [...info.amountI],
      dateI: [...info.dateI],
      startTimeI: current,
      endTimeI: [...info.endTimeI],
      paidLeaveI: [...info.paidLeaveI],
      sentHomeI: [...info.sentHomeI]
    });
  }

  function handleEndTimePickerChange(date, index) {
    let current = [...info.endTimeI];
    current[index] = date;

    let currentStartTime = [...info.startTimeI][index];
    let minuteInMs = 1000 * 60;

    if (date <= (currentStartTime + minuteInMs)) {

      current[index] = "";
    }

    // if (new Date(date).getHours() <= new Date(currentStartTime).getHours()) {

    //   current[index] = "";
    // }

    setInfo({
      customersI: [...info.customersI],
      divisionsI: [...info.divisionsI],
      activitiesI: [...info.activitiesI],
      amountI: [...info.amountI],
      dateI: [...info.dateI],
      startTimeI: [...info.startTimeI],
      endTimeI: current,
      paidLeaveI: [...info.paidLeaveI],
      sentHomeI: [...info.sentHomeI]
    });
  }

  function removeActivityComponent() {

    let currentActivityComponents = [...activityItems];
    let currentCustomerItems = [...info.customersI];
    let currentDivisionItems = [...info.divisionsI];
    let currentActitiviesItems = [...info.activitiesI];
    let currentAmountItems = [...info.amountI];
    let currentDateItems = [...info.dateI];
    let currentStartTimeItems = [...info.startTimeI];
    let currentEndTimeItems = [...info.endTimeI];
    let currentPaidLeaveItems = [...info.paidLeaveI];
    let currentSentHomeItems = [...info.sentHomeI];

    currentActivityComponents.pop();
    currentCustomerItems.pop();
    currentCustomerItems.pop();
    //currentCustomerItems.push("");
    currentDivisionItems.pop();
    currentDivisionItems.pop();
    //currentDivisionItems.push("");
    currentActitiviesItems.pop();
    currentActitiviesItems.pop();
    //currentActitiviesItems.push("");
    currentAmountItems.pop();
    currentAmountItems.pop();
    //currentAmountItems.push("");
    currentPaidLeaveItems.pop();
    currentPaidLeaveItems.pop();

    currentSentHomeItems.pop();
    currentSentHomeItems.pop();

    if (latestEntry) {
      currentCustomerItems.push(latestEntry.customer);
      currentDivisionItems.push(latestEntry.division);
      currentActitiviesItems.push(latestEntry.activity);
      currentAmountItems.push(latestEntry.amount);
    } else {
      currentCustomerItems.push("");
      currentDivisionItems.push("");
      currentActitiviesItems.push("");
      currentAmountItems.push("");
    }

    currentPaidLeaveItems.push(false);
    currentSentHomeItems.push(false);

    currentDateItems.pop();
    currentDateItems.pop();
    currentDateItems.push(new Date());
    currentStartTimeItems.pop();
    currentStartTimeItems.pop();
    currentStartTimeItems.push(createStartTime());
    currentEndTimeItems.pop();
    currentEndTimeItems.pop();
    currentEndTimeItems.push(new Date());
    setActivityItems(currentActivityComponents);
    setInfo({
      customersI: currentCustomerItems,
      divisionsI: currentDivisionItems,
      activitiesI: currentActitiviesItems,
      amountI: currentAmountItems,
      dateI: currentDateItems,
      startTimeI: currentStartTimeItems,
      endTimeI: currentEndTimeItems,
      paidLeaveI: currentPaidLeaveItems,
      sentHomeI: currentSentHomeItems
    });
    setCountActivityItems(countActivityItems => countActivityItems - 1);
  }

  useEffect(() => {
    addActivityComponent();
  }, []);

  function addActivityComponent() {
    let currentActivityItems = [...activityItems];
    let currentCustomerItems = [...info.customersI];
    let currentDivisionItems = [...info.divisionsI];
    let currentActitiviesItems = [...info.activitiesI];
    let currentAmountItems = [...info.amountI];
    let currentDateItems = [...info.dateI];
    let currentStartTimeItems = [...info.startTimeI];
    let currentEndTimeItems = [...info.endTimeI];
    let currentPaidLeaveItems = [...info.paidLeaveI];
    let currentSentHomeItems = [...info.sentHomeI];

    if (latestEntry) {
      currentCustomerItems.push(latestEntry.customer);
      currentDivisionItems.push(latestEntry.division);
      currentActitiviesItems.push(latestEntry.activity);
      currentAmountItems.push(latestEntry.amount);
    } else {
      currentCustomerItems.push("");
      currentDivisionItems.push("");
      currentActitiviesItems.push("");
      currentAmountItems.push("");
    }
    currentPaidLeaveItems.push(false);
    currentSentHomeItems.push(false);

    currentDateItems.push(new Date());
    currentEndTimeItems.push(new Date());
    currentStartTimeItems.push(new Date());

    if (countActivityItems >= 1) {
      currentStartTimeItems[countActivityItems] = currentEndTimeItems[countActivityItems - 1];
    }

    currentActivityItems.push(activityComponent(countActivityItems));

    setActivityItems(currentActivityItems);
    setInfo({
      customersI: currentCustomerItems,
      divisionsI: currentDivisionItems,
      activitiesI: currentActitiviesItems,
      amountI: currentAmountItems,
      dateI: currentDateItems,
      startTimeI: currentStartTimeItems,
      endTimeI: currentEndTimeItems,
      paidLeaveI: currentPaidLeaveItems,
      sentHomeI: currentSentHomeItems
    });

    setCountActivityItems(countActivityItems => countActivityItems + 1);
  }

  const firstUpdateComponent = useRef(true);
  useEffect(() => {
    if (firstUpdateComponent.current) {
      firstUpdateComponent.current = false;
      return;
    }

    updateActivityComponents();
  }, [countActivityItems]);

  function updateActivityComponents() {
    
    setLoading(true);
    let currentActivityItems = [...activityItems];

    for (let i = 0; i < currentActivityItems.length; i++) {
      currentActivityItems[i] = activityComponent(i);
    }
    setActivityItems(currentActivityItems);
    setLoading(false);
  }

  function handlePaidLeaveChange(input, index) {
    let current = [...info.paidLeaveI];
    current[index] = input;
    setInfo({
      customersI: [...info.customersI],
      divisionsI: [...info.divisionsI],
      activitiesI: [...info.activitiesI],
      amountI: [...info.amountI],
      dateI: [...info.dateI],
      startTimeI: [...info.startTimeI],
      endTimeI: [...info.endTimeI],
      paidLeaveI: current,
      sentHomeI: [...info.sentHomeI]
    });
  }

  function handleSentHomeChange(input, index) {
    let current = [...info.sentHomeI];
    current[index] = input;
    setInfo({
      customersI: [...info.customersI],
      divisionsI: [...info.divisionsI],
      activitiesI: [...info.activitiesI],
      amountI: [...info.amountI],
      dateI: [...info.dateI],
      startTimeI: [...info.startTimeI],
      endTimeI: [...info.endTimeI],
      paidLeaveI: [...info.paidLeaveI],
      sentHomeI: current
    });
  }

  function handleCustomersChange2(event) {
  }

  function handleCustomersChange(input, index) {
    let current = [...info.customersI];
    current[index] = input;

    let currentActivity = [...info.activitiesI];
    currentActivity[index] = "";

    let currentDivision = [...info.divisionsI];
    currentDivision[index] = "";

    setInfo({
      customersI: current,
      divisionsI: currentDivision,
      activitiesI: currentActivity,
      amountI: [...info.amountI],
      dateI: [...info.dateI],
      startTimeI: [...info.startTimeI],
      endTimeI: [...info.endTimeI],
      paidLeaveI: [...info.paidLeaveI],
      sentHomeI: [...info.sentHomeI]
    });
    divisionsGET(input);
  }

  function handleDivisionsChange(input, index) {
    let current = [...info.divisionsI];
    current[index] = input;

    let currentActivity = [...info.activitiesI];
    currentActivity[index] = "";

    setInfo({
      customersI: [...info.customersI],
      divisionsI: current,
      activitiesI: currentActivity,
      amountI: [...info.amountI],
      dateI: [...info.dateI],
      startTimeI: [...info.startTimeI],
      endTimeI: [...info.endTimeI],
      paidLeaveI: [...info.paidLeaveI],
      sentHomeI: [...info.sentHomeI]
    });
    activitiesGET(info.customersI[index], input);
  }

  function handleActivitiesChange(input, index) {
    let current = [...info.activitiesI];
    current[index] = input;

    setInfo({
      customersI: [...info.customersI],
      divisionsI: [...info.divisionsI],
      activitiesI: current,
      amountI: [...info.amountI],
      dateI: [...info.dateI],
      startTimeI: [...info.startTimeI],
      endTimeI: [...info.endTimeI],
      paidLeaveI: [...info.paidLeaveI],
      sentHomeI: [...info.sentHomeI]
    });
  }

  function handleAmountChange(input, index) {
    let current = [...info.amountI];
    current[index] = input;
    setInfo({
      customersI: [...info.customersI],
      divisionsI: [...info.divisionsI],
      activitiesI: [...info.activitiesI],
      amountI: current,
      dateI: [...info.dateI],
      startTimeI: [...info.startTimeI],
      endTimeI: [...info.endTimeI],
      paidLeaveI: [...info.paidLeaveI],
      sentHomeI: [...info.sentHomeI]
    });
  }

  const firstUpdateActivityComponent = useRef(true);

  useEffect(() => {
    if (firstUpdateActivityComponent.current) {
      firstUpdateActivityComponent.current = false;
      return;
    }

    updateActivityComponents();

  }, [info]);

  const firstUpdateActivities = useRef(true);
  useEffect(() => {
    if (firstUpdateActivities.current) {
      firstUpdateActivities.current = false;
      return;
    }

    updateActivityComponents();
  }, [activities]);

  // useEffect(()=>{
  //   updateActivityComponents();
  // }, [info])

  const firstUpdateActivities2 = useRef(true);
  useEffect(() => {
    if (firstUpdateActivities2.current) {
      firstUpdateActivities2.current = false;
      return;
    }

    updateActivityComponents();
  }, [activitiesOptions]);

  function activityComponent(index) {
    return (
      <div className={inputsm ? classes.activityComponentMobile : classes.activityComponent} key={index}>
        {inputsm ? (
   // #region MOBILE
        <Grid container spacing={3} className={classes.gridContainer}>
        <Grid className={classes.headerProps}>
          <Typography
            variant="h6"
            className={classes.activityHeader}
            style={{ fontSize: "1rem", color: "#FFFFFF", paddingLeft: 13, paddingTop: "5px", paddingBottom: "5px", textTransform: "uppercase", display: "flex", flexGrow: 1 }}
          >
            {props.t("TimeRegistration.Task")} {index + 1}
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ display: "flex", alignItems: "center", backgroundColor: "#47a6f3", display: "flex", paddingTop: "0px", paddingBottom: "0px" }}>
          <Typography variant="h6" style={{ paddingLeft: "3px", fontSize: "0.9rem", color: "#FFFFFF", fontWeight: "400px", display: "flex", flexGrow: 1 }}>
          {props.t("TimeRegistration.PaidLeave")}
          </Typography>
          <Switch
            inputProps={{ "aria-label": "primary checkbox" }}
            disabled={info.sentHomeI[index] || props.userDataProp.paidLeaveDays <= 0}
            color="secondary"
            checked={info.paidLeaveI[index]}
            onClick={event => handlePaidLeaveChange(event.target.checked, index)}

          />
          </Grid>

          <Grid item xs={12} style={{ display: "flex", alignItems: "center", backgroundColor: "#47a6f3", display: "flex", paddingTop: "0px", paddingBottom: "0px", borderTop: "1px solid rgb(34, 150, 243)" }}>
          <Typography variant="h6" style={{ paddingLeft: "3px", fontSize: "0.9rem", color: "#FFFFFF", fontWeight: "400px", display: "flex", flexGrow: 1  }}>
          {props.t("TimeRegistration.SentHome")}
          </Typography>
          <Switch
            inputProps={{ "aria-label": "secondary checkbox" }}
            disabled={info.paidLeaveI[index]}
            //color="inherit"
            checked={info.sentHomeI[index]}
            onClick={event => handleSentHomeChange(event.target.checked, index)}

          />
        </Grid>

        
            <Grid item xs={12} style={{ paddingBottom: "0px", display: "inline-block" }}>
              <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4.5vmin", display: "inline-block" }}>
              {props.t("TimeRegistration.GeneralInfo")}
              </Typography>
              <IconButton color="inherit" style={{ padding: "0px", marginLeft: "5px", marginBottom: "4px" }} classes={{ root: classes.iconButtonStyle }}>
                  <InfoIcon />
                </IconButton>
            </Grid>

            {loading ? (
              <Grid item xs={12}>
                {loadingAnimation()}
              </Grid>
            ) : (


              <Grid item xs={12} style={{paddingBottom: "0px", paddingTop: "0px"}}>
                <div style={{position: "relative"}}>
                  <ArrowDropDown className="arrow-down-icon"/>
                  <select 
                    className="native-select"
                    value={info.customersI[index]}
                    disabled={info.paidLeaveI[index] || info.sentHomeI[index]}
                    onChange={(e) => handleCustomersChange(e.target.value, index)}>
                  {insertMenuItemsMobile(customers)}
                  </select>
                </div>
              </Grid>
            )}

            {loading ? (
              <Grid item xs={12}>
                {loadingAnimation()}
              </Grid>
            ) : (
              

              <Grid item xs={12} style={{paddingBottom: "0px"}}>
                <div style={{position: "relative"}}>
                  <ArrowDropDown className="arrow-down-icon"/>
                  <select 
                    className="native-select"
                    value={info.divisionsI[index]}
                    disabled={info.paidLeaveI[index] || info.sentHomeI[index]}
                    onChange={event => handleDivisionsChange(event.target.value, index)}>
                    {insertDivisionItemsMobile(info.customersI[index])}
                  </select>
                </div>

                {/* <TextField
                  fullWidth
                  select
                  label={props.t("TimeRegistration.Division")}
                  value={info.divisionsI[index]}
                  disabled={info.paidLeaveI[index] || info.sentHomeI[index]}
                  onChange={event => handleDivisionsChange(event.target.value, index)}
                  variant="outlined"
                  style={{ backgroundColor: bgColorTimesheetLabels }}
                  margin="dense"
                >
                  {insertDivisionItems(info.customersI[index])}
                </TextField> */}
              </Grid>
            )}

            {loading ? (
              <Grid item xs={12}>
                {loadingAnimation()}
              </Grid>
            ) : (


              <Grid item xs={12}>
                <div style={{position: "relative"}}>
                  <ArrowDropDown className="arrow-down-icon"/>
                  <select 
                    className="native-select"
                    value={info.activitiesI[index]}
                    disabled={info.paidLeaveI[index] || info.sentHomeI[index]}
                    onChange={event => handleActivitiesChange(event.target.value, index)}>
                    {insertActivityItemsMobile(info.divisionsI[index])}
                  </select>
                </div>
                {/* <TextField
                  fullWidth
                  select
                  label={props.t("TimeRegistration.Activity")}
                  value={info.activitiesI[index]}
                  disabled={info.paidLeaveI[index] || info.sentHomeI[index]}
                  onChange={event => handleActivitiesChange(event.target.value, index)}
                  variant="outlined"
                  style={{ backgroundColor: bgColorTimesheetLabels }}
                  margin="dense"
                >
                  {insertActivityItemsMobile(info.divisionsI[index])}
                </TextField> */}
              </Grid>
            )}

            {loading ? (
              <Grid item xs={12}>
                {loadingAnimation()}
              </Grid>
            ) : (
              <Grid item xs={12} style={{ marginTop: -15 }}>
                <TextField
                  id="outlined-amount"
                  label={props.t("TimeRegistration.Quantity")}
                  variant="outlined"
                  type="number"
                  style={{ backgroundColor: bgColorTimesheetLabels }}
                  disabled={info.paidLeaveI[index] || info.sentHomeI[index]}
                  // helperText={<em>Required*</em>}
                  fullWidth
                  value={info.amountI[index]}
                  onChange={event => handleAmountChange(event.target.value, index)}
                  margin="dense"
                />
              </Grid>
            )}

            <Grid item xs={12} style={{ paddingBottom: "0px" }}>
              <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4.5vmin" }}>
              {props.t("TimeRegistration.DateTime")}
                <IconButton
                  color="inherit"
                  style={{ padding: "0px", paddingLeft: "5px", transform: "translateY(-2px)" }}
                  classes={{ root: classes.iconButtonStyle }}
                >
                  <DateRangeIcon />
                </IconButton>
              </Typography>
            </Grid>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} md={3} classes={{ root: classes.timePickers }} style={{ paddingTop: "0px" }}>
                <KeyboardDatePicker
                  fullWidth
                  id="mui-pickers-date"
                  label={props.t("TimeRegistration.Date")}
                  value={info.dateI[index]}
                  inputVariant="outlined"
                  style={{ backgroundColor: bgColorTimesheetLabels }}
                  margin="dense"
                  maxDate={new Date()}
                  shouldDisableDate={disableWeekends}
                  onChange={(event, date) => {
                    handleDatePickerChange(event, date, index);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3} classes={{ root: classes.timePickers }} style={{ paddingTop: "0px" }}>
                <KeyboardTimePicker
                  fullWidth
                  ampm={false}
                  id="pick-start-time"
                  label={props.t("TimeRegistration.StartTime")}
                  value={info.startTimeI[index]}
                  inputVariant="outlined"
                  style={{ backgroundColor: bgColorTimesheetLabels }}
                  disabled={info.paidLeaveI[index]}
                  margin="dense"
                  onChange={event => handleStartTimePickerChange(event, index)} //(event, date)=>{handleStartTimePickerChange(event, date, index);}
                  KeyboardButtonProps={{
                    "aria-label": "change time"
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3} classes={{ root: classes.timePickers }} style={{ paddingTop: "0px" }}>
                <KeyboardTimePicker
                  invalidDateMessage={props.t("TimeRegistration.EndTimeError")}
                  fullWidth
                  ampm={false}
                  id="pick-end-time"
                  label={props.t("TimeRegistration.EndTime")}
                  value={info.endTimeI[index]}
                  inputVariant="outlined"
                  style={{ backgroundColor: bgColorTimesheetLabels }}
                  disabled={info.paidLeaveI[index]}
                  onChange={event => handleEndTimePickerChange(event, index)}
                  margin="dense"
                  KeyboardButtonProps={{
                    "aria-label": "change time"
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
      </Grid>
      // #endregion
        ) :
        (
          // #region DESKTOP
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid className={classes.headerProps}>
            <Typography
              variant="h6"
              className={classes.activityHeader}
              style={{ color: "#FFFFFF", paddingLeft: 13, paddingTop: "5px", paddingBottom: "5px", textTransform: "uppercase", display: "flex", flexGrow: 1 }}
            >
              {props.t("TimeRegistration.Task")} {index + 1}
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ backgroundColor: "#47a6f3", display: "flex", paddingTop: "0px", paddingBottom: "0px" }}>
            <Typography variant="h6" style={{ fontSize: "1.1em", color: "#FFFFFF", paddingTop: "6px", fontWeight: "400px" }}>
              {props.t("TimeRegistration.PaidLeave")}
            </Typography>
            <Switch
              inputProps={{ "aria-label": "primary checkbox" }}
              disabled={info.sentHomeI[index] || props.userDataProp.paidLeaveDays <= 0}
              color="secondary"
              checked={info.paidLeaveI[index]}
              onClick={event => handlePaidLeaveChange(event.target.checked, index)}

            />

            <Typography variant="h6" style={{ fontSize: "1.1em", color: "#FFFFFF", paddingTop: "6px", fontWeight: "400px" }}>
            {props.t("TimeRegistration.SentHome")}
            </Typography>
            <Switch
              inputProps={{ "aria-label": "secondary checkbox" }}
              disabled={info.paidLeaveI[index]}
              color="secondary"
              checked={info.sentHomeI[index]}
              onClick={event => handleSentHomeChange(event.target.checked, index)}

            />
          </Grid>

          
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <Typography variant="h6" style={{ fontSize: "1.1rem" }}>
                {props.t("TimeRegistration.GeneralInfo")}
                  <IconButton color="inherit" style={{ padding: "0px", paddingLeft: "5px" }} classes={{ root: classes.iconButtonStyle }}>
                    <InfoIcon />
                  </IconButton>
                </Typography>
              </Grid>

              {loading ? (
                <Grid item xs={12}>
                  {loadingAnimation()}
                </Grid>
              ) : (


                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label={props.t("TimeRegistration.Customer")}
                    value={info.customersI[index]}
                    disabled={info.paidLeaveI[index] || info.sentHomeI[index]}
                    onChange={event => handleCustomersChange(event.target.value, index)}
                    variant="outlined"
                    style={{ backgroundColor: bgColorTimesheetLabels }}
                  >
                    {insertMenuItems(customers)}
                  </TextField>
                </Grid>
              )}

              {loading ? (
                <Grid item xs={12}>
                  {loadingAnimation()}
                </Grid>
              ) : (


                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label={props.t("TimeRegistration.Division")}
                    value={info.divisionsI[index]}
                    disabled={info.paidLeaveI[index] || info.sentHomeI[index]}
                    onChange={event => handleDivisionsChange(event.target.value, index)}
                    variant="outlined"
                    style={{ backgroundColor: bgColorTimesheetLabels }}
                  >
                    {insertDivisionItems(info.customersI[index])}
                  </TextField>
                </Grid>
              )}

              {loading ? (
                <Grid item xs={12}>
                  {loadingAnimation()}
                </Grid>
              ) : (


                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label={props.t("TimeRegistration.Activity")}
                    value={info.activitiesI[index]}
                    disabled={info.paidLeaveI[index] || info.sentHomeI[index]}
                    onChange={event => handleActivitiesChange(event.target.value, index)}
                    variant="outlined"
                    style={{ backgroundColor: bgColorTimesheetLabels }}
                  >
                    {insertActivityItems(info.divisionsI[index])}
                  </TextField>
                </Grid>
              )}

              {loading ? (
                <Grid item xs={12}>
                  {loadingAnimation()}
                </Grid>
              ) : (
                <Grid item xs={12} style={{ marginTop: -15 }}>
                  <TextField
                    id="outlined-amount"
                    label={props.t("TimeRegistration.Quantity")}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    style={{ backgroundColor: bgColorTimesheetLabels }}
                    disabled={info.paidLeaveI[index] || info.sentHomeI[index]}
                    // helperText={<em>Required*</em>}
                    fullWidth
                    value={info.amountI[index]}
                    onChange={event => handleAmountChange(event.target.value, index)}
                  />
                </Grid>
              )}

              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <Typography variant="h6" style={{ fontSize: "1.1rem" }}>
                {props.t("TimeRegistration.DateTime")}
                  <IconButton
                    color="inherit"
                    style={{ padding: "0px", paddingLeft: "5px", transform: "translateY(-2px)" }}
                    classes={{ root: classes.iconButtonStyle }}
                  >
                    <DateRangeIcon />
                  </IconButton>
                </Typography>
              </Grid>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} md={3} classes={{ root: classes.timePickers }} style={{ paddingTop: "0px" }}>
                  <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    id="mui-pickers-date"
                    label={props.t("TimeRegistration.Date")}
                    value={info.dateI[index]}
                    inputVariant="outlined"
                    maxDate={new Date()}
                    style={{ backgroundColor: bgColorTimesheetLabels }}
                    shouldDisableDate={disableWeekends}
                    onChange={(event, date) => {
                      handleDatePickerChange(event, date, index);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={3} classes={{ root: classes.timePickers }} style={{ paddingTop: "0px" }}>
                  <KeyboardTimePicker
                    fullWidth
                    ampm={false}
                    margin="normal"
                    id="pick-start-time"
                    label={props.t("TimeRegistration.StartTime")}
                    value={info.startTimeI[index]}
                    inputVariant="outlined"
                    style={{ backgroundColor: bgColorTimesheetLabels }}
                    disabled={info.paidLeaveI[index]}
                    onChange={event => handleStartTimePickerChange(event, index)} //(event, date)=>{handleStartTimePickerChange(event, date, index);}
                    KeyboardButtonProps={{
                      "aria-label": "change time"
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={3} classes={{ root: classes.timePickers }} style={{ paddingTop: "0px" }}>
                  <KeyboardTimePicker
                    invalidDateMessage={props.t("TimeRegistration.EndTimeError")}
                    fullWidth
                    ampm={false}
                    margin="normal"
                    id="pick-end-time"
                    label={props.t("TimeRegistration.EndTime")}
                    value={info.endTimeI[index]}
                    inputVariant="outlined"
                    style={{ backgroundColor: bgColorTimesheetLabels }}
                    disabled={info.paidLeaveI[index]}
                    onChange={event => handleEndTimePickerChange(event, index)}
                    KeyboardButtonProps={{
                      "aria-label": "change time"
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            {/* </React.Fragment> */}
        </Grid>
        // #endregion
        )}
      </div>
    );
  }

  function updateActivityComponentsConfirmation() {

    let currentActivityItems = [...activityItems];
    for (let i = 0; i < currentActivityItems.length; i++) {
      currentActivityItems[i] = activityComponentConfirmation(i);
    }
    setActivityItemsConfirmation(currentActivityItems);
    setLoading(false);
  }

  function activityComponentConfirmation(index) {
    return (
      <div className={inputsm  ? classes.activityComponentMobile : classes.activityComponent} key={index}>
        {inputsm ? (
          // #region MOBILE
          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid className={classes.headerProps}>
              <Typography
                variant="h6"
                className={classes.activityHeader}
                style={{ color: "#FFFFFF", paddingLeft: 13, paddingTop: "5px", paddingBottom: "5px", textTransform: "uppercase", fontSize: minWidth ? "1.25rem" : "5vmin" }}
              >
                {props.t("TimeRegistration.Task")} {index + 1}
              </Typography>
            </Grid>
  
            {md ? (
              <Grid item xs={12}>
                <Table
                  size="small"
                  style={{ marginBottom: 10, paddingLeft: "10px", paddingRight: "10px", backgroundColor: "whitesmoke", border: "1px solid #e0e0e0", display: "inline-block", overflowX: "auto" }}
                >
                  <TableHead>
                    <TableRow>
                      {[props.t("TimeRegistration.Customer"), props.t("TimeRegistration.Division"), props.t("TimeRegistration.Activity"), props.t("TimeRegistration.Quantity"), props.t("TimeRegistration.Date"), props.t("TimeRegistration.StartTime"), props.t("TimeRegistration.EndTime")].map((value, index) => (
                        <TableCell align="left" style={{ fontWeight: 600, fontSize: "0.875rem", color: "rgb(0, 0, 0, 1)" }} key={value}>
                          {value}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.customersI[index]}</TableCell>
                      <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.divisionsI[index]}</TableCell>
                      <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.activitiesI[index]}</TableCell>
                      <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.amountI[index]}</TableCell>
                      <TableCell align="left">
                        {`${info.dateI[index].toLocaleDateString("en-EN", { weekday: "long" })}, ${info.dateI[index].toLocaleDateString("nl-NL")}`}
                      </TableCell>
                      <TableCell align="left">{info.paidLeaveI[index] ? null : info.startTimeI[index].toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" })}</TableCell>
                      <TableCell align="left">{info.paidLeaveI[index] ? null : info.endTimeI[index].toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" })}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                  <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4.5vmin" }}>
                  {props.t("TimeRegistration.GeneralInfo")}
                    <IconButton color="inherit" style={{ padding: "0px", paddingLeft: "5px", transform: "translateY(-2px)" }}>
                      <InfoIcon />
                    </IconButton>
                  </Typography>
                </Grid>
  
                <Grid item xs={12} style={{paddingBottom: "0px"}}>
                  <Table
                    size="small"
                    style={{ marginBottom: 0, paddingLeft: "10px", paddingRight: "10px", backgroundColor: "whitesmoke", border: "1px solid #e0e0e0", display: "inline-block", overflowX: "auto" }}
                  >
                    <TableHead>
                      <TableRow>
                        {[props.t("TimeRegistration.Customer"), props.t("TimeRegistration.Division"), props.t("TimeRegistration.Activity"), props.t("TimeRegistration.Quantity")].map((value, index) => (
                          <TableCell align="left" style={{ fontWeight: 600, fontSize: "0.875rem", color: "rgb(0, 0, 0, 1)" }} key={value}>
                            {value}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                      <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.customersI[index]}</TableCell>
                      <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.divisionsI[index]}</TableCell>
                      <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.activitiesI[index]}</TableCell>
                      <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.amountI[index]}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
  
                <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                  <Typography variant="h6" style={{ fontSize: minWidth ? "1.1rem" : "4.5vmin" }}>
                  {props.t("TimeRegistration.DateTime")}
                    <IconButton color="inherit" style={{ padding: "0px", paddingLeft: "5px", transform: "translateY(-1px)" }}>
                      <DateRangeIcon />
                    </IconButton>
                  </Typography>
                </Grid>
  
                <Grid item xs={12}>
                  <Table
                    size="small"
                    style={{ marginBottom: 10, paddingLeft: "10px", paddingRight: "10px", backgroundColor: "whitesmoke", border: "1px solid #e0e0e0" }}
                  >
                    <TableHead>
                      <TableRow>
                        {[props.t("TimeRegistration.Date"), props.t("TimeRegistration.StartTime"), props.t("TimeRegistration.EndTime")].map((value, index) => (
                          <TableCell align="left" style={{ fontWeight: 600, fontSize: "0.875rem", color: "rgb(0, 0, 0, 1)" }} key={value}>
                            {value}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          {`${info.dateI[index].toLocaleDateString("en-EN", { weekday: "long" })}, ${info.dateI[index].toLocaleDateString("nl-NL")}`}
                        </TableCell>
                        <TableCell align="left">{info.paidLeaveI[index] ? null : info.startTimeI[index].toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" })}</TableCell>
                        <TableCell align="left">{info.paidLeaveI[index] ? null : info.endTimeI[index].toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" })}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
          // #endregion
        ) :
        (
          // #region DESKTOP
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid className={classes.headerProps}>
            <Typography
              variant="h6"
              className={classes.activityHeader}
              style={{ color: "#FFFFFF", paddingLeft: 13, paddingTop: "5px", paddingBottom: "5px", textTransform: "uppercase" }}
            >
              {props.t("TimeRegistration.Task")} {index + 1}
            </Typography>
          </Grid>

          {md ? (
            <Grid item xs={12}>
              <Table
                size="small"
                style={{ marginBottom: 10, paddingLeft: "10px", paddingRight: "10px", backgroundColor: "whitesmoke", border: "1px solid #e0e0e0" }}
              >
                <TableHead>
                  <TableRow>
                    {[props.t("TimeRegistration.Customer"), props.t("TimeRegistration.Division"), props.t("TimeRegistration.Activity"), props.t("TimeRegistration.Quantity"), props.t("TimeRegistration.Date"), props.t("TimeRegistration.StartTime"), props.t("TimeRegistration.EndTime")].map((value, index) => (
                      <TableCell align="left" style={{ fontWeight: 600, fontSize: "0.875rem", color: "rgb(0, 0, 0, 1)" }} key={value}>
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.customersI[index]}</TableCell>
                    <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.divisionsI[index]}</TableCell>
                    <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.activitiesI[index]}</TableCell>
                    <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.amountI[index]}</TableCell>
                    <TableCell align="left">
                      {`${info.dateI[index].toLocaleDateString("en-EN", { weekday: "long" })}, ${info.dateI[index].toLocaleDateString("nl-NL")}`}
                    </TableCell>
                    <TableCell align="left">{info.paidLeaveI[index] ? null : info.startTimeI[index].toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" })}</TableCell>
                    <TableCell align="left">{info.paidLeaveI[index] ? null : info.endTimeI[index].toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" })}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <Typography variant="h6" style={{ fontSize: "1.1rem" }}>
                  {props.t("TimeRegistration.GeneralInfo")}
                  <IconButton color="inherit" style={{ padding: "0px", paddingLeft: "5px", transform: "translateY(-2px)" }}>
                    <InfoIcon />
                  </IconButton>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Table
                  size="small"
                  style={{ marginBottom: 10, paddingLeft: "10px", paddingRight: "10px", backgroundColor: "whitesmoke", border: "1px solid #e0e0e0" }}
                >
                  <TableHead>
                    <TableRow>
                      {[props.t("TimeRegistration.Customer"), props.t("TimeRegistration.Division"), props.t("TimeRegistration.Activity"), props.t("TimeRegistration.Quantity")].map((value, index) => (
                        <TableCell align="left" style={{ fontWeight: 600, fontSize: "0.875rem", color: "rgb(0, 0, 0, 1)" }} key={value}>
                          {value}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{backgroundColor: "#2296f3"}}>
                    <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.customersI[index]}</TableCell>
                      <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.divisionsI[index]}</TableCell>
                      <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.activitiesI[index]}</TableCell>
                      <TableCell align="left">{info.sentHomeI[index] || info.paidLeaveI[index] ? null : info.amountI[index]}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <Typography variant="h6" style={{ fontSize: "1.1rem" }}>
                {props.t("TimeRegistration.DateTime")}
                  <IconButton color="inherit" style={{ padding: "0px", paddingLeft: "5px", transform: "translateY(-1px)" }}>
                    <DateRangeIcon />
                  </IconButton>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Table
                  size="small"
                  style={{ marginBottom: 10, paddingLeft: "10px", paddingRight: "10px", backgroundColor: "whitesmoke", border: "1px solid #e0e0e0" }}
                >
                  <TableHead>
                    <TableRow>
                      {[props.t("TimeRegistration.Date"), props.t("TimeRegistration.StartTime"), props.t("TimeRegistration.EndTime")].map((value, index) => (
                        <TableCell align="left" style={{ fontWeight: 600, fontSize: "0.875rem", color: "rgb(0, 0, 0, 1)" }} key={value}>
                          {value}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        {`${info.dateI[index].toLocaleDateString("en-EN", { weekday: "long" })}, ${info.dateI[index].toLocaleDateString("nl-NL")}`}
                      </TableCell>
                      <TableCell align="left">{info.paidLeaveI[index] ? null : info.startTimeI[index].toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" })}</TableCell>
                      <TableCell align="left">{info.paidLeaveI[index] ? null : info.endTimeI[index].toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit" })}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        // #endregion
        )}
      </div>
    );
  }

  const connector = (
    <StepConnector
      classes={{
        // active: classes.connectorActive,
        completed: classes.connectorCompleted,
        disabled: classes.connectorDisabled,
        line: classes.connectorLine
      }}
    />
  );

  function getStepComponent(step) {
    switch (currentStep) {
      case 0:
        return (
          <React.Fragment>
            {inputsm ? (
              // #region MOBILE

              <React.Fragment>
              {!receivedLatestEntry ? (
                <Grid item xs={12} align="center" style={{ marginTop: 100, marginBottom: 100 }}>
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                

              <div>
              <Grid container spacing={3} style={{ marginTop: 0 }}>
              <Grid
                item
                xs={12}
                style={{
                  border: "1px solid rgba(34, 150, 243, 1.0)",
                  borderRadius: "8px",
                  backgroundColor: "#2296f30f",
                  display: "flex",
                  flexGrow: 1,
                  marginLeft: 12,
                  marginRight: 12
                }}
              >
                <Typography variant="h6" style={{fontSize: "0.9rem"}}>
                {props.t("TimeRegistration.FillOut")}
                </Typography>
              </Grid>
            </Grid>

            {activityItems}

            <Grid item xs={12} style={{ marginLeft: 0, marginTop: 30, marginBottom: "12px" }}>
              <Typography variant="caption">{fieldsRequired}</Typography>
            </Grid>

            <div>
              {countActivityItems <= 1 ? (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addActivityComponent}
                    style={{ width: "100%", marginLeft: 0 }}
                    className="no-shadow button-padding"
                  >
                    {props.t("TimeRegistration.AddActivity")}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} style={{display: "flex"}}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addActivityComponent}
                    style={{ width: "70%", marginLeft: 0 }}
                    className="no-shadow button-padding"
                  >
                    {props.t("TimeRegistration.AddActivity")}
                  </Button>

                  <Button
                    variant="contained"
                    onClick={removeActivityComponent}
                    style={{ width: "30%", marginLeft: "16px", marginRight: "0px", backgroundColor: "rgb(245, 245, 245)"}}
                    className="no-shadow button-padding"
                  >
                    {props.t("TimeRegistration.Delete")}
                  </Button>
                </Grid>
              )}
            </div>
            </div>
              )}
              </React.Fragment>
            // #endregion
            ) : 
            (
              //#region DESKTOP
              <React.Fragment>
              {!receivedLatestEntry ? (
                <Grid item xs={12} align="center" style={{ marginTop: 100, marginBottom: 100 }}>
                  <div style={{ position: "relative" }}>
                    <CircularProgress />
                  </div>
                </Grid>
              ) : (
                

            <div>
               <Grid container spacing={3} style={{ marginTop: 15 }}>
               <Grid
                item
                xs={12}
                style={{
                  border: "1px solid rgba(34, 150, 243, 1.0)",
                  borderRadius: "8px",
                  backgroundColor: "#2296f30f",
                  display: "flex",
                  flexGrow: 1,
                  marginLeft: 12,
                  marginRight: 12
                }}
              >
                <Typography variant="h6" className={classes.remarkPadding}>
                {props.t("TimeRegistration.FillOut")}
                </Typography>
              </Grid>
            </Grid>

            {activityItems}

            <Grid item xs={12} style={{ marginLeft: 0, marginTop: 30, marginBottom: "12px" }}>
              <Typography variant="caption">{fieldsRequired}</Typography>
            </Grid>

            <div>
              {countActivityItems <= 1 ? (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addActivityComponent}
                    style={{ width: "100%", marginLeft: 0 }}
                    className="no-shadow button-padding"
                  >
                    {props.t("TimeRegistration.AddActivity")}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} style={{display: "flex"}}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addActivityComponent}
                    style={{ width: "70%", marginLeft: 0 }}
                    className="no-shadow button-padding"
                  >
                    {props.t("TimeRegistration.AddActivity")}
                  </Button>

                  <Button
                    variant="contained"
                    onClick={removeActivityComponent}
                    style={{ width: "30%", marginLeft: "16px", marginRight: "0px", backgroundColor: "rgb(245, 245, 245)"}}
                    className="no-shadow button-padding"
                  >
                    {props.t("TimeRegistration.Delete")}
                  </Button>
                </Grid>
              )}
            </div>
            </div>

              )}
              </React.Fragment>

            // #endregion
            )}
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            {inputsm ? (
              //#region MOBILE
              <div>
              <Grid container spacing={3} style={{ marginTop: 0, marginBottom: "0px" }}>
              <Grid
                item
                xs={12}
                style={{
                  border: "1px solid rgba(34, 150, 243, 1.0)",
                  borderRadius: "8px",
                  backgroundColor: "#2296f30f",
                  display: "flex",
                  flexGrow: 1,
                  marginLeft: 12,
                  marginRight: 12
                }}
              >
                <Typography variant="h6" style={{fontSize: "0.9rem"}}>
                {props.t("TimeRegistration.VerifyShort")}
                </Typography>
              </Grid>
            </Grid>

            <Grid container style={{ marginBottom: 25, marginTop: 0 }}>
              <Grid item xs={12}>
                <div>{activityItemsConfirmation}</div>
              </Grid>
            </Grid>
            </div>
            // #endregion
            ) :
            (
              // #region DESKTOP
              <div>
            <Grid container spacing={3} style={{ marginTop: 15 }}>
              <Grid
                item
                xs={12}
                style={{
                  border: "1px solid rgba(34, 150, 243, 1.0)",
                  borderRadius: "8px",
                  backgroundColor: "#2296f30f",
                  display: "flex",
                  flexGrow: 1,
                  marginLeft: 12,
                  marginRight: 12
                }}
              >
                <Typography variant="h6" className={classes.remarkPadding} style={{ paddingLeft: 13 }}>
                {props.t("TimeRegistration.VerifyLong")}
                </Typography>
              </Grid>
            </Grid>

            <Grid container style={{ marginBottom: 25, marginTop: 15 }}>
              <Grid item xs={12}>
                <div>{activityItemsConfirmation}</div>
              </Grid>
            </Grid>
            </div>
            // #endregion
            )}
          </React.Fragment>
        );
    }
  }

  if (redirectDashboard) {
    return <Redirect to={"/Home"} style={{ textDecoration: "none" }} key={"ToHomeDashboard"} />;
  } else {
    return (
      <div>
        {launchPage && props.userDataProp.role==="Admin" ? (
          launchPageComponent()
        ) : (
          <div className={props.userDataProp.role==="Admin" ? classes.paperPosition : (inputsm ? classes.paperPositionUserMobile : classes.paperPositionUser)}>
            {inputsm ? (
              // #region MOBILE
              <ThemeProvider theme={theme}>
              <Paper
                className={classes.paperPropsMobile}
                classes={{
                  rounded: classes.paperShape,
                  elevation1: classes.paperShadow
                }}
              >
                <Stepper activeStep={currentStep} alternativeLabel connector={connector} classes={{root: classes.stepperRoot}}>
                  {steps.map(i => (
                    <Step key={i}>
                      <StepLabel>{i}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {currentStep >= steps.length ? (
                  loading ? (
                    <React.Fragment>
                      <Grid item xs={12} align="center" style={{ marginTop: 100, marginBottom: 100 }}>
                        <div style={{ position: "relative" }}>
                          <CircularProgress />
                        </div>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{
                            border: "1px solid rgba(34, 150, 243, 1.0)",
                            borderRadius: "8px",
                            backgroundColor: "#2296f30f",
                            display: "flex",
                            flexGrow: 1,
                            marginLeft: 0,
                            marginRight: 0,
                            marginBottom: 0
                          }}
                        >
                          <Grid item xs={12} align="center">
                            <Typography variant="h6" style={{ paddingTop: 25, paddingBottom: 25, fontSize: minWidth ? "1.25rem" : "3.75vmin" }}>
                            {props.t("TimeRegistration.Submitted")}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} align="center" style={{ marginTop: 16 }}>
                          <Link to="/Timesheet" style={{ textDecoration: "none", color: "#0000001f" }}>
                            <Button  fullWidth variant="contained" color="primary" className="no-shadow button-padding" disabled={loading} onClick={event => setRedirectDashboard(true)}>
                            {props.t("TimeRegistration.ToDashboard")}
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    {getStepComponent(currentStep)}
                    <div>
                      <div style={{marginTop: "32px", display: "flex", position: "relative" }}>
                        {currentStep === 0 ? (
                          <Link to="/Timesheet" style={{ textDecoration: "none", display: "flex", width: "50%" }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{width: "100%"}}
                              disabled={loading}
                              onClick={event => setRedirectDashboard(true)}
                            >
                              {props.t("TimeRegistration.Cancel")}
                              <ClearIcon style={{ paddingBottom: 2}} />
                            </Button>
                          </Link>
                        ) : null}

                      {currentStep !== 0 && (
                        <Button variant="outlined" onClick={Previous} color="primary" style={{width: "50%"}}>
                          {props.t("TimeRegistration.Previous")}
                        </Button>
                      )}

                        <Button variant="contained" color="primary" onClick={async () => await Next()} disabled={loading} style={{width: "50%", marginLeft: "16px"}} className="no-shadow button-padding">
                          {currentStep === steps.length - 1 ? props.t("TimeRegistration.Submit") : props.t("TimeRegistration.Next")}
                          <KeyboardArrowRightIcon style={{ paddingBottom: 2 }} />
                          {loading && <CircularProgress size={24} style={{top: "17%", left: "42%", position: "absolute"}} />}
                        </Button>
                        
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Paper>
            </ThemeProvider>
            // #endregion
            ) : 
            (
              // #region DESKTOP
            <ThemeProvider theme={theme}>
              <Paper
                className={classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                  elevation1: classes.paperShadow
                }}
              >
                <Stepper activeStep={currentStep} alternativeLabel connector={connector}>
                  {steps.map(i => (
                    <Step key={i}>
                      <StepLabel>{i}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {currentStep >= steps.length ? (
                  loading ? (
                    <React.Fragment>
                      <Grid item xs={12} align="center" style={{ marginTop: 100, marginBottom: 100 }}>
                        <div style={{ position: "relative" }}>
                          <CircularProgress />
                        </div>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{
                            border: "1px solid rgba(34, 150, 243, 1.0)",
                            borderRadius: "8px",
                            backgroundColor: "#2296f30f",
                            display: "flex",
                            flexGrow: 1,
                            marginLeft: 12,
                            marginRight: 12,
                            marginBottom: 35
                          }}
                        >
                          <Grid item xs={12} align="center">
                            <Typography variant="h6" style={{ paddingTop: 25, paddingBottom: 25 }}>
                            {props.t("TimeRegistration.Submitted")}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} align="center" style={{ marginTop: 20 }}>
                          <Link to="/Timesheet" style={{ textDecoration: "none", color: "#0000001f" }}>
                            <Button variant="contained" color="primary" className="no-shadow button-padding" disabled={loading} onClick={event => setRedirectDashboard(true)}>
                            {props.t("TimeRegistration.ToDashboard")}
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    {getStepComponent(currentStep)}
                    {/* <div className={classes.buttons}>
                      {currentStep !== 0 && (
                        <Button variant="outlined" color="primary" onClick={Previous} className={classes.button}>
                          Previous
                        </Button>
                      )}
                      <div style={{ position: "relative" }}>
                        {currentStep === 0 ? (
                          <Link to="/Timesheet" style={{ textDecoration: "none", color: "#0000001f" }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                              disabled={loading}
                              onClick={event => setRedirectDashboard(true)}
                            >
                              BACK TO DASHBOARD
                            </Button>
                          </Link>
                        ) : null}

                        <Button variant="contained" color="primary" onClick={Next} className={classes.button} disabled={loading}>
                          {currentStep === steps.length - 1 ? "Confirm & submit" : "Next"}
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                    </div> */}

                      <div>
                      
                      <div style={{marginTop: "32px", display: "flex", position: "relative" }}>
                        {currentStep === 0 ? (
                          <Link to="/Timesheet" style={{ textDecoration: "none", marginLeft: "auto"}}>
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{width: "100%"}}
                              disabled={loading}
                              onClick={event => setRedirectDashboard(true)}
                            >
                              {props.t("TimeRegistration.Cancel")}
                              <ClearIcon style={{ paddingBottom: 2}} />
                            </Button>
                          </Link>
                        ) : null}

                      {currentStep !== 0 && (
                        <Button variant="outlined" onClick={Previous} color="primary" style={{marginLeft: "auto"}}>
                          {props.t("TimeRegistration.Previous")}
                        </Button>
                      )}

                        <Button variant="contained" color="primary" onClick={Next} disabled={loading} style={{marginLeft: "16px", marginRight: "0px"}} className="no-shadow button-padding">
                          {currentStep === steps.length - 1 ? props.t("TimeRegistration.Submit") : props.t("TimeRegistration.Next")}
                          <KeyboardArrowRightIcon style={{ paddingBottom: 2 }} />
                          {loading && <CircularProgress size={24} style={{top: "17%", left: "42%", position: "absolute"}} />}
                        </Button>
                        
                      </div>
                    </div>


                  </React.Fragment>
                )}
              </Paper>
            </ThemeProvider>
            // #endregion
            )}
          </div>
        )}
        <div>
          <CustomSnackbar showSnackbarProp={showSnackbar} closeSnackbarCallbackProp={closeSnackbarCallback} snackbarMessageProp={snackbarMessage} autoHideDurationProp={autoHideDuration}/>
        </div>
        <div>
          <CustomSnackbarError showSnackbarProp={showErrorSnackbar} closeSnackbarCallbackProp={closeSnackbarCallback} snackbarMessageProp={snackbarErrorMessage} autoHideDurationProp={autoHideDuration}/>
        </div>
      </div>
    );
  }
}

const bgColor = "#E4E6ED";
const drawerWidth = 250;
const minToolbarHeight = 64;

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] },
    secondary: { main: "#f3b147" },
    inherit: {main: "#5022f3"}
  }
});

const useStyles = makeStyles(theme => ({
  // root: {
  //   backgroundColor: blue[500]
  // },
  iconButtonStyle: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },

  switchTrack: {
    backgroundColor: "#f5b042",
    opacity: 1,
    transition: theme.transitions.create(["opacity", "background-color"], {
      duration: theme.transitions.duration.shortest
    })
  },

  switchBase: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1, // Render above the focus ripple.
    color: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[400],
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    "&$checked": {
      transform: "translateX(50%)"
    },
    "&$disabled": {
      color: theme.palette.type === "light" ? theme.palette.grey[400] : theme.palette.grey[800]
    },
    // '&$checked + $track': {
    //   opacity: 0.5,
    // },

    color: purple[300],
      "&$checked": {
        color: purple[500]
      },
      "&$checked + $track": {
        backgroundColor: purple[500]
      },
    "&$disabled + $track": {
      opacity: theme.palette.type === "light" ? 0.12 : 0.1
    }
  },

  switchBaseNew: {
    // color: purple[300],
    "&$checked": {
      color: purple[500]
    },
    "&$checked + $track": {
      backgroundColor: purple[500]
    }
  },

  headerProps: {
    border: "1px solid rgba(34, 150, 243, 1.0)",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    backgroundColor: "#2296f3",
    display: "flex",
    flexGrow: 1
  },

  launchPage: {
    paddingLeft: "40px",
    paddingTop: `calc(${minToolbarHeight}px + 40px)`,

    [theme.breakpoints.up("sm")]: {
      paddingLeft: `calc(${drawerWidth}px + 100px)`,
      paddingTop: `calc(${minToolbarHeight}px + 40px)`
    }
  },

  background: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "200%",
    height: "100vh",

    [theme.breakpoints.up("sm")]: {
      backgroundSize: "cover",
      width: "195%"
    },

    [theme.breakpoints.up("md")]: {
      width: "130%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%"
    }
  },

  registerText: {
    marginRight: "60%",
    fontSize: "3.0rem",

    [theme.breakpoints.up("sm")]: {
      marginRight: "60%",
      fontSize: "3.0rem"
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "30%",
      fontSize: "3.4rem"
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "0%",
      fontSize: "3.75rem"
    }
  },

  timeText: {
    fontSize: "1.2rem",

    [theme.breakpoints.up("sm")]: {
      marginRight: "50%",
      fontSize: "1.2rem"
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "60%",
      fontSize: "1.35rem"
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "0%",
      fontSize: "1.5rem"
    }
  },

  borderMargin: {
    width: "30%",
    [theme.breakpoints.up("sm")]: {
      width: "30%"
    },
    [theme.breakpoints.up("md")]: {
      width: "40%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%"
    }
  },

  paperPositionUser: {
    marginLeft: "32px",
    marginRight: "32px",
    marginTop: `calc(${minToolbarHeight}px + 32px)`,
    marginBottom: "32px",
  },

  paperPositionUserMobile: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: `calc(${minToolbarHeight}px + 16px)`,
    marginBottom: "16px",
  },

  paperPosition: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    marginBottom: "16px",

    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth + 32,
      marginRight: "32px",
      marginTop: "32px",
      marginBottom: "32px",
    }
  },
  paperShape: {
    borderRadius: 8
  },
  paperProps: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3)
  },
  paperPropsMobile: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3)
    }
  },

  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },

  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },

  buttonMobile: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },

  remarkPadding: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },

  mobileStepper: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },

  mobileStepperRoot: {
    background: "transparent"
  },

  mobileStepperProgress: {
    width: "100%"
  },
  // connectorActive: {
  //   '& $connectorLine': {
  //     borderColor: theme.palette.secondary.main,
  //   },
  // },
  connectorCompleted: {
    "& $connectorLine": {
      borderColor: "#2196F3"
    }
  },
  connectorDisabled: {
    "& $connectorLine": {
      borderColor: theme.palette.grey[100]
    }
  },
  connectorLine: {
    transition: theme.transitions.create("border-color")
  },
  timePickers: {
    flexGrow: 1,
    maxWidth: "100%"
  },
  gridContainer: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "8px"
    // padding: "40px 14px"
  },
  activityComponent: {
    marginTop: 50,
    marginLeft: 12,
    marginRight: 12
  },
  activityComponentMobile: {
    marginTop: 30,
    marginLeft: 12,
    marginRight: 12
  },
  activityHeader: {
    paddingBottom: 5
  },
  dividerProps: {
    marginTop: 10,
    marginBottom: 35
  },
  textFieldSubmitProps: {
    backgroundColor: "#F3F3F3",
    borderRadius: 4
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "45%"
  },
  efficiencyProgressText: {
    justifyContent: "center",
    display: "flex",
    position: "absolute",
    width: "101%",
    top: "40%"
  },
  containerBottom: {
    marginBottom: 10
  },
  stepperRoot:{
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: "12px"
  },

  root2: {},
  thumb2: {},
  track2: {},
  checked2: {}
}));

export default withRouter(Timesheet);
