/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
import DateFnsUtils from "@date-io/date-fns";
import { Button, createMuiTheme, Divider, Grid, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography, useMediaQuery, Zoom } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Close from "@material-ui/icons/Close";
import Done from "@material-ui/icons/Done";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import CustomSnackbar from "./CustomSnackbar";
import Alert from '@material-ui/lab/Alert';
import "./MyAccount.css"

function MyAccount(props) {
  props.headerNameProp(props.t("MyAccount.MyAccountText"));
  const autoHideDuration = 2500;
  const classes = useStyles(props);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
    showPassword: false
  });
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [editFields, setEditFields] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const inputsm = useMediaQuery(theme.breakpoints.down("sm"));


  const [fields, setFields] = useState({
    firstName: props.userDataProp.firstName,
    lastName: props.userDataProp.lastName,
    email: props.userDataProp.email,
    dob: props.userDataProp.dob,
    userName: props.userDataProp.userName,
    role: props.userDataProp.role,
  });

  useEffect(() => {
    const item = sessionStorage?.getItem("userData")
    if(item) {
      const parsed = JSON.parse(item)
      const keys = Object.keys(parsed)
      if(keys.length > 0 
        && keys.includes("firstName")
        && keys.includes("lastName")
        && keys.includes("email")
        && keys.includes("dob")
        && keys.includes("userName")
        && keys.includes("role")
      ) {
        setFields({
          firstName: parsed.firstName,
          lastName: parsed.lastName,
          email: parsed.email,
          dob: parsed.dob,
          userName: parsed.userName,
          role: parsed.role,
        })
      }
    }
  }, [])

  function handleClickShowPassword() {
    setPasswordData({ ...passwordData, showPassword: !passwordData.showPassword });
  }

  function handleFieldChange(event, name) {
    setPasswordData({ ...passwordData, [name]: event.target.value });
  }

  function handleEditFieldChange(event, name) {
    setFields({ ...fields, [name]: event.target.value.trim() });
  }

  function handleDateChange(event, date) {
    setFields({ ...fields, dob: date });
  }

  function handleAccept(event)
  {
    accountPOST();
  }

  function handleDecline(event)
  {
    setFields({
      firstName: props.userDataProp.firstName,
      lastName: props.userDataProp.lastName,
      email: props.userDataProp.email,
      dob: props.userDataProp.dob,
      userName: props.userDataProp.userName,
    });

    setEditFields(!editFields);
    setShowErrorMessage(false);
  }

  function closeSnackbarCallback() {
    setShowSnackbar(false);
  }

  async function accountPOST() {
    if (checkErrorMessagesAccount()) {
      return;
    }

    const address = props.baseAddressProp + "/Authentication" + "/ModifyAccountData";
    try {
      let result = await Axios.post(address, {
        UserName: fields.userName,
        FirstName: fields.firstName,
        LastName: fields.lastName,
        DateOfBirth: fields.dob,
        Email: fields.email
      });

      let data = props.userDataProp;
      data.userName = fields.userName;
      data.firstName = fields.firstName;
      data.lastName = fields.lastName;
      data.dob = fields.dob;
      data.email = fields.email;
      props.userDataCallbackProp(data);

      setSnackbarMessage(props.t("TimeRegistration.Snackbar"));
      setShowSnackbar(true)
      setSuccessful(true);
      setEditFields(!editFields);
    } catch (error) {
      setSuccessful(false);
      //setShowErrorMessage(true);
      //setLoading(false);
    }
  }

  function checkErrorMessagesAccount()
  {
    //new account error checks

    for (var i in fields) {
      if (fields[i] === "") {
        setErrorMessage(props.t("RegisterPage.Error1"));
        setSuccessful(false);
        setShowErrorMessage(true);
        return true;
      }
    }

    if (fields["userName"].length > 15) {
      setErrorMessage(props.t("RegisterPage.Error2"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (
      !new RegExp(
        /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/
      ).test(fields["email"])
    ) {
      setErrorMessage(props.t("RegisterPage.ValidEmailAddress"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (fields["firstName"].length > 35) {
      setErrorMessage(props.t("RegisterPage.Error8"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (fields["lastName"].length > 35) {
      setErrorMessage(props.t("RegisterPage.Error9"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    setShowErrorMessage(false);
  }

  function checkErrorMessages() {
    for (var i in passwordData) {
      if (passwordData[i] === "") {
        setErrorMessage(props.t("MyAccount.Error1"));
        setSuccessful(false);
        setShowErrorMessage(true);
        return true;
      }
    }

    if (passwordData["newPassword1"].length <= 7) {
      setErrorMessage(props.t("MyAccount.Error2"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (!new RegExp(/[0-9]/).test(passwordData["newPassword1"])) {
      setErrorMessage(props.t("MyAccount.Error3"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (!new RegExp(/[A-Z]/).test(passwordData["newPassword1"])) {
      setErrorMessage(props.t("MyAccount.Error4"));
      setShowErrorMessage(true);
      return true;
    }

    if (!new RegExp(/[!#@$%^&*)(+=._-]/).test(passwordData["newPassword1"])) {
      setErrorMessage(props.t("MyAccount.Error5"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    if (passwordData.newPassword1 !== passwordData.newPassword2) {
      setErrorMessage(props.t("MyAccount.Error6"));
      setSuccessful(false);
      setShowErrorMessage(true);
      return true;
    }

    setShowErrorMessage(false);
    return false;
  }

  async function changePasswordPUT() {
    if (checkErrorMessages()) {
      return;
    }

    const address = props.baseAddressProp + "/Authentication" + "/ChangePassword";
    try {
      await Axios.put(address, {
        UserName: props.userDataProp.userName,
        OldPassword: passwordData.oldPassword,
        NewPassword: passwordData.newPassword1
      });
      setSnackbarMessage(props.t("ResetPassword.PasswordChanged"));
      setShowSnackbar(true)
      setSuccessful(true);
      setShowPasswordFields(false)
    } catch (error) {
      setSuccessful(false);
    }
  }

  function insertPasswordComponent() {
    return (
      <React.Fragment>
        <Grid item xs={12} style={{ marginTop: 30, marginBottom: 10 }}>
          <Typography variant="button">{props.t("MyAccount.CreateNewPassword")}</Typography>
        </Grid>
        <Divider style={{ marginBOttom: 20 }} />
        <Grid item xs={12}>
          <TextField
            id="oldPassword"
            variant="outlined"
            type={passwordData.showPassword ? "text" : "password"}
            label={props.t("MyAccount.OldPassword")}
            fullWidth
            value={passwordData.oldPassword}
            margin={inputsm ? "dense" : "normal"}
            onChange={event => handleFieldChange(event, "oldPassword")}
            style={{ marginBottom: inputsm ? "12px" : "8px", marginTop: inputsm ? "12px" : "8px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                    {passwordData.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="newPassword1"
            variant="outlined"
            type={passwordData.showPassword ? "text" : "password"}
            label={props.t("MyAccount.NewPassword")}
            fullWidth
            value={passwordData.newPassword1}
            margin={inputsm ? "dense" : "normal"}
            onChange={event => handleFieldChange(event, "newPassword1")}
            style={{ marginBottom: inputsm ? "12px" : "8px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                    {passwordData.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="newPassword2"
            variant="outlined"
            type={passwordData.showPassword ? "text" : "password"}
            label={props.t("MyAccount.ConfirmNewPassword")}
            fullWidth
            value={passwordData.newPassword2}
            margin={inputsm ? "dense" : "normal"}
            onChange={event => handleFieldChange(event, "newPassword2")}
            style={{ marginBottom: inputsm ? "12px" : "8px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" aria-label="Toggle password visibility" onClick={event => handleClickShowPassword(event)}>
                    {passwordData.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12} className="requirements">
          <Typography variant="caption">
            <em>
            {props.t("MyAccount.Requirement1")} <br />
            {props.t("MyAccount.Requirement2")} <br />
            {props.t("MyAccount.Requirement3")} <br />
            {props.t("MyAccount.Requirement4")}
              <br />
            </em>
          </Typography>
        </Grid>

        

        {inputsm ? (
          <Grid item xs={12} style={{display: "flex"}}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: inputsm ? 0 : 15, marginRight: 15, display: "inline-block" }}
              className="no-shadow button-padding"
              onClick={event => changePasswordPUT()}
            >
              {props.t("MyAccount.ApplyChanges")}
            </Button>
            {props.userDataProp.role === "User" ? (
              <Link to="/Home" style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="primary" style={{ marginTop: 0, display: "inline-block", float: "right" }}>
                {props.t("MyAccount.Back")}
                </Button>
              </Link>
            ) : null}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Button 
              variant="contained" 
              color="primary" 
              style={{ marginTop: inputsm ? 0 : 15, marginRight: 15 }}
              className="no-shadow button-padding"
              onClick={event => changePasswordPUT()}>
            {props.t("MyAccount.ApplyChanges")}
            </Button>
            {props.userDataProp.role === "User" ? (
              <Link to="/Home" style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="primary" style={{ marginTop: 15 }}>
                {props.t("MyAccount.BackToDashboard")}
                </Button>
              </Link>
            ) : null}
          </Grid>
        )}
      </React.Fragment>
    );
  }

  return (
    <div>
      {/* <div className={classes.backgroundUser}> */}
      <ThemeProvider theme={theme}>
        <div className={props.userDataProp.role === "User" ? classes.launchPageUser : classes.launchPageUserAdmin}>
          {/* <Typography variant="h4" style={{ marginBottom: 75 }}>
            Hello, {props.userDataProp.firstName}!
          </Typography> */}
          <Grid container>
            <Grid item xs={12}>
              <Paper
                className={inputsm ? classes.paperPropsMobile : classes.paperProps}
                classes={{
                  rounded: classes.paperShape,
                }}
              >
                <Typography variant="h6" style={{ marginBottom: 5 }}>
                {props.t("MyAccount.MyAccountText")}
                  <IconButton color="inherit" style={{top: "-2px"}}>
                    <AccountCircle />
                  </IconButton>

                  {editFields ? 
                  (
                    <React.Fragment>
                      <Tooltip title={props.t("Database.Decline").toLowerCase().charAt(0).toUpperCase() + props.t("Database.Decline").toLowerCase().substring(1)} TransitionComponent={Zoom}>
                        <IconButton color="inherit" style={{top: "-2px", float: "right", color: "#2196f3"}} 
                          onClick={(event) => {handleDecline(event)}}>
                          <Close />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={props.t("Database.Accept").toLowerCase().charAt(0).toUpperCase() + props.t("Database.Accept").toLowerCase().substring(1)} TransitionComponent={Zoom}>
                        <IconButton color="inherit" style={{top: "-2px", float: "right", color: "#2196f3"}} 
                          onClick={(event) => {handleAccept(event)}}>
                          <Done />
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  )
                  :
                  (<React.Fragment>
                    {fields.userName !== "admin" && (
                    <Tooltip title={props.t("HomeUser.Edit")} TransitionComponent={Zoom}>
                      <IconButton color="inherit" style={{top: "-2px", float: "right", color: "#2196f3"}} 
                        onClick={(event) => {setEditFields(!editFields);}}>
                        <Edit />
                      </IconButton>
                    </Tooltip>)}
                  </React.Fragment>
                  )}

                  
                </Typography>

                <Divider style={{ marginBottom: 20 }} />
                { fields.userName == "admin" && (
                  <Alert severity="info" style={{marginBottom: "10px"}}>
                    <div>
                      <em>{props.t("MyAccount.AdminCannotBeChanged")}</em>
                    </div>
                  </Alert>)
                }
                <TextField
                  inputProps={{ readOnly: !editFields, disabled: !editFields }}
                  id="firstName"
                  label={props.t("MyAccount.FirstName")}
                  value={fields.firstName}
                  margin={inputsm ? "dense" : "normal"}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: inputsm ? "12px" : "8px", backgroundColor: editFields ? "#FFFFFF" : "#F3F3F3", borderRadius: 4}}
                  onChange={event => handleEditFieldChange(event, "firstName")}
                />

                <TextField
                  inputProps={{ readOnly: !editFields, disabled: !editFields }}
                  id="lastName"
                  label={props.t("MyAccount.LastName")}
                  value={fields.lastName}
                  margin={inputsm ? "dense" : "normal"}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: inputsm ? "12px" : "8px", backgroundColor: editFields ? "#FFFFFF" : "#F3F3F3", borderRadius: 4}}
                  onChange={event => handleEditFieldChange(event, "lastName")}
                />

                <TextField
                  inputProps={{ readOnly: !editFields, disabled: !editFields }}
                  id="email"
                  label={props.t("Database.EmailAddress")}
                  value={fields.email}
                  margin={inputsm ? "dense" : "normal"}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: inputsm ? "12px" : "8px", backgroundColor: editFields ? "#FFFFFF" : "#F3F3F3", borderRadius: 4}}
                  onChange={event => handleEditFieldChange(event, "email")}
                />

                {editFields ?
                (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={12} classes={{ root: classes.timePickers }}>
                          <KeyboardDatePicker
                            autoOk
                            variant="outlined"
                            inputVariant="outlined"
                            format="yyyy/MM/dd"
                            fullWidth
                            margin={inputsm ? "dense" : "normal"}
                            id="mui-pickers-date"
                            label={props.t("RegisterPage.Dob")}
                            value={fields.dob}
                            onChange={(event, date) => handleDateChange(event, date)}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                ) :
                (
                  <TextField
                  inputProps={{ readOnly: !editFields, disabled: !editFields }}
                  id="dob"
                  label={props.t("RegisterPage.Dob")}
                  value={fields.dob}
                  margin={inputsm ? "dense" : "normal"}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: inputsm ? "12px" : "8px", backgroundColor: editFields ? "#FFFFFF" : "#F3F3F3", borderRadius: 4}}
                />
                )}

                <TextField
                  inputProps={{ readOnly: false, disabled: true }}
                  id="userName"
                  label={props.t("MyAccount.UserName")}
                  value={fields.userName}
                  margin={inputsm ? "dense" : "normal"}
                  variant="outlined"
                  fullWidth
                  className={classes.textFieldSubmitProps}
                  style={{ marginBottom: inputsm ? "12px" : "8px" }}
                />

                <TextField
                  inputProps={{ readOnly: false, disabled: true }}
                  id="role"
                  label={props.t("MyAccount.Role")}
                  value={fields.role}
                  margin={inputsm ? "dense" : "normal"}
                  variant="outlined"
                  fullWidth
                  className={classes.textFieldSubmitProps}
                  style={{ marginBottom: inputsm ? "12px" : "8px" }}
                />

                {showPasswordFields ? (
                  insertPasswordComponent()
                ) : (
                  //   <Link to="/Timesheet" style={{ textDecoration: "none", color: "#0000001f" }}>
                  //   <Button variant="contained" color="primary" disabled={loading} onClick={event => setRedirectDashboard(true)}>
                  //     BACK TO DASHBOARD
                  //   </Button>
                  // </Link>

                  <div>
                    {inputsm ? (
                      <Grid item xs={12}>
                        {fields.userName !== "admin" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginTop: 15, marginRight: 15}}
                          className="no-shadow button-padding"
                          onClick={event => setShowPasswordFields(true)}
                        >
                          {props.t("MyAccount.ChangePassword")}
                        </Button>) : null}
                        {props.userDataProp.role === "User" ? (
                          <Link to="/Home" style={{ textDecoration: "none" }}>
                            <Button variant="outlined" color="primary" style={{ marginTop: 15}}>
                            {props.t("MyAccount.Back")}
                            </Button>
                          </Link>
                        ) : null}
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        {fields.userName != "admin" ? (
                        <Button 
                          variant="contained" 
                          color="primary" 
                          style={{ marginTop: 15, marginRight: 15 }}
                          className="no-shadow button-padding" 
                          onClick={event => setShowPasswordFields(true)}>
                        {props.t("MyAccount.ChangePassword")}
                        </Button>) : null}
                        {props.userDataProp.role === "User" ? (
                          <Link to="/Home" style={{ textDecoration: "none" }}>
                            <Button variant="outlined" color="primary" style={{ marginTop: 15 }}>
                            {props.t("MyAccount.BackToDashboard")}
                            </Button>
                          </Link>
                        ) : null}
                      </Grid>
                    )}
                  </div>
                )}

                  <Grid item xs={12} style={{marginTop: "20px"}}>
                  {showErrorMessage ? (
                    <Alert severity="error">
                      <em>
                        <b>{props.t("MyAccount.Error")} </b>
                        {errorMessage}
                      </em>
                    </Alert>
                  ) : successful ? (

                    <div>
                    <CustomSnackbar showSnackbarProp={showSnackbar} closeSnackbarCallbackProp={closeSnackbarCallback} snackbarMessageProp={snackbarMessage} autoHideDurationProp={autoHideDuration}/>
                    </div>
                  ) : null}
                </Grid>

              </Paper>
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default withRouter(MyAccount);

const drawerWidth = 250;

const theme = createMuiTheme({
  palette: {
    primary: { main: blue[500] },
    secondary: { main: "#9f9f9f" }
  }
});

const useStyles = makeStyles(theme => ({
  textFieldSubmitProps: {
    backgroundColor: "#F3F3F3",
    borderRadius: 4
  },
  registerText: {
    marginRight: "60%",
    fontSize: "3.0rem"
  },
  timeText: {
    fontSize: "1.2rem",
    color: "#FFFFFF"
  },

  paperRoot: {
    backgroundColor: blue[500]
  },

  paperShape: {
    borderRadius: 8
  },
  paperProps: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  paperPropsMobile: {
    padding: "16px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3)
    }
  },

  launchPageUser: {
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "16px",
    paddingTop: 64 + 16,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "32px",
      marginRight: "32px",
      marginTop: "32px",
      paddingTop: "64px"
    }
  },
  launchPageUserAdmin: {
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "16px",
    paddingTop: 64 + 16,

    [theme.breakpoints.up("lg")]: {
      marginLeft: drawerWidth + 32,
      marginRight: "32px",
      marginTop: "32px",
      paddingTop: "64px"
    }
  }
}));
